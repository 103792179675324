import { FC } from 'react';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Box, Breadcrumbs, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Element } from 'react-scroll';
import { MedicalWebPage } from 'schema-dts';

import { RichText } from 'shared/src//contentful-text/rich-text-renderer';
import healthmatchLogo from 'shared/src/assets/healthmatch-logo-primary.svg';
import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { Link } from 'shared/src/link/link';
import { ShareSocialIcons } from 'shared/src/social-icons/social-icons';
import { notEmpty } from 'shared/src/util/not-empty';

import { ArticleContentMenu } from 'components/article-content-menu/article-content-menu';
import { ArticleRichText } from 'components/article-rich-text/article-rich-text';
import { ArticleMenuCta } from 'components/article-sticky-menu/article-menu-cta/article-menu-cta';
import {
  ArticleStickyMenu,
  isSectionHeading,
} from 'components/article-sticky-menu/article-sticky-menu';
import {
  ArticleLink,
  ContentLinks,
  normalizeRelatedArticles,
} from 'components/content-links/content-links';
import { ContentMeta } from 'components/content-meta/content-meta';
import { ContentThemeProvider } from 'components/content-theme/content-theme';
import { getRedirectIfExists } from 'components/contentful-redirect/contentful-redirect';
import { FootNotes } from 'components/foot-notes/foot-notes';
import { FooterWaveBleedMode } from 'components/footer/footer';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { CanonicalUrl, OgArticleType, OgImage } from 'components/seo/meta-data-fragments';
import { useFaqSchemaFromRichText } from 'components/seo/use-faq-schema-from-rich-text';
import { EmbeddedQuestionnaire } from 'pages/condition-article/embedded-questionnaire/embedded-questionnaire';
import { ExploreMore } from 'pages/condition-article/explore-more/explore-more';
import { NotFound } from 'pages/not-found/not-found';

import { ConditionArticle, useConditionArticle } from './queries.contentful';

const heroImageMaxWidth = 720;

const useStyles = makeStyles(({ spacing, shape, palette, typography, breakpoints }) => ({
  image: {
    width: '100%',
    borderRadius: shape.borderRadius,
    marginBottom: spacing(5),
    maxWidth: heroImageMaxWidth,
  },
  sharePost: {
    color: palette.text.secondary,
    '&& a:hover': {
      color: palette.primary.main,
    },
  },
  faqTitle: {
    fontSize: typography.pxToRem(55),
  },
  shownOnMobile: {
    display: 'none',
    [breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

const overviewSectionName = 'Overview';

const conditionArticleFooterBleedConfig: FooterWaveBleedMode = {
  kind: 'background',
  // not sure why this isn't a standard gray, have to revisit...
  color: '#f3f3f3',
};

export const ConditionArticlePage: FC<{
  entry: ConditionArticle;
  editorsPicks: ArticleLink[];
  latestNews: ArticleLink[];
  orphanArticles: ArticleLink[];
}> = ({ entry, editorsPicks, latestNews, orphanArticles }) => {
  di(useLocation, ExploreMore, PageBase);

  const classes = useStyles();
  const { pathname } = useLocation();

  const { author, medicalReviewer, textBody, title, heroImage, references, category, faqs } = entry;

  const faqPageSchema = useFaqSchemaFromRichText(faqs?.json);

  const relatedArticles = normalizeRelatedArticles(
    entry.category?.relatedConditionsCollection,
    entry.sys.id
  );

  const combinedRelatedArticleData = [...orphanArticles, ...relatedArticles].slice(0, 12);

  const hasRelatedArticles = combinedRelatedArticleData.length > 0;
  const hasLatestNews = latestNews && latestNews.length > 0;
  const hasEditorsPicks = editorsPicks && editorsPicks.length > 0;

  const headings =
    entry.textBody?.json?.content
      .filter(isSectionHeading)
      .map(node => documentToPlainTextString(node)) ?? [];
  const menuItems = [overviewSectionName, ...headings];

  if (faqs != null) {
    menuItems.push('FAQ');
  }

  const sharePost = (
    <div className={classes.sharePost}>
      <Typography gutterBottom variant="h5">
        Share this story
      </Typography>
      <ShareSocialIcons shareText={title ?? ''} />
    </div>
  );
  const ogImageSource = heroImage ?? category?.heroImage;

  const conditionName = entry.category?.condition ?? undefined;

  return (
    <PageBase
      windowTitle={title ?? ''}
      descriptionMeta={entry.descriptionMeta ?? ''}
      windowTitleHmPrefix={false}
      footerBleed={conditionArticleFooterBleedConfig}
    >
      <Helmet
        script={[
          helmetJsonLdProp<MedicalWebPage>({
            '@context': 'https://schema.org',
            '@type': 'MedicalWebPage',
            headline: entry.titleMeta ?? entry.title ?? '',
            description: entry.descriptionMeta ?? '',
            // "createdAt" = first published, "updatedAt" = latest publish, see "sys properties" table at:
            // https://www.contentful.com/developers/docs/references/content-management-api/#/introduction/collection-resources-and-pagination
            datePublished: entry.sys.firstPublishedAt ?? undefined,
            dateModified: entry.sys.publishedAt ?? undefined,
            url: `${import.meta.env.VITE_HMIO_URL}/${entry.slug ?? ''}`,
            ...(heroImage?.url ? { image: heroImage.url } : {}),
            publisher: {
              '@type': 'Organization',
              name: 'HealthMatch',
              logo: {
                '@type': 'ImageObject',
                url: healthmatchLogo,
              },
            },
            author: {
              '@type': 'Person',
              name: author?.name ?? undefined,
            },
            ...(medicalReviewer
              ? {
                  reviewedBy: {
                    '@type': 'Person',
                    name: medicalReviewer.name ?? undefined,
                  },
                }
              : {}),
            ...(faqPageSchema != null ? { hasPart: faqPageSchema } : {}),
          }),
        ]}
      />
      <CanonicalUrl url={`${import.meta.env.VITE_HMIO_URL}${pathname}`} />
      {ogImageSource && <OgImage image={ogImageSource} />}
      <OgArticleType
        published={entry.sys.firstPublishedAt}
        modified={entry.sys.publishedAt}
        author={entry.author?.name ?? ''}
      />
      <Container>
        <Box display="flex" marginTop={{ xs: 3, sm: 5 }}>
          <Box>
            <ContentThemeProvider>
              {textBody && (
                <ArticleRichText
                  document={textBody}
                  injectElementAfterOverview={
                    <div className={classes.shownOnMobile}>
                      <ArticleMenuCta conditionName={conditionName} />
                    </div>
                  }
                >
                  <Box marginBottom={{ xs: 2, md: 5 }}>
                    <Breadcrumbs>
                      <Link color="inherit" to="/">
                        Home
                      </Link>
                      <Link color="inherit" to={`/${category?.slug ?? ''}`}>
                        {category?.condition}
                      </Link>
                      <Link color="inherit" to={pathname}>
                        {title}
                      </Link>
                    </Breadcrumbs>
                  </Box>
                  <Typography variant="h1" gutterBottom>
                    {title}
                  </Typography>
                  <Box marginY={{ xs: 3, sm: 5 }}>
                    <ContentMeta
                      author={author}
                      medicalReviewer={medicalReviewer}
                      updatedAt={entry.sys.publishedAt}
                    />
                  </Box>
                  {heroImage && (
                    <ContentfulImage
                      image={heroImage}
                      className={classes.image}
                      maxWidth={heroImageMaxWidth}
                      lazy={false}
                    />
                  )}
                  <ArticleContentMenu items={menuItems} />
                </ArticleRichText>
              )}
            </ContentThemeProvider>
            {faqs && (
              <Element name="faq">
                <Box marginY={5}>
                  <Typography variant="h1" component="h2" className={classes.faqTitle}>
                    FAQ
                  </Typography>
                  <ContentThemeProvider>
                    <RichText document={faqs} />
                  </ContentThemeProvider>
                </Box>
              </Element>
            )}
            {references && (
              <Box marginY={{ xs: 7, sm: 10 }}>
                <FootNotes title="Sources">
                  <RichText document={references} />
                </FootNotes>
              </Box>
            )}
            <Box marginTop={{ xs: 7, md: 10 }} display={{ xs: 'block', md: 'none' }}>
              {sharePost}
            </Box>
          </Box>
          <ArticleStickyMenu articleTitle={title ?? ''} conditionName={conditionName} />
        </Box>
        {hasRelatedArticles && <Divider />}
      </Container>
      {category && conditionName && (
        <EmbeddedQuestionnaire
          questionOverrides={category.embeddedQuestionnaireQuestions?.filter(notEmpty)}
          conditionName={conditionName}
        />
      )}
      {hasRelatedArticles && (
        <Box marginY={{ xs: 7, sm: 10 }}>
          <ContentLinks links={combinedRelatedArticleData} title="Related articles" categoryLinks />
        </Box>
      )}
      {category && (
        <ExploreMore
          condition={category.condition}
          conditionIdList={category.conditionIdList?.filter(notEmpty) ?? []}
          statisticsSlug={category?.statisticsLink?.slug ?? null}
          treatmentsSlug={category?.treatmentsLink?.slug ?? null}
          fallback={
            <Container>
              <Divider />
            </Container>
          }
        />
      )}
      {hasEditorsPicks && (
        <Box marginY={{ xs: 7, sm: 10 }}>
          <ContentLinks links={editorsPicks} title="Editor’s picks" />
        </Box>
      )}
      {hasEditorsPicks && hasLatestNews && (
        <Container>
          <Divider />
        </Container>
      )}
      {hasLatestNews && (
        <Box paddingY={{ xs: 7, sm: 10 }}>
          <ContentLinks links={latestNews} title="Latest news" />
        </Box>
      )}
    </PageBase>
  );
};

export const ConditionArticleRoute: FC<RouteComponentProps<{ slug: string; category: string }>> = ({
  match,
}) => {
  const { data, error, loading } = useConditionArticle({
    variables: {
      slug: `${match.params.category}/${match.params.slug}`,
      categorySlug: match.params.category,
      orphanArticleTag: `orphan-${match.params.category}`,
    },
  });

  const notSelfLink = <T extends ArticleLink | null>(article: T): article is NonNullable<T> =>
    article ? article.sys.id !== entry?.sys.id : false;

  const entry = data?.conditionArticleCollection?.items[0];
  const latestNews = data?.latestNews?.items?.filter(notSelfLink) ?? [];
  const editorsPicks = data?.editorsPicks?.items?.filter(notSelfLink) ?? [];
  const orphanArticles = data?.orphanArticles?.items?.filter(notSelfLink) ?? [];

  if (loading) {
    return <LoadingPage windowTitle="Loading" />;
  }

  const redirect = getRedirectIfExists(data?.redirectCollection?.items[0]);

  if (redirect) {
    return redirect;
  }

  if (error || !entry) {
    return <NotFound />;
  }

  return (
    <ConditionArticlePage
      entry={entry}
      editorsPicks={editorsPicks}
      latestNews={latestNews}
      orphanArticles={orphanArticles}
    />
  );
};
