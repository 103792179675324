import { FC } from 'react';

import { Box, Breadcrumbs, Card, Container, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { ErrorPageContent } from 'shared/src/error-page-content/error-page-content';
import { NotFoundPageContent } from 'shared/src/error-page-content/not-found-page-content';
import { Link } from 'shared/src/link/link';
import { LoadingPageContent } from 'shared/src/loading-page-content/loading-page-content';
import { beige, gray } from 'shared/src/palette/color-system';
import { notEmpty } from 'shared/src/util/not-empty';

import { TrialListPublic } from 'mats/src/components/trial-list-public/trial-list-public';

import { PageBase } from 'components/page-base/page-base';
import { CanonicalUrl } from 'components/seo/meta-data-fragments';
import { StatsSection } from 'components/stats-section/stats-section';
import { BlogContentThemeProvider } from 'pages/blog-post/blog-content-theme';
import { StatsSection as ContentfulStatsSection } from 'pages/condition-landing-page/components/stats-section/stats-section';
import { NotFound } from 'pages/not-found/not-found';
import { FaqSection } from 'pages/trials-public/faq-section/faq-section';
import { useConditionCategoryForPublicTrialsPage } from 'pages/trials-public/queries.contentful';
import { ConditionCategoryForPublicTrialsPage } from 'pages/trials-public/types/queries.contentful.generated';

import { AboutHealthmatch } from './about-healthmatch/about-healthmatch';
import { ClinicalTrialsInfo } from './clinical-trials-info/clinical-trials-info';
import { WhyClinicalTrials } from './why-clinical-trials/why-clinical-trials';

type TrialsPublicProps = {
  category: DeepExtractTypeSkipArrays<
    ConditionCategoryForPublicTrialsPage,
    ['conditionCategoryCollection', 'items']
  >;
};

function usePageTitleAndDesc({ condition }: { condition?: string | null }): [string, string] {
  if (condition == null) {
    return ['', ''];
  }

  return [
    `Latest ${condition} Clinical Trials - Search and Apply Today`,
    `Are you researching ${condition} clinical
trials? With HealthMatch you'll access cutting-edge
${condition} treatments, new medications,
innovative studies. On your terms.`,
  ];
}

export const TrialsPublic: FC<TrialsPublicProps> = ({ category }) => {
  di(TrialListPublic, PageBase);

  const { pathname } = useLocation();

  const { conditionIdList, condition, slug } = category;
  const [title, description] = usePageTitleAndDesc(category);

  const conditionId =
    conditionIdList && conditionIdList.length >= 1 ? Number(conditionIdList[0]) : 0;

  if (!conditionId || !condition) {
    return <NotFound />;
  }

  return (
    <PageBase windowTitle={title} descriptionMeta={description} windowTitleHmPrefix={false}>
      <CanonicalUrl url={`${import.meta.env.VITE_HMIO_URL}${pathname}`} />
      <BlogContentThemeProvider>
        <Box padding={2} bgcolor={beige[50]}>
          <Container>
            <Box marginTop={{ xs: 2, md: 5 }}>
              <Breadcrumbs>
                <Link color="inherit" to="/">
                  Home
                </Link>
                <Link color="inherit" to={`/${slug}`}>
                  {condition}
                </Link>
                <Link color="inherit" to={pathname}>
                  Clinical trials for {condition.toLocaleLowerCase()}
                </Link>
              </Breadcrumbs>
            </Box>
          </Container>
          <ClinicalTrialsInfo conditionName={condition} content={category.conditionDescription} />
        </Box>
        <Box paddingY={10}>
          <Container>
            <TrialListPublic conditionId={conditionId as ConditionID} conditionName={condition} />
          </Container>
        </Box>
        <Box padding={{ lg: 10, xs: 0 }} bgcolor={beige[50]}>
          {category.healthcareStatsCollection && (
            <Container>
              <ContentfulStatsSection
                stats={category.healthcareStatsCollection.items.filter(notEmpty)}
                statsHeadingColor={gray[1000]}
              />
            </Container>
          )}
          <WhyClinicalTrials />
        </Box>
        <AboutHealthmatch />
        <Container>
          <Card elevation={0}>
            {!category.faqEntriesCollection?.items ||
            category.faqEntriesCollection.items.length === 0 ? (
              <StatsSection />
            ) : (
              <Box padding={10} bgcolor={beige[50]}>
                <Typography variant="h3" align="center">
                  FAQ
                </Typography>
                <Box height={40} />
                <Container maxWidth="md">
                  <FaqSection
                    faqs={category.faqEntriesCollection.items.filter(notEmpty)}
                    spacing={3}
                  />
                </Container>
              </Box>
            )}
          </Card>
        </Container>
        {category.referencesFooter && (
          <Container>
            <Box paddingY={12}>
              <RichText document={category.referencesFooter} />
            </Box>
          </Container>
        )}
        <Box height={40} />
      </BlogContentThemeProvider>
    </PageBase>
  );
};

export const TrialsPublicRoute = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const { data, loading, error } = useConditionCategoryForPublicTrialsPage({
    variables: {
      slug: match.params.slug,
    },
  });

  if (loading) {
    return <LoadingPageContent />;
  }

  if (!data || error) {
    return <ErrorPageContent />;
  }

  if (
    data.conditionCategoryCollection?.items.length === 0 ||
    data.conditionCategoryCollection?.items[0] == null
  ) {
    return <NotFoundPageContent />;
  }

  const category = data.conditionCategoryCollection?.items[0];

  return <TrialsPublic category={category} />;
};
