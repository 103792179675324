import {
  Cluster,
  MarkerClusterer,
  MarkerClustererOptions,
  Renderer,
} from '@googlemaps/markerclusterer';

import { gray } from 'shared/src/palette/color-system';
import { notEmpty } from 'shared/src/util/not-empty';

import { TrialPublicRecord } from 'mats/src/fragments/trial/types/trial-public-record-fragment.generated';

import clusterPin from './cluster-pin.svg';
import hospitalPin from './hospital-pin.svg';

type SiteClustererOptions = MarkerClustererOptions & {
  googleMaps: typeof google.maps;
  element: HTMLDivElement;
  onSiteClick(markerId: LocationID): void;
  locations: TrialPublicRecord['trialSites'][0]['location'][];
};

export class SiteMapController extends MarkerClusterer {
  // Zoom level comfortable for a city overview
  private readonly defaultZoom = 8;

  private readonly inactiveIcon: google.maps.Icon;

  private readonly activeIcon: google.maps.Icon;

  private readonly locations = new Map<LocationID, google.maps.Marker>();

  constructor({ googleMaps, element, locations, onSiteClick, ...options }: SiteClustererOptions) {
    super({
      ...options,
      map: new googleMaps.Map(element, {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControlOptions: {
          // Render zoom control in a way it's never hidden by sites list
          position: googleMaps.ControlPosition.RIGHT_TOP,
        },
      }),
      renderer: new PurpleRenderer(googleMaps),
    });

    const { LatLngBounds, Marker, Point, Size } = googleMaps;

    const getIcon = (size: number) => ({
      url: hospitalPin,
      scaledSize: new Size(size, size),
      anchor: new Point(size / 2, size),
    });
    this.inactiveIcon = getIcon(40);
    this.activeIcon = getIcon(48);

    const bounds = new LatLngBounds();
    const markers = locations.map(location => {
      const lat = location.latitude;
      const lng = location.longitude;

      if (!lat || !lng) {
        return null;
      }

      const marker = new Marker({
        position: { lat, lng },
        title: location.name,
        icon: this.inactiveIcon,
        map: options.map,
      });

      marker.addListener('click', () => onSiteClick(location.id));

      this.locations.set(location.id, marker);

      bounds.extend({ lat, lng });

      return marker;
    });

    this.addMarkers(markers.filter(notEmpty));
    const onlyMarkerPosition = markers.length === 1 ? markers[0]?.getPosition() : undefined;
    if (onlyMarkerPosition) {
      this.map?.setCenter(onlyMarkerPosition);
      this.map?.setZoom(this.defaultZoom);
    } else {
      this.map?.fitBounds(bounds);
    }
  }

  setTrialSiteActive(siteId: LocationID, prevSiteId?: LocationID): void {
    const marker = this.locations.get(siteId);

    if (!this.map || !marker) {
      return;
    }

    if (prevSiteId) {
      this.locations.get(prevSiteId)?.setIcon(this.inactiveIcon);
    }
    marker?.setIcon(this.activeIcon);

    // If the marker is a part of the cluster, make the map fit the cluster...
    for (const cluster of this.clusters) {
      if (
        cluster.bounds &&
        cluster.markers &&
        cluster.markers.length > 1 &&
        cluster.markers.includes(marker)
      ) {
        this.map.fitBounds(cluster.bounds);
        return;
      }
    }

    // ... otherwise just show the marker at default zoom level
    // https://developers.google.com/maps/documentation/javascript/overview#MapOptions
    const position = marker.getPosition();
    const bounds = this.map.getBounds();
    if (position && !bounds?.contains(position)) {
      this.map.panTo(position);
      this.map.setZoom(this.defaultZoom);
    }
  }
}

// A copy of DefaultRenderer that uses a brand-colored pin
// https://github.com/googlemaps/js-markerclusterer/blob/7586af4dcbf8716ad5f718b85125c0c7df040ea1/src/renderer.ts#L68
class PurpleRenderer implements Renderer {
  constructor(private googleMaps: typeof google.maps) {}

  public render({ count, position }: Cluster): google.maps.Marker {
    const { Marker, Size } = this.googleMaps;

    return new Marker({
      position,
      icon: {
        url: clusterPin,
        scaledSize: new Size(40, 40),
      },
      label: {
        text: String(count),
        color: gray[0],
        fontSize: '12px',
      },
      // adjust zIndex to be above other markers
      zIndex: Number(Marker.MAX_ZINDEX) + count,
    });
  }
}
