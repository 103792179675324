import { FC } from 'react';

import { parseISO, isValid, format } from 'date-fns';

// Reformats the ISO date string from Contentful into a human readable format
// See https://date-fns.org/docs/format for format documentation
export const ContentfulDate: FC<{ date: string; format?: string }> = ({
  date,
  format: dateFormat = 'd MMMM yyy',
}) => {
  const parsedDate = parseISO(date);

  if (isValid(parsedDate)) {
    return <>{format(parsedDate, dateFormat)}</>;
  }

  return <>{date}</>;
};
