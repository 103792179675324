import { gql } from '@apollo/client';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

export const CONDITION_AUTHOR_FRAGMENT = gql`
  fragment ConditionAuthorFragment on ConditionAuthor {
    sys {
      ...SysFragment
    }
    slug
    name
    profilePicture {
      ...AssetFragment
    }
    about {
      json
      links {
        assets {
          block {
            ...AssetFragment
          }
        }
        entries {
          block {
            ...EmbeddedEntryFragment
          }
          hyperlink {
            ...SluggableEntryFragment
          }
        }
      }
    }
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
`;
