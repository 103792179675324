import { FC } from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  HttpOptions,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { datadogLogs } from '@datadog/browser-logs';
import { createContentfulLink } from 'contentful-gql/src/create-link';
import fetch from 'cross-fetch';
import { Agent as HttpAgent } from 'http';
import { Agent as HttpsAgent } from 'https';
import { performance } from 'performance-ponyfill';

import { Analytics, useAnalytics } from 'shared/src/analytics/analytics';
import { buildFeatureFlagLink } from 'shared/src/feature-flags/use-dev-flags';
import { getIsServer } from 'shared/src/util/get-is-server';
import { notEmpty } from 'shared/src/util/not-empty';

import { AuthContext } from 'mats/src/components/auth/auth';
import { useAuth } from 'mats/src/components/auth/use-auth';
import { LOGIN_OPERATION_NAME } from 'mats/src/components/login/queries';

import { createCache } from './apollo-cache';

const buildAuthRestLink = ({ getAuthToken, setAuthToken }: AuthContext) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: Record<string, any>) => {
      const token = getAuthToken();

      if (!token) {
        return { headers };
      }
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });

    return forward(operation).map(result => {
      const { response } = operation.getContext();
      const authTokenResponse = response.headers.has('Authorization');

      // You might also filter on res.url to find the response of a specific API call
      if (authTokenResponse) {
        setAuthToken(response.headers.get('Authorization'));
      }

      return result;
    });
  });

const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  const networkAuthError =
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401 &&
    !(operation.operationName === LOGIN_OPERATION_NAME);
  const gqlAuthError =
    graphQLErrors &&
    graphQLErrors.some(
      err =>
        // log out for unauthorized or unauthenticated requests
        (err.message === 'Unauthorized' || err.message === 'Unauthenticated') &&
        // prevent redirection if we are attempting to login.
        err.path &&
        !(err.path[0] === LOGIN_OPERATION_NAME)
    );
  if (networkAuthError || gqlAuthError) {
    // This isn't great. there's a lot of more involved options to pursue here
    // https://healthmatch.atlassian.net/browse/HEAL-532
    window.location.assign('/logout');
  }
});

// link to report analytics for latency measuring
const buildAnalyticsLink = (analytics: Analytics) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: Record<string, any>) => {
      // go whole hog on rudder anonymous id so we don't have to support like 50 types of id
      const correlationId = analytics.getAnonymousId('rudder');

      if (!correlationId) {
        return { startTime: performance.now(), headers };
      }
      return {
        startTime: performance.now(),
        headers: {
          ...headers,
          // if we get the anonymous ID from rudderstack send it off to backend via headers so we can track an entire requests lifetime
          'correlation-id': correlationId,
        },
      };
    });

    return forward(operation).map(result => {
      const { startTime } = operation.getContext();

      if (datadogLogs.getInitConfiguration() !== undefined) {
        // calling uninitialized datadog logger breaks ssr
        datadogLogs.logger.info('Graphql operation complete', {
          event: 'hmio.graphql.timing',
          operation: operation.operationName,
          time: performance.now() - startTime,
        });
      }

      return result;
    });
  });

const uri = import.meta.env.VITE_HM_API_ENDPOINT || 'http://localhost:8080/graphql';

const buildKatchHttpLink = (options: Partial<HttpOptions> = {}) =>
  typeof window === 'undefined'
    ? new BatchHttpLink({
        fetch,
        uri,
        batchInterval: 20,
        batchDebounce: true, // this can introduce dead time to the rendering but since some pages are heavy we can test first
        ...options,
      })
    : new HttpLink({
        fetch,
        uri,
        ...options,
      });

export type MakeApolloClientCallback = (options: {
  extraLinks: ApolloLink[];
  cacheData: NormalizedCacheObject | undefined;
  fetchOptions?: { agent: HttpAgent | HttpsAgent };
}) => ApolloClient<NormalizedCacheObject>;

export const makeApolloClient: MakeApolloClientCallback = options =>
  new ApolloClient({
    ssrMode: getIsServer(),
    cache:
      options.cacheData !== undefined ? createCache().restore(options.cacheData) : createCache(),
    link: createContentfulLink({
      config: {
        host: 'https://graphql.contentful.com',
        space: import.meta.env.VITE_CONTENTFUL_SPACE || 'no-space',
        accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN || 'no-token',
        environment: 'master',
      },
      enablePreview: import.meta.env.VITE_CONTENTFUL_PREVIEW === 'true',
      fetchOptions: options.fetchOptions,
      main: ApolloLink.from(
        [
          buildFeatureFlagLink(),
          ...options.extraLinks,
          errorLink,
          buildKatchHttpLink({ fetchOptions: options.fetchOptions }),
        ].filter(notEmpty)
      ),
    }),
  });

type ApolloProviderProps = {
  onCreateApolloProvider: MakeApolloClientCallback;
};

export const ApolloProviderWithProxy: FC<ApolloProviderProps> = ({
  children,
  onCreateApolloProvider,
}) => {
  const auth = useAuth();
  const analytics = useAnalytics();
  const apolloClient = onCreateApolloProvider({
    cacheData: undefined,
    extraLinks: [buildAuthRestLink(auth), buildAnalyticsLink(analytics)].filter(notEmpty),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
