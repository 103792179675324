import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Citation } from 'pages/why-clinical-trials/components/citation';

const useStyles = makeStyles(({ spacing, breakpoints, palette, typography }) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: spacing(4, 'auto'),
    padding: spacing(0, 16, 2),
    width: spacing(90),
    rowGap: spacing(1.5),
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },
  citationDescription: {
    top: '-1em',
    position: 'relative',
    fontSize: typography.pxToRem(11),
    color: palette.text.secondary,
  },
}));

export const CitationFooter = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container item>
        <Grid className={classes.grid} item>
          <Citation>1,6</Citation>
          <div className={classes.citationDescription}>
            <b>US National Library of Medicine National Institutes of Health,</b>
            Global Public Attitudes About Clinical Research and Patient Experiences With Clinical
            Trials
          </div>
          <Citation>2</Citation>
          <div className={classes.citationDescription}>
            <b>Pharmacy Times,</b>
            Specialty Rare Disease Management: Taking a Holistic Approach
          </div>
          <Citation>3</Citation>
          <div className={classes.citationDescription}>
            <b>US National Library of Medicine National Institutes of Health,</b> Patient
            involvement in clinical research: why, when, and how
          </div>
        </Grid>
      </Grid>
      <Box paddingX={[4, 4, 18]} paddingTop={1.5}>
        <Typography className={classes.citationDescription}>Updated May 2024</Typography>
      </Box>
    </>
  );
};
