import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client';

export const CONTENTFUL_CLIENT_NAME = 'contentful';

type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type VarBase = Exact<{ preview: boolean | null | undefined }>;
type WithoutPreview<V> = Omit<V, 'preview'>;

export function contentfulQueryFactory<D, V extends VarBase>(
  query: DocumentNode,
  factoryOptions: QueryHookOptions<D, WithoutPreview<V>> = {}
) {
  return function useCustomQuery(options: QueryHookOptions<D, WithoutPreview<V>> = {}) {
    return useQuery<D, WithoutPreview<V>>(query, {
      ...factoryOptions,
      ...options,
      context: { clientName: CONTENTFUL_CLIENT_NAME },
    });
  };
}
