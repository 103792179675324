import { ComponentType, memo, ReactNode } from 'react';

import { Container, makeStyles, Link as MuiLink, Typography } from '@material-ui/core';

import { Copyright } from 'shared/src/copyright/copyright';
import { OpenInNewTabIcon } from 'shared/src/icon/icons';
import { Link } from 'shared/src/link/link';
import { darkPurple, gray } from 'shared/src/palette/color-system';
import { StaticSocialIcons } from 'shared/src/social-icons/social-icons';
import { UnreachableError } from 'shared/src/types/unreachable-error';

import { Wave } from 'components/footer/components/footer-wave/footer-wave';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  backdrop: {
    background: darkPurple[500],
    color: gray[0],
    display: 'grid',
    gridGap: spacing(10),
    gridAutoFlow: 'row',
    paddingTop: spacing(8),
    paddingBottom: spacing(10),
  },
  content: {
    background: darkPurple[500],
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: 'repeat(6, 1fr)',
    overflow: 'hidden',
    [breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 3fr',
      gridTemplateRows: 'repeat(2, 1fr)',
      gridGap: spacing(5, 0),
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 3fr',
      gridTemplateRows: 'repeat(3, auto)',
    },
    [breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 2fr',
    },
    [`@media (max-width: ${spacing(42)}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  contentColumn: {
    display: 'grid',
    gridGap: spacing(2),
  },
  contentColumnList: {
    padding: 0,
    margin: 0,
    display: 'grid',
    gridGap: spacing(2),
  },
  contentColumnListItem: {
    color: 'inherit',
    listStyle: 'none',
  },
  openInNewTab: {
    display: 'inline',
    paddingLeft: spacing(1),
    verticalAlign: 'middle',
  },
  disclaimer: {
    display: 'grid',
    [breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      gridGap: spacing(3),
      gridAutoFlow: 'column',
      gridTemplateColumn: 'auto auto',
    },
    [breakpoints.down('sm')]: {
      gridAutoFlow: 'row',
      gridGap: spacing(2),
    },
  },
  endRow: {
    display: 'grid',
    [breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      gridAutoFlow: 'column',
      gridTemplateColumn: 'auto auto',
    },
    [breakpoints.down('sm')]: {
      gridAutoFlow: 'row',
      gridGap: spacing(5),
    },
  },
}));

const HELP_CENTRE = 'https://support.healthmatch.io/hc/en-us';
const TRIAL_SITE_PORTAL = '/portal/login';
const CAREERS_LINK = 'https://jobs.lever.co/health-match';

export type FooterWaveBleedMode =
  | { kind: 'transparent' }
  | { kind: 'disable-wave' }
  | { kind: 'background'; color: string };

const TRANSPARENT_MODE = { kind: 'transparent' } as const;

type FooterProps = {
  mode?: FooterWaveBleedMode;
};

export const Footer: ComponentType<FooterProps> = memo(({ mode = TRANSPARENT_MODE }) => {
  const styles = useStyles();

  let enableWave = true;
  let background: string | undefined;

  switch (mode.kind) {
    case 'transparent':
      break;

    case 'disable-wave':
      enableWave = false;
      break;

    case 'background':
      background = mode.color;
      break;

    default:
      throw new UnreachableError(mode);
  }

  return (
    <footer>
      {enableWave && <Wave background={background} />}
      <div className={styles.backdrop}>
        <Container className={styles.content}>
          <FooterColumn label="For patients">
            <InternalLink to="/why-clinical-trials">Why Clinical Trials?</InternalLink>
            <InternalLink to="/guides">Guides</InternalLink>
            <InternalLink to="/patients">Patients</InternalLink>
            <InternalLink to="/blog">Blog</InternalLink>
          </FooterColumn>
          <FooterColumn label="For sponsors">
            <InternalLink to="/sponsors">Overview</InternalLink>
            <InternalLink to="/sponsors/recruitment">Recruitment</InternalLink>
            <InternalLink to="/sponsors/patient-insights">Patient insights</InternalLink>
            <InternalLink to="/sponsors/pricing">Pricing</InternalLink>
          </FooterColumn>
          <FooterColumn label="For sites">
            <InternalLink to="/sites">Overview</InternalLink>
            <InternalLink to="/sites/pricing">Pricing</InternalLink>
            <ExternalLinkItem newTab to={TRIAL_SITE_PORTAL}>
              Trial Site Portal
            </ExternalLinkItem>
          </FooterColumn>
          <FooterColumn label="Company">
            <InternalLink to="/about">About HealthMatch</InternalLink>
            <ExternalLinkItem newTab to={CAREERS_LINK}>
              Careers
            </ExternalLinkItem>
          </FooterColumn>
          <FooterColumn label="Support">
            <ExternalLinkItem newTab to={HELP_CENTRE}>
              Help centre
            </ExternalLinkItem>
            <ExternalLinkItem label="General contact" to="mailto:hello@healthmatch.io">
              hello@healthmatch.io
            </ExternalLinkItem>
            <ExternalLinkItem label="Media contact" to="mailto:media@healthmatch.io">
              media@healthmatch.io
            </ExternalLinkItem>
          </FooterColumn>
        </Container>
        <Container className={styles.endRow}>
          <div className={styles.disclaimer}>
            <Copyright color="inherit" />
            <InternalLink to="/privacy">Privacy</InternalLink>
            <InternalLink to="/terms">Terms of Use</InternalLink>
          </div>
          <StaticSocialIcons />
        </Container>
      </div>
    </footer>
  );
});

const InternalLink = ({ children, to }: { children: ReactNode; to: string }) => {
  const styles = useStyles();

  return (
    <Typography variant="body1" component="li" className={styles.contentColumnListItem}>
      <Link to={to} color="inherit">
        {children}
      </Link>
    </Typography>
  );
};

const ExternalLinkItem = ({
  children,
  label,
  newTab,
  to,
}: {
  children: ReactNode;
  label?: string;
  newTab?: boolean;
  to: string;
}) => {
  const styles = useStyles();

  return (
    <Typography variant="body1" component="li" className={styles.contentColumnListItem}>
      {label && (
        <>
          <strong>{label}</strong>
          <br />
        </>
      )}
      <MuiLink href={to} color="inherit" target="_blank">
        {children}
      </MuiLink>
      {newTab && (
        <span className={styles.openInNewTab}>
          <OpenInNewTabIcon fontSize="inherit" />
        </span>
      )}
    </Typography>
  );
};

const FooterColumn = ({ children, label }: { children: ReactNode; label: string }) => {
  const styles = useStyles();

  return (
    <div className={styles.contentColumn}>
      <Typography variant="h5" component="p" color="inherit">
        {label}
      </Typography>
      <ul className={styles.contentColumnList}>{children}</ul>
    </div>
  );
};
