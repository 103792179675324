import { FC } from 'react';

import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { Link } from 'shared/src/link/link';
import { collectBy } from 'shared/src/util/collect-by';

import { TrialCardPublic } from 'mats/src/components/trial-card-public/trial-card-public';
import { BrowseCardPublic } from 'mats/src/components/trial-list-public/browse-card-public/browse-card-public';
import { PublicTrialsForCondition } from 'mats/src/components/trial-list-public/types/queries.generated';

type PublicTrialsSectionProps = {
  trialsForConditionData: PublicTrialsForCondition;
  conditionInfo?: {
    conditionName: string;
    viewMoreTrialsUrl: string;
  };
};

const useStyles = makeStyles(({ typography, spacing }) => ({
  exploreTitle: {
    fontSize: typography.pxToRem(35),
    paddingBottom: spacing(5),
  },
}));

export const PublicTrialsSection: FC<PublicTrialsSectionProps> = ({
  conditionInfo,
  trialsForConditionData,
}) => {
  di(TrialCardPublic, BrowseCardPublic);

  const classes = useStyles();

  return (
    <Container>
      <Grid container>
        <Grid container item xs={12} className={classes.exploreTitle}>
          <Grid item xs={12} sm={11}>
            <Typography variant="h3" gutterBottom>
              {conditionInfo !== undefined
                ? `Clinical trials for ${conditionInfo.conditionName.toLocaleLowerCase()}`
                : 'Explore related clinical trials'}
            </Typography>
          </Grid>
          {conditionInfo && (
            <Grid container item xs={12} sm={1} direction="column" justifyContent="center">
              <Typography color="textSecondary" variant="body2" gutterBottom>
                <Link color="inherit" to={conditionInfo.viewMoreTrialsUrl}>
                  View more
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {trialsForConditionData.searchTrials.slice(0, 3).map(trial => (
            <Grid item xs={12} md={6} lg={4} key={trial.idOpaque}>
              <TrialCardPublic
                trialIdOpaque={trial.idOpaque}
                trialTitle={trial.title}
                locations={collectBy(
                  trial.trialSites.map(ts => ts.location),
                  l => l.countryCode,
                  l => l.state
                )}
                recruitingStatus={trial.patientRecruitingStatus}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
