import { FC } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { RichTextData, RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { FAQEntry } from 'shared/src/faq-entry/faq-entry';

import faqIllustration from './faq-section.svg';

type FaqEntry = {
  sys: { id: string };
  question: string | null;
  answer: RichTextData | null;
};

type FaqEntryNotEmpty = {
  sys: { id: string };
  question: string;
  answer: RichTextData;
};

interface Props {
  faqs: FaqEntry[];
}

const notEmptyFaq = (faq: FaqEntry): faq is FaqEntryNotEmpty => !!faq.answer && !!faq.question;

export const FAQSection: FC<Props> = ({ faqs }) => (
  <Container>
    <Box marginY={{ xs: 7, sm: 10 }}>
      <Grid container direction="row-reverse" justifyContent="center">
        <Grid item container xs={12} justifyContent="flex-end">
          <Grid item xs={12} md={8}>
            <Box marginBottom={{ xs: 7, sm: 10 }}>
              <Typography variant="h2">FAQ</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={8} alignContent="flex-start">
          {faqs.filter(notEmptyFaq).map((faq, i) => (
            <Grid item xs={12} key={faq.sys.id}>
              <FAQEntry first={i === 0} question={faq.question}>
                <RichText document={faq.answer} />
              </FAQEntry>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={5} md={4}>
          <Box marginRight={{ xs: 0, md: 8 }} marginTop={{ xs: 10, md: 0 }}>
            <img alt="" src={faqIllustration} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Container>
);
