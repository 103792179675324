import { FC } from 'react';

import { Container, Divider } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { PageBase } from 'components/page-base/page-base';
import { BoardMembersSection } from 'pages/about/components/board-members-section';
import { BottomImagesSection } from 'pages/about/components/bottom-images-section';
import { CommunitySection } from 'pages/about/components/community-section';
import { FounderSection } from 'pages/about/components/founder-section';
import { HeroSection } from 'pages/about/components/hero-section';
import { InvestorsSection } from 'pages/about/components/investors-section';
import { MiddleImagesSection } from 'pages/about/components/middle-images-section';

const FOOTER_BLEED = { kind: 'disable-wave' } as const;

export const AboutPage: FC = () => {
  di(PageBase);

  return (
    <PageBase windowTitle="About Us" footerBleed={FOOTER_BLEED}>
      <HeroSection />
      <CommunitySection />
      <Container maxWidth="lg">
        <Divider />
      </Container>
      <FounderSection />
      <MiddleImagesSection />
      <InvestorsSection />
      <BoardMembersSection />
      <BottomImagesSection />
    </PageBase>
  );
};
