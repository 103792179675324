import { Box, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { RichText, RichTextData } from 'shared/src/contentful-text/rich-text-renderer';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { ConditionSelectButton } from 'components/condition-select-button/condition-select-button';

interface Props {
  footerTitle: string | undefined;
  referencesFooter: RichTextData | null;
  featuredCondition?: ConditionID;
}

const useStyles = makeStyles(theme => ({
  container: {
    // Fix horizontal and vertical scroll for small breakpoints
    // See https://material-ui.com/components/grid/#negative-margin
    overflow: 'hidden',
  },
  top: {
    textAlign: 'center',
    marginTop: '6rem',
  },
  middle: {
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  sectionTitle: {
    marginBottom: theme.spacing(7),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}));

export const FooterSection = ({ footerTitle, referencesFooter, featuredCondition }: Props) => {
  di(ConditionSelectPanel, ConditionSelectButton);

  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <Typography variant="h2" className={styles.sectionTitle}>
        {footerTitle}
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xl={7} lg={7} md={9} sm={11} xs={12} className={styles.middle}>
          {featuredCondition ? (
            <ConditionSelectButton conditionId={featuredCondition} />
          ) : (
            <ConditionSelectPanel />
          )}
        </Grid>
      </Grid>
      {referencesFooter && (
        <>
          <Divider />
          <Box marginTop={10} marginBottom={10}>
            <RichText document={referencesFooter} />
          </Box>
        </>
      )}
    </Container>
  );
};
