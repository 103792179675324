import { FC } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';

import {
  FacebookCircle,
  LinkedInCircleIcon,
  TwitterCircleIcon,
  InstagramCircleIcon,
  IconComponentType,
} from 'shared/src/icon/icon-images';
import { ExternalLink } from 'shared/src/link/link';

const useStyles = makeStyles(theme => ({
  icon: {
    width: theme.spacing(4),
  },
  visuallyHidden: {
    position: 'absolute',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
  },
}));

enum ContentType {
  URL,
  SHARE_TEXT,
}

type IconData = Record<
  string,
  {
    IconComponent: IconComponentType;
    staticUrl: string;
    shareUrl: string;
    shareParams: Record<string, string | ContentType>;
  }
>;

const icons: IconData = {
  facebook: {
    IconComponent: FacebookCircle,
    staticUrl: 'https://www.facebook.com/healthmatchAU',
    shareUrl: 'https://www.facebook.com/dialog/share',
    shareParams: {
      app_id: '602164220727129',
      hashtag: '#clinicaltrials', // only one with #
      quote: ContentType.SHARE_TEXT,
      redirect_uri: ContentType.URL,
      href: ContentType.URL,
    },
  },
  twitter: {
    IconComponent: TwitterCircleIcon,
    staticUrl: 'https://twitter.com/HealthMatchio',
    shareUrl: 'https://twitter.com/intent/tweet',
    shareParams: {
      via: 'HealthMatchio',
      hashtags: 'clinicaltrials', // comma-delimited list without #
      text: ContentType.SHARE_TEXT,
      url: ContentType.URL,
    },
  },
  linkedin: {
    IconComponent: LinkedInCircleIcon,
    staticUrl: 'https://www.linkedin.com/company/healthmatchptyltd',
    shareUrl: 'https://www.linkedin.com/sharing/share-offsite/',
    shareParams: {
      // TODO: the rest should come from OpenGraph tags, see
      // https://www.linkedin.com/help/linkedin/answer/46687/making-your-website-shareable-on-linkedin
      url: ContentType.URL,
    },
  },
};

const Icon: FC<{
  name: string;
  url: string;
  IconComponent: IconComponentType;
}> = ({ name, url, IconComponent }) => {
  const classNames = useStyles();
  return (
    <Grid item>
      <ExternalLink
        color="inherit"
        eventLabel={`Social-${name}`}
        to={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className={classNames.visuallyHidden}>{name}</span>
        <IconComponent className={classNames.icon} />
      </ExternalLink>
    </Grid>
  );
};

export const StaticSocialIcons: FC = () => (
  <Grid container spacing={2}>
    <Icon
      name="Instagram"
      url="https://www.instagram.com/healthmatch.io/"
      IconComponent={InstagramCircleIcon}
    />
    {Object.entries(icons).map(([name, { IconComponent, staticUrl }]) => (
      <Icon name={name} url={staticUrl} IconComponent={IconComponent} key={name} />
    ))}
  </Grid>
);

export const ShareSocialIcons: FC<{ shareText: string }> = ({ shareText }) => {
  const location = useLocation();
  return (
    <Grid container spacing={2}>
      {Object.entries(icons).map(([name, { IconComponent, shareUrl, shareParams }]) => {
        const url = new URL(shareUrl);
        for (const [param, staticValue] of Object.entries(shareParams)) {
          let value: string;
          switch (staticValue) {
            case ContentType.SHARE_TEXT:
              value = shareText;
              break;
            case ContentType.URL:
              value = `https://healthmatch.io${location.pathname}${location.search}`;
              break;
            default:
              value = staticValue;
          }
          url.searchParams.set(param, value);
        }
        return <Icon key={name} name={name} url={url.toString()} IconComponent={IconComponent} />;
      })}
    </Grid>
  );
};
