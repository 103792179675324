import { FC } from 'react';

import { Block, BLOCKS, Inline, Text } from '@contentful/rich-text-types';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { ShareSocialIcons } from 'shared/src/social-icons/social-icons';

import { ArticleMenuCta } from 'components/article-sticky-menu/article-menu-cta/article-menu-cta';

type ArticleStickyMenuProps = {
  articleTitle: string;
  conditionName: string | undefined;
  conditionIds?: string[];
};

// we have to know the sticky element height to be able to roughly align it with the center of the screen
// remember to update this when you add/remove content from the sticky section!
const stickyMenuHeight = '280px';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  sharePost: {
    color: palette.text.secondary,
    '&& a:hover': {
      color: palette.primary.main,
    },
  },
  stickySection: {
    paddingTop: spacing(15),
    position: 'sticky',
    bottom: `calc(50vh - ${stickyMenuHeight})`,
    display: 'grid',
    rowGap: spacing(2),
  },
  hiddenOnMobile: {
    display: 'block',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const ArticleStickyMenu: FC<ArticleStickyMenuProps> = ({ articleTitle, conditionName }) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      marginLeft={15}
      marginY={10}
      flexDirection="column"
      className={classes.hiddenOnMobile}
    >
      <div className={classes.sharePost}>
        <Typography gutterBottom variant="h5">
          Share this story
        </Typography>
        <ShareSocialIcons shareText={articleTitle} />
      </div>
      <Box flexGrow="1" />
      <Box className={classes.stickySection}>
        <ArticleMenuCta conditionName={conditionName} />
      </Box>
    </Box>
  );
};
export const isSectionHeading = (node: Block | Inline | Text): node is Block =>
  node.nodeType === BLOCKS.HEADING_2;
