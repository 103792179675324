import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import { PatientsPageList, PatientsPageListVariables } from './types/queries.contentful.generated';

const PATIENTS_PAGE_LIST = gql`
  query PatientsPageList($preview: Boolean) {
    # In reality we only have 3 stories and unlikely to have more in the future
    patientsStoryCollection(preview: $preview, limit: 6) {
      items {
        sys {
          ...SysFragment
        }
        slug
        name
        age
        condition
        location
        summary {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        patientPortrait {
          ...AssetFragment
        }
      }
    }
    # Fine not to have limit, because no rich text field requested in this
    # query, which means query complexity is not getting out of hand
    conditionLandingPageCollection(preview: $preview) {
      items {
        sys {
          ...SysFragment
        }
        slug
        condition
        title
        contentTitle
        contentImage {
          ...AssetFragment
        }
        conditionId
        conditionCardText
        hideCard
      }
    }
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
`;

export const usePatientsPageList = contentfulQueryFactory<
  PatientsPageList,
  PatientsPageListVariables
>(PATIENTS_PAGE_LIST);
