import { useMemo } from 'react';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { FAQPage } from 'schema-dts';

type FaqPair = {
  question: string;
  answer: string;
};

// should be inserted under "hasPart" of the json-ld schema for a page
export const useFaqSchemaFromRichText = (faq: Document | undefined | null): FAQPage | null =>
  useMemo(() => {
    if (!faq) {
      return null;
    }

    const faqPairs: FaqPair[] = faq.content.reduce<FaqPair[]>((acc, currBlock) => {
      if (currBlock.nodeType === BLOCKS.HEADING_2) {
        return [...acc, { question: documentToPlainTextString(currBlock), answer: '' }];
      }

      // text before first faq, can't associate it with anything
      if (acc.length === 0) {
        return acc;
      }

      const accRest = acc.slice(0, acc.length - 1);
      const currQuestion = acc[acc.length - 1];

      return [
        ...accRest,
        {
          question: currQuestion.question,
          answer:
            currQuestion.answer.length === 0
              ? documentToPlainTextString(currBlock)
              : `${currQuestion.answer} ${documentToPlainTextString(currBlock)}`,
        },
      ];
    }, []);

    return {
      '@type': 'FAQPage',
      mainEntity: faqPairs.map(f => ({
        '@type': 'Question',
        name: f.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: f.answer,
        },
      })),
    };
  }, [faq]);
