import { FC, useContext } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { ContentfulLinkContext } from 'shared/src/contentful-text/components/context';
import {
  EmbeddedEntryFragment,
  EmbeddedEntryFragment_ConditionArticle_,
  EmbeddedEntryFragment_ConditionCategory_,
  EmbeddedEntryFragment_ConditionLink_,
} from 'shared/src/fragments/contentful/types/embedded-entry-fragment.contentful.generated';
import { Link } from 'shared/src/link/link';

const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: 'bold',
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: theme.spacing(22.5),
  },
}));

type LinkOrPage =
  | EmbeddedEntryFragment_ConditionLink_
  | EmbeddedEntryFragment_ConditionArticle_
  | EmbeddedEntryFragment_ConditionCategory_;

const isLinkOrPage = (entry: EmbeddedEntryFragment): entry is LinkOrPage =>
  entry.__typename === 'ConditionArticle' ||
  entry.__typename === 'ConditionCategory' ||
  entry.__typename === 'ConditionLink';

export const BlockEntryLink: FC<{ id: string }> = ({ id }) => {
  const classes = useStyles();
  const { entries } = useContext(ContentfulLinkContext);
  const entry = entries.get(id);

  // Skip not condition categories, articles or links
  if (!entry || !isLinkOrPage(entry)) {
    return null;
  }

  const linkedPage = entry.__typename === 'ConditionLink' ? entry.linkedPage : entry;

  if (linkedPage == null) {
    return null;
  }

  return (
    <Box
      border="1px solid"
      borderColor="rgba(0, 0, 0, 0.25) transparent"
      paddingY={2}
      marginY={5}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems={{ sm: 'center' }}
    >
      {linkedPage.heroImage && (
        <Box marginRight={2} marginBottom={{ xs: 2, sm: 0 }}>
          <ContentfulImage className={classes.image} maxWidth={180} image={linkedPage.heroImage} />
        </Box>
      )}
      <Link
        to={`/${linkedPage.slug}`}
        variant="body2"
        color="textSecondary"
        classes={{ root: classes.link }}
      >
        {entry.title}
      </Link>
    </Box>
  );
};
