import { gql } from '@apollo/client';

import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

export const ASSET_FRAGMENT = gql`
  fragment AssetFragment on Asset {
    sys {
      ...SysFragment
    }
    title
    description
    url
    contentType
    width
    height
  }
  ${SYS_FRAGMENT}
`;
