import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AuraImage } from 'pages/trials-public/aura-image/aura-image';

const useStyles = makeStyles(({ shape, spacing, palette, typography }) => ({
  title: {
    fontSize: typography.pxToRem(20),
  },
  subtitle: {
    fontSize: typography.pxToRem(30),
  },
  text: {
    color: palette.text.secondary,
  },
  manuriAura: {
    borderRadius: shape.borderRadius,
  },
  manuriNameBold: {
    fontWeight: 700,
    marginRight: spacing(4),
  },
}));

export const AboutHealthmatch = () => {
  const classes = useStyles();

  return (
    <Box paddingY={{ xs: 7, md: 10 }} paddingX={2}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              About HealthMatch
            </Typography>
            <Typography
              variant="h3"
              component="h6"
              color="primary"
              gutterBottom
              className={classes.subtitle}
            >
              HealthMatch is an organisation driven by a mission to accelerate medical research
              allowing for faster and more efficient access to life-changing medication.
            </Typography>
            <Typography paragraph variant="body2" color="textSecondary">
              Our community consists of a diverse team of doctors, engineers, scientists, and people
              dedicated to challenging the status quo of medical research.
            </Typography>
            <Typography paragraph variant="body2" color="textSecondary">
              We are united by a passion to deliver better healthcare options, for all, regardless
              of location, background or means. This means access to trials and the revolutionary
              treatments that come from them.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box paddingY={3} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AuraImage className={classes.manuriAura} />
            <Box display="flex" paddingY={1}>
              <Box margin="auto">
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.manuriNameBold}
                >
                  <Box component="b">Manuri Gunawardena</Box>
                </Typography>
                <Typography component="span" variant="subtitle2">
                  Founder and CEO and HealthMatch
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
