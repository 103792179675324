import { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import { AspectRatioContainer } from 'shared/src/aspect-ratio-container/aspect-ratio-container';
import { LazyImage } from 'shared/src/image/image';
import { beige, gray } from 'shared/src/palette/color-system';

import { MatsLinkButton } from 'mats/src/components/mats-link/mats-link';

import trialFooter from './trial-footer.webp';

type TrialFooterProps = {
  background: 'gradient' | 'solid';
  conditionId: ConditionID;
};

const useStyles = makeStyles({
  twoColorBackground: {
    backgroundImage: `linear-gradient(0, ${beige[50]} 0%, ${beige[50]} 50%, ${beige[200]} 50%)`,
  },
  solidBackground: {
    backgroundColor: beige[50],
  },
  image: {
    width: '100%',
  },
});

export const TrialFooter: FC<TrialFooterProps> = ({ conditionId, background }) => {
  const classes = useStyles();
  const rootClassName =
    background === 'gradient' ? classes.twoColorBackground : classes.solidBackground;

  return (
    <>
      <div className={rootClassName}>
        <Container>
          <Box bgcolor={gray[0]} padding={5} borderRadius="borderRadius">
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={6}>
                <AspectRatioContainer ratio={387 / 620}>
                  <LazyImage src={trialFooter} className={classes.image} alt="" />
                </AspectRatioContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" gutterBottom>
                  Apply to this trial
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  We make it easy for you to apply to trials you’re eligible for. All the medical
                  jargon? It’s simplified, so you understand exactly what the trial is about.
                </Typography>
                <MatsLinkButton
                  variant="contained"
                  color="primary"
                  disableElevation
                  to={`/signup/${conditionId}`}
                >
                  Check your eligibility now
                </MatsLinkButton>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Box
        bgcolor={beige[50]}
        paddingTop={{ xs: 7, sm: 10 }}
        paddingBottom={{ xs: 13, sm: 10 }}
        textAlign={{ xs: 'left', sm: 'center' }}
      >
        <Container>
          <Typography variant="h5" gutterBottom>
            Not sure if this is the right trial for you?
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Answer some questions to create your profile, and we’ll start searching for the right
            match.
          </Typography>
          <MatsLinkButton
            variant="outlined"
            color="primary"
            disableElevation
            to={`/signup/${conditionId}`}
          >
            Create your profile
          </MatsLinkButton>
        </Container>
      </Box>
    </>
  );
};
