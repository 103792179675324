import { ElementType, FC, ReactElement, ReactNode } from 'react';

import { Button, ButtonProps, Link as MuiLink, makeStyles } from '@material-ui/core';
import { LinkProps as MuiLinkProps } from '@material-ui/core/Link/Link';
import { Theme } from '@material-ui/core/styles';
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom';

import { ButtonChromeless } from 'shared/src/button/button-chromeless';
import { ExternalLink } from 'shared/src/link/link';
import { gray, purple } from 'shared/src/palette/color-system';

const useStyles = makeStyles(theme => ({
  rootCommon: {
    '&[class*="MuiLink-root"]': {
      display: 'flex',
      alignItems: 'center',
      color: gray[1000],
      whiteSpace: 'nowrap',
      '&:hover': {
        color: purple[500],
        textDecoration: 'none',
      },
      '&[class*="NavLinkItemActive"]': {
        color: purple[700],
        fontWeight: 700,
        '&> [class*="NavLinkItemActive-underline"]': {
          display: 'block',
        },
      },
    },
  },
  rootToolbarItem: {
    composes: '$rootCommon',
    height: '100%',
    position: 'relative',
  },
  rootDrawerItem: {
    composes: '$rootCommon',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectedUnderline: {
    composes: 'NavLinkItemActive-underline',
    display: 'none',
    width: '100%',
    maxWidth: theme.spacing(9),
    height: theme.spacing(0.6),
    borderRadius: theme.spacing(0.3),
    backgroundColor: purple[500],
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  selectedSidebar: {
    composes: 'NavLinkItemActive-underline',
    display: 'none',
    position: 'absolute',
    left: 0,
    height: theme.spacing(6),
    width: theme.spacing(0.6),
    borderRadius: theme.spacing(0.3),
    backgroundColor: purple[500],
  },
  consistentSizePlaceholder: {
    height: 0,
    overflow: 'hidden',
    fontWeight: 700,
  },
}));

// Allocates space for its children so that there are no layout shifts when increasing font weight
const ConsistentSize: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div>
      {children}
      <div className={classes.consistentSizePlaceholder} aria-hidden>
        {children}
      </div>
    </div>
  );
};

export const ToolbarLinkItem: FC<MuiLinkProps<RouterLink, RouterLinkProps>> = ({
  children,
  ...props
}) => {
  const classes = useStyles();

  const isExternalLink = typeof props.to === 'string' && props.to.startsWith('http');

  return (
    <MuiLink
      component={isExternalLink ? ExternalLink : RouterLink}
      underline="none"
      className={classes.rootToolbarItem}
      {...(!isExternalLink && { activeClassName: 'NavLinkItemActive' })}
      {...props}
    >
      <ConsistentSize>{children}</ConsistentSize>
      <div className={classes.selectedUnderline} />
    </MuiLink>
  );
};

export const DrawerLinkItem: FC<MuiLinkProps<RouterLink, RouterLinkProps>> = ({
  children,
  ...props
}) => {
  const classes = useStyles();

  const isExternalLink = typeof props.to === 'string' && props.to.startsWith('http');

  return (
    <MuiLink
      component={isExternalLink ? ExternalLink : RouterLink}
      underline="none"
      className={classes.rootDrawerItem}
      {...(!isExternalLink && { activeClassName: 'NavLinkItemActive' })}
      {...props}
    >
      {children}
      <div className={classes.selectedSidebar} />
    </MuiLink>
  );
};

type NavButtonProps = {
  onClick(): void;
  children: ReactNode;
  active: boolean;
};

export const ToolbarButtonItem: FC<NavButtonProps> = ({ active, children, onClick }) => {
  const classes = useStyles();
  const className = active
    ? `NavLinkItemActive ${classes.rootToolbarItem}`
    : classes.rootToolbarItem;
  return (
    <MuiLink component={ButtonChromeless} onClick={onClick} className={className} underline="none">
      <ConsistentSize>{children}</ConsistentSize>
      <div className={classes.selectedUnderline} />
    </MuiLink>
  );
};

export const DrawerButtonItem: FC<NavButtonProps> = ({ active, children, onClick }) => {
  const classes = useStyles();
  const className = active ? `NavLinkItemActive ${classes.rootDrawerItem}` : classes.rootDrawerItem;
  return (
    <MuiLink component={ButtonChromeless} onClick={onClick} className={className} underline="none">
      {children}
      <div className={classes.selectedSidebar} />
    </MuiLink>
  );
};

const useButtonStyles = makeStyles<Theme, { responsive?: boolean }>(() => ({
  root: {
    // Fix for unusual text wrapping on Safari
    width: 'max-content',
  },
}));

export type LoginButtonProps = {
  responsive?: boolean;
};

export const LoginButton = <C extends ElementType>({
  responsive,
  ...props
}: ButtonProps<C, { component?: C }> & LoginButtonProps): ReactElement => {
  const classes = useButtonStyles({ responsive });
  const className = props.className ? `${classes.root} ${props.className}` : classes.root;

  return <Button variant="outlined" color="primary" className={className} {...props} />;
};
