import { crawlablePages, webflowPages } from 'components/app/page-paths-crawlable';

export type ItemType = {
  title: string;
  to: `/${string}`;
};

export const patientGuideLinks: readonly ItemType[] = [
  {
    title: 'Breast cancer',
    to: '/breast-cancer',
  },
  {
    title: 'Prostate cancer',
    to: '/prostate-cancer',
  },
  {
    title: 'Skin cancer',
    to: '/skin-cancer',
  },
  {
    title: 'Lung cancer',
    to: '/lung-cancer',
  },
  {
    title: 'Colon cancer',
    to: '/colon-cancer',
  },
  {
    title: 'Stomach cancer',
    to: '/stomach-cancer',
  },
  {
    title: 'Rectal cancer',
    to: '/rectal-cancer',
  },
  {
    title: 'Mental health',
    to: '/mental-health',
  },
];

export const patientTopicLinks: readonly ItemType[] = [
  {
    title: 'Latest News',
    to: '/topic/latest-health-news-and-updates',
  },
  {
    title: "Women's Health",
    to: '/topic/womens-health',
  },
  {
    title: "Men's Health",
    to: '/topic/mens-health',
  },
  {
    title: 'Mental Health',
    to: '/topic/mental-health',
  },
  {
    title: 'Sexual Health',
    to: '/topic/sexual-health',
  },
  {
    title: 'Sleep',
    to: '/topic/sleep',
  },
  {
    title: 'Medications',
    to: crawlablePages.MedicationsDirectoryRoute.path,
  },
  {
    title: 'Blog',
    to: crawlablePages.BlogIndexPage.path,
  },
];

export const patientClinicalTrialsLinks: readonly ItemType[] = [
  {
    title: 'Overview',
    to: crawlablePages.PatientsPage.path,
  },
  {
    title: 'Search clinical trials',
    to: '/signup/363',
  },
  {
    title: 'Why join a trial?',
    to: crawlablePages.WhyClinicalTrials.path,
  },
  {
    title: 'Patient login',
    to: '/login/existing',
  },
];

export const sponsorResourcesLinks: readonly ItemType[] = [
  {
    title: 'SCOPE Summit 2024',
    to: webflowPages.SponsorsScope.path,
  },
  {
    title: 'DEI Report',
    to: crawlablePages.DeiReport.path,
  },
  {
    title: 'About HealthMatch',
    to: crawlablePages.AboutPage.path,
  },
  {
    title: 'Insights Portal Login',
    to: '/insights/login',
  },
];

export const sponsorSolutionsLinks: readonly ItemType[] = [
  {
    title: 'Overview',
    to: webflowPages.SponsorsPage.path,
  },
  {
    title: 'Recruitment',
    to: webflowPages.SponsorsRecruitment.path,
  },
  {
    title: 'Patient insights',
    to: webflowPages.SponsorsPatientInsights.path,
  },
  {
    title: 'Pricing',
    to: webflowPages.SponsorsPricing.path,
  },
];

export const siteResourcesLinks: readonly ItemType[] = [
  {
    title: 'SCOPE Summit 2024',
    to: webflowPages.SponsorsScope.path,
  },
  {
    title: 'DEI Report',
    to: crawlablePages.DeiReport.path,
  },
  {
    title: 'About HealthMatch',
    to: crawlablePages.AboutPage.path,
  },
  {
    title: 'Trial Site Login',
    to: '/portal/login',
  },
];

export const siteSolutionsLinks: readonly ItemType[] = [
  {
    title: 'Overview',
    to: webflowPages.SitesOverview.path,
  },
  {
    title: 'Pricing',
    to: webflowPages.SitesPricing.path,
  },
];
