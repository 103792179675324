import { FC } from 'react';

import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { LocationsList } from 'shared/src/trials/components/locations-list/locations-list';
import { RecruitingStatus } from 'shared/src/types/graphql-schema';

import { HmioLinkButton } from 'mats/src/components/hmio-link/hmio-link';

import { RecruitingPill } from './recruiting-pill/recruiting-pill';

type TrialCardPublicProps = {
  trialIdOpaque: TrialID;
  trialTitle: string;
  locations: Record<string, StateCode[]>;
  recruitingStatus: RecruitingStatus;
};

const useStyles = makeStyles({
  card: {
    height: '100%',
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  image: {
    height: '160px',
    width: '100%',
    objectFit: 'cover',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
});

export const TrialCardPublic: FC<TrialCardPublicProps> = ({
  trialIdOpaque,
  trialTitle,
  locations,
  recruitingStatus,
}) => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ condition: string }>('/:condition/');

  // This is the contentful condition name, which is different to condition name from the db
  const conditionName = match?.params.condition !== 'trials' ? match?.params.condition : null;

  const classes = useStyles();

  return (
    <Card data-test-id="trial-card" className={classes.card}>
      <Box>
        <Box marginX={3} marginY={2} display="flex">
          <RecruitingPill status={recruitingStatus} />
        </Box>
        <Box marginX={3} marginY={2}>
          <Typography variant="h5" className={classes.title}>
            {trialTitle}
          </Typography>
        </Box>
        <Box marginX={3} marginY={2} display="flex">
          <LocationsList locations={locations} />
        </Box>
        <Box margin={3} marginTop={1}>
          <HmioLinkButton
            color="primary"
            variant="contained"
            to={
              conditionName
                ? `/${conditionName}/trials/${trialIdOpaque}`
                : `/clinical-trial/${trialIdOpaque}`
            }
          >
            {t('mats.trialCard.buttonLearnMore')}
          </HmioLinkButton>
        </Box>
      </Box>
    </Card>
  );
};
