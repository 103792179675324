import { Box, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { nantesFontFamily } from 'shared/src/fonts/fonts';
import { FullWidthImage } from 'shared/src/image/image';
import { lightPurple } from 'shared/src/palette/color-system';

import medicalReviewers from './medical-reviewers.svg';

const useStyles = makeStyles(theme => ({
  splitBackground: {
    width: '100%',
    // takes into account padding at the bottom
    background: `linear-gradient(0deg,  ${lightPurple[50]} 60%, transparent 40%)`,
    // padding is required because of the paper dropshadow being cut off and if margin is used the background doesn't get applied...
    paddingBottom: theme.spacing(10), // have to use padding because of background...
  },
  image: {
    borderRadius: theme.shape.borderRadius,
  },
  paper: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    fontFamily: nantesFontFamily,
  },
  textContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export const WhyMedicalReviewers = () => {
  const classes = useStyles();

  return (
    <Box className={classes.splitBackground}>
      <Container>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12} sm={5}>
              <FullWidthImage src={medicalReviewers} alt="" className={classes.image} />
            </Grid>
            <Grid item container xs={12} sm={7} alignItems="center">
              <Grid item className={classes.textContainer}>
                <Typography
                  color="textSecondary"
                  variant="h4"
                  gutterBottom
                  className={classes.heading}
                >
                  Why we have medical reviewers
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Our mission is to provide health and wellness information that’s easy to
                  understand and, most importantly, accurate. No complicated jargon or terminology
                  that seems like it’s written in another language. No unproven or unsubstantiated
                  claims. Just the facts - checked by a team of doctors, oncologists, psychiatrists,
                  nutritionists, and many other experienced healthcare professionals dedicated to
                  bringing you the right information when you need it.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};
