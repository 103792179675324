import { FC } from 'react';

import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';

import { useAnalytics } from 'shared/src/analytics/analytics';
import { purple } from 'shared/src/palette/color-system';

import { MatsLinkButton } from 'mats/src/components/mats-link/mats-link';

import { ALL_CONDITIONS_ID } from 'components/constants';

type ArticleMenuCtaProps = {
  conditionName: string | undefined;
  conditionIds?: string[];
};

const useStyles = makeStyles(({ spacing }) => ({
  ctaContainer: {
    backgroundColor: purple[50],
    padding: spacing(3),
    display: 'grid',
    rowGap: spacing(2),
  },
}));

export const ArticleMenuCta: FC<ArticleMenuCtaProps> = ({ conditionName, conditionIds }) => {
  di(useAnalytics);
  const classes = useStyles();
  const analytics = useAnalytics();

  const { pathname } = useLocation();
  const isVaccinePage = pathname.includes('vaccine');

  const ctaText = 'Check your eligibility';

  const ctaLinkTo =
    conditionIds === undefined || conditionIds.length > 1 || isVaccinePage
      ? `/signup/${ALL_CONDITIONS_ID}`
      : `/signup/${conditionIds[0]}`;

  const ctaOnClick = () => {
    analytics.track({
      event: 'hmio.article.cta.clicked',
      conditionIds,
      conditionName,
      ctaName: ctaText,
    });
  };

  let title;
  if (isVaccinePage) {
    title = <VaccineTitle />;
  } else if (conditionName !== undefined) {
    title = <ConditionTitle conditionName={conditionName} />;
  } else {
    title = <GenericTitle />;
  }

  let description;
  if (isVaccinePage) {
    description = <VaccineDescription />;
  } else if (conditionName !== undefined) {
    description = <ConditionDescription conditionName={conditionName} />;
  } else {
    description = <GenericDescription />;
  }

  return (
    <Paper elevation={0} className={classes.ctaContainer}>
      <Typography variant="h6">{title}</Typography>
      <Typography color="textSecondary">{description}</Typography>
      <Box display="flex" justifyContent="center">
        <MatsLinkButton variant="contained" color="primary" to={ctaLinkTo} onClick={ctaOnClick}>
          <Box whiteSpace="nowrap">{ctaText}</Box>
        </MatsLinkButton>
      </Box>
    </Paper>
  );
};

const ConditionTitle: FC<{ conditionName: string }> = ({ conditionName }) => (
  <>{`Have you considered clinical trials for ${conditionName}?`}</>
);

const GenericTitle: FC = () => <>Curious about clinical trials?</>;

const VaccineTitle: FC = () => <>Have you considered vaccine trials?</>;

const ConditionDescription: FC<{ conditionName: string }> = ({ conditionName }) => (
  <>
    {`We make it easy for you to participate in a clinical trial for ${conditionName}, and get access to the latest treatments not yet widely available - and be a part of finding a cure.`}
  </>
);

const GenericDescription: FC = () => (
  <>
    Access the latest treatments and medications. unavailable elsewhere - entirely free of charge.
    We make it easy to take part.
  </>
);

const VaccineDescription: FC = () => (
  <>
    Join our Vaccine Study Priority Waitlist to be the first to know about new and upcoming studies,
    including potential compensation and/or reimbursement for your time and expenses.
  </>
);
