import { FC } from 'react';

import { di } from 'react-magnetic-di/macro';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { useLocation } from 'react-use';

export const MatsRedirect: FC<Omit<RouteProps, 'component'>> = props => (
  <Route {...props} component={MatsFauxRouteContent} />
);

const MatsFauxRouteContent: FC = () => {
  di(useLocation);
  const { pathname, search } = useLocation();

  window.location.replace(`${import.meta.env.VITE_MATS_URL}${pathname}${search}`);

  return null;
};
