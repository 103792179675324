import { datadogLogs } from '@datadog/browser-logs';
import Cookies from 'js-cookie';
import { di } from 'react-magnetic-di/macro';
import { uuid } from 'short-uuid';

import { ConversionsEventInput, EventName } from 'shared/src/types/graphql-schema';

import { rdtTrack } from 'mats/src/components/conversion-event-tracking/pixels/reddit-pixel/reddit-pixel';
import { ttqTrack } from 'mats/src/components/conversion-event-tracking/pixels/tiktok-pixel/tiktok-pixel';
import { useAttributionIdentifiersFromStorage } from 'mats/src/components/use-fields-from-storage/use-fields-from-storage';

import { fbqTrack, MetaParamObject } from './pixels/facebook-pixel/facebook-tracking-pixel';
import { useSendConversionsEventMutation } from './queries';

export const FACEBOOK_CLICK_ID = '_fbc';
export const FACEBOOK_BROWSER_ID = '_fbp';

export const useConversionEventTracking = () => {
  di(
    useSendConversionsEventMutation,
    useAttributionIdentifiersFromStorage,
    fbqTrack,
    ttqTrack,
    rdtTrack,
    uuid
  );

  const [eventAttributionIdentifiers] = useAttributionIdentifiersFromStorage();

  const [sendConversionEvent] = useSendConversionsEventMutation({
    onError: error => {
      datadogLogs.logger.error('Failed to send Conversion API event to the back-end', error);
    },
  });

  return async (eventName: EventName, metaPixelParams?: MetaParamObject) => {
    const eventId = uuid();
    const { userAgent } = window.navigator || '';
    const eventsourceUrl = window.location.href;

    const fbc = Cookies.get(FACEBOOK_CLICK_ID);
    const fbp = Cookies.get(FACEBOOK_BROWSER_ID);

    const metaIdentifiers = {
      fbc: validateFacebookPixelCookieParamOrUndefined(fbc),
      fbp: validateFacebookPixelCookieParamOrUndefined(fbp),
    };

    const event: ConversionsEventInput = {
      eventId,
      eventName,
      eventsourceUrl,
      userAgent,
      metaIdentifiers,
      eventAttributionIdentifiers,
    };

    fbqTrack(eventName, { eventID: eventId }, metaPixelParams);
    ttqTrack(eventName, { event_id: eventId });
    rdtTrack(eventName, { eventId });
    return sendConversionEvent({ variables: { input: event } });
  };
};

export function validateFacebookPixelCookieParamOrUndefined(
  identifier: string | undefined
): string | undefined {
  return identifier && /^fb\.\d\.\d+\..+$/.test(identifier) ? identifier : undefined;
}
