import { FC, useEffect, useRef, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { gray } from 'shared/src/palette/color-system';
import { useGoogleMaps } from 'shared/src/use-google-maps/use-google-maps';

import { TrialPublicRecord } from 'mats/src/fragments/trial/types/trial-public-record-fragment.generated';

import { Site } from './site';
import { SiteMapController } from './site-map-controller';

const useStyles = makeStyles(theme => ({
  moveGoogleLogo: {
    '& a[target = "_blank"]': {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        bottom: theme.spacing(4),
      },
      [theme.breakpoints.up('sm')]: {
        left: theme.spacing(4),
      },
    },
  },
}));

type Locations = TrialPublicRecord['trialSites'][0]['location'][];

export const SiteMap: FC<{ locations: Locations }> = ({ locations }) => {
  const [activeSiteId, setActiveSiteId] = useState<LocationID>();
  const controllerRef = useRef<SiteMapController>();
  const mapRef = useRef<HTMLDivElement | null>(null);
  const googleMaps = useGoogleMaps();
  const classes = useStyles();

  const setActiveSite = (id: LocationID) =>
    setActiveSiteId(prevId => {
      controllerRef.current?.setTrialSiteActive(id, prevId);
      return id;
    });

  useEffect(() => {
    if (mapRef.current && !controllerRef.current && googleMaps) {
      controllerRef.current = new SiteMapController({
        element: mapRef.current,
        locations,
        googleMaps,
        onSiteClick: setActiveSite,
      });
    }
  }, [mapRef, controllerRef, googleMaps, locations]);

  return (
    <Box
      display="flex"
      borderRadius="borderRadius"
      height="100%"
      width="100%"
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
    >
      {/* Mui v4 doesn't have ref prop for Box in its types */}
      {/* https://github.com/mui-org/material-ui/issues/17010#issuecomment-756751645 */}
      <Box
        display="flex"
        flexDirection={{ xs: 'row', sm: 'column' }}
        flexShrink={0}
        zIndex={1}
        borderRadius="borderRadius"
        boxShadow={1}
        paddingX={{ xs: 1, sm: 4 }}
        paddingY={{ xs: 4, sm: 1 }}
        bgcolor={gray[0]}
        overflow="auto"
        maxWidth={{ xs: 'auto', sm: '40%' }}
      >
        {locations.map(location => (
          <Site
            key={location.id}
            location={location}
            active={activeSiteId === location.id}
            onSetActive={setActiveSite}
          />
        ))}
      </Box>
      <Box
        {...{ ref: mapRef }}
        flexGrow={1}
        flexShrink={0}
        borderRadius="borderRadius"
        bgcolor="rgba(0, 0, 0, 0.1)"
        marginBottom={{ xs: -4, sm: 0 }}
        marginLeft={{ xs: 0, sm: -4 }}
        // Move the logo to compensate negative margins above
        className={classes.moveGoogleLogo}
        width={{ xs: '100%', sm: 'auto' }}
        height={{ xs: 320, sm: 'auto' }}
      />
    </Box>
  );
};
