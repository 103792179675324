import { ComponentType, FC, ReactNode, useCallback, useRef } from 'react';

import { AppBar, Box, BoxProps, Container, IconButton, Toolbar, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useEffectOnce, useToggle } from 'react-use';

import { CloseIcon } from 'shared/src/icon/icons';
import { gray } from 'shared/src/palette/color-system';

import { DrawerContentComponent, DrawerTrigger } from './components/drawer-trigger';

interface NavBarProps {
  logo: ReactNode;
  ToolbarContent: ComponentType;
  DrawerContent: DrawerContentComponent;
  localeSelector?: ReactNode;
  showDrawerTrigger?: boolean;
  maxWidth?: number;
}

const SPACING_XS_UP = 8;
const SPACING_MD_UP = 10;

const useStyles = makeStyles(theme => ({
  dummyAppBar: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer - 1,
    height: theme.spacing(SPACING_XS_UP),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(SPACING_MD_UP),
    },
  },
  root: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: gray[0],
  },
  toolbar: {
    height: theme.spacing(SPACING_XS_UP),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(SPACING_MD_UP),
    },
    justifyContent: 'space-between',
  },
}));

export const NavBar: FC<NavBarProps> = ({
  logo,
  localeSelector,
  ToolbarContent,
  DrawerContent,
  showDrawerTrigger,
  maxWidth,
}: NavBarProps) => {
  const classes = useStyles();

  const [isDrawerOpen, toggleDrawerOpen] = useToggle(false);
  const onCloserDrawer = useCallback(() => toggleDrawerOpen(false), [toggleDrawerOpen]);

  return (
    <>
      {/* Double appbar to show dropshadow at zIndex below the drawer, so it doesn't draw on top when drawer is open */}
      <AppBar
        elevation={1}
        component="div"
        position={isDrawerOpen ? 'fixed' : 'relative'}
        className={classes.dummyAppBar}
      />
      <AppBar
        color="transparent"
        elevation={0}
        position={isDrawerOpen ? 'fixed' : 'relative'}
        className={classes.root}
        component="nav"
      >
        <Container maxWidth="lg" style={{ maxWidth }}>
          <Toolbar className={classes.toolbar} disableGutters>
            {logo}
            {localeSelector && (
              <Box justifyContent="end" flex={1} display="flex" marginRight={{ sm: 0, md: 2 }}>
                {localeSelector}
              </Box>
            )}
            <Box height="100%" display={{ xs: 'none', md: 'block' }}>
              <ToolbarContent />
            </Box>
            <Box display={{ xs: 'block', md: showDrawerTrigger ? 'block' : 'none' }}>
              <IconButton onClick={toggleDrawerOpen} aria-label={isDrawerOpen ? 'close' : 'open'}>
                {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <DrawerTrigger
                layout="full"
                isOpen={isDrawerOpen}
                onToggleOpen={toggleDrawerOpen}
                anchor="top"
              >
                <DrawerContent onCloseDrawer={onCloserDrawer} />
              </DrawerTrigger>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

/**
 * Adds `full` keyword as an additional value of `minHeight` prop in object from
 * that makes the box to take the viewport height minus toolbar height, e.g.
 * <FullPageBox minHeight={{xs: 'full', md: 0}}>...</FullPageBox>
 */
export const FullPageBox: FC<BoxProps> = ({ minHeight, ...props }) => {
  const theme = useTheme();
  const boxRef = useRef<HTMLElement>(null);

  const updateHeight = () => {
    if (boxRef.current != null) {
      boxRef.current.style.setProperty('--full-page-box-height', `${window.innerHeight}px`);
    }
  };

  useEffectOnce(() => {
    let lastWidth = window.innerWidth;
    let lastHeight = window.innerHeight;

    const hasOrientationChanged = () => {
      const wentLandscape = lastWidth < lastHeight && window.innerWidth > window.innerHeight;
      const wentPortrait = lastWidth > lastHeight && window.innerWidth < window.innerHeight;
      return wentLandscape || wentPortrait;
    };

    const onResize = () => {
      // Only update the height when changing orientation. This avoids jumps in the height of the content when
      // the mobile browser chrome disappears/appears with scrolling.
      if (hasOrientationChanged()) {
        updateHeight();
      }
      lastWidth = window.innerWidth;
      lastHeight = window.innerHeight;
    };

    updateHeight();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  const getCalcExpression = (spacing: number) =>
    `calc(var(--full-page-box-height) - ${theme.spacing(spacing)}px)`;

  const transformMinHeight = (minHeightBreakpoints: BoxProps['minHeight']) =>
    Object.fromEntries(
      Object.entries(minHeightBreakpoints).map(([breakpoint, value]) => [
        breakpoint,
        value === 'full'
          ? getCalcExpression(
              breakpoint === 'xs' || breakpoint === 'sm' ? SPACING_XS_UP : SPACING_MD_UP
            )
          : value,
      ])
    );

  return (
    <Box
      {...props}
      // Required because the mui type defs don't include `ref` on a `Box` component, even though it exists
      // See https://github.com/mui-org/material-ui/issues/17010
      // TODO: Replace with ref={boxRef} after upgrading to material UI 5
      {...{ ref: boxRef }}
      minHeight={typeof minHeight === 'object' ? transformMinHeight(minHeight) : minHeight}
    />
  );
};
