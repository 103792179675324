import { FC } from 'react';

import { di } from 'react-magnetic-di/macro';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { useLocation } from 'react-use';

export const InsightsRedirect: FC<Omit<RouteProps, 'component'>> = props => (
  <Route {...props} component={InsightsFauxRouteContent} />
);

const InsightsFauxRouteContent: FC = () => {
  di(useLocation);
  const { pathname, search } = useLocation();

  const pathnameWithPrefixRemoved = pathname ? pathname.replace('/insights', '') : '';

  window.location.replace(
    `${import.meta.env.VITE_INSIGHTS_URL}${pathnameWithPrefixRemoved}${search}`
  );

  return null;
};
