import { useContext } from 'react';

import { useUpdateFeatureFlagUser } from 'mats/src/components/mats-feature-flag-provider/feature-flag-user-update';

import { AuthContext, authContext } from './auth';

export type LoggedInUser = AuthContext & {
  logoutUser: () => void;
};

export const useAuth = (): LoggedInUser => {
  const updateFFUser = useUpdateFeatureFlagUser();
  const auth = useContext(authContext);

  if (!auth) {
    throw new Error('AuthProvider is missing in the tree');
  }

  const logoutUser = () => {
    auth.removeAuthToken();
    updateFFUser();
  };

  return {
    ...auth,
    logoutUser,
  };
};
