import { FC } from 'react';

import { Box } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Route } from 'react-router';

import { NotFoundPageContent } from 'shared/src/error-page-content/not-found-page-content';

import { PageBase } from 'components/page-base/page-base';

export const NotFound: FC = () => {
  di(PageBase);

  // Set status code for SSR, see
  // https://reactrouter.com/web/guides/server-rendering/404-401-or-any-other-status
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = 404;
        }

        return (
          <PageBase windowTitle="Page not found">
            <Box marginY={12}>
              <NotFoundPageContent />
            </Box>
          </PageBase>
        );
      }}
    />
  );
};
