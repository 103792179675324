import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';

import { REDIRECT_FRAGMENT } from 'components/contentful-redirect/redirect.contentful';

import {
  ConditionLandingPageBySlug,
  ConditionLandingPageBySlugVariables,
} from './types/queries.contentful.generated';

const CONDITION_LANDING_PAGE_BY_SLUG = gql`
  query ConditionLandingPageBySlug(
    $slugForLandingPage: String!
    $slug: String!
    $preview: Boolean
  ) {
    conditionLandingPageCollection(
      where: { slug: $slugForLandingPage }
      preview: $preview
      limit: 1
    ) {
      items {
        title
        windowTitle
        introParagraph {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        mainImage {
          ...AssetFragment
        }
        conditionId
        contentImage {
          ...AssetFragment
        }
        contentTitle
        mainContent {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        themeColor
        foundation {
          name
          description {
            json
            links {
              assets {
                block {
                  ...AssetFragment
                }
              }
              entries {
                block {
                  ...EmbeddedEntryFragment
                }
                hyperlink {
                  ...SluggableEntryFragment
                }
              }
            }
          }
          image {
            ...AssetFragment
          }
          foundationType
        }
        conditionSearchFooterTitle
        referencesFooter {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        statsCollection(limit: 3) {
          items {
            sys {
              id
            }
            bigNumber
            description {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
          }
        }
        faqsCollection(limit: 20) {
          items {
            sys {
              id
            }
            question
            answer {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
          }
        }
        resourcesCollection(limit: 10) {
          items {
            sys {
              id
            }
            title
            icon {
              ...AssetFragment
            }
            description {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
          }
        }
      }
    }

    ...RedirectFragment
  }
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
  ${REDIRECT_FRAGMENT}
`;

export const useConditionLandingPageBySlug = contentfulQueryFactory<
  ConditionLandingPageBySlug,
  ConditionLandingPageBySlugVariables
>(CONDITION_LANDING_PAGE_BY_SLUG);
