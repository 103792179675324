// Adds feature flag information onto analytics events
import { FC, useEffect } from 'react';

import { EventProperty, useAnalytics } from 'shared/src/analytics/analytics';
import { useConfigCat } from 'shared/src/feature-flags/feature-flag-provider';

export const AnalyticsFlagContextConsumer: FC = () => {
  const analytics = useAnalytics();
  const flagState = useConfigCat();
  const flagValues = flagState?.flagValues;

  useEffect(() => {
    if (flagValues) {
      const eventSanitisedFlags = Object.fromEntries(
        [...flagValues].filter(
          (entry): entry is [string, EventProperty] =>
            typeof entry[1] === 'boolean' ||
            typeof entry[1] === 'number' ||
            typeof entry[1] === 'string'
        )
      );
      analytics.setContext('featureFlags', eventSanitisedFlags);
    }
  }, [analytics, flagValues]);

  return null;
};
