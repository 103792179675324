import { FC, useEffect } from 'react';

import * as braze from '@braze/web-sdk';

import { useAuth } from 'mats/src/components/auth/use-auth';

export const BrazeSdk: FC = () => {
  const user = useAuth();
  const userId = user.getAuthenticatedUserId();

  useEffect(() => {
    if (userId) {
      braze.initialize(import.meta.env.VITE_BRAZE_API_KEY, {
        baseUrl: import.meta.env.VITE_BRAZE_URL,
        allowUserSuppliedJavascript: true,
      });
      braze.automaticallyShowInAppMessages();

      braze.changeUser(userId.toString());

      braze.openSession();
    }
  }, [userId]);

  return null;
};
