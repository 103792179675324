import { Box, Container, Grid, makeStyles } from '@material-ui/core';

import { AspectRatioContainer } from 'shared/src/aspect-ratio-container/aspect-ratio-container';
import { CarouselWithDots } from 'shared/src/carousel/carousel';
import { FullCoverImage } from 'shared/src/image/image';

import allHandsPhoto from './images/all-hands.jpg';
import dulanYCPhoto from './images/dulan-yc.jpg';
import mollyPhoto from './images/molly.jpg';

const useStyles = makeStyles(theme => ({
  carouselPhoto: {
    borderRadius: theme.spacing(1),
  },
  collagePhoto: {
    borderRadius: theme.shape.borderRadius,
  },
}));

export const BottomImagesSection = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box marginY={{ xs: 7, sm: 10 }}>
        <Box display={{ xs: 'block', sm: 'none' }}>
          <CarouselWithDots
            autoPlay
            slides={[dulanYCPhoto, allHandsPhoto, mollyPhoto]}
            renderSlide={({ slide, key }) => (
              <Box marginY={1} key={key}>
                <AspectRatioContainer ratio={6 / 8}>
                  <FullCoverImage src={slide} className={classes.carouselPhoto} alt="" />
                </AspectRatioContainer>
              </Box>
            )}
          />
        </Box>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AspectRatioContainer ratio={6 / 8}>
                <FullCoverImage className={classes.collagePhoto} src={dulanYCPhoto} alt="" />
              </AspectRatioContainer>
            </Grid>
            <Grid item xs={6}>
              <AspectRatioContainer ratio={6 / 8}>
                <FullCoverImage className={classes.collagePhoto} src={allHandsPhoto} alt="" />
              </AspectRatioContainer>
            </Grid>
            <Grid item xs={12}>
              <AspectRatioContainer ratio={6 / 16}>
                <FullCoverImage className={classes.collagePhoto} src={mollyPhoto} alt="" />
              </AspectRatioContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
