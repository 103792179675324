/**
 * An error to throw `default` case of an exhaustive switch when the types and data
 * are defined in our code, meaning that throwing won't break anything in prod
 */
export class UnreachableError extends Error {
  constructor(unreachable: never) {
    super(`Unexpected value ${JSON.stringify(unreachable)}`);
  }
}

/**
 * Alternative method with a fallback result to use when types and data are coming
 * dynamically from a third party like Contentful and it's not safe to have `throw` expression
 */
export const unreachableResult = <T>(type: never, result: T): T => result
