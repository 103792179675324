import { ComponentType, ReactNode, useState } from 'react';

import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';
import {
  autoPlay as withAutoPlay,
  SlideRenderProps,
  virtualize,
} from 'react-swipeable-views-utils';

import { CarouselArrows } from './carousel-arrows';
import { CarouselDots } from './carousel-dots';
import { mod } from './mod';
import { CarouselIndicatorProps } from './types';

export const VirtualizedCarousel = virtualize(SwipeableViews);
export const AutoPlayCarousel = withAutoPlay(VirtualizedCarousel);

export type RenderSlide<T> = (params: { slide: T } & SlideRenderProps) => ReactNode;

type CarouselProps<T> = SwipeableViewsProps & {
  slides: T[];
  renderSlide: RenderSlide<T>;
  autoPlay?: boolean;
  autoPlayInterval?: number;
  dark?: boolean;
};

type CarouselBaseProps<T> = CarouselProps<T> & {
  Indicator: ComponentType<CarouselIndicatorProps>;
};

export function CarouselBase<T>({
  slides,
  renderSlide,
  Indicator,
  autoPlay,
  dark,
  ...props
}: CarouselBaseProps<T>) {
  const [index, setIndex] = useState(0);
  const count = slides.length;

  if (!count) {
    return null;
  }

  const Carousel = autoPlay ? AutoPlayCarousel : VirtualizedCarousel;

  // Limit overscan to prevent duplicated renders
  const maxOverScan = Math.floor((count - 1) / 2);
  return (
    <>
      <Carousel
        // 2 and 3 — library default
        overscanSlideAfter={Math.min(maxOverScan, 2)}
        overscanSlideBefore={Math.min(maxOverScan, 3)}
        {...props}
        index={index}
        onChangeIndex={setIndex}
        slideRenderer={params => {
          const slideIndex = mod(params.index, count);
          return renderSlide({ slide: slides[slideIndex], index: slideIndex, key: params.key });
        }}
        interval={autoPlay ? props.autoPlayInterval : undefined}
      />
      <Indicator dark={dark} count={count} index={index} onChange={setIndex} />
    </>
  );
}

export function CarouselWithArrows<T>(props: CarouselProps<T>) {
  return <CarouselBase Indicator={CarouselArrows} {...props} />;
}

export function CarouselWithDots<T>(props: CarouselProps<T>) {
  return <CarouselBase Indicator={CarouselDots} {...props} />;
}
