import { gql } from '@apollo/client';

import { queryFactory } from 'shared/src/util/query-factory';

import {
  getCondition,
  getConditionVariables,
} from 'components/condition-select-button/types/queries.generated';

const GET_CONDITION = gql`
  query getCondition($conditionId: ConditionID!) {
    condition(id: $conditionId) {
      id
      name
    }
  }
`;

export const useGetConditionQuery = queryFactory<getCondition, getConditionVariables>(
  GET_CONDITION
);
