import { FC } from 'react';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Box, Breadcrumbs, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Element } from 'react-scroll';
import { MedicalWebPage } from 'schema-dts';

import healthmatchLogo from 'shared/src/assets/healthmatch-logo-primary.svg';
import { RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { Link } from 'shared/src/link/link';
import { ShareSocialIcons } from 'shared/src/social-icons/social-icons';
import { notEmpty } from 'shared/src/util/not-empty';

import { usePublicTrialsForCondition } from 'mats/src/components/trial-list-public/queries';

import { ArticleContentMenu } from 'components/article-content-menu/article-content-menu';
import { ArticleRichText } from 'components/article-rich-text/article-rich-text';
import { ArticleMenuCta } from 'components/article-sticky-menu/article-menu-cta/article-menu-cta';
import {
  ArticleStickyMenu,
  isSectionHeading,
} from 'components/article-sticky-menu/article-sticky-menu';
import { ContentLinks } from 'components/content-links/content-links';
import { ContentMeta } from 'components/content-meta/content-meta';
import { ContentThemeProvider } from 'components/content-theme/content-theme';
import { getRedirectIfExists } from 'components/contentful-redirect/contentful-redirect';
import { FootNotes } from 'components/foot-notes/foot-notes';
import { FooterWaveBleedMode } from 'components/footer/footer';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import {
  ConditionPageMeta,
  RelatedConditionLinks,
} from 'components/related-condition-links/related-condition-links';
import { CanonicalUrl, OgArticleType } from 'components/seo/meta-data-fragments';
import { useFaqSchemaFromRichText } from 'components/seo/use-faq-schema-from-rich-text';
import { PublicTrialsSection } from 'pages/condition-category/components/public-trials-section/public-trials-section';
import {
  MedicationArticle,
  useMedicationArticleBySlug,
} from 'pages/medication-article/queries.contentful';
import { NotFound } from 'pages/not-found/not-found';

import { Disclaimer } from './disclaimer/disclaimer';

type MedicationArticleProps = {
  entry: MedicationArticle;
};

const useStyles = makeStyles(({ palette, typography, breakpoints }) => ({
  sharePost: {
    color: palette.text.secondary,
    '&& a:hover': {
      color: palette.primary.main,
    },
  },
  faqTitle: {
    fontSize: typography.pxToRem(55),
  },
  shownOnMobile: {
    display: 'none',
    [breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

const medicationArticleFooterBleedConfig: FooterWaveBleedMode = {
  kind: 'background',
  // not sure why this isn't a standard gray, have to revisit...
  color: '#f3f3f3',
};

export const MedicationArticlePage: FC<MedicationArticleProps> = ({ entry }) => {
  di(useLocation, PageBase, usePublicTrialsForCondition);

  const classes = useStyles();
  const { pathname } = useLocation();

  const {
    author,
    medicalReviewer,
    textBody,
    faqs,
    title,
    references,
    conditionCategoriesCollection,
    descriptionMeta,
    titleMeta,
    reviewedAt,
    slug,
    pregnancyWarning,
    prescriptionStatus,
    sys: { publishedAt, firstPublishedAt },
  } = entry;

  const faqPageSchema = useFaqSchemaFromRichText(faqs?.json);

  const latestNews = conditionCategoriesCollection?.items
    .flatMap(c => c?.linkedFrom?.conditionArticleCollection?.items ?? [])
    .filter(notEmpty);

  const menuItems =
    entry.textBody?.json?.content
      .filter(isSectionHeading)
      .map(node => documentToPlainTextString(node)) ?? [];

  if (faqs != null) {
    menuItems.push('FAQ');
  }

  const conditionIdForTrialCards =
    conditionCategoriesCollection?.items?.find(
      c => c?.conditionIdList && c.conditionIdList.length > 0
    )?.conditionIdList?.[0] ?? null;

  const { data: publicTrialsData, loading: publicTrialsLoading } = usePublicTrialsForCondition({
    variables: { conditionId: Number(conditionIdForTrialCards) as ConditionID },
    skip: !conditionIdForTrialCards,
  });

  const relatedConditionTrialsLinks: ConditionPageMeta[] =
    conditionCategoriesCollection?.items
      .map(c =>
        c && c.slug && c.condition
          ? {
              condition: c.condition,
              slug: `${c.slug}/clinical-trials`,
            }
          : null
      )
      .filter(notEmpty) ?? [];

  const hasPublicTrials =
    conditionIdForTrialCards !== null &&
    !publicTrialsLoading &&
    publicTrialsData &&
    publicTrialsData?.searchTrials.length > 0;

  return (
    <PageBase
      windowTitle={title ?? ''}
      descriptionMeta={descriptionMeta ?? ''}
      footerBleed={medicationArticleFooterBleedConfig}
    >
      <Helmet
        script={[
          helmetJsonLdProp<MedicalWebPage>({
            '@context': 'https://schema.org',
            '@type': 'MedicalWebPage',
            headline: titleMeta ?? title ?? undefined,
            description: descriptionMeta ?? undefined,
            datePublished: firstPublishedAt ?? undefined,
            dateModified: publishedAt ?? undefined,
            url: `${import.meta.env.VITE_HMIO_URL}/medications/${slug}`,
            audience: {
              '@type': 'MedicalAudience',
              audienceType: 'Patient',
            },
            lastReviewed: reviewedAt ?? publishedAt ?? undefined,
            publisher: {
              '@type': 'Organization',
              name: 'HealthMatch',
              logo: {
                '@type': 'ImageObject',
                url: healthmatchLogo,
              },
            },
            author: {
              '@type': 'Person',
              name: author?.name ?? undefined,
            },
            ...(medicalReviewer
              ? {
                  reviewedBy: {
                    '@type': 'Person',
                    name: medicalReviewer.name ?? undefined,
                  },
                }
              : {}),
            ...(title
              ? {
                  about: {
                    '@type': 'Drug',
                    name: title,
                    pregnancyWarning: pregnancyWarning ?? undefined,
                    prescriptionStatus: prescriptionStatus ?? undefined,
                  },
                }
              : {}),
            ...(faqPageSchema != null ? { hasPart: faqPageSchema } : {}),
          }),
        ]}
      />
      <CanonicalUrl url={`${import.meta.env.VITE_HMIO_URL}${pathname}`} />
      <OgArticleType
        published={entry.sys.firstPublishedAt}
        modified={entry.sys.publishedAt}
        author={entry.author?.name ?? ''}
      />
      <Container>
        <Box display="flex" marginTop={{ xs: 3, sm: 5 }}>
          <div>
            <ContentThemeProvider>
              {textBody && (
                <ArticleRichText
                  document={textBody}
                  injectElementAfterOverview={
                    <div className={classes.shownOnMobile}>
                      <ArticleMenuCta conditionName={undefined} conditionIds={undefined} />
                    </div>
                  }
                >
                  <Box marginBottom={{ xs: 2, md: 5 }}>
                    <Breadcrumbs>
                      <Link color="inherit" to="/">
                        Home
                      </Link>
                      <Link color="inherit" to="/medications">
                        Medications
                      </Link>
                      <Link color="inherit" to={pathname}>
                        {title}
                      </Link>
                    </Breadcrumbs>
                  </Box>
                  <Typography variant="h1" gutterBottom>
                    {title}
                  </Typography>
                  <Box marginY={{ xs: 3, sm: 5 }}>
                    <ContentMeta
                      author={author}
                      medicalReviewer={medicalReviewer}
                      updatedAt={entry.sys.publishedAt}
                    />
                  </Box>
                  <ArticleContentMenu items={menuItems} />
                </ArticleRichText>
              )}
            </ContentThemeProvider>
            {faqs && (
              <Box marginY={5}>
                <Typography variant="h1" component="h2" className={classes.faqTitle}>
                  FAQ
                </Typography>
                <ContentThemeProvider>
                  <RichText document={faqs} />
                </ContentThemeProvider>
              </Box>
            )}
            {references && (
              <Element name="faq">
                <Box marginY={{ xs: 7, sm: 10 }}>
                  <FootNotes title="Sources">
                    <RichText document={references} />
                  </FootNotes>
                </Box>
              </Element>
            )}
            <Box marginTop={{ xs: 7, md: 10 }} display={{ xs: 'block', md: 'none' }}>
              <div className={classes.sharePost}>
                <Typography gutterBottom variant="h5">
                  Share this story
                </Typography>
                <ShareSocialIcons shareText={title ?? ''} />
              </div>
            </Box>
          </div>
          <ArticleStickyMenu
            articleTitle={title ?? ''}
            conditionIds={undefined}
            conditionName={undefined}
          />
        </Box>
        {hasPublicTrials && (
          <>
            <Divider />
            <Box paddingTop={10}>
              <PublicTrialsSection trialsForConditionData={publicTrialsData} />
            </Box>
          </>
        )}
        {relatedConditionTrialsLinks && relatedConditionTrialsLinks.length > 0 && (
          <Box paddingTop={5}>
            <Box paddingBottom={2}>
              <Typography variant="h6" align="center">
                View related condition trials page
              </Typography>
            </Box>
            <RelatedConditionLinks links={relatedConditionTrialsLinks} />
          </Box>
        )}
        {latestNews && latestNews.length > 0 && (
          <Box paddingY={10}>
            <ContentLinks links={latestNews} title="Latest news" />
          </Box>
        )}
        <Box paddingY={10}>
          <Disclaimer />
        </Box>
      </Container>

      <Box bgcolor="#f3f3f3" marginTop={7} paddingY={7} display={{ xs: 'block', md: 'none' }}>
        <Container>
          <ArticleMenuCta conditionName={undefined} />
        </Container>
      </Box>
    </PageBase>
  );
};

// eslint-disable-next-line import/no-unused-modules
export const MedicationArticleRoute: FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {
  di(useMedicationArticleBySlug);

  const { data, loading, error } = useMedicationArticleBySlug({
    variables: {
      slug: match.params.slug,
    },
  });

  const redirect = getRedirectIfExists(data?.redirectCollection?.items[0]);

  if (redirect) {
    return redirect;
  }

  if (loading) {
    return <LoadingPage windowTitle="Loading" />;
  }

  const entry = data?.medicationArticleCollection?.items[0];

  if (error || !entry) {
    return <NotFound />;
  }

  return <MedicationArticlePage entry={entry} />;
};
