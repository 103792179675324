import { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import { Link } from 'shared/src/link/link';

type MedicationData = {
  slug: string;
  title: string;
};

type MedicationsListSectionProps = {
  conditionName: string;
  medicationsData: MedicationData[];
};

const useStyles = makeStyles(({ typography, spacing }) => ({
  exploreTitle: {
    fontSize: typography.pxToRem(35),
    paddingBottom: spacing(5),
  },
}));

export const MedicationsListSection: FC<MedicationsListSectionProps> = ({
  medicationsData,
  conditionName,
}) => {
  const classes = useStyles();

  const hasMedications = medicationsData.length > 0;

  if (!hasMedications) {
    return null;
  }

  return (
    <Container>
      <Grid container className={classes.exploreTitle}>
        <Grid item container xs={12} lg={9}>
          <Typography variant="h3" gutterBottom noWrap>
            Medications for {conditionName.toLocaleLowerCase()}
          </Typography>
        </Grid>
        <Grid container item xs={12} lg={3} direction="column" justifyContent="center">
          <Typography color="textSecondary" variant="body2" gutterBottom noWrap>
            <Link color="inherit" to="/medications">
              View all medications
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)">
        {medicationsData.map(({ title, slug }) => (
          <Typography key={title} color="textSecondary" variant="body2" gutterBottom>
            <Link color="inherit" to={`/medications/${slug}`}>
              {title}
            </Link>
          </Typography>
        ))}
      </Box>
    </Container>
  );
};
