import { Container, Grid, Typography, Box, makeStyles } from '@material-ui/core';

import manuri from './images/manuri.jpg';

const useStyles = makeStyles(theme => ({
  image: {
    borderRadius: theme.spacing(2),
    alignSelf: 'center',
    width: '100%',
  },
}));

export const FounderSection = () => {
  const classes = useStyles();

  const header = (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        Our founder&apos;s story
      </Typography>
      <Typography variant="h2" gutterBottom>
        Manuri Gunawardena
      </Typography>
    </Box>
  );

  const photo = <img src={manuri} alt="Manuri Gunawardena" className={classes.image} />;

  const body = (
    <Box>
      <Typography paragraph>
        I founded HealthMatch in 2017, after witnessing first hand the challenges of patients
        looking to gain access to clinical trials.
      </Typography>
      <Typography paragraph>
        In my final years of medical school, I worked in neuro-oncology research particularly
        focussed on Glioblastoma multiforme (GBM) tumours, an incurable and aggressive form of brain
        cancer.
      </Typography>
      <Typography paragraph>
        At the lab I came to learn of two young people, both in their thirties with the same
        diagnosis. A young man and woman, in the prime of their lives. The woman’s father was a
        doctor, and could effectively navigate the medical system. He worked tirelessly to get her
        onto a trial. She’s alive today. I unfortunately heard this story from the young man’s
        parents after he had passed away, who had wished they had known of trials as an option.
      </Typography>
      <Typography paragraph>
        It was incredibly frustrating seeing how difficult it was for researchers to find
        appropriate patients and how reliant patients were on their doctors having knowledge, I
        became resolute in finding a solution for connecting patients to clinical trials. Knowledge
        of treatment options should not be limited by geography or access to leading specialists.
      </Typography>
      <Typography paragraph>
        HealthMatch is about empowering patients to not only understand their treatment options, but
        gain access. Every person deserves this and by reaching the largest audience as a global
        community we all benefit from fast-tracked new medicines.
      </Typography>
    </Box>
  );

  return (
    <Box paddingY={{ xs: 7, md: 10 }}>
      <Container maxWidth="lg">
        <Box display={{ xs: 'none', sm: 'block' }}>
          <Grid container>
            <Grid item container justifyContent="center" xs={5}>
              {photo}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              {header}
              {body}
            </Grid>
          </Grid>
        </Box>
        <Box display={{ xs: 'block', sm: 'none' }}>
          <Box marginY={2}>{header}</Box>
          <Box marginY={2}>{photo}</Box>
          <Box marginY={2}>{body}</Box>
        </Box>
      </Container>
    </Box>
  );
};
