import { FC } from 'react';

import {
  DropdownContentDrawerButton,
  DropdownContentToolbarButton,
} from 'components/header-nav/components/dropdown-drawer-button/dropdown-content-button';

import { Sites } from './sites';

export const SitesDrawerButton: FC<{ onNavigation: () => void }> = ({ onNavigation }) => (
  <DropdownContentDrawerButton title="For sites" onNavigation={onNavigation}>
    <Sites showGraphic={false} />
  </DropdownContentDrawerButton>
);

export const SitesToolbarButton: FC = () => (
  <DropdownContentToolbarButton title="For sites">
    <Sites showGraphic />
  </DropdownContentToolbarButton>
);
