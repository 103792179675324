import { Box, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { countryNames } from 'shared/src/constants/constants';
import { PinIcon } from 'shared/src/icon/icons';

interface Props {
  locations: Record<string, StateCode[]>;
  selectedLocation?: {
    countryCode: CountryCode;
    state: StateCode;
  };
}

const STATES_TO_SHOW = 4;

const StatesList = ({ states, selectedState }: { states: string[]; selectedState?: string }) => {
  const hasSelectedState = states.find(s => s === selectedState) !== undefined;

  const statesToShow = states.sort().filter(state => state !== selectedState);

  const hiddenStateCount = statesToShow.length - STATES_TO_SHOW + (hasSelectedState ? 1 : 0);

  return (
    <span>
      {hasSelectedState && selectedState && (
        <>
          <b>{selectedState}</b>
          {statesToShow.length > 0 && ', '}
        </>
      )}
      {statesToShow.slice(0, hasSelectedState ? STATES_TO_SHOW - 1 : STATES_TO_SHOW).join(', ')}
      {hiddenStateCount === 1
        ? `, ${statesToShow[hasSelectedState ? STATES_TO_SHOW - 1 : STATES_TO_SHOW]}`
        : null}
      {hiddenStateCount > 1 ? ` and ${hiddenStateCount} more locations` : null}
    </span>
  );
};

export const LocationsList = ({ locations, selectedLocation }: Props) => {
  di(PinIcon);

  const locationCount = Object.keys(locations).length;

  if (locationCount === 0) {
    return null;
  }

  // more than one country, just show countries
  if (locationCount > 1) {
    return (
      <Typography variant="body1" component="div" gutterBottom>
        {(Object.entries(locations) as [CountryCode, StateCode[]][])
          .sort(([countryCodeA], [countryCodeB]) => countryCodeA.localeCompare(countryCodeB))
          .map(([countryCode]) => (
            <span key={countryCode}>
              <PinIcon fontSize="inherit" />
              <Box marginX={0.5} component="span">
                {selectedLocation?.countryCode === countryCode ? (
                  <b>{countryNames.get(countryCode)}</b>
                ) : (
                  countryNames.get(countryCode)
                )}
              </Box>
            </span>
          ))}
      </Typography>
    );
  }

  const [countryCode, states] = Object.entries(locations)[0] as [CountryCode, StateCode[]];

  const selectedState =
    countryCode === selectedLocation?.countryCode
      ? states.find(s => s === selectedLocation?.state)
      : undefined;

  return (
    <Typography variant="body1" component="div" gutterBottom>
      <span key={countryCode}>
        <PinIcon fontSize="inherit" />
        <Box marginX={0.5} component="span">
          {states.length > 0 && (
            <>
              <StatesList states={states} selectedState={selectedState} /> (
              {selectedLocation?.countryCode === countryCode ? <b>{countryCode}</b> : countryCode})
            </>
          )}
          {states.length === 0 &&
            (selectedLocation?.countryCode === countryCode ? (
              <b>{countryNames.get(countryCode)}</b>
            ) : (
              countryNames.get(countryCode)
            ))}
        </Box>
      </span>
    </Typography>
  );
};
