import { FC } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import SsrCookies from 'ssr-cookie';

import { PageAnalytics } from 'shared/src/analytics/analytics';
import { ErrorBoundary, ErrorBoundaryProps } from 'shared/src/error-boundary/error-boundary';
import { ContextStorageProvider } from 'shared/src/util/context-storage/context-storage-provider';

import { AuthProvider } from 'mats/src/components/auth/auth';
import { BrazeSdk } from 'mats/src/components/braze-sdk/braze-sdk';
import { EmailProvider } from 'mats/src/components/email-context/email-context';
import { PatientLocaleConsumer } from 'mats/src/components/use-patient-locale/patient-locale-consumer';

import { HmioAnalyticsProvider } from 'components/analytics/analytics';
import { MakeApolloClientCallback } from 'components/app/apollo-provider';
import { ErrorPage } from 'components/error/error-page';
import { HmioFeatureFlagProvider } from 'components/hmio-feature-flag-provider/hmio-feature-flag-provider.client';
import { HmioClientLocaleProvider } from 'components/hmio-locale-provider/hmio-locale-provider.client';
import { HubspotEventPublisher } from 'components/hubspot-event-publisher/hubspot-event-publisher';

import { hmioPages, pages } from './pages';
import { SharedProviders } from './shared-providers';

const RoutableErrorBoundary: FC<ErrorBoundaryProps> = props => {
  const location = useLocation();
  // Reset error state after navigation
  return <ErrorBoundary {...props} errorKey={location.key} />;
};

const ssrCookies = new SsrCookies();

export const AppClient: FC<{
  onCreateApolloProvider: MakeApolloClientCallback;
}> = ({ onCreateApolloProvider }) => (
  <ErrorBoundary logger={datadogLogs.logger}>
    <ContextStorageProvider>
      <AuthProvider ssrCookies={ssrCookies}>
        <HelmetProvider>
          <HmioClientLocaleProvider>
            <HmioAnalyticsProvider>
              <SharedProviders onCreateApolloProvider={onCreateApolloProvider} swrFallback={null}>
                <HmioFeatureFlagProvider>
                  <EmailProvider>
                    <BrowserRouter>
                      <RoutableErrorBoundary logger={datadogLogs.logger} ErrorComponent={ErrorPage}>
                        <PatientLocaleConsumer>
                          <PageAnalytics />
                          <HubspotEventPublisher />
                          <BrazeSdk />
                          <Switch>
                            {hmioPages}
                            {pages}
                          </Switch>
                        </PatientLocaleConsumer>
                      </RoutableErrorBoundary>
                    </BrowserRouter>
                  </EmailProvider>
                </HmioFeatureFlagProvider>
              </SharedProviders>
            </HmioAnalyticsProvider>
          </HmioClientLocaleProvider>
        </HelmetProvider>
      </AuthProvider>
    </ContextStorageProvider>
  </ErrorBoundary>
);
