import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
  description?: string;
  hmPrefix?: boolean;
}

export const WindowTitle = ({ title, description, hmPrefix }: Props) => (
  <Helmet>
    <title>
      {hmPrefix === false ? '' : 'HealthMatch - '}
      {title}
    </title>
    <meta name="description" content={description} />
    {title && <meta property="og:title" content={title} />}
  </Helmet>
);

WindowTitle.defaultProps = {
  title: 'easily find the right clinical trial',
  description:
    'Gain quick and easy access to clinical trials from a network of care teams around the world.',
};
