import { ReactNode } from 'react';

import Card from '@material-ui/core/Card';
import { makeStyles, StyledComponentProps } from '@material-ui/core/styles';

// TODO: This is no way to organise components.

const useStyles = makeStyles({
  card: {
    paddingTop: '15px',
    border: 'unset',
    marginBottom: '30px',
    overflow: 'initial',
  },
});

interface RoundedCardProps extends StyledComponentProps {
  children: ReactNode | ReactNode[];
}

export const RoundedCard = ({ children, ...styleProps }: RoundedCardProps) => {
  const classNames = useStyles();
  return (
    <Card className={classNames.card} {...styleProps}>
      {children}
    </Card>
  );
};
