import { Box, Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { AssetInfo, ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichTextData, RichText } from 'shared/src/contentful-text/rich-text-renderer';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { ConditionSelectButton } from 'components/condition-select-button/condition-select-button';

interface Props {
  titleText: string;
  introContent: RichTextData;
  mainImage: AssetInfo;
  featuredCondition?: ConditionID;
}

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 100,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 64,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  mainImage: {
    width: '100%',
  },
  contentPane: {
    paddingTop: 16,
  },
}));

export const TitleSection = ({ titleText, introContent, mainImage, featuredCondition }: Props) => {
  di(ConditionSelectPanel, ConditionSelectButton);

  const classNames = useStyles();

  return (
    <Container>
      <Box marginBottom={{ xs: 5, md: 10 }} marginTop={{ xs: 0, md: 10 }}>
        <Grid container justifyContent="space-between" wrap="wrap-reverse">
          <Grid item md={6} xs={12}>
            <ContentfulImage maxWidth="md" image={mainImage} className={classNames.mainImage} />
          </Grid>
          <Grid item md={5} xs={12}>
            <div className={classNames.contentPane}>
              <Typography variant="h2" className={classNames.sectionTitle}>
                {titleText}
              </Typography>
              <Box marginBottom={4}>
                <RichText document={introContent} />
              </Box>
              <Box marginBottom={4} textAlign="center">
                {featuredCondition ? (
                  <ConditionSelectButton conditionId={featuredCondition} />
                ) : (
                  <ConditionSelectPanel />
                )}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
