import { FC } from 'react';

import { Box, Container, Grid, Typography, useTheme } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { ErrorPage } from 'components/error/error-page';
import { Directory } from 'components/guides-directory/directory/directory';
import { useDirectoryItemsGroupedByTitle } from 'components/guides-directory/directory/use-directory-items-grouped-by-title';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';

import { useMedicationDirectoryMedicationArticles } from './queries.contentful';

type GuidesDirectoryProps = {};

export const MedicationsDirectory: FC<GuidesDirectoryProps> = () => {
  di(
    useMedicationDirectoryMedicationArticles,
    PageBase,
    useDirectoryItemsGroupedByTitle,
    LoadingPage,
    ErrorPage,
    Directory
  );

  const theme = useTheme();

  const { data, error, loading } = useMedicationDirectoryMedicationArticles();

  const directoryData = useDirectoryItemsGroupedByTitle(
    data?.medicationArticleCollection?.items,
    i => i.medicationName || i.genericMedicationName,
    i => `medications/${i.slug}`,
    [data?.medicationArticleCollection?.items]
  );

  if (loading) {
    return <LoadingPage windowTitle="Guide directory" />;
  }

  if (error || !directoryData) {
    return <ErrorPage />;
  }

  return (
    <PageBase windowTitle="Medication directory">
      <Container>
        <Box paddingTop={5}>
          <Typography variant="h1">Medication Directory</Typography>
        </Box>
        <Box paddingY={3}>
          <Typography variant="body2" color="textSecondary">
            View our full library of medication guides
          </Typography>
        </Box>
        <Grid container>
          <Directory inputs={directoryData} legendTitle="Browse by medication name" />
        </Grid>
      </Container>
      <Box height={theme.spacing(10)} />
    </PageBase>
  );
};
