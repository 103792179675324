import { FC } from 'react';

import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeepExtractType } from 'ts-deep-extract-types';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { notEmpty } from 'shared/src/util/not-empty';

import { ConditionLinkButton } from 'components/condition-link-button/condition-link-button';
import { ContentMeta } from 'components/content-meta/content-meta';
import { ConditionCategoryBySlugContent } from 'pages/condition-category/queries.contentful';

import { SectionLink, SectionTypes } from './section-link';

const heroImageMaxWidth = 720;

const useStyles = makeStyles(({ spacing, shape }) => ({
  image: {
    width: '100%',
    borderRadius: shape.borderRadius,
    maxWidth: heroImageMaxWidth,
  },
  banner: {
    margin: 'auto',
    marginTop: spacing(5),
    maxWidth: spacing(120),
  },
}));

type RawSections = DeepExtractType<ConditionCategoryBySlugContent, ['sectionsCollection', 'items']>;

export const useSectionArray = (
  sectionsIn: RawSections,
  hasTrials?: boolean,
  hasMedications?: boolean
): SectionTypes[] => {
  const sections: SectionTypes[] =
    sectionsIn == null
      ? ['Overview']
      : ['Overview', ...sectionsIn.map(section => section?.type).filter(notEmpty)];

  if (hasTrials) {
    sections.push('Clinical trials');
  }

  if (hasMedications) {
    sections.push('Medications');
  }

  return sections;
};

export const CategoryHeroBanner: FC<{
  category: ConditionCategoryBySlugContent;
  hasTrials?: boolean;
  hasMedications?: boolean;
}> = ({ category, hasTrials, hasMedications }) => {
  const { title, author, medicalReviewer, subtitle, heroImage, subpagesCollection } = category;
  const classes = useStyles();

  const sections = useSectionArray(
    category.sectionsCollection?.items ?? [],
    hasTrials,
    hasMedications
  );
  const middle = Math.ceil(sections.length / 2);
  const columns = [sections.slice(0, middle), sections.slice(middle)];
  const subpages = subpagesCollection?.items
    .map(linkOrPage => {
      if (!linkOrPage) {
        return null;
      }
      const page =
        linkOrPage.__typename === 'ConditionArticle' ? linkOrPage : linkOrPage.linkedPage;
      return page ? { slug: page.slug, title: linkOrPage.title } : null;
    })
    .filter(notEmpty);

  return (
    <>
      <Container disableGutters maxWidth="sm">
        <Typography variant="h1" align="center">
          {title}
        </Typography>
      </Container>
      <Box marginTop={{ xs: 3, sm: 5 }}>
        <ContentMeta
          author={author}
          medicalReviewer={medicalReviewer}
          updatedAt={category.sys.publishedAt}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="stretch"
        margin="auto"
        marginTop={{ xs: 5, sm: 7 }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        {heroImage && (
          <ContentfulImage
            image={heroImage}
            maxWidth={heroImageMaxWidth}
            className={classes.image}
            lazy={false}
          />
        )}
        <Box marginLeft={{ xs: 0, sm: 6 }} marginTop={{ xs: 4, sm: 0 }}>
          <Box textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography variant="h5">Your complete guide</Typography>
          </Box>
          <Box display="flex">
            {columns.map(column => (
              <Box key={column[0]} marginRight={2} marginLeft={{ xs: 2, sm: 0 }} flexGrow={1}>
                {column.map(section => (
                  <Box key={section} marginY={4} marginX={{ xs: 0, sm: 2 }}>
                    <SectionLink type={section} />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {subpages && subpages.length > 0 && (
        <Box marginTop={{ xs: 7, sm: 10 }}>
          <Box marginX={4} textAlign={{ sm: 'center' }}>
            <Typography variant="h6">{subtitle}</Typography>
          </Box>
          <Box display="flex" justifyContent="center" marginTop={1}>
            <Box display="flex" overflow="auto" paddingX={2}>
              {subpages.map(subpage => (
                <Box marginTop={1} paddingLeft={1} paddingBottom={2} key={subpage.slug}>
                  <ConditionLinkButton to={`/${subpage.slug}`}>{subpage.title}</ConditionLinkButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
