import { FC, useMemo } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ContentfulDate } from 'shared/src/contentful-text/components/date';
import { ArrowRightIcon } from 'shared/src/icon/icons';
import { Link, ButtonLink } from 'shared/src/link/link';
import { notEmpty } from 'shared/src/util/not-empty';

import { TopicHubPageList } from 'pages/topic-hub-page/types/queries.contentful.generated';

type Article = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items', 'articlesCollection', 'items']
>;
type Category = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items', 'articlesCollection', 'items', 'category']
>;
type Reviewer = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items', 'articlesCollection', 'items', 'medicalReviewer']
>;
type Sys = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items', 'articlesCollection', 'items', 'sys']
>;

type TopicDenulledArticle = Omit<
  Article,
  'title' | 'slug' | 'medicalReviewer' | 'sys' | 'category'
> & {
  title: string;
  slug: string;
  medicalReviewer: Reviewer & { name: string };
  sys: Sys & { firstPublishedAt: string };
  category: Category & { condition: string; slug: string };
};

export const TopicHubCategories: FC<{
  articles?: readonly (Article | null)[] | null;
}> = ({ articles }) => {
  const conditionArticleMapMemo = useMemo(() => buildArticleMap(articles), [articles]);

  const children = Array.from(conditionArticleMapMemo.entries()).map(([title, articleList]) => (
    <Box key={title}>
      <Box marginBottom={4} display="flex" justifyContent="space-between">
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
        <Box display="flex" alignContent="center" alignSelf="flex-end">
          <ButtonLink
            to={`/${articleList[0].category.slug}`}
            variant="text"
            color="primary"
            endIcon={<ArrowRightIcon />}
          >
            Learn more about {title}
          </ButtonLink>
        </Box>
      </Box>
      <Box marginBottom={4}>
        <Divider />
      </Box>
      <Grid container spacing={3}>
        {articleList.slice(0, 12).map(article => (
          <Grid item xs={12} md={4} key={article.title}>
            <Box marginBottom={2}>
              <ContentfulDate date={article.sys.firstPublishedAt} format="MMMM d, yyyy" />
            </Box>
            <Link to={`/${article.slug}`} color="inherit">
              <Box marginBottom={2}>
                <Typography variant="h5" component="h3">
                  {article.title}
                </Typography>
              </Box>
            </Link>
            <Box marginBottom={2}>
              <Typography>Reviewed by {article.medicalReviewer.name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  ));

  return <>{children}</>;
};

function isTopicDenulledArticles(article: Article | null): article is TopicDenulledArticle {
  return (
    notEmpty(article) &&
    notEmpty(article.category?.condition) &&
    notEmpty(article.category?.slug) &&
    notEmpty(article.title) &&
    notEmpty(article.slug) &&
    notEmpty(article.medicalReviewer?.name) &&
    notEmpty(article.sys?.firstPublishedAt)
  );
}

function buildArticleMap(
  articles?: readonly (Article | null)[] | null
): ReadonlyMap<string, readonly TopicDenulledArticle[]> {
  const conditionArticleMap = new Map();

  articles?.forEach(value => {
    if (isTopicDenulledArticles(value)) {
      const key = value.category.condition;

      let array = conditionArticleMap.get(key);

      if (array == null) {
        array = [];
        conditionArticleMap.set(key, array);
      }

      if (array.length < 12) {
        array.push(value);
      }
    }
  });

  return conditionArticleMap;
}
