import { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useRef, useState } from 'react';

import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints';
import { Asset as AssetGraphql } from 'contentful-gql/src/types/graphql-schema';
import { di } from 'react-magnetic-di/macro';

import { useFeatureFlag } from 'shared/src/feature-flags/use-feature-flag';
import { LazyImage } from 'shared/src/image/image';
import { gray } from 'shared/src/palette/color-system';
import { useStickyIntersection } from 'shared/src/use-near-viewport/use-sticky-intersection';

export type AssetInfo = Pick<AssetGraphql, 'url' | 'contentType' | 'description'>;

type VerticalFocus = 'top' | 'bottom';
type HorizontalFocus = 'left' | 'right';

type ContentfulImageProps = DetailedHTMLProps<
  HTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  image: AssetInfo;
  maxWidth?: number | keyof Omit<BreakpointValues, 'xs'>;
  maxHeight?: number;
  resize?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
  additionalParams?: string;
  imageFocus?:
    | 'face'
    | 'faces'
    | 'center'
    | VerticalFocus
    | HorizontalFocus
    | `${VerticalFocus}_${HorizontalFocus}`;
  // set to false if you want to force eager loading of the image (for images above-the-fold)
  lazy?: boolean;
};

export const ContentfulImage: FC<ContentfulImageProps> = props => {
  di(useFeatureFlag);

  const { value: contentfulImageProxyEnabled } = useFeatureFlag(
    'contentfulImageProxyEnabled',
    false
  );

  const { image, maxWidth, maxHeight, resize, imageFocus, lazy, ...imgProps } = props;

  const { url: contentfulUrl, contentType, description } = image;

  const searchParams = new URLSearchParams();

  if (!contentfulUrl) {
    return null;
  }
  if (resize) {
    searchParams.set('fit', resize);
  }
  if (imageFocus) {
    searchParams.set('f', imageFocus);
  }

  const hmAssetsHost =
    import.meta.env.VITE_CONTENTFUL_PROXY_ASSETS_HOST ?? 'images.healthmatch-assets.io';
  const contentfulSpaceId = import.meta.env.VITE_CONTENTFUL_SPACE;
  const contentfulImagesHost = import.meta.env.VITE_CONTENTFUL_IMAGES_HOST;

  // reroute the contentful image assets to our own proxy when `contentfulImageProxyEnabled`
  const url = contentfulImageProxyEnabled
    ? contentfulUrl.replace(`${contentfulImagesHost}/${contentfulSpaceId}`, hmAssetsHost)
    : contentfulUrl;

  if (contentType === 'image/svg+xml') {
    return (
      <LazyImage
        alt={description ?? ''}
        {...imgProps}
        src={`${url}?${searchParams.toString()}`}
        forceLoad={lazy === false}
      />
    );
  }

  return <RasterContentfulImage {...props} image={{ ...image, url }} />;
};

const RasterContentfulImage: FC<ContentfulImageProps> = ({
  image: { url, description },
  maxWidth: themedMaxWidth,
  maxHeight,
  resize,
  imageFocus = 'center',
  lazy,
  ...imgProps
}) => {
  const theme = useTheme();
  const maxWidth = useMaxWidth(themedMaxWidth);
  const imgRef = useRef<HTMLImageElement>(null);
  const [fallbackEnabled, setFallbackEnabled] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useStickyIntersection(intersectionRef, {
    root: null,
    rootMargin: '500px',
  });

  useEffect(() => {
    // If <picture> or webp is not supported, set src of fallback <img>
    // Otherwise undefined src in <img> prevents Firefox from always downloading fallback image
    if (url && imgRef.current && !imgRef.current.currentSrc) {
      setFallbackEnabled(true);
    }
  }, [url, imgRef]);

  if (!url) {
    return null;
  }

  const imgParams = new URLSearchParams();
  imgParams.set('q', (50).toString());

  if (maxHeight) {
    imgParams.set('h', Math.round(maxHeight).toString());
  }
  if (maxWidth) {
    imgParams.set('w', Math.round(maxWidth).toString());
  }
  if (resize) {
    imgParams.set('fit', resize);
  }
  if (imageFocus) {
    imgParams.set('f', imageFocus);
  }

  // Source URL has one extra query param, so we add that here.
  const sourceParams = new URLSearchParams(imgParams);
  sourceParams.set('fm', 'webp');
  // Double-density source
  const source2xParams = new URLSearchParams(sourceParams);
  if (maxHeight) {
    source2xParams.set('h', Math.round(maxHeight * 2).toString());
  }
  if (maxWidth) {
    source2xParams.set('w', Math.round(maxWidth * 2).toString());
  }

  if (import.meta.env.MODE !== 'production') {
    if (!maxWidth && !maxHeight) {
      throw new Error(
        `Neither maxWidth nor maxHeight supplied for a non-svg image "${description}"`
      );
    }
    if (maxHeight && maxWidth && resize === 'pad') {
      throw new Error("'pad' can be used with either maxWidth or maxHeight, but not both");
    }
    if (
      (resize === 'pad' || resize === 'scale') &&
      (imageFocus === 'face' || imageFocus === 'faces')
    ) {
      throw new Error(
        "Using 'face' or 'faces' as focus with 'pad' or 'scale' does not work if your image doesn't have faces in the right position. We made this throw an error, but you can test your image and see if it will work (and maybe change this code to be more selective)."
      );
    }
  }

  const shouldLoad = intersection?.hasIntersected || lazy === false;
  // use `sm` breakpoint if maxwidth, since iphones self-report as 375px (iphone 6) with 2x pixel density
  // this ensures that if we have a mobile screen we still load a smaller image for them, but a larger one for tablets/desktops
  const breakpoint = theme.breakpoints.values.sm;
  const imageData: [string, number][] = [
    [`${url}?${sourceParams.toString()}`, breakpoint],
    [`${url}?${source2xParams.toString()}`, breakpoint * 2],
  ];

  const srcSet = shouldLoad
    ? imageData.map(([imageUrl, width]) => `${imageUrl} ${width}w`).join(', ')
    : undefined;

  return (
    /*
      Yes any styles getting passed in get applied twice, this is not a bug!
      Any padding/margin should be applied to a container around the image not to the image itself.
      This is because the picture is already a container to the img tag, so some styles may need to be applied to that as well as the img.
      Heed my warning, fair traveller.
      Times "fix" was attempted so far: 1
       */
    <picture ref={intersectionRef} className={imgProps.className}>
      <source srcSet={srcSet} type="image/webp" />
      <img
        alt={description ?? ''}
        {...imgProps}
        src={fallbackEnabled && shouldLoad ? `${url}?${imgParams.toString()}` : undefined}
        ref={imgRef}
      />
    </picture>
  );
};

const useStyles = makeStyles<
  Theme,
  {
    borderRadius: number;
  }
>((theme: Theme) => ({
  image: {
    display: 'block',
    maxWidth: '100%',
  },
  imageContainer: {
    display: 'inline-block',
    maxWidth: '100%',
    overflow: 'hidden',
    borderRadius: ({ borderRadius }) => theme.spacing(borderRadius),
    borderColor: gray[300],
    borderStyle: 'solid',
    borderWidth: 1,
    '@media (min-device-pixel-ratio: 2)': {
      borderWidth: 0.5,
    },
  },
}));

const BORDER_RADIUS = {
  sm: 2,
  md: 2.5,
};

type AccentuatedContentfulImageProps = ContentfulImageProps & {
  containerClassName?: string;
  borderRadius: keyof typeof BORDER_RADIUS;
};

export const AccentuatedContentfulImage: FC<AccentuatedContentfulImageProps> = ({
  borderRadius,
  className,
  containerClassName,
  ...props
}) => {
  const styles = useStyles({
    borderRadius: BORDER_RADIUS[borderRadius],
  });

  const imgClassName = concatClassNames(styles.image, className);
  const ctnClassName = concatClassNames(styles.imageContainer, containerClassName);

  return (
    <div className={ctnClassName}>
      <ContentfulImage {...props} className={imgClassName} />
    </div>
  );
};

function concatClassNames(...names: readonly (string | undefined)[]): string {
  return names.filter(name => name != null).join(' ');
}

function useMaxWidth(maxWidth?: number | keyof Omit<BreakpointValues, 'xs'>) {
  const theme = useTheme();

  return maxWidth && typeof maxWidth !== 'number' ? theme.breakpoints.values[maxWidth] : maxWidth;
}
