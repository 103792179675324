import { gql } from '@apollo/client';

export const REDIRECT_FRAGMENT = gql`
  fragment RedirectFragment on Query {
    redirectCollection(limit: 1, preview: $preview, where: { previousSlug: $slug }) {
      items {
        sys {
          id
        }
        newPage {
          ... on Entry {
            sys {
              id
            }
          }
          ... on ConditionArticle {
            slug
          }
          ... on ConditionCategory {
            slug
          }
          ... on MedicationArticle {
            slug
          }
        }
      }
    }
  }
`;
