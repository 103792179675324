import { FC } from 'react';

import { Box, Link, makeStyles } from '@material-ui/core';
import { Link as ScrollLink } from 'react-scroll';

import {
  BarChartIcon,
  ChatIcon,
  ClinicalTrialsIcon,
  DoctorIcon,
  DollarCircleIcon,
  GlassesIcon,
  MedicalCrossShieldIcon,
  MicroscopeIcon,
  PillIcon,
  RibbonIcon,
  StethoscopeIcon,
  ThermometerIcon,
} from 'shared/src/icon/icons';
import { purple } from 'shared/src/palette/color-system';

import { slugify } from 'pages/condition-category/components/slugify/slugify';

// I'm so sorry that this had to be expanded :cry:
export type SectionTypes = string;

const getIconComponent = (type: SectionTypes) => {
  switch (type) {
    case 'Overview':
      return GlassesIcon;
    case 'Treatments':
      return StethoscopeIcon;
    case 'Symptoms':
      return ThermometerIcon;
    case 'Causes':
      return MicroscopeIcon;
    case 'Prevention':
      return MedicalCrossShieldIcon;
    case 'Awareness':
      return RibbonIcon;
    case 'Clinical trials':
      return ClinicalTrialsIcon;
    case 'Doctors & specialists':
      return DoctorIcon;
    case 'Medications':
      return PillIcon;
    case 'Statistics':
      return BarChartIcon;
    case 'Costs':
      return DollarCircleIcon;
    case 'FAQs':
      return ChatIcon;
    default:
      return null;
  }
};

const useStyles = makeStyles(theme => ({
  sectionLink: {
    '&:hover': {
      color: purple[500],
    },
  },
  icon: {
    position: 'relative',
    top: theme.spacing(1),
    fontSize: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
}));

export const SectionLink: FC<{ type: SectionTypes }> = ({ type }) => {
  const classes = useStyles();
  const IconComponent = getIconComponent(type);
  const slug = slugify(type);

  return (
    <Link
      component={ScrollLink}
      to={slug}
      href={`#${slug}`}
      color="textSecondary"
      classes={{ root: classes.sectionLink }}
    >
      <Box display="inline-flex" alignItems="center">
        <div className={classes.icon}>{IconComponent && <IconComponent fontSize="inherit" />}</div>
        <div>{type.replace(' &', '\u00A0&')}</div>
      </Box>
    </Link>
  );
};
