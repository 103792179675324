import { useEffect, useRef, useState } from 'react';

import { useScript } from 'shared/src/use-script/use-script';

declare global {
  interface Window {
    dummyGoogleMapsCallback: () => void;
  }
}

const createAutocompleteService = (googleMaps: typeof google.maps) =>
  new googleMaps.places.AutocompleteService();
const createGeocoder = (googleMaps: typeof google.maps) => new googleMaps.Geocoder();

function useRefWhenReady<T>(constructor: (googleMaps: typeof google.maps) => T | null) {
  const googleMaps = useGoogleMaps();
  const ref = useRef<T | null>(null);
  const [refVal, setRefVal] = useState<T | null>(null);

  useEffect(() => {
    if (ref.current == null && googleMaps) {
      ref.current = constructor(googleMaps);
      setRefVal(ref.current);
    }
  }, [googleMaps, constructor]);

  return refVal;
}

export const useGoogleAutocomplete = (): google.maps.places.AutocompleteService | null =>
  useRefWhenReady(createAutocompleteService);

export const useGoogleGeocoder = (): google.maps.Geocoder | null => useRefWhenReady(createGeocoder);

export const GOOGLE_MAP_API_KEY = 'AIzaSyBTO3fCm_GIGsMV4TltJPAngo3kpCVKQg0';

export const useGoogleMaps = (): typeof google.maps | null => {
  useEffect(() => {
    if (!window.dummyGoogleMapsCallback) {
      // The google maps api requires a global function to be provided as a callback, or it will log an error, which makes our storybooks sad.
      // This is a dummy function that does nothing, but it's enough to make the google maps api happy.
      window.dummyGoogleMapsCallback = () => {};
    }
  }, []);

  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places&language=en&callback=dummyGoogleMapsCallback`
  );

  return status === 'ready' ? window.google.maps : null;
};
