import { FC } from 'react';

import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { di } from 'react-magnetic-di/macro';
import { useHistory } from 'react-router';

import { latoFontFamily } from 'shared/src/fonts/fonts';
import { ButtonLink } from 'shared/src/link/link';
import { gray, purple, vividPurple } from 'shared/src/palette/color-system';

import errorImage from './error-screen.svg';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: theme.spacing(100),
    objectFit: 'contain',
  },
}));

export type ErrorPageProps = { onError?: () => void; error?: Error };

export const ErrorPageContentLite: FC<ErrorPageProps> = ({ onError }) => {
  const { t } = useTranslation();
  return (
    <>
      <style>{`
      html, body, #root, .error-container {
       height: 100%;
       padding: 0;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
      }
      .error-container { width: 100%; display: flex }
      .error-wrapper { margin: auto; font-family: ${latoFontFamily}; }
      .error-wrapper h1 {
        font-size: 24px;
        color: ${gray[1000]};
      }
      .error-wrapper button, .error-wrapper a { 
        padding: 16px 32px 14px;
        font-size: 16px;
        font-family: ${latoFontFamily};
        font-weight: 700;
        line-height: 1;
        border-radius: 32px;  
        border: 1px solid currentColor;
      }
      .error-wrapper button {
        color: ${gray[600]};  
        background-color: ${gray[0]};
      }
      .error-wrapper button:hover {
        color: ${gray[400]};  
        cursor: pointer;
      }
      .error-wrapper a {
        display: inline-block;
        margin-left: 8px;
        color: ${gray[0]};
        background-color: ${purple[500]};
        text-decoration: none;
      } 
      .error-wrapper a:hover {
        background-color: ${vividPurple[500]};
      }
    `}</style>
      <div className="error-container">
        <div className="error-wrapper">
          <h1>{t('shared.errorPage.title')}</h1>
          {onError ? (
            <button type="button" onClick={onError}>
              {t('shared.errorPage.buttonTryAgain')}
            </button>
          ) : (
            <button type="button" onClick={() => window.location.assign('/')}>
              {t('shared.errorPage.buttonReturnHome')}
            </button>
          )}
          <a href="https://support.healthmatch.io" target="_blank" rel="noreferrer">
            {t('shared.errorPage.buttonGetSupport')}
          </a>
        </div>
      </div>
    </>
  );
};

export const ErrorPageContent: FC<ErrorPageProps> = ({ onError }) => {
  di(ButtonLink, useHistory);
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Box marginBottom={4}>
        <Typography variant="h4" align="center">
          {t('shared.errorPage.title')}
        </Typography>
        <Box marginTop={5}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              {onError ? (
                <Button variant="outlined" onClick={onError}>
                  {t('shared.errorPage.buttonTryAgain')}
                </Button>
              ) : (
                <Button variant="outlined" onClick={history.goBack}>
                  {t('shared.errorPage.buttonGoBack')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                component="a"
                href="https://support.healthmatch.io"
                target="_blank"
                variant="contained"
                color="primary"
              >
                {t('shared.errorPage.buttonGetSupport')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container justifyContent="center">
        <img alt="" src={errorImage} className={classes.image} />
      </Grid>
    </Container>
  );
};

export const ErrorPageWithErrorDetails: FC<ErrorPageProps> = ({ onError, error }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box marginTop={5}>
        <Typography variant="h5" gutterBottom>
          {t('shared.errorPage.titleVariation')}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t('shared.errorPage.instructions')}
        </Typography>
        <div>
          <textarea value={error?.stack} style={{ width: '100%', height: 200, color: '#aaa' }} />
        </div>

        {onError ? (
          <button type="button" onClick={onError}>
            {t('shared.errorPage.buttonTryAgain')}
          </button>
        ) : (
          <button type="button" onClick={() => window.location.assign('/')}>
            {t('shared.errorPage.buttonReturnHome')}
          </button>
        )}
      </Box>
    </Container>
  );
};
