import { FC, useEffect } from 'react';

import { Box } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';

import { useAnalytics } from 'shared/src/analytics/analytics';
import { ErrorPageContent, ErrorPageProps } from 'shared/src/error-page-content/error-page-content';

import { PageBase } from 'components/page-base/page-base';

export const ErrorPage: FC<ErrorPageProps> = props => {
  di(PageBase, useAnalytics, useLocation);

  const analytics = useAnalytics();
  const history = useLocation();

  useEffect(() => {
    analytics.track({
      event: 'hmio.error_page.viewed',
      location: history.pathname,
    });
  });

  return (
    <PageBase windowTitle="Something has gone wrong">
      <Box marginY={12}>
        <ErrorPageContent {...props} />
      </Box>
    </PageBase>
  );
};
