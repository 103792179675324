import { FC } from 'react';

import { Box, Theme, ThemeProvider, Typography } from '@material-ui/core';
import { deepmerge } from '@material-ui/utils';

import { RichTextData, RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { lightPurple } from 'shared/src/palette/color-system';
import { notEmpty } from 'shared/src/util/not-empty';

import {
  ConditionPageMeta,
  RelatedConditionLinks,
} from 'components/related-condition-links/related-condition-links';

type MaybeRelatedCondition =
  | {
      __typename: 'ConditionCategory';
      condition?: string | null | undefined;
      slug?: string | null | undefined;
    }
  | { __typename: 'ConditionLink'; linkedPage?: MaybeRelatedCondition | null }
  | { __typename: 'ConditionArticle' };

interface Props {
  overviewText: RichTextData | null;
  relatedConditions: MaybeRelatedCondition[] | null;
}

const getConditionOrArticleMeta = (
  relatedCondition: MaybeRelatedCondition | null
): ConditionPageMeta | undefined => {
  if (relatedCondition == null) {
    return undefined;
  }

  if (
    relatedCondition.__typename === 'ConditionCategory' &&
    relatedCondition.condition != null &&
    relatedCondition.slug != null
  ) {
    return {
      condition: relatedCondition.condition,
      slug: relatedCondition.slug,
    };
  }
  if (relatedCondition.__typename === 'ConditionLink' && relatedCondition.linkedPage != null) {
    return getConditionOrArticleMeta(relatedCondition.linkedPage);
  }

  return undefined;
};

export const CategoryOverview: FC<Props> = ({ overviewText, relatedConditions }) => {
  const relatedConditionLinks = relatedConditions?.map(getConditionOrArticleMeta).filter(notEmpty);

  return (
    <>
      <Box id="overview" maxWidth="md" marginX={{ xs: 2, sm: 20 }}>
        <ThemeProvider
          theme={(theme: Theme) =>
            deepmerge(theme, {
              typography: {
                ...theme.typography,
                h2: {
                  ...theme.typography.h2,
                  [theme.breakpoints.up('sm')]: {
                    textAlign: 'center',
                  },
                },
              },
            })
          }
        >
          {overviewText && <RichText document={overviewText} />}
        </ThemeProvider>
      </Box>
      {relatedConditionLinks && relatedConditionLinks.length > 0 && (
        <Box
          bgcolor={lightPurple[50]}
          marginTop={{ xs: 7, sm: 10 }}
          paddingTop={{ xs: 7, sm: 5 }}
          paddingBottom={{ xs: 5, sm: 3 }}
          borderRadius={12}
        >
          <Box marginX={4} marginBottom={5} textAlign={{ sm: 'center' }}>
            <Typography variant="h6" component="h2">
              Other related conditions
            </Typography>
          </Box>
          <RelatedConditionLinks links={relatedConditionLinks} />
        </Box>
      )}
    </>
  );
};
