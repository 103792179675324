import { gql } from '@apollo/client';

import { mutationFactory } from 'shared/src/util/query-factory';

import {
  PatientLogin,
  PatientLoginVariables,
  SendLoginCode,
  SendLoginCodeNew,
  SendLoginCodeNewVariables,
  SendLoginCodeVariables,
} from './types/queries.generated';

export const LOGIN_OPERATION_NAME = 'loginPatient';

// IMPORTANT: If you change 'loginPatient' below, please also change the const LOGIN_OPERATION_NAME above!!
// Also be mindful that we cannot request fields as part of the login mutation as there's no way to update the authentication
// within a request context in the backend, which means all fields annotated by @AllowedRoles will fail.
const PATIENT_LOGIN = gql`
  mutation PatientLogin($input: UserCredentialsInput!) {
    loginPatient(input: $input) {
      id
    }
  }
`;

export const usePatientLogin = mutationFactory<PatientLogin, PatientLoginVariables>(PATIENT_LOGIN);

const SEND_LOGIN_CODE = gql`
  mutation SendLoginCode($input: String!) {
    sendLoginCode(input: $input) {
      status
    }
  }
`;

export const useSendLoginCode = mutationFactory<SendLoginCode, SendLoginCodeVariables>(
  SEND_LOGIN_CODE
);

const SEND_LOGIN_CODE_NEW = gql`
  mutation SendLoginCodeNew($input: String!, $preferredLoginCodeFlowType: LoginCodeFlowType!) {
    sendLoginCodeNew(input: $input, preferredLoginCodeFlowType: $preferredLoginCodeFlowType) {
      loginCodeDestination
      loginCodeFlowType
    }
  }
`;

export const useSendLoginCodeNew = mutationFactory<SendLoginCodeNew, SendLoginCodeNewVariables>(
  SEND_LOGIN_CODE_NEW
);
