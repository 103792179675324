import { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { LoadingPageContent as CommonLoadingPage } from 'shared/src/loading-page-content/loading-page-content';

import { PageBase, PageBaseProps } from 'components/page-base/page-base';

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: '50vh',
  },
}));

export const LoadingPage: FC<PageBaseProps> = ({ classes, ...props }) => {
  di(PageBase);
  const loadingPageClasses = useStyles();

  return (
    <PageBase
      {...props}
      classes={{
        contentContainer: loadingPageClasses.container,
      }}
    >
      <CommonLoadingPage />
    </PageBase>
  );
};
