import { FC } from 'react';

import { Button, Grid, makeStyles } from '@material-ui/core';

import { mod } from './mod';
import { CarouselIndicatorProps } from './types';

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 0,
    width: theme.spacing(2),
    height: theme.spacing(2),
    padding: 0,
    '&&': {
      boxShadow: theme.shadows[0],
    },
  },
  fieldset: {
    border: 'none',
  },
}));

export const CarouselDots: FC<CarouselIndicatorProps> = ({ count, index, onChange }) => {
  const classes = useStyles();

  const currentIndex = mod(index, count);

  return (
    <fieldset aria-label="carousel controls" className={classes.fieldset}>
      <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
        {Array.from({ length: count }).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={i}>
            <Button
              variant="contained"
              className={classes.button}
              color={i === currentIndex ? 'primary' : 'default'}
              onClick={() => onChange(i)}
              aria-label={`carousel slide ${i}`}
            />
          </Grid>
        ))}
      </Grid>
    </fieldset>
  );
};
