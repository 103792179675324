import { FC, useState } from 'react';

import { di } from 'react-magnetic-di/macro';

import { useAnalytics } from 'shared/src/analytics/analytics';
import { AnalyticsFlagContextConsumer } from 'shared/src/feature-flags/analytics-flag-context-consumer';
import {
  ANONYMOUS_CONFIGCAT_USER,
  FlagsStatus,
  FlagUser,
} from 'shared/src/feature-flags/config-cat-utils';
import { FeatureFlagProvider } from 'shared/src/feature-flags/feature-flag-provider';

import { getAppState } from 'mats/src/components/app-state/app-state';
import { useAuth } from 'mats/src/components/auth/use-auth';
import { featureFlagUserUpdateContext as matsContext } from 'mats/src/components/mats-feature-flag-provider/feature-flag-user-update';

import { featureFlagUserUpdateContext as hmioContext } from './feature-flag-user-update';

export const HmioFeatureFlagProvider: FC = ({ children }) => {
  di(useAnalytics, FeatureFlagProvider, useAuth, AnalyticsFlagContextConsumer);

  const { getAuthenticatedUserId, getAuthenticatedUserEmail } = useAuth();
  const flagValues = getAppState()?.flagValues;
  const analytics = useAnalytics();

  const generateCurrentUser = (): FlagUser => {
    // Makes a ConfigCat user. Identifies a user by their user ID if logged in.
    // Otherwise it uses a generated anonymous ID from rudderstack
    const anonymousId = analytics.getAnonymousId('rudder');
    const userId: string | undefined = getAuthenticatedUserId()?.toString();
    const email = getAuthenticatedUserEmail();
    const identifier = userId ?? anonymousId;

    return identifier
      ? {
          identifier,
          customAttributes: {
            loggedIn: Boolean(userId !== undefined).toString(),
            ...(anonymousId ? { anonymousId } : {}),
            ...(email ? { emailDomain: email.split('@')[1] } : {}),
          },
          email,
        }
      : ANONYMOUS_CONFIGCAT_USER;
  };

  const [currentUser, setCurrentUser] = useState(generateCurrentUser);

  const handleUpdateUser = () => {
    setCurrentUser(generateCurrentUser());
  };

  return (
    <matsContext.Provider value={{ triggerUserUpdate: handleUpdateUser }}>
      <hmioContext.Provider value={{ triggerUserUpdate: handleUpdateUser }}>
        <FeatureFlagProvider
          user={currentUser}
          flagState={flagValues ? { flagValues, status: FlagsStatus.SUCCESS } : undefined}
        >
          {children}
          <AnalyticsFlagContextConsumer />
        </FeatureFlagProvider>
      </hmioContext.Provider>
    </matsContext.Provider>
  );
};
