import { FC } from 'react';

import { responsiveFontSizes, Theme, ThemeProvider } from '@material-ui/core';
import { deepmerge } from '@material-ui/utils';

import { gray } from 'shared/src/palette/color-system';
import { spacing, staticFontsTheme } from 'shared/src/theme/theme';

const contentTheme = responsiveFontSizes(
  deepmerge(staticFontsTheme, {
    typography: {
      h1: { fontSize: 48 },
      h2: { fontSize: 40 },
      h3: { fontSize: 32 },
      h4: { fontSize: 26 },
      h5: { fontSize: 22 },
      h6: { fontSize: 20 },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 20,
        color: gray[1000],
      },
    },
    overrides: {
      MuiTypography: {
        gutterBottom: {
          marginTop: spacing(5),
          marginBottom: spacing(2),
        },
      },
    },
  } as Theme)
);

export const ContentThemeProvider: FC = ({ children }) => (
  <ThemeProvider theme={contentTheme}>{children}</ThemeProvider>
);
