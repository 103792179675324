import * as rudderanalytics from 'rudder-sdk-js';

import {
  AnalyticsPlugin,
  EventProperties,
  EventProperty,
  PageViewProperties,
  UserInfo,
} from './analytics';

// Taken from https://docs.rudderstack.com/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk#3-1-load
type RudderConfig = {
  dataPlaneURL: string;
  writeKey: string;
  options?: {
    logLevel?: 'DEBUG' | 'INFO' | 'WARN';
    integrations?: RudderIntegrationOpts;
    configUrl?: string; // defaults to https://api.rudderlabs.com
    queueOptions?: RudderQueueOpts;
    loadIntegration?: boolean; // defaults to true
  };
};

type RudderIntegrationOpts = {
  All: boolean;
  [integrationName: string]: boolean;
};

type RudderQueueOpts = {
  maxRetryDelay: 360000; // Upper cap on maximum delay for an event
  minRetryDelay: 1000; // minimum delay before sending an event
  backoffFactor: 2; // exponential base
  maxAttempts: 10; // max attempts
  maxItems: 100; // max number of events in storage
};

type RudderTrackOpts = {
  integrations?: RudderIntegrationOpts;
  anonymousId?: string;
  // ISO 8601 date string
  originalTimestamp?: string;
};

export class RudderAnalyticsPlugin implements AnalyticsPlugin {
  readonly name = 'rudder';

  private readonly readyPromise: Promise<void>;

  private readyResolve = () => {};

  private isReady = false;

  private customContext: Record<string, EventProperty> = {};

  constructor(private readonly config: RudderConfig) {
    this.readyPromise = new Promise(resolve => {
      this.readyResolve = resolve;
    });
  }

  async init(userInfo?: UserInfo): Promise<void> {
    // Set anonymous id if provided from server
    if (userInfo?.anonymousId) {
      rudderanalytics.setAnonymousId(userInfo.anonymousId);
    }

    rudderanalytics.load(this.config.writeKey, this.config.dataPlaneURL, {
      // Don't load any integrations by default
      loadIntegration: false,
      ...this.config.options,
    });
    rudderanalytics.ready(this.readyResolve);

    await this.readyPromise;
    this.isReady = true;

    if (userInfo) {
      this.identify(userInfo);
    }
  }

  ready(): Promise<void> {
    return this.readyPromise;
  }

  async page(
    { name, category, ...properties }: PageViewProperties = {},
    options?: RudderTrackOpts
  ): Promise<void> {
    await this.readyPromise;
    await new Promise(resolve => {
      rudderanalytics.page(
        name,
        category,
        properties,
        { ...options, ...this.customContext },
        () => {
          resolve(undefined);
        }
      );
    });
  }

  async track({ event, ...properties }: EventProperties, options?: RudderTrackOpts): Promise<void> {
    await this.readyPromise;
    await new Promise(resolve => {
      rudderanalytics.track(event, properties, { ...options, ...this.customContext }, () => {
        resolve(undefined);
      });
    });
  }

  identify({ userId, ...traits }: UserInfo): void {
    if (!this.isReady) {
      return;
    }
    rudderanalytics.identify(userId, {
      ...traits,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  getAnonymousId(): string | null {
    return rudderanalytics.getAnonymousId();
  }

  setContext(propertyName: string, propertyValue: EventProperty) {
    this.customContext[propertyName] = propertyValue;
  }

  unsetContext(propertyName: string) {
    delete this.customContext[propertyName];
  }
}
