import { gql } from '@apollo/client';

export const SHARED_LOCATION_FRAGMENT = gql`
  fragment SharedLocation on Location {
    id
    name
    countryCode
    state
    address
    latitude
    longitude
    locales {
      languageCode
      code
      displayLanguage
      displayLanguageLocal
    }
  }
`;

// todo do we need all the rest of it, or just languageCode?
