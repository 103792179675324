import { FC } from 'react';

import { useEffectOnce } from 'react-use';

import { Analytics, AnalyticsProvider } from 'shared/src/analytics/analytics';
import { LoggingPlugin } from 'shared/src/analytics/logging-plugin';
import { RudderAnalyticsPlugin } from 'shared/src/analytics/rudder-analytics-plugin';
import { notEmpty } from 'shared/src/util/not-empty';

import { getAppState } from 'mats/src/components/app-state/app-state';
import { useAuth } from 'mats/src/components/auth/use-auth';

const debug = !!import.meta.env.VITE_DEBUG_ANALYTICS;

const writeKey = import.meta.env.VITE_RUDDER_WRITE_KEY;
const dataPlaneURL = import.meta.env.VITE_RUDDER_DATA_PLANE_URL;

const analytics = new Analytics({
  plugins: [
    writeKey && dataPlaneURL
      ? new RudderAnalyticsPlugin({
          writeKey,
          dataPlaneURL,
          options: {
            logLevel: debug ? 'INFO' : undefined,
          },
        })
      : null,
    import.meta.env.MODE === 'development'
      ? // eslint-disable-next-line no-console
        new LoggingPlugin({ log: (...args) => console.info(...args) })
      : null,
  ].filter(notEmpty),
});

export const HmioAnalyticsProvider: FC = ({ children }) => {
  const { getAuthenticatedUserId } = useAuth();

  useEffectOnce(() => {
    const userId = getAuthenticatedUserId()?.toString();
    analytics.init({
      anonymousId: getAppState()?.anonymousId,
      userId: userId ?? '',
      isLoggedIn: !!userId,
    });
  });

  return <AnalyticsProvider analytics={analytics}>{children}</AnalyticsProvider>;
};
