import { FC, useEffect, useState } from 'react';

import { Box, Container, makeStyles, Typography } from '@material-ui/core';

import { purple } from 'shared/src/palette/color-system';
import { useScript } from 'shared/src/use-script/use-script';

import { PageBase } from 'components/page-base/page-base';

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (options: { formId: string; portalId: string; target: string }) => void;
      };
    };
  }
}

type HubspotFormProps = {
  formId: string;
  windowTitle: string;
  subtitle: string;
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const HubspotForm: FC<HubspotFormProps> = ({ formId, windowTitle, subtitle }) => {
  const scriptStatus = useScript('//js.hsforms.net/forms/v2.js');

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const eventListener = (event: MessageEvent) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        setFormSubmitted(true);
      }
    };

    if (scriptStatus === 'ready') {
      window.hbspt?.forms.create({
        formId,
        portalId: '22552879',
        target: '#hubspot-target',
      });
      window.addEventListener('message', eventListener);
      return () => {
        window.removeEventListener('message', eventListener);
      };
    }
    return () => {};
  }, [formId, scriptStatus]);

  const classes = useStyles();

  return (
    <PageBase windowTitle={windowTitle}>
      <Box marginY={8}>
        <Container maxWidth="sm">
          {scriptStatus === 'ready' && (
            <Box
              paddingX={8}
              paddingY={5}
              bgcolor={purple[50]}
              borderRadius="16px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Box marginBottom={2}>
                <Typography variant="h4" color="primary">
                  Shape the future of clinical trials
                </Typography>
              </Box>
              <Box color="white" marginBottom={4}>
                <Typography variant="subtitle1" color="inherit" className={classes.bold}>
                  {subtitle}
                </Typography>
              </Box>
              <Box width="100%" id="hubspot-target" />
              {!formSubmitted && (
                <Box color="white" lineHeight={1}>
                  <Typography variant="caption" color="inherit">
                    By clicking above, you acknowledge that HealthMatch will record your email and
                    use it for marketing communications & information.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Container>
      </Box>
    </PageBase>
  );
};

export const DownloadDeiReport = () => (
  <HubspotForm
    formId="5ae06f43-d6a4-43f9-8ae7-d5c1c0a420a1"
    windowTitle="Download DEI Report"
    subtitle="Get access to the HealthMatch DEI report by filling out your information below."
  />
);

export const RequestDemo = () => (
  <HubspotForm
    formId="be5c76c1-09dd-4cc2-aaf1-5f7b64e1b503"
    windowTitle="Request a Demo"
    subtitle="Get access to a product demo by filling out your information below."
  />
);
