import { FC } from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { beige } from 'shared/src/palette/color-system';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { HeroBanner } from 'components/hero-banner/hero-banner';

import headerBg from './images/background.svg';
import femaleImage from './images/female-1.jpg';
import maleImage from './images/male-1.jpg';

const useStyles = makeStyles(({ breakpoints }) => ({
  background: {
    background: beige[500],
    backgroundImage: `url("${headerBg}")`,
    [breakpoints.down('sm')]: {
      backgroundImage: 'none',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
}));

export const WhyClinicalTrialsHeroBanner: FC = () => {
  di(ConditionSelectPanel);

  const classes = useStyles();

  return (
    <HeroBanner
      className={classes.background}
      imageTop={maleImage}
      imageBottom={femaleImage}
      colorTop="#fee4cb"
      colorBottom="#e4e1fd"
    >
      <Box paddingBottom={2}>
        <Typography variant="h2">Help shape the future of healthcare.</Typography>
      </Box>
      <Box paddingBottom={4}>
        <Typography color="textSecondary">
          Clinical trials drive medical breakthroughs, discovering new ways to prevent, detect, and
          treat diseases. By joining a clinical trial today, you can contribute to advancements that
          may benefit your health and the health of future generations.
        </Typography>
      </Box>
      <ConditionSelectPanel />
    </HeroBanner>
  );
};
