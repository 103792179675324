import { Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { AssetInfo, ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichText, RichTextData } from 'shared/src/contentful-text/rich-text-renderer';
import { gray } from 'shared/src/palette/color-system';

interface Props {
  title: string;
  mainContent: RichTextData;
  mainContentImage: AssetInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: gray[50],
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(10),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginBottom: theme.spacing(2),
    },
  },
  mainImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    padding: theme.spacing(0, 2),
  },
  mainImage: {
    width: '100%',
  },
}));

export const MainContentSection = ({ title, mainContent, mainContentImage }: Props) => {
  const classNames = useStyles();

  return (
    <div className={classNames.container}>
      <Container>
        <Typography variant="h2" component="h1" className={classNames.sectionTitle}>
          {title}
        </Typography>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item sm={6} xs={12}>
            <RichText document={mainContent} />
          </Grid>
          <Grid item sm={5} xs={12}>
            <div className={classNames.mainImageContainer}>
              <ContentfulImage
                maxWidth="md"
                image={mainContentImage}
                className={classNames.mainImage}
                lazy={false}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
