import { FC } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

export const LoadingPageContent: FC = () => {
  di(Grid, CircularProgress);
  return (
    <Grid
      data-test-id="loading-page"
      aria-label="Loading"
      container
      direction="column"
      style={{ width: '100%', height: '100%', flexGrow: 1 }}
    >
      <Grid
        container
        item
        style={{ alignItems: 'center', justifyContent: 'center', flex: '1 0 auto' }}
      >
        <CircularProgress size={40} color="inherit" />
      </Grid>
    </Grid>
  );
};
