import { ComponentType, memo, ReactNode } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { ContentfulDate } from 'shared/src/contentful-text/components/date';
import { Link } from 'shared/src/link/link';
import { gray } from 'shared/src/palette/color-system';
import { assertExists } from 'shared/src/types/assert-exists';
import { notEmpty } from 'shared/src/util/not-empty';

import { TopicHubPageList } from 'pages/topic-hub-page/types/queries.contentful.generated';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  hubPageHero: {
    display: 'grid',
    gridGap: spacing(3),
    gridTemplateColumns: '1fr auto 1fr',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridAutoFlow: 'row',
      gridGap: spacing(5),
    },
  },
  heroBlog: {
    alignContent: 'start',
    display: 'grid',
    gridGap: spacing(2),
    gridAutoFlow: 'row',
  },
  heroBlogHeroImage: {
    width: '100%',
    borderRadius: spacing(1.5),
  },
  articleColumn: {
    alignContent: 'start',
    display: 'grid',
    gridGap: spacing(5),
    gridAutoFlow: 'row',
  },
  articleColumnItem: {
    display: 'grid',
    gridGap: spacing(3),
    gridTemplateColumns: '1fr auto',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridAutoFlow: 'row',
    },
  },
  articleColumnItemImage: {
    borderRadius: spacing(1.5),
    width: spacing(22.5),
    height: 'auto',
    [breakpoints.down('sm')]: {
      gridRow: 1,
      width: '100%',
    },
  },
  articleColumnItemCopy: {
    alignContent: 'start',
    display: 'grid',
    gridGap: spacing(1),
    gridAutoFlow: 'row',
    [breakpoints.down('sm')]: {
      gridRow: 2,
    },
  },
  centerDivider: {
    background: gray[400],
    height: '100%',
    width: '1px',
    '@media (min-device-pixel-ratio: 2)': {
      width: '0.5px',
    },
    [breakpoints.down('sm')]: {
      width: '100%',
      height: '1px',
      '@media (min-device-pixel-ratio: 2)': {
        width: '100%',
        height: '0.5px',
      },
    },
  },
  verticalDivider: {
    background: gray[400],
    width: '100%',
    height: '1px',
    '@media (min-device-pixel-ratio: 2)': {
      height: '0.5px',
    },
  },
}));

type UnfilteredArticles = readonly (FeaturedArticle | null | undefined)[] | null;

type TopicHubHeroProps = {
  // assume the input isn't sanitized.
  articles?: UnfilteredArticles;
  heroBlog?: HeroBlog | null;
};

export const TopicHubHero: ComponentType<TopicHubHeroProps> = memo(({ articles, heroBlog }) => {
  const styles = useStyles();

  const showDivider = heroBlog != null && articles != null && articles.length > 0;
  const filteredArticles = articles?.filter(notEmpty);

  return (
    <div className={styles.hubPageHero}>
      {heroBlog && <PrimaryColumn heroBlog={heroBlog} />}
      {showDivider && <div className={styles.centerDivider} />}
      {filteredArticles && <SecondaryColumn articles={filteredArticles} />}
    </div>
  );
});

type HeroBlog = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items', 'heroBlog']
>;

const PrimaryColumn: ComponentType<{
  heroBlog: HeroBlog;
}> = ({ heroBlog: { title, date, authorProfile, slug, heroImage } }) => {
  const styles = useStyles();

  const reviewedBy = authorProfile?.name != null && <>Reviewed by {authorProfile?.name}</>;

  return (
    <div className={styles.heroBlog}>
      {heroImage && (
        <Link to={`/blog/${assertExists(slug)}`}>
          <ContentfulImage
            image={heroImage}
            maxWidth="lg"
            lazy={false}
            className={styles.heroBlogHeroImage}
          />
        </Link>
      )}
      {date && (
        <Typography variant="body1">
          <ContentfulDate date={date} format="MMMM d, yyyy" />
        </Typography>
      )}
      {title && (
        <Typography variant="h4" component="h2">
          <Link to={`/blog/${assertExists(slug)}`}>{title}</Link>
        </Typography>
      )}
      {reviewedBy && <Typography variant="body1">{reviewedBy}</Typography>}
    </div>
  );
};

export type FeaturedArticle = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items', 'featuredArticlesCollection', 'items']
>;

const SecondaryColumn: ComponentType<{
  articles: readonly FeaturedArticle[];
}> = ({ articles }) => {
  const styles = useStyles();

  const items = separatedBy(
    articles,
    article => (
      <div key={article.sys.id} className={styles.articleColumnItem}>
        <div className={styles.articleColumnItemCopy}>
          <ArticleDate article={article} />
          <Typography variant="h5" component="h3">
            <Link to={getSlug(article)}>{article.title}</Link>
          </Typography>
          <ArticleAuthor article={article} />
        </div>
        {article.heroImage && (
          <Link to={getSlug(article)}>
            <ContentfulImage
              image={article.heroImage}
              maxWidth="md"
              lazy={false}
              className={styles.articleColumnItemImage}
            />
          </Link>
        )}
      </div>
    ),
    index => <div key={`divider-${index}`} className={styles.verticalDivider} />
  );

  return <div className={styles.articleColumn}>{items}</div>;
};

function getSlug(article: FeaturedArticle) {
  if (article.__typename === 'ConditionArticle') {
    const segment1 = assertExists(article.category?.slug);
    const segment2 = assertExists(article.slug);
    return `/${segment1}/${segment2}`;
  }
  return `/blog/${assertExists(article.slug)}`;
}

function ArticleDate({ article }: { article: FeaturedArticle }) {
  let date: string | null | undefined = null;

  if (article.__typename === 'ConditionArticle') {
    date = article?.reviewedAt;
  } else {
    date = article?.date;
  }

  if (date == null) {
    return null;
  }

  return (
    <Typography variant="body1">
      <ContentfulDate date={date} format="MMMM d, yyyy" />
    </Typography>
  );
}

function ArticleAuthor({ article }: { article: FeaturedArticle }) {
  let prefix: string;
  let name: string | null | undefined = null;

  if (article.__typename === 'ConditionArticle') {
    prefix = 'Reviewed by';
    name = article?.medicalReviewer?.name;
  } else {
    prefix = 'Written by';
    name = article?.authorProfile?.name;
  }

  if (name == null) {
    return null;
  }

  return (
    <Typography variant="body1">
      {prefix} {name}
    </Typography>
  );
}

function separatedBy<T>(
  items: readonly T[],
  createItem: (it: T) => ReactNode,
  createSeparator: (index: number) => ReactNode
): ReactNode[] {
  return items.flatMap((it, i) =>
    i === 0 ? createItem(it) : [createSeparator(i), createItem(it)]
  );
}
