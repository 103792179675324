import { Children, FC } from 'react';

import { Box } from '@material-ui/core';

const getMargin = (isZero: boolean) => ({
  xs: isZero ? 0 : 1.5,
  lg: isZero ? 0 : 3,
});

export const ToolBarItems: FC = ({ children }) => {
  const lastIndex = Children.count(children) - 1;

  return (
    <Box display="flex" alignItems="center" flexWrap="nowrap" height="100%" marginTop={0}>
      {Children.map(children, (item, index) => (
        <Box
          display="inline-flex"
          alignItems="center"
          height="100%"
          marginLeft={getMargin(index === 0)}
          marginRight={getMargin(index === lastIndex)}
        >
          {item}
        </Box>
      ))}
    </Box>
  );
};

export const DrawerItems: FC = ({ children }) => (
  <Box paddingLeft={4} position="relative">
    {children}
  </Box>
);
