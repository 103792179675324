import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useUpdateEffect } from 'react-use';

import { EventName } from 'shared/src/types/graphql-schema';

import { useAuth } from 'mats/src/components/auth/use-auth';

type RedditEventName =
  | 'PageVisit'
  | 'ViewContent'
  | 'Search'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'Purchase'
  | 'Lead'
  | 'SignUp'
  | 'Custom';

const eventToRedditEventMap: Record<EventName, RedditEventName> = {
  ConditionSearch: 'Search',
  QuestionnaireStarted: 'AddToCart',
  ScreeningQuestionAnswered: 'AddToWishlist',
  QuestionnaireCompleted: 'Lead',
  AccountCreated: 'SignUp',
  SubmitApplication: 'Custom',
};

export const RedditPixel = ({ redditPixelId }: { redditPixelId?: string }) => {
  di(Helmet, useAuth);

  const authenticatedUserId = useAuth().getAuthenticatedUserId();

  useUpdateEffect(() => {
    if (window.rdt && redditPixelId) {
      window.rdt?.('init', redditPixelId, { externalId: authenticatedUserId });
    }
  }, [authenticatedUserId]);

  return redditPixelId ? (
    <Helmet>
      <script>{`
!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${redditPixelId}' ${
        authenticatedUserId ? `, {"externalId":"${authenticatedUserId}"}` : ''
      });rdt('track', 'PageVisit');
`}</script>
    </Helmet>
  ) : null;
};

export interface RedditParamObject {
  eventId: string;
}

export const rdtTrack = (eventName: EventName, paramObj?: RedditParamObject): void => {
  const redditEventName = eventToRedditEventMap[eventName] || 'Custom';
  window.rdt?.('track', redditEventName, {
    ...paramObj,
    customEventName: redditEventName === 'Custom' ? eventName : undefined,
  });
};
