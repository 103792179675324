import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  header: {
    margin: 'auto',
    maxWidth: spacing(141),
    padding: spacing(10, 4),
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      padding: spacing(4, 4, 4, 4),
      textAlign: 'left',
    },
  },
}));

export const InformationHeader = () => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.header}>
      What are the potential benefits of clinical trial participation?
    </Typography>
  );
};
