import { FC, useState } from 'react';

import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { CarouselWithArrows } from 'shared/src/carousel/carousel';
import { ConditionLandingPageFragmentMinimal } from 'shared/src/fragments/contentful/types/condition-landing-page.contentful.generated';
import { ArrowDownIcon } from 'shared/src/icon/icons';
import { lightPurple } from 'shared/src/palette/color-system';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { ConditionCard } from 'pages/patients/components/condition-card/condition-card';

import backgroundLeft from './conditions-background-left.svg';
import backgroundRight from './conditions-background-right.svg';

export type ConditionsProps = {
  conditions: ConditionLandingPageFragmentMinimal[];
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: lightPurple[500],
    backgroundSize: '15%, 15%, 15%', // roughly 2 cols of 12
    backgroundPosition: 'top left, bottom left, right center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${backgroundLeft}), url(${backgroundLeft}), url(${backgroundRight})`,
    },
  },
  showMoreContainer: {
    marginTop: theme.spacing(10),
  },
  showMoreIcon: {
    width: theme.spacing(1.5),
  },
}));

const cardVisibleStep = 9;
const sortByConditionAlpha = (
  a: ConditionLandingPageFragmentMinimal,
  b: ConditionLandingPageFragmentMinimal
) => (a.condition ?? '').localeCompare(b.condition ?? '');

export const Conditions: FC<ConditionsProps> = ({ conditions }) => {
  di(ConditionSelectPanel);
  const classes = useStyles();
  const [visibleCount, setVisibleCount] = useState(cardVisibleStep);
  const visibleConditions = conditions.filter(c => !c.hideCard).sort(sortByConditionAlpha);
  const handleShowMoreClick = () =>
    setVisibleCount(c => Math.min(c + cardVisibleStep, visibleConditions.length));
  const showMoreVisible = visibleConditions.length && visibleCount < visibleConditions.length;

  return (
    <Box paddingY={{ xs: 7, sm: 10 }} className={classes.container}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item sm={8} xs={12}>
            <Box marginBottom={2}>
              <Typography align="center" variant="h2">
                Your resources
              </Typography>
            </Box>
            <Typography align="center" color="textSecondary" variant="body2">
              Here at HealthMatch, you’re never alone. That’s why we created specific resources
              pages, just for your condition. You’ll find information, organisations, phone apps,
              podcasts and more!
            </Typography>
            <Box paddingTop={4} paddingBottom={{ xs: 4, sm: 8 }} textAlign="center">
              <ConditionSelectPanel />
            </Box>
          </Grid>
          <Box display={{ xs: 'none', sm: 'block' }}>
            <Grid container item xs={12} spacing={3} alignItems="stretch">
              {visibleConditions.slice(0, visibleCount).map(condition => (
                <Grid key={condition.sys.id} item xs={12} sm={4}>
                  <ConditionCard condition={condition} />
                </Grid>
              ))}
            </Grid>
            {showMoreVisible && (
              <Grid
                className={classes.showMoreContainer}
                container
                item
                xs={12}
                justifyContent="center"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleShowMoreClick}
                  endIcon={<ArrowDownIcon className={classes.showMoreIcon} />}
                >
                  Show more
                </Button>
              </Grid>
            )}
          </Box>
        </Grid>
      </Container>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <CarouselWithArrows
          slides={visibleConditions}
          renderSlide={({ key, slide }) => (
            <Box margin={2} marginBottom={4} key={key}>
              <ConditionCard condition={slide} />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};
