import { gql } from '@apollo/client';

import { GetConditions } from 'shared/src/condition-select/types/queries.generated';
import { queryFactory } from 'shared/src/util/query-factory';

const GET_CONDITIONS = gql`
  query GetConditions {
    conditions {
      id
      name
      aliases
    }
  }
`;

export const useConditionsQuery = queryFactory<GetConditions>(GET_CONDITIONS);
