import { useEffect, useRef } from 'react';

import { di } from 'react-magnetic-di/macro';
import { useIntersection } from 'react-use';

import { EventProperties, useAnalytics } from './analytics';

export const useFireAnalyticsOnFirstView = (properties: EventProperties) => {
  di(useIntersection, useAnalytics);

  // Uses the viewport as the 'object' the element is intersecting with
  const viewportRef = useRef(null);
  const intersection = useIntersection(viewportRef, { root: null, threshold: 1 });
  const isFirstViewRef = useRef(true);
  const isFirstView = isFirstViewRef.current;
  const percentageIntersecting = intersection?.intersectionRatio;
  const analytics = useAnalytics();

  useEffect(() => {
    if (percentageIntersecting === 1 && isFirstView) {
      analytics.track(properties);
      isFirstViewRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, isFirstView, percentageIntersecting]);

  return viewportRef;
};
