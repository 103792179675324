import { useRef } from 'react';

import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from 'shared/src/analytics/analytics';
import { EventName } from 'shared/src/types/graphql-schema';

import { useConversionEventTracking } from 'mats/src/components/conversion-event-tracking/use-conversion-event-tracking';

/**
 * Sends analytics data about our users searching habits.
 *
 * Assumed user behaviour: User types few words. If not happy with the result,
 * user will delete few characters/words and then maybe type few new ones.
 * Finally user selects condition from the dropdown list. When user selects value
 * the rawInput becomes empty.
 *
 *  If user types "Ear disorder" and then updates to "Ear disease" this implementation
 *  sends two messages "Ear disorder" and "Ear disease".
 */
export const useAnalyticsLogger = (): ((rawInput: string) => void) => {
  di(useAnalytics, useLocation);
  const analytics = useAnalytics();
  const location = useLocation();
  const dispatchedSearches = useRef<string[]>([]);
  const previousSearch = useRef<string>('');

  return (rawInput: string) => {
    const currentSearch = (rawInput.match(/\S+/g) || []).join(' ').toLowerCase(); // normalise

    // When user deletes character or word we will dispatch previous search term to GA
    if (currentSearch.length < previousSearch.current.length) {
      // only dispatch search term if it hasn't been dispatched before
      const hasBeenDispatched = dispatchedSearches.current.find(s =>
        s.startsWith(previousSearch.current)
      );
      if (!hasBeenDispatched) {
        dispatchedSearches.current.unshift(previousSearch.current);
        analytics.track({
          event: 'hmio.condition.search.searched',
          query: previousSearch.current,
          location: location.pathname,
        });
      }
    }
    previousSearch.current = currentSearch;
  };
};

/**
 * Sends Google Analytics data about selected condition.
 */
export const useLogSelectedCondition = (): ((
  conditionName: string,
  conditionId: number,
  component: string,
  existingConditions: number[]
) => void) => {
  di(useAnalytics, useLocation, useConversionEventTracking);
  const analytics = useAnalytics();
  const location = useLocation();
  const trackConversionEvent = useConversionEventTracking();

  return (conditionName: string, conditionId, component, existingConditions): void => {
    analytics.track({
      event: 'hmio.condition.search.submitted',
      conditionName,
      conditionId,
      component,
      existingConditions: JSON.stringify(existingConditions),
      location: location.pathname,
    });
    trackConversionEvent(EventName.ConditionSearch, {
      alias: 'search',
    });
  };
};
