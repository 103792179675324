import { FC } from 'react';

import { Button } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useHistory, useLocation } from 'react-router-dom';

import { useFireAnalyticsOnFirstView } from 'shared/src/analytics/use-fire-analytics-on-first-view';

import { useAuth } from 'mats/src/components/auth/use-auth';
import { useLogSelectedCondition } from 'mats/src/components/condition-select-panel/analytics-logger';
import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { useGetConditionQuery } from 'components/condition-select-button/queries';

interface Props {
  conditionId: ConditionID;
}

export const ConditionSelectButton: FC<Props> = ({ conditionId }: Props) => {
  di(
    useGetConditionQuery,
    useAuth,
    useHistory,
    ConditionSelectPanel,
    useLogSelectedCondition,
    useLocation,
    useFireAnalyticsOnFirstView
  );

  const logSelectedCondition = useLogSelectedCondition();

  const { isAuthenticated } = useAuth();

  const location = useLocation();

  const { data, error } = useGetConditionQuery({ variables: { conditionId } });
  const condition = data?.condition;

  const viewportRef = useFireAnalyticsOnFirstView({
    event: 'hmio.condition.search.viewed',
    location: location.pathname,
    component: 'condition.select.button',
    ...(condition?.name ? { conditionName: condition.name } : {}),
    conditionId,
  });

  if (error || isAuthenticated) {
    // Fall back to the condition picker dropdown
    return <ConditionSelectPanel />;
  }

  if (!condition) {
    return null;
  }

  const handleClick = () => {
    logSelectedCondition(condition.name, condition.id, 'condition.select.button', []);

    window.location.href = `${import.meta.env.VITE_MATS_URL}/signup/${condition.id}`;
  };

  return (
    <Button ref={viewportRef} color="primary" variant="contained" onClick={handleClick}>
      Find trials for {condition.name}
    </Button>
  );
};
