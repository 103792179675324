import { FC } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { latoFontFamily } from 'shared/src/fonts/fonts';
import { gray } from 'shared/src/palette/color-system';

const useStyles = makeStyles<Theme, { first: boolean }>((theme: Theme) => ({
  faqSummary: {
    '&>div, &.Mui-expanded>div': {
      padding: 0,
      margin: 0,
    },
    padding: 0,
  },
  faqTitle: {
    fontFamily: latoFontFamily,
    margin: theme.spacing(4, 0, 4),
  },
  faqAnswer: {
    padding: theme.spacing(0, 0, 4),
  },
  accordion: {
    borderWidth: 0.25,
    borderColor: gray[1000],
    borderTopStyle: props => (props.first ? 'solid' : 'none'),
    borderBottomStyle: 'solid',
  },
}));

type FAQEntryProps = {
  first: boolean;
  question: string;
  questionTypography?: Variant;
};

export const FAQEntry: FC<FAQEntryProps> = ({ question, first, children, questionTypography }) => {
  const { faqSummary, faqTitle, accordion, faqAnswer } = useStyles({ first });
  return (
    <Accordion elevation={0} square className={accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={faqSummary}>
        <Typography variant={questionTypography ?? 'h5'} className={faqTitle}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={faqAnswer}>
        <Typography variant="body2" component="div">
          {children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
