import { ComponentType } from 'react';

import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichText, RichTextData } from 'shared/src/contentful-text/rich-text-renderer';
import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';

import { ContentThemeProvider } from 'components/content-theme/content-theme';

const useProfilePictureStyles = makeStyles(({ spacing }) => ({
  profileImage: {
    borderRadius: '100%',
    objectFit: 'cover',
    height: spacing(15),
    width: spacing(15),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridGap: spacing(3),
  },
}));

export const AuthorProfilePicture: ComponentType<{
  label: string;
  profilePicture: AssetFragment;
}> = ({ label, profilePicture }) => {
  const styles = useProfilePictureStyles();

  return (
    <div className={styles.container}>
      <ContentfulImage
        image={profilePicture}
        resize="thumb"
        imageFocus="face"
        maxHeight={120}
        maxWidth={120}
        lazy={false}
        className={styles.profileImage}
      />
      <Typography variant="body2" color="primary">
        {label}
      </Typography>
    </div>
  );
};

export const AuthorTitle: ComponentType<{
  name: string;
}> = ({ name }) => (
  <Container maxWidth="sm">
    <Typography variant="h3" component="h1" align="center">
      {name}
    </Typography>
  </Container>
);

export const AuthorAbout: ComponentType<{ about: RichTextData }> = ({ about }) => (
  <Container maxWidth="sm">
    <ContentThemeProvider>
      <RichText document={about} />
    </ContentThemeProvider>
  </Container>
);
