import { Children, FC, Fragment, ReactElement, ReactNode, useMemo } from 'react';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';
import { makeStyles } from '@material-ui/core';

import {
  createRenderOptions,
  RichText,
  RichTextProps,
} from 'shared/src/contentful-text/rich-text-renderer';
import { notEmpty } from 'shared/src/util/not-empty';

import { isSectionHeading } from 'components/article-sticky-menu/article-sticky-menu';
import { ContentSection } from 'components/content-menu/content-menu';

const useStyles = makeStyles({
  // Prevent margin collapse between sections so there's no seams between
  // when updating active menu element on scroll
  section: {
    overflow: 'auto',
  },
});

const overviewSectionName = 'Overview';

type ArticleDocumentProps = {
  injectElementAfterOverview?: ReactElement;
  documentNode: Block | Inline;
  documentChildren: ReactNode;
};

const ArticleDocument: FC<ArticleDocumentProps> = ({
  documentChildren,
  documentNode,
  children,
  injectElementAfterOverview,
}) => {
  const classes = useStyles();
  const childrenArray = Children.toArray(documentChildren);
  const sectionNodeIndexes = documentNode.content
    .map((node, index) => (isSectionHeading(node) ? index : null))
    .filter(notEmpty);

  return (
    <>
      {sectionNodeIndexes.map((index, i) => {
        const name = documentToPlainTextString(documentNode.content[index] as Block);
        const section = (
          <ContentSection key={name} name={name} className={classes.section}>
            {childrenArray.slice(index, sectionNodeIndexes[i + 1])}
          </ContentSection>
        );

        if (i !== 0) {
          return section;
        }

        // Prepend overview section wrapping the children before the first section
        return (
          <Fragment key={name}>
            <ContentSection
              key={overviewSectionName}
              name={overviewSectionName}
              className={classes.section}
            >
              {children}
              {childrenArray.slice(0, index)}
            </ContentSection>
            {injectElementAfterOverview}
            {section}
          </Fragment>
        );
      })}
    </>
  );
};

type ArticleRichTextProps = RichTextProps & {
  injectElementAfterOverview?: ReactElement;
};

export const ArticleRichText: FC<ArticleRichTextProps> = ({
  children,
  injectElementAfterOverview,
  ...props
}): ReactElement => {
  const articleRenderOptions = useMemo(
    () =>
      createRenderOptions({
        [BLOCKS.DOCUMENT]: (documentNode, documentChildren) => (
          <ArticleDocument
            documentChildren={documentChildren}
            documentNode={documentNode}
            injectElementAfterOverview={injectElementAfterOverview}
          >
            {children}
          </ArticleDocument>
        ),
      }),
    [children, injectElementAfterOverview]
  );

  return <RichText {...props} renderOptions={articleRenderOptions} />;
};
