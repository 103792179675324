import { FC, useContext } from 'react';

import { makeStyles } from '@material-ui/core';

import { AccentuatedContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { ContentfulLinkContext } from 'shared/src/contentful-text/components/context';

const inlineImageMaxWidth = 640;

const useStyles = makeStyles(theme => ({
  imageContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export const MediaBlock: FC<{ id: string }> = ({ id }) => {
  const { assets } = useContext(ContentfulLinkContext);
  const asset = assets.get(id);
  const styles = useStyles();

  if (!asset) {
    return null;
  }

  if (asset.contentType === 'video/mp4' && asset.url) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video controls>
        <source src={asset.url} type="video/mp4" />
      </video>
    );
  }

  if (asset.contentType === 'video/mp4') {
    console.warn(`Video asset ${asset.contentType}: missing url`);
    return null;
  }

  if (asset.contentType?.startsWith('image/')) {
    return (
      <AccentuatedContentfulImage
        maxWidth={inlineImageMaxWidth}
        image={asset}
        containerClassName={styles.imageContainer}
        borderRadius="md"
      />
    );
  }

  console.warn(`Unknown content type: ${asset.contentType}`);

  return null;
};
