import { datadogLogs } from '@datadog/browser-logs';

export const initLogger = (service: string) => {
  const clientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;

  if (!clientToken) {
    console.warn('Missing Datadog client token - no logs will be sent');
  } else {
    datadogLogs.init({
      clientToken,
      service,
      env: import.meta.env.VITE_DATADOG_ENV,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  }
};
