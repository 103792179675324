import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';

import { TopicHubPageList, TopicHubPageListVariables } from './types/queries.contentful.generated';

const TOPIC_HUB_PAGE_LIST = gql`
  query TopicHubPageList($slug: String!, $preview: Boolean) {
    topicHubPageCollection(where: { slug: $slug }, preview: $preview, limit: 1) {
      items {
        title
        titleMeta
        descriptionMeta
        slug
        heroBlog {
          sys {
            id
          }
          authorProfile {
            name
          }
          date
          heroImage {
            ...AssetFragment
          }
          slug
          title
        }
        featuredArticlesCollection(limit: 3) {
          items {
            ... on BlogPost {
              sys {
                id
              }
              authorProfile {
                name
              }
              date
              heroImage {
                ...AssetFragment
              }
              slug
              title
            }
            ... on ConditionArticle {
              sys {
                id
              }
              heroImage {
                ...AssetFragment
              }
              medicalReviewer {
                name
              }
              category {
                sys {
                  id
                }
                slug
              }
              reviewedAt
              slug
              title
            }
          }
        }
        articlesCollection {
          items {
            sys {
              id
              firstPublishedAt
            }
            title
            slug
            category {
              sys {
                id
              }
              condition
              slug
            }
            medicalReviewer {
              name
            }
          }
        }
      }
    }
  }
  ${ASSET_FRAGMENT}
`;

export const useTopicHubPage = contentfulQueryFactory<TopicHubPageList, TopicHubPageListVariables>(
  TOPIC_HUB_PAGE_LIST
);
