import { Link } from 'react-router-dom';

import { LoginButton } from 'shared/src/nav-bar/components/nav-items';

import { MatsLinkButton } from 'mats/src/components/mats-link/mats-link';

export const LoginButtonItem = () => (
  <MatsLinkButton variant="outlined" color="primary" to="/">
    Log in
  </MatsLinkButton>
);

export const LogoutButtonItem = () => (
  <LoginButton component={Link} to="/logout">
    Logout
  </LoginButton>
);
