import { gql } from '@apollo/client';

import { mutationFactory, queryFactory } from 'shared/src/util/query-factory';

import {
  GetExistingPatientInfo,
  RegisterPatient,
  RegisterPatientVariables,
  UpdatePatient,
  UpdatePatientVariables,
} from './types/queries.generated';

const REGISTER_PATIENT = gql`
  mutation RegisterPatient($input: RegisterRequestInput!) {
    registerPatient(input: $input) {
      id
      firstName
      lastName
      country
      localeWithInfo {
        code
      }
      state
      suburb
      lat
      lng
      dob
      sex
      email
    }
  }
`;

const GET_EXISTING_INFO_QUERY = gql`
  query GetExistingPatientInfo {
    patient {
      id
      firstName
      lastName
      email
      dob
      sex
      suburb
      country
      state
      phoneNumber
      lat
      lng
      applications {
        id
      }
      conditions {
        id
        name
      }
    }
  }
`;

const UPDATE_PATIENT_MUTATION = gql`
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      id
      firstName
      lastName
      email
      dob
      sex
      suburb
      country
      state
      phoneNumber
      lat
      lng
      applications {
        id
      }
      conditions {
        id
        name
      }
      conditionsWithMatchingInfo {
        id
        condition {
          id
          name
        }
        matchingInfo {
          id
          matchingTrials {
            id
          }
          matchingTrialsWithSkips {
            id
          }
          notMatchingTrialsWithSkips {
            id
          }
          unansweredTrials {
            id
          }
          nextMandatoryAttributeDefinition {
            id
          }
          nextMissingAttributeDefinition {
            id
          }
          nextSkippedAttributeDefinition {
            id
          }
        }
      }
    }
  }
`;

/**
 * @deprecated Use {@link usePatientBasicInfoQuery} from `hooks/patient/queries` instead
 */
export const useGetExistingPatientInfoQuery =
  queryFactory<GetExistingPatientInfo>(GET_EXISTING_INFO_QUERY);

/**
 * @deprecated Use {@link useUpdatePatient} from `hooks/patient/queries` instead
 */
export const usePatientUpdateMutation = mutationFactory<UpdatePatient, UpdatePatientVariables>(
  UPDATE_PATIENT_MUTATION
);

export const useRegisterPatient = mutationFactory<RegisterPatient, RegisterPatientVariables>(
  REGISTER_PATIENT
);
