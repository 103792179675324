import { FC, MutableRefObject, ReactNode } from 'react';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { di } from 'react-magnetic-di/macro';

import { useConditionsQuery } from 'shared/src/condition-select/queries';
import { SearchIcon } from 'shared/src/icon/icons';
import { SupportedLocale } from 'shared/src/locales/locales';
import { darkPurple, gray, lightPurple } from 'shared/src/palette/color-system';
import { useMemoizeAndMakeConditionOption } from 'shared/src/use-memoize-and-make-condition-option/use-memoize-and-make-condition-option';

export type ConditionOption = {
  id: ConditionID;
  aliases?: string[];
  label: string;
  value: string;
  prevAdded?: boolean;
};

const useStyles = makeStyles(({ breakpoints, spacing, palette, typography }) => ({
  paper: {
    borderRadius: spacing(1.5),
  },
  listbox: {
    padding: 0,
  },
  option: {
    padding: spacing(1, 0, 1, 8),
    // Align with input
    [breakpoints.down('sm')]: {
      paddingLeft: spacing(2),
    },
    flexWrap: 'wrap',
    '&&[aria-selected="true"]': {
      backgroundColor: lightPurple[500],
    },
    '&[data-focus="true"]': {
      backgroundColor: lightPurple[50],
    },
    '&[aria-disabled="true"]': {
      opacity: 1,
      // make option height uniform, account for chip
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },
  aliases: {
    fontSize: typography.pxToRem(12),
    fontWeight: typography.fontWeightBold,
    fontStyle: 'italic',
  },
  input: {
    color: palette.text.secondary,
    fontSize: typography.pxToRem(16),
    borderRadius: spacing(2),
    backgroundColor: gray[0],
    // Override all Autocomplete's right padding options
    '&&&&': {
      padding: 4,
      [breakpoints.down('sm')]: {
        paddingLeft: spacing(2),
      },
    },
  },
  inputFocused: {},
  searchIcon: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
    '$inputFocused &': {
      color: palette.primary.main,
    },
    color: gray[400],
    width: spacing(4),
    margin: spacing(0, 1.5),
  },
  outline: {
    borderColor: darkPurple[400],
    '$input:not($disabled):not($focused):hover &&&': {
      borderColor: palette.primary.main,
    },
    borderWidth: 2,
    borderRadius: spacing(2),
  },
  disableOutline: {
    borderStyle: 'none',
  },
  button: {
    lineHeight: 1,
    [breakpoints.down('sm')]: {
      minWidth: 0,
      padding: spacing(1.5),
    },
  },
  disabledButton: {
    '&&': {
      color: gray[0],
      backgroundColor: gray[400],
    },
  },
  selectedConditionText: {
    color: gray[400],
  },
  addedChip: {
    marginLeft: spacing(1),
    backgroundColor: gray[50],
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
  addedLabel: {
    color: gray[600],
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '23px',
  },
}));

export type ConditionSelectProps = {
  conditionId?: ConditionID;
  onChange: (condition?: ConditionOption) => void;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  onSubmit?: () => void | Promise<void>;
  onFocus?: () => void;
  loading?: boolean;
  popupOpen?: true; // exposed for testing
  variant?: 'outlined' | 'filled';
  innerRef?: MutableRefObject<null>;
  placeholder: string;
  showStartAdornment?: boolean;
  customEndAdornment?: ReactNode;
  fullWidth?: boolean;
  existingPatientConditions?: ConditionID[];
  locale?: SupportedLocale;
};

/**
 * Case insensitive matching of condition name based on user rawInput.
 * rawInput is tokenised and every token has to match the condition name.
 * Eg searching for "br can" will match "breast cancer" but not "breath disease" or "colon cancer"
 */
export const isConditionMatching = (conditionName: string, rawInput: string) =>
  (rawInput.toLowerCase().match(/\S+/g) || []) // tokenize rawInput https://stackoverflow.com/a/14912552
    .every(token => conditionName.toLowerCase().indexOf(token) > -1);

/**
 * Filtering options based on user input (rawInput)..
 */
export const filterOptions = (
  options: ConditionOption[],
  { inputValue }: { inputValue: string }
): ConditionOption[] => {
  if (!inputValue) {
    return options;
  }

  return options.filter(
    option =>
      isConditionMatching(option.label, inputValue) ||
      option.aliases?.some(a => isConditionMatching(a, inputValue))
  );
};

export const ConditionSelect: FC<ConditionSelectProps> = ({
  onFocus,
  conditionId,
  onChange,
  inputValue,
  onInputChange,
  onSubmit,
  loading,
  popupOpen = undefined,
  variant = 'outlined',
  innerRef,
  placeholder,
  showStartAdornment,
  customEndAdornment,
  fullWidth,
  existingPatientConditions,
  locale,
}) => {
  di(useConditionsQuery);
  const { data } = useConditionsQuery({
    ssr: false,
    context: { headers: { 'HM-LOCALE-CODE': locale } },
  });
  const classes = useStyles();
  const { spacing } = useTheme();
  const { t } = useTranslation();

  // Prevent needless re-renders of Autocomplete by memoizing the massaged data
  const conditions = useMemoizeAndMakeConditionOption(
    data?.conditions ?? [],
    existingPatientConditions ?? []
  );
  const value = conditions?.find(c => c.id === conditionId);

  return (
    <Autocomplete<ConditionOption, false, false, false>
      // Uncomment next prop to prevent popup from hiding when debugging its styles, etc
      // debug={true}
      classes={{
        paper: classes.paper,
        listbox: classes.listbox,
        option: classes.option,
      }}
      innerRef={innerRef}
      autoHighlight
      fullWidth={fullWidth}
      onFocus={onFocus}
      options={conditions ?? []}
      value={value ?? null}
      getOptionLabel={option => option?.label ?? ''}
      filterOptions={filterOptions}
      getOptionDisabled={option => option.prevAdded ?? false}
      renderOption={(option, state) => (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          marginRight={3}
        >
          <Box>
            <Typography
              data-test-id="option-label"
              variant="body2"
              color="textSecondary"
              className={option.prevAdded ? classes.selectedConditionText : ''}
            >
              {option.label}
            </Typography>
            {option.aliases?.length ? (
              <>
                {/* Break aliases to the new row, see https://tobiasahlin.com/blog/flexbox-break-to-new-row/ */}
                <Box flexBasis="100%" height={0} />
                <Typography
                  data-test-id="option-aliases"
                  color="textSecondary"
                  className={`${classes.aliases} ${
                    option.prevAdded ? classes.selectedConditionText : ''
                  }`}
                >
                  {option.aliases.filter(a => isConditionMatching(a, state.inputValue)).join(', ')}
                </Typography>
              </>
            ) : null}
          </Box>
          {option.prevAdded && (
            <Chip
              classes={{ root: classes.addedChip, label: classes.addedLabel }}
              label={t('shared.conditionSelect.added')}
              disabled
            />
          )}
        </Box>
      )}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => onInputChange?.(newInputValue)}
      onChange={(_, newValue) => onChange(newValue ?? undefined)}
      onKeyDown={e => {
        if (e.key === 'Enter' && conditionId) {
          onSubmit?.();
        }
        if (e.key === 'Escape' && conditionId) {
          onInputChange?.('');
          onChange();
        }
      }}
      popupIcon={null}
      open={popupOpen}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            color: 'primary',
            startAdornment: showStartAdornment && (
              <SearchIcon fontSize="large" className={classes.searchIcon} />
            ),
            endAdornment: customEndAdornment || (
              <Button
                aria-label={t('shared.conditionSelect.searchButtonText')}
                disabled={!conditionId || loading}
                variant="contained"
                color="primary"
                onClick={onSubmit}
                classes={{ root: classes.button, disabled: classes.disabledButton }}
              >
                <Box display={{ xs: 'none', md: 'inherit' }}>
                  {t('shared.conditionSelect.searchButtonText')}{' '}
                  {loading && (
                    <Box marginLeft={1} lineHeight={`${spacing(1)}px`}>
                      <CircularProgress size={spacing(2)} color="inherit" />
                    </Box>
                  )}
                </Box>
                <Box display={{ xs: 'inherit', md: 'none' }}>
                  {loading ? (
                    <CircularProgress size={spacing(3)} color="inherit" />
                  ) : (
                    <SearchIcon />
                  )}
                </Box>
              </Button>
            ),
            classes: {
              root: classes.input,
              focused: classes.inputFocused,
              notchedOutline: variant !== 'outlined' ? classes.disableOutline : classes.outline,
            },
          }}
          placeholder={placeholder}
          color="primary"
          variant="outlined"
        />
      )}
      data-test-id="condition-select"
    />
  );
};
