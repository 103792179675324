import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import {
  CONTENT_LINK_FRAGMENT,
  RELATED_CONDITIONS_FRAGMENT,
} from 'components/content-links/query.contentful';
import { REDIRECT_FRAGMENT } from 'components/contentful-redirect/redirect.contentful';
import {
  ConditionArticleBySlug,
  ConditionArticleBySlugVariables,
} from 'pages/condition-article/types/queries.contentful.generated';

const CONDITION_ARTICLE = gql`
  query ConditionArticleBySlug(
    $slug: String!
    $categorySlug: String!
    $orphanArticleTag: String!
    $preview: Boolean
  ) {
    conditionArticleCollection(where: { slug: $slug }, preview: $preview, limit: 1) {
      items {
        slug
        author {
          name
          profilePicture {
            ...AssetFragment
          }
          slug
        }
        medicalReviewer {
          name
          profilePicture {
            ...AssetFragment
          }
          slug
        }
        textBody {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        faqs {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        title
        titleMeta
        descriptionMeta
        heroImage {
          ...AssetFragment
        }
        references {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        category {
          sys {
            ...SysFragment
          }
          embeddedQuestionnaireQuestions
          condition
          conditionIdList
          heroImage {
            ...AssetFragment
          }
          slug
          relatedConditionsCollection(limit: 12) {
            ...RelatedConditionsFragment
          }
          statisticsLink {
            ... on ConditionCategory {
              sys {
                ...SysFragment
              }
              slug
            }
            ... on ConditionArticle {
              sys {
                ...SysFragment
              }
              slug
            }
          }
          treatmentsLink {
            ... on ConditionCategory {
              sys {
                ...SysFragment
              }
              slug
            }
            ... on ConditionArticle {
              sys {
                ...SysFragment
              }
              slug
            }
          }
        }
        sys {
          ...SysFragment
        }
      }
    }
    latestNews: conditionArticleCollection(
      preview: $preview
      limit: 20
      where: { category: { slug: $categorySlug } }
      order: [sys_publishedAt_DESC]
    ) {
      ...ContentLinksFragment
    }
    editorsPicks: conditionArticleCollection(
      preview: $preview
      limit: 6
      where: { category: { slug: $categorySlug } }
      order: [searchVolume_DESC]
    ) {
      ...ContentLinksFragment
    }
    orphanArticles: conditionArticleCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: [$orphanArticleTag] } } }
      limit: 12
    ) {
      ...ContentLinksFragment
    }
    ...RedirectFragment
  }
  ${ASSET_FRAGMENT}
  ${SYS_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
  ${RELATED_CONDITIONS_FRAGMENT}
  ${CONTENT_LINK_FRAGMENT}
  ${REDIRECT_FRAGMENT}
`;

export const useConditionArticle = contentfulQueryFactory<
  ConditionArticleBySlug,
  ConditionArticleBySlugVariables
>(CONDITION_ARTICLE);

export type ConditionArticle = DeepExtractTypeSkipArrays<
  ConditionArticleBySlug,
  ['conditionArticleCollection', 'items']
>;
