import { FC, useState } from 'react';

import { Box, Card, Grid, makeStyles, Typography } from '@material-ui/core';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { AspectRatioContainer } from 'shared/src/aspect-ratio-container/aspect-ratio-container';
import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { ArrowRightIcon, PinIcon } from 'shared/src/icon/icons';
import { Link } from 'shared/src/link/link';

import { PatientsPageList } from 'pages/patients/types/queries.contentful.generated';

export type PatientStory = DeepExtractTypeSkipArrays<
  PatientsPageList,
  ['patientsStoryCollection', 'items']
>;

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    // Match light grey backgrounds
    backgroundColor: 'inherit',
    padding: theme.spacing(3),
  },
  container: {
    height: '100%',
  },
  condition: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  pinIcon: {
    height: theme.spacing(3),
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  location: {
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
    borderRadius: theme.spacing(1),
  },
  link: {
    fontWeight: 700,
  },
  linkIcon: {
    width: theme.spacing(1.5),
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'middle',
  },
}));

type Props = {
  story: PatientStory;
  elevation?: number;
};

const defaultElevation = 1;

export const PatientStoryCard: FC<Props> = ({ story, elevation }) => {
  const classes = useStyles();
  const [internalElevation, setInternalElevation] = useState(0);

  const handleMouseOver =
    elevation == null ? () => setInternalElevation(defaultElevation) : undefined;
  const handleMouseOut = elevation == null ? () => setInternalElevation(0) : undefined;

  return (
    <Card
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={classes.card}
      elevation={elevation ?? internalElevation}
    >
      <Grid className={classes.container} container direction="column" wrap="nowrap">
        <Grid item>
          <Typography className={classes.condition}>{story.condition}</Typography>
          <Typography className={classes.heading} variant="h4" component="h3">
            {story.name}, {story.age}
          </Typography>
          <Typography className={classes.location} variant="body1">
            <PinIcon className={classes.pinIcon} />
            {story.location}
          </Typography>
          {story.patientPortrait && (
            <AspectRatioContainer ratio={3 / 4}>
              <ContentfulImage
                className={classes.image}
                maxWidth="sm"
                image={story.patientPortrait}
              />
            </AspectRatioContainer>
          )}
        </Grid>
        {story.summary && (
          <Grid item xs={12}>
            <Box marginTop={2} marginBottom={3}>
              <RichText document={story.summary} />
            </Box>
          </Grid>
        )}
        {story.name && story.slug && (
          <Grid item>
            <Typography variant="body2">
              <Link className={classes.link} to={`/patients/${story.slug}`}>
                Read {story.name}&apos;{!story.name.endsWith('s') && 's'} story
                <ArrowRightIcon className={classes.linkIcon} />
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
