import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useAnalytics } from 'shared/src/analytics/analytics';

import { usePatientLocale } from 'mats/src/components/use-patient-locale/use-patient-locale';

/**
 * This component gets the locale for the patient and passes it along to the i18n provider and analytics context.
 * It should be used in the root of the app.
 */
export const PatientLocaleConsumer: FC = ({ children }) => {
  const { i18n } = useTranslation();
  const { locale } = usePatientLocale();
  const analytics = useAnalytics();

  useEffect(() => {
    if (i18n.language !== locale) {
      i18n.changeLanguage(locale);
    }
    analytics.setLocale(locale);
  }, [analytics, i18n, locale]);

  return <>{children}</>;
};
