import { createContext, FC, useContext, useEffect, useRef, useState } from 'react';

import { di } from 'react-magnetic-di/macro';

import { useReliableLocalStorage } from 'shared/src/util/use-reliable-storage/use-reliable-storage';

import {
  FlagState,
  FlagUser,
  getConfigCatConfig,
  initConfigCat,
  refreshFlagState,
} from './config-cat-utils';

type FlagStateOptionalClient = Omit<FlagState, 'configCatClient'> &
  Partial<Pick<FlagState, 'configCatClient'>>;
const ConfigCatContext = createContext<FlagStateOptionalClient | null>(null);
export const useConfigCat = () => useContext(ConfigCatContext);

interface FeatureFlagProviderProps {
  user?: FlagUser;
  flagState?: FlagStateOptionalClient;
}

const emptyFlagState = {};

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({
  children,
  user,
  flagState: initialFlagState = null,
}) => {
  di(initConfigCat, getConfigCatConfig, refreshFlagState);
  // don't talk to me or my son ever again

  const [disableConfigCatLocalstorageFlag] = useReliableLocalStorage('disableConfigCat');
  const disableConfigCat =
    import.meta.env.VITE_ENABLE_URL_FLAGS === 'true' && disableConfigCatLocalstorageFlag;
  const { sdkKey, ...configCatConfig } = getConfigCatConfig();
  // Skip refreshing flags on first render if we have flag values already
  const skipRefresh = useRef(!!initialFlagState?.flagValues);

  const [flagState, setFlagState] = useState<FlagStateOptionalClient | null>(() => {
    if (!sdkKey || disableConfigCat || initialFlagState?.configCatClient) {
      return initialFlagState;
    }

    return {
      ...initConfigCat({ ...configCatConfig, sdkKey }),
      ...initialFlagState,
    };
  });

  useEffect(() => {
    if (flagState?.configCatClient && user && !skipRefresh.current) {
      refreshFlagState(flagState.configCatClient, user).then(setFlagState);
    }
    skipRefresh.current = false;
  }, [flagState?.configCatClient, user]);

  if (!sdkKey || disableConfigCat) {
    // Fallback mode for when the client does not get initialised, usually for tests.
    // Flags will instead resolve to an undefined value.
    return <>{children}</>;
  }

  if (!flagState?.flagValues) {
    // Wait for the feature flag client to initialise before rendering anything
    return null;
  }

  // Render children even when flags themselves are still loading.
  return (
    <ConfigCatContext.Provider value={flagState ?? emptyFlagState}>
      {children}
    </ConfigCatContext.Provider>
  );
};
