import { FC } from 'react';

import { Box, Container, Grid, makeStyles, Theme } from '@material-ui/core';

type Props = {
  imageTop: string;
  imageBottom: string;
  colorTop: string;
  colorBottom: string;
  className: string;
  imageFit?: 'contain' | 'cover';
};

const useStyles = makeStyles<Theme, Props>(({ spacing, breakpoints }) => ({
  image: {
    flex: '1 1 50%',
    width: '100%',
    height: 0,
    paddingTop: '30%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    [breakpoints.down('sm')]: {
      backgroundSize: '110%',
      backgroundPosition: '50% 25%',
    },
  },
  imageTop: {
    composes: '$image',
    borderRadius: '0 0 20px 20px',
    marginBottom: spacing(3),
    backgroundImage: props => `url("${props.imageTop}")`,
    backgroundColor: props => props.colorTop,
    backgroundSize: props => props.imageFit ?? 'contain',
    [breakpoints.down('sm')]: {
      borderRadius: '0 20px 20px 0',
      margin: spacing(0, 0.5, 0, 0),
    },
  },
  imageBottom: {
    composes: '$image',
    borderRadius: '20px',
    backgroundImage: props => `url("${props.imageBottom}")`,
    backgroundColor: props => props.colorBottom,
    backgroundSize: props => props.imageFit ?? 'contain',
    [breakpoints.down('sm')]: {
      borderRadius: '20px 0 0 20px',
      margin: spacing(0, 0, 0, 0.5),
    },
  },
}));

export const HeroBanner: FC<Props> = ({ children, ...props }) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={props.className}>
        <Container>
          <Grid direction="row" container>
            <Box flex="1 1 auto" width={40} paddingY={[4, 4, 8]}>
              {children}
            </Box>
            <Box display={{ xs: 'none', md: 'block' }} width={80} flex="0 1 auto" />
            <Box display={{ xs: 'none', md: 'flex' }} flexDirection="column" flex="1 1 auto">
              <Box className={classes.imageTop} />
              <Box className={classes.imageBottom} />
            </Box>
          </Grid>
        </Container>
      </div>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        flex="1 1 auto"
        flexDirection="row"
        paddingTop={1.5}
      >
        <Box className={classes.imageTop} />
        <Box className={classes.imageBottom} />
      </Box>
    </>
  );
};
