import { FC } from 'react';

import { Box } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useLocation, useMount } from 'react-use';

import { Footer, FooterWaveBleedMode } from 'components/footer/footer';
import { HeaderNav } from 'components/header-nav/header-nav';
import { WindowTitle } from 'components/window-title/window-title';

export interface PageBaseProps {
  windowTitle: string;
  windowTitleHmPrefix?: boolean;
  descriptionMeta?: string;
  footerBleed?: FooterWaveBleedMode;
  classes?: { contentContainer?: string };
  variant?: 'full' | 'logoOnly';
}

export const PageBase: FC<PageBaseProps> = ({
  children,
  classes,
  descriptionMeta,
  footerBleed,
  variant = 'full',
  windowTitle,
  windowTitleHmPrefix,
}) => {
  di(HeaderNav, useLocation);

  const { search, pathname } = useLocation();

  const params = new URLSearchParams(search);

  // redirect to mats if we get here and user is requesting login
  if (params.has('login')) {
    window.location.replace(`${import.meta.env.VITE_MATS_URL}${pathname}${search}`);
  }

  useMount(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <WindowTitle
        hmPrefix={windowTitleHmPrefix}
        title={windowTitle}
        description={descriptionMeta}
      />
      {variant === 'logoOnly' ? <HeaderNav variant="logoOnlyNav" /> : <HeaderNav />}
      <Box flexGrow={1} className={classes?.contentContainer}>
        {children}
      </Box>
      {variant !== 'logoOnly' && <Footer mode={footerBleed} />}
    </Box>
  );
};
