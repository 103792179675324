import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';

import {
  GetEnhancedTrialCardsByTrialId,
  GetEnhancedTrialCardsByTrialIdVariables,
} from './types/queries.contentful.generated';

const GET_ENHANCED_TRIAL_CARDS_BY_TRIAL_ID = gql`
  query GetEnhancedTrialCardsByTrialId($trialIds: [String], $preview: Boolean, $maxTrials: Int) {
    enhancedTrialCardCollection(
      preview: $preview
      where: { trialId_in: $trialIds }
      limit: $maxTrials
    ) {
      items {
        trialId
        photo {
          ...AssetFragment
        }
        compensationAvailable
        lengthOfTrial
        numberOfVisits
        appointmentOptions
        trialPhase
        trialType
        overnightStay
      }
    }
  }
  ${ASSET_FRAGMENT}
`;

export const useRawGetEnhancedTrialCardsByTrialId = contentfulQueryFactory<
  GetEnhancedTrialCardsByTrialId,
  GetEnhancedTrialCardsByTrialIdVariables
>(GET_ENHANCED_TRIAL_CARDS_BY_TRIAL_ID);
