import { createContext, FC, useState } from 'react';

import { addMinutes } from 'date-fns';
import decode, { JwtPayload } from 'jwt-decode';
import SsrCookies from 'ssr-cookie';

import { AUTH_COOKIE_NAME } from './constants';

type MatsJwtPayload = JwtPayload & {
  Email?: string;
};

export type AuthContext = {
  getAuthToken(): string | null;
  setAuthToken(token: string): void;
  removeAuthToken(): void;
  getAuthenticatedUserId(): number | null;
  getAuthenticatedUserEmail(): string | undefined;
  isAuthenticated: boolean;
  checkAndSetIsAuthenticated(): boolean;
};

export const authContext = createContext<AuthContext | null>(null);

export const AuthProvider: FC<{ ssrCookies: SsrCookies }> = ({ children, ssrCookies }) => {
  const getAuthToken = () => ssrCookies.get(AUTH_COOKIE_NAME) ?? null;

  const [isAuthenticated, setIsAuthenticated] = useState(!!getAuthToken());

  const checkAndSetIsAuthenticated = () => {
    const tokenExists = !!getAuthToken();
    setIsAuthenticated(tokenExists);
    return tokenExists;
  };

  const setAuthToken = (token: string) => {
    ssrCookies.set(AUTH_COOKIE_NAME, token, {
      // Default for modern versions, but not for a bit older ones
      sameSite: 'lax',
      secure: true,
      // Same as token expiration
      // https://github.com/healthmatch/backend/blob/af1b8bc42313642ffd7132a779cab717df0a4877/katch/src/main/kotlin/io/healthmatch/katch/service/security/AuthorizationService.kt#L38
      expires: addMinutes(Date.now(), 90),
    });

    setIsAuthenticated(true);
  };

  const removeAuthToken = () => {
    ssrCookies.remove(AUTH_COOKIE_NAME);
    setIsAuthenticated(false);
  };

  const getAuthenticatedUserId = (): null | number => {
    const jwtToken = getAuthToken();
    if (jwtToken) {
      // Get the subject (which in our case is the user's ID) from the token
      const { sub } = decode<MatsJwtPayload>(jwtToken);

      const parsed = Number.parseInt(sub ?? '', 10);

      if (Number.isFinite(parsed)) {
        return parsed;
      }
    }
    return null;
  };

  const getAuthenticatedUserEmail = (): string | undefined => {
    const jwtToken = getAuthToken();
    if (jwtToken) {
      return decode<MatsJwtPayload>(jwtToken).Email;
    }
    return undefined;
  };

  return (
    <authContext.Provider
      value={{
        getAuthToken,
        setAuthToken,
        removeAuthToken,
        getAuthenticatedUserId,
        getAuthenticatedUserEmail,
        isAuthenticated,
        checkAndSetIsAuthenticated,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
