import { FC, ReactNode, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useAnalytics } from 'shared/src/analytics/analytics';
import { useFireAnalyticsOnFirstView } from 'shared/src/analytics/use-fire-analytics-on-first-view';
import {
  ConditionOption,
  ConditionSelect,
  ConditionSelectProps,
} from 'shared/src/condition-select/condition-select';
import { Link } from 'shared/src/link/link';

import { useAuth } from 'mats/src/components/auth/use-auth';
import { useGetExistingPatientConditions } from 'mats/src/components/condition-select-panel/queries';
import { MatsLink } from 'mats/src/components/mats-link/mats-link';
import { usePatientLocale } from 'mats/src/components/use-patient-locale/use-patient-locale';
import { usePatientUpdateMutation } from 'mats/src/hooks/signup/queries';

import { useAnalyticsLogger, useLogSelectedCondition } from './analytics-logger';

export type ConditionSelectPanelProps = Pick<ConditionSelectProps, 'variant'> & {
  placeholder?: string;
  hideHelpText?: boolean;
  helpText?: ReactNode;
};

const useStyles = makeStyles(() => ({
  default: {
    justifyContent: 'center',
  },
  filled: {
    '&.MuiLink-root': {
      color: 'inherit',
    },
  },
}));

export const ConditionSelectPanel: FC<ConditionSelectPanelProps> = ({
  variant,
  placeholder,
  hideHelpText = false,
  helpText,
}) => {
  di(
    useAnalyticsLogger,
    useAuth,
    useHistory,
    Link,
    MatsLink,
    useLogSelectedCondition,
    useAnalytics,
    useLocation,
    useFireAnalyticsOnFirstView,
    useGetExistingPatientConditions,
    usePatientUpdateMutation,
    usePatientLocale
  );

  const classes = useStyles();
  const { locale } = usePatientLocale();
  const logSelectedCondition = useLogSelectedCondition();
  const [condition, setCondition] = useState<ConditionOption>();
  const trackSearch = useAnalyticsLogger();
  const location = useLocation();
  const analytics = useAnalytics();
  const { isAuthenticated } = useAuth();
  const { push } = useHistory();
  const [inputValue, setInputValue] = useState('');
  const viewportRef = useFireAnalyticsOnFirstView({
    event: 'hmio.condition.search.viewed',
    location: location.pathname,
    component: 'condition.select.dropdown',
  });
  const { t } = useTranslation();

  const { data } = useGetExistingPatientConditions({
    skip: !isAuthenticated,
  });
  const [patientUpdateInfo, { loading }] = usePatientUpdateMutation();

  const onConditionSelect = (newCondition: ConditionOption) => {
    const conditionIds = data?.patient.conditions.map(c => c.id);
    if (conditionIds && !conditionIds.includes(newCondition.id)) {
      logSelectedCondition(
        newCondition.label,
        newCondition.id,
        'condition.select.dropdown',
        conditionIds.slice()
      );
      conditionIds.push(newCondition.id);
      patientUpdateInfo({
        variables: { input: { conditionIds } },
        onCompleted() {
          push(`/questionnaire/condition/${newCondition.id}`);
        },
      });
    }
  };

  return (
    <>
      <ConditionSelect
        locale={locale}
        onFocus={() =>
          analytics.track({
            event: 'hmio.condition.search.focused',
            ...(condition?.label ? { conditionName: condition.label } : {}),
            ...(condition?.id ? { conditionId: condition.id } : {}),
            component: 'condition.select.dropdown',
            location: location.pathname,
          })
        }
        showStartAdornment
        conditionId={condition?.id}
        placeholder={placeholder ?? t('mats.conditionSelectPanel.placeholderText')}
        onChange={setCondition}
        onSubmit={() => {
          if (condition?.id != null) {
            if (isAuthenticated) {
              onConditionSelect(condition);
            } else {
              logSelectedCondition(condition.label, condition.id, 'condition.select.dropdown', []);

              const matsUrl = import.meta.env.VITE_MATS_URL ?? '';
              if (matsUrl.includes(window.location.host)) {
                // Already on mats, so use app routing
                push(`/signup/${condition.id}`);
              } else {
                window.location.href = `${import.meta.env.VITE_MATS_URL}/signup/${condition.id}`;
              }
            }
          }
        }}
        loading={loading}
        inputValue={inputValue}
        onInputChange={value => {
          trackSearch(value);
          setInputValue(value);
        }}
        variant={variant}
        innerRef={viewportRef}
        existingPatientConditions={data?.patient.conditions.map(c => c.id)}
      />
      {isAuthenticated && !hideHelpText && (
        <Box
          display="flex"
          marginTop={2}
          marginBottom={1}
          className={variant === 'filled' ? '' : classes.default}
        >
          {helpText || (
            <Typography variant="body2">
              <MatsLink className={variant === 'filled' ? classes.filled : ''} to="/profile">
                {t('mats.conditionSelectPanel.authenticatedHelpText')}
              </MatsLink>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
