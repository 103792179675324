import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import {
  PatientStoryBySlug,
  PatientStoryBySlugVariables,
} from './types/queries.contentful.generated';

const PATIENT_STORY_BY_SLUG_QUERY = gql`
  query PatientStoryBySlug($preview: Boolean, $slug: String!) {
    patientsStoryCollection(preview: $preview, where: { slug: $slug }, limit: 1) {
      items {
        sys {
          ...SysFragment
        }
        slug
        name
        age
        condition
        location
        summary {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        patientImage {
          ...AssetFragment
        }
        quote
        quoteImage {
          ...AssetFragment
        }
        story {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        patientPortrait {
          ...AssetFragment
        }
      }
    }
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
`;

export const usePatientsStoryBySlug = contentfulQueryFactory<
  PatientStoryBySlug,
  PatientStoryBySlugVariables
>(PATIENT_STORY_BY_SLUG_QUERY);
