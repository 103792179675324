import { FC } from 'react';

import { Box, Breadcrumbs, Container, Divider, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { scroller } from 'react-scroll';
import { useEffectOnce } from 'react-use';
import { MedicalWebPage } from 'schema-dts';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import healthmatchLogo from 'shared/src/assets/healthmatch-logo-primary.svg';
import { Link } from 'shared/src/link/link';
import { beige, gray, lightPurple } from 'shared/src/palette/color-system';
import { notEmpty } from 'shared/src/util/not-empty';

import { usePublicTrialsForCondition } from 'mats/src/components/trial-list-public/queries';

import {
  ArticleLink,
  ContentLinks,
  normalizeRelatedArticles,
} from 'components/content-links/content-links';
import {
  ContentMenu,
  ContentMenuPlaceholder,
  ContentSection,
} from 'components/content-menu/content-menu';
import { ContentThemeProvider } from 'components/content-theme/content-theme';
import { getRedirectIfExists } from 'components/contentful-redirect/contentful-redirect';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { CanonicalUrl, OgArticleType, OgImage } from 'components/seo/meta-data-fragments';
import { MedicationsListSection } from 'pages/condition-category/components/medications-list-section/medications-list-section';
import { PublicTrialsSection } from 'pages/condition-category/components/public-trials-section/public-trials-section';
import { slugify } from 'pages/condition-category/components/slugify/slugify';
import {
  ConditionCategoryBySlugContent,
  useConditionCategoryBySlug,
} from 'pages/condition-category/queries.contentful';
import { NotFound } from 'pages/not-found/not-found';

import {
  CategoryHeroBanner,
  useSectionArray,
} from './components/category-hero-banner/category-hero-banner';
import { CategoryOverview } from './components/category-overview/category-overview';
import { ConditionBodySection } from './components/condition-body-section/condition-body-section';

const sectionColors = [beige[100], undefined, gray[50], undefined];
const getSectionColor = (index: number) => sectionColors[index % sectionColors.length];

const useStyles = makeStyles(theme => ({
  disablePointerEvents: {
    pointerEvents: 'none',
  },
  enablePointerEvents: {
    pointerEvents: 'all',
  },
  // Results in text line being within recommended 45-85 characters range
  textWidthLimit: {
    maxWidth: theme.breakpoints.values.sm,
  },
  // Make sure section takes 100% width
  fullWidthSection: {
    flexBasis: '100%',
  },
}));

const sectionPadding = 10;
const menuTopMargin = sectionPadding + 1;
const overviewSectionName = 'Overview';

export const ConditionCategoryContent: FC<{
  category: ConditionCategoryBySlugContent;
  editorsPicks?: ArticleLink[];
  latestNews?: ArticleLink[];
  orphanArticles?: ArticleLink[];
}> = ({ category, editorsPicks, latestNews, orphanArticles = [] }) => {
  di(PageBase, usePublicTrialsForCondition);
  const location = useLocation();

  // react-router consumes the hash so we just wanna force a scroll here
  useEffectOnce(() => {
    scroller.scrollTo(location.hash.slice(1), {
      duration: 0,
      // only works when smooth is true for some reason...
      smooth: true,
    });
  });

  const { pathname } = useLocation();
  const classes = useStyles();
  const conditionId = category?.conditionIdList?.[0];

  const conditionQueryResult = usePublicTrialsForCondition({
    variables: { conditionId: Number(conditionId) as ConditionID },
    skip: !conditionId,
  });

  const hasTrials =
    conditionQueryResult.data != null && conditionQueryResult.data.searchTrials.length > 0;

  const medicationList = useMedicationList(category);

  const sections = category.sectionsCollection?.items ?? [];
  const sectionTypes = useSectionArray(sections, hasTrials, medicationList != null);

  const relatedArticles = normalizeRelatedArticles(category.relatedConditionsCollection);
  const combinedRelatedArticleData = [...orphanArticles, ...relatedArticles].slice(0, 12);
  const hasRelatedArticles = combinedRelatedArticleData.length > 0;

  const hasLatestNews = latestNews && latestNews.length > 0;
  const hasEditorsPicks = editorsPicks && editorsPicks.length > 0;
  const lastSectionIsWhite = getSectionColor(sections.length - 1) == null;

  const footerBleed = getFooterBleed({
    maybeTrials: conditionQueryResult.data?.searchTrials,
    medicationList,
  });

  return (
    <PageBase
      windowTitle={category.title ?? ''}
      footerBleed={footerBleed}
      descriptionMeta={category.descriptionMeta ?? ''}
      windowTitleHmPrefix={false}
    >
      <Helmet
        script={[
          helmetJsonLdProp<MedicalWebPage>({
            '@context': 'https://schema.org',
            '@type': 'MedicalWebPage',
            headline: category.titleMeta ?? category.title ?? undefined,
            description: category.descriptionMeta ?? undefined,
            // "createdAt" = first published, "updatedAt" = latest publish, see "sys properties" table at:
            // https://www.contentful.com/developers/docs/references/content-management-api/#/introduction/collection-resources-and-pagination
            datePublished: category.sys.firstPublishedAt ?? undefined,
            dateModified: category.sys.publishedAt ?? undefined,
            url: `${import.meta.env.VITE_HMIO_URL}/${category.slug}`,
            ...(category.heroImage?.url ? { image: category.heroImage.url } : {}),
            lastReviewed: category.reviewedAt ?? category.sys.publishedAt ?? undefined,
            publisher: {
              '@type': 'Organization',
              name: 'HealthMatch',
              logo: {
                '@type': 'ImageObject',
                url: healthmatchLogo,
              },
            },
            author: {
              '@type': 'Person',
              name: category.author?.name ?? undefined,
            },
            ...(category.medicalReviewer
              ? {
                  reviewedBy: {
                    '@type': 'Person',
                    name: category.medicalReviewer.name ?? undefined,
                  },
                }
              : {}),
          }),
        ]}
      />
      <CanonicalUrl url={`${import.meta.env.VITE_HMIO_URL}${pathname}`} />
      <OgArticleType
        published={category.sys.publishedAt}
        modified={category.sys.publishedAt}
        author={category.author?.name}
      />
      {category.heroImage && <OgImage image={category.heroImage} />}
      <ContentSection name={overviewSectionName}>
        <Container>
          <ContentThemeProvider>
            <Box marginTop={{ xs: 2, md: 5 }}>
              <Breadcrumbs>
                <Link color="inherit" to="/">
                  Home
                </Link>
                <Link color="inherit" to={pathname}>
                  {category.condition}
                </Link>
              </Breadcrumbs>
            </Box>
            <Box marginTop={{ xs: 4, sm: 5 }} marginBottom={{ xs: 7, sm: 10 }}>
              <CategoryHeroBanner
                category={category}
                hasTrials={hasTrials}
                hasMedications={medicationList != null}
              />
            </Box>
            <Box marginTop={7} paddingBottom={7}>
              <CategoryOverview
                overviewText={category.overview}
                relatedConditions={
                  category.relatedConditionsCollection?.items.filter(notEmpty) ?? null
                }
              />
            </Box>
          </ContentThemeProvider>
        </Container>
      </ContentSection>
      <Box display="flex" flexWrap="wrap">
        <Box
          // A sticky-positioned flex item
          position="sticky"
          top={0}
          // Don't some when too narrow
          display={{ xs: 'none', md: 'block' }}
          // Make sure it's zero-width
          flexBasis={0}
          // Make it only no more than the height of the menu, so it can go
          // all the way to bottom
          alignSelf="flex-start"
          // Take paragraph margin into account
          marginBottom={sectionPadding + 2}
        >
          <Box position="relative">
            <Box
              // Take block out the flow and make it viewport-wide,
              // so we can position the menu using <Container>
              position="absolute"
              top={menuTopMargin}
              left={0}
              width="100vw"
              // Avoid interfering with content interaction
              className={classes.disablePointerEvents}
            >
              <Container>
                <Box
                  // Align to the right side of container
                  display="flex"
                  justifyContent="flex-end"
                >
                  {/*
                   * Due to a bug described in the task GET-175, this forces
                   * a remount to workaround a defect in react-scroll where
                   * scroll listeners aren't always correctly attached after
                   * navigation. So it needs to be remounted after navigation.
                   * Making the path a key won't work as the parent remounts
                   * after the path changes
                   */}
                  <ContentMenu
                    key={conditionId}
                    // Re-enable interaction with menu
                    className={classes.enablePointerEvents}
                    items={sectionTypes}
                  />
                </Box>
              </Container>
            </Box>
            <Box
              // Make sure the zero-width block as much vertical space as the actual menu
              marginTop={menuTopMargin}
            >
              <ContentMenuPlaceholder length={sectionTypes.length} />
            </Box>
          </Box>
        </Box>
        {sections.map((section, index) => {
          if (!section || !section.type) {
            return null;
          }

          return (
            <ContentSection
              // ContentSection should wrap actual content to leave no seams
              // when updating active menu element on scroll
              name={section.type}
              className={classes.fullWidthSection}
              key={section.sys.id}
            >
              <Box paddingY={sectionPadding} bgcolor={getSectionColor(index)}>
                <Container>
                  <div className={classes.textWidthLimit}>
                    <ConditionBodySection section={section} />
                  </div>
                </Container>
              </Box>
            </ContentSection>
          );
        })}
      </Box>
      {hasRelatedArticles && (
        <Box paddingY={{ xs: 7, sm: 10 }} bgcolor={lightPurple[50]}>
          <ContentLinks links={combinedRelatedArticleData} categoryLinks title="Related articles" />
        </Box>
      )}
      {/* not visible when no content rendered */}
      {lastSectionIsWhite && !hasRelatedArticles && (
        <Container>
          <Divider />
        </Container>
      )}
      {hasEditorsPicks && (
        <Box paddingY={{ xs: 7, sm: 10 }}>
          <ContentLinks links={editorsPicks} title="Editor’s picks" />
        </Box>
      )}
      {hasEditorsPicks && hasLatestNews && (
        <Container>
          <Divider />
        </Container>
      )}
      {hasLatestNews && (
        <Box paddingY={{ xs: 7, sm: 10 }}>
          <ContentLinks links={latestNews} title="Latest news" />
        </Box>
      )}

      {conditionQueryResult.data && hasTrials && category.condition && (
        <Box id={slugify('Clinical trials')} paddingY={10} bgcolor={beige[50]}>
          <PublicTrialsSection
            trialsForConditionData={conditionQueryResult.data}
            conditionInfo={{
              conditionName: category.condition,
              viewMoreTrialsUrl: `${location.pathname}/clinical-trials`,
            }}
          />
        </Box>
      )}
      {medicationList && (
        <Box id={slugify('Medications')} paddingY={10}>
          <MedicationsListSection
            medicationsData={
              category.linkedFrom?.medicationArticleCollection?.items
                .map(m => {
                  if (!m || !m.slug) {
                    return null;
                  }

                  const title = m.medicationName || m.genericMedicationName;

                  if (!title) {
                    return null;
                  }

                  return {
                    title,
                    slug: m.slug,
                  };
                })
                .filter(notEmpty) ?? []
            }
            conditionName={medicationList.condition}
          />
        </Box>
      )}
    </PageBase>
  );
};

export const ConditionCategoryPage: FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {
  const { data, loading } = useConditionCategoryBySlug({
    variables: {
      slug: match.params.slug,
      orphanArticleTag: `orphan-${match.params.slug}`,
    },
  });

  const category = data?.conditionCategoryCollection?.items[0];

  if (loading) {
    return <LoadingPage windowTitle="Loading" />;
  }

  const latestNews = data?.latestNews?.items?.filter(notEmpty);
  const editorsPicks = data?.editorsPicks?.items?.filter(notEmpty);
  const orphanArticles = data?.orphanArticles?.items?.filter(notEmpty);

  const redirect = getRedirectIfExists(data?.redirectCollection?.items[0]);

  if (redirect) {
    return redirect;
  }

  if (category == null) {
    return <NotFound />;
  }

  return (
    <ConditionCategoryContent
      category={category}
      editorsPicks={editorsPicks}
      latestNews={latestNews}
      orphanArticles={orphanArticles}
    />
  );
};

const BEIGE_BLEED = { kind: 'background', color: beige[50] } as const;
const WHITE_BLEED = { kind: 'background', color: gray[0] } as const;

function getFooterBleed<A, B>({
  maybeTrials,
  medicationList,
}: {
  maybeTrials: A[] | undefined | null;
  medicationList: B | undefined | null;
}) {
  if (medicationList != null) {
    return WHITE_BLEED;
  }
  if (maybeTrials && maybeTrials.length > 0) {
    return BEIGE_BLEED;
  }

  return WHITE_BLEED;
}

type MedicationListItem = DeepExtractTypeSkipArrays<
  ConditionCategoryBySlugContent,
  ['linkedFrom', 'medicationArticleCollection', 'items']
>;

const useMedicationList = (
  category: ConditionCategoryBySlugContent | undefined | null
):
  | {
      list: (MedicationListItem | null)[];
      condition: string;
    }
  | undefined => {
  const list = category?.linkedFrom?.medicationArticleCollection?.items;

  if (list != null && list?.length > 0 && category?.condition != null) {
    return { list, condition: category.condition };
  }

  return undefined;
};
