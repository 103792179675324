import { FC } from 'react';

import { Box } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { DrawerContentComponent } from 'shared/src/nav-bar/components/drawer-trigger';
import { DrawerItems, ToolBarItems } from 'shared/src/nav-bar/components/nav-item-containers';
import { DrawerLinkItem, ToolbarLinkItem } from 'shared/src/nav-bar/components/nav-items';

import { LoginButtonItem } from 'components/header-nav/components/login-logout/login-logout';
import {
  PatientsDrawerButton,
  PatientsToolbarButton,
} from 'components/header-nav/components/patients/patients-button';

import { SitesDrawerButton, SitesToolbarButton } from '../sites/sites-button';
import { SponsorsDrawerButton, SponsorsToolbarButton } from '../sponsors/sponsors-button';

export const ToolbarLoggedOutItems: FC = () => {
  di(LoginButtonItem, SponsorsToolbarButton, PatientsToolbarButton, SitesToolbarButton);

  return (
    <ToolBarItems>
      <SponsorsToolbarButton />
      <PatientsToolbarButton />
      <SitesToolbarButton />
      <ToolbarLinkItem to="/about" key="/about">
        About HealthMatch
      </ToolbarLinkItem>
      <LoginButtonItem />
    </ToolBarItems>
  );
};

export const DrawerLoggedOutItems: DrawerContentComponent = ({ onCloseDrawer }) => {
  di(
    DrawerLinkItem,
    SponsorsDrawerButton,
    PatientsDrawerButton,
    SitesDrawerButton,
    LoginButtonItem
  );

  return (
    <DrawerItems>
      <SponsorsDrawerButton onNavigation={onCloseDrawer} />
      <PatientsDrawerButton onNavigation={onCloseDrawer} />
      <SitesDrawerButton onNavigation={onCloseDrawer} />
      <DrawerLinkItem to="/about" key="/about">
        About HealthMatch
      </DrawerLinkItem>
      <Box marginTop={2}>
        <LoginButtonItem />
      </Box>
    </DrawerItems>
  );
};
