import { FC, useEffect, useRef } from 'react';

import { ClickAwayListener, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ButtonChromeless } from 'shared/src/button/button-chromeless';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from 'shared/src/icon/icons';
import { DrawerTrigger } from 'shared/src/nav-bar/components/drawer-trigger';
import { DrawerButtonItem, ToolbarButtonItem } from 'shared/src/nav-bar/components/nav-items';

const useStyles = makeStyles(({ spacing }) => ({
  buttonLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: spacing(1.5),
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerGrid: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: spacing(4.5),
    // the copyright has this annoying padding.
    padding: spacing(4.5, 2, 0),
  },
  drawerGuide: {
    paddingLeft: spacing(2),
  },
  toolbarGuide: {
    padding: spacing(8, 0),
  },
  icon: {
    fontSize: spacing(1.5),
  },
  iconDismiss: {
    fontSize: spacing(2.5),
  },
}));

const useShowContentToggle = ({ onNavigation }: { onNavigation: (() => void) | undefined }) => {
  const [contentShown, toggleContentVisibility] = useToggle(false);
  const previousLocation = useRef<unknown | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    if (previousLocation.current == null || previousLocation.current === location) {
      previousLocation.current = location;
      return;
    }

    toggleContentVisibility(false);
    onNavigation?.();
  }, [location, toggleContentVisibility, onNavigation]);

  return [contentShown, toggleContentVisibility] as const;
};

export const DropdownContentDrawerButton: FC<{
  onNavigation: () => void;
  title: string;
}> = ({ onNavigation, title, children }) => {
  const [contentShown, toggleContentVisibility] = useShowContentToggle({ onNavigation });
  const onClose = () => toggleContentVisibility(false);
  const classes = useStyles();

  return (
    <>
      <DrawerButtonItem active={contentShown} onClick={() => toggleContentVisibility()}>
        <span className={classes.buttonLabel}>
          {title} <ArrowRightIcon className={classes.icon} />
        </span>
      </DrawerButtonItem>
      <DrawerTrigger
        isOpen={contentShown}
        onToggleOpen={toggleContentVisibility}
        layout="full"
        anchor="left"
      >
        <div className={classes.drawerGrid}>
          <ButtonChromeless onClick={onClose}>
            <Typography variant="h6">
              <span className={classes.buttonLabel}>
                <ArrowLeftIcon className={classes.iconDismiss} /> {title}
              </span>
            </Typography>
          </ButtonChromeless>
          <div className={classes.drawerGuide}>{children}</div>
        </div>
      </DrawerTrigger>
    </>
  );
};

export const DropdownContentToolbarButton: FC<{
  title: string;
}> = ({ title, children }) => {
  const [contentShown, toggleContentVisibility] = useShowContentToggle({ onNavigation: undefined });
  const onClose = () => toggleContentVisibility(false);
  const classes = useStyles();
  const Icon = contentShown ? ArrowUpIcon : ArrowDownIcon;
  const drawerTriggerCommon = {
    anchor: 'top',
    layout: 'auto',
    onToggleOpen: toggleContentVisibility,
  } as const;

  return (
    <>
      <ToolbarButtonItem onClick={toggleContentVisibility} active={contentShown}>
        <span className={classes.buttonLabel}>
          {title} <Icon className={classes.icon} />
        </span>
      </ToolbarButtonItem>
      <DrawerTrigger isOpen={contentShown} {...drawerTriggerCommon}>
        {/* Since drawer trigger hides the backdrop this is
         * needed to handle the dismissing the guides when
         * clicking outside */}
        <ClickAwayListener onClickAway={onClose}>
          <Container maxWidth="lg">
            <div className={classes.toolbarGuide}>{children}</div>
          </Container>
        </ClickAwayListener>
      </DrawerTrigger>
      {!contentShown && (
        <DrawerTrigger renderedForSEO isOpen={false} {...drawerTriggerCommon}>
          {children}
        </DrawerTrigger>
      )}
    </>
  );
};
