import { FC } from 'react';

import { di } from 'react-magnetic-di/macro';

import { FlagResolution, useFeatureFlag } from 'shared/src/feature-flags/use-feature-flag';

import { LoadingPage } from 'components/loading-page/loading-page';
import { NotFound } from 'pages/not-found/not-found';

export function withFeatureFlagCover(flagName: string, loadingWindowTitle = '') {
  return function wrapper<P>(WrappedPage: FC<P>): FC<P> {
    return props => {
      di(useFeatureFlag, LoadingPage, NotFound);

      const { value, resolution } = useFeatureFlag(flagName, false);

      if (resolution === FlagResolution.Loading) {
        return <LoadingPage windowTitle={loadingWindowTitle} />;
      }

      if (!value) {
        return <NotFound />;
      }

      return <WrappedPage {...props} />;
    };
  };
}
