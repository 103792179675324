import { setContext } from '@apollo/client/link/context';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';

import { getIsServer } from 'shared/src/util/get-is-server';

import { Flags } from './config-cat-utils';

const urlFlagsStorage: Flags = new Map();
const useUrlFlags = (prefix: string): Map<string, unknown> => {
  di(useLocation);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  for (const [key, value] of searchParams.entries()) {
    if (!key.startsWith(prefix)) {
      continue;
    }

    const flagName = key.replace(prefix, '');
    switch (value) {
      case 'true':
        urlFlagsStorage.set(flagName, true);
        break;
      case 'false':
        urlFlagsStorage.set(flagName, false);
        break;
      default:
        urlFlagsStorage.set(flagName, value);
    }
  }

  return urlFlagsStorage;
};

export const getLocalStorageFlags = (key: string): Map<string, unknown> => {
  try {
    const storedItem = globalThis.localStorage?.getItem(key);
    if (storedItem) {
      return new Map(Object.entries(JSON.parse(storedItem)));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse stored flags', e);
  }

  return new Map();
};

export const isUrlFlagsEnabled = () => import.meta.env.VITE_ENABLE_URL_FLAGS === 'true';

export const useDevModeFlags = (): Map<string, unknown> => {
  di(isUrlFlagsEnabled);
  const localStorageFlags = getLocalStorageFlags('devFeatureFlags');
  const urlFlags = useUrlFlags('flag:');

  if (isUrlFlagsEnabled()) {
    return new Map([...localStorageFlags, ...urlFlags]);
  }

  return new Map();
};

export const buildFeatureFlagLink = () =>
  import.meta.env.MODE === 'production'
    ? undefined
    : setContext((_, { headers }) => {
        // Get feature flags from local storage when in web, and from env variables when in CLI
        const storedItem = !getIsServer()
          ? window.localStorage.getItem('devFeatureFlags')
          : import.meta.env.DEV_FEATURE_FLAGS;

        return {
          headers: {
            ...headers,
            ...(storedItem ? { 'x-hm-ff': storedItem } : {}),
          },
        };
      });
