import { gql } from '@apollo/client';

import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

export const SLUGGABLE_ENTRY_FRAGMENT = gql`
  fragment SluggableEntryFragment on Entry {
    sys {
      ...SysFragment
    }
    ... on BlogPost {
      slug
    }
    ... on ConditionArticle {
      slug
    }
    ... on MedicationArticle {
      slug
    }
    ... on ConditionCategory {
      slug
    }
    ... on ConditionBodySection {
      type
      linkedFrom {
        # confirmed unique to a category by content
        conditionCategoryCollection(limit: 1) {
          items {
            sys {
              ...SysFragment
            }
            slug
          }
        }
      }
    }
  }
  ${SYS_FRAGMENT}
`;
