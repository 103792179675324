import ekgIcon from 'components/images/icon-ekg.svg';
import healthcareIcon from 'components/images/icon-healthcare.svg';
import heartIcon from 'components/images/icon-heart.svg';
import lifeIcon from 'components/images/icon-life.svg';
import pillIcon from 'components/images/icon-pill.svg';
import stethoscopeIcon from 'components/images/icon-stethoscope.svg';

type StatisticType = {
  type: 'stat';
  percentage: number;
  percentageLabel?: string;
  content: string;
  citationNumber: number;
};

type InformationType = {
  type: 'info';
  icon: string;
  title: string;
  subtitle: string;
};

type GridSizeType = {
  desktopIndex: number;
};

type InformationShape = (StatisticType & GridSizeType) | (InformationType & GridSizeType);

// linear array ordered how it should render on mobile, set the desktop index to change how it renders on desktop
// information will have 2 columns on desktop, stats will have rows of 3
export const informationData: InformationShape[] = [
  {
    type: 'info',
    icon: pillIcon,
    title: 'Investigational treatments and medications',
    subtitle: 'Access investigational treatments and medications – at no cost to you.',
    desktopIndex: 1,
  },
  {
    type: 'info',
    icon: healthcareIcon,
    title: 'Access to leading specialists',
    subtitle:
      'Receive specialized care and support from trained research teams who have an in-depth understanding of your condition.',
    desktopIndex: 2,
  },
  {
    type: 'stat',
    percentage: 85,
    content:
      'of clinical trial participants rated the care they received during the trial as better than treatment they would have otherwise received',
    desktopIndex: 7,
    citationNumber: 1,
  },
  {
    type: 'info',
    icon: ekgIcon,
    title: 'Accessible participation',
    subtitle:
      'Ensuring that clinical trials are accessible and inclusive is crucial. To support this goal, health insurance is typically not required for participation.',
    desktopIndex: 3,
  },
  {
    type: 'info',
    icon: stethoscopeIcon,
    title: 'Understand your condition',
    subtitle:
      'Trials can give you the chance to deepen your knowledge of your condition and learn more about potential advances.',
    desktopIndex: 4,
  },
  {
    type: 'stat',
    percentageLabel: 'Only',
    percentage: 5,
    content: 'of rare diseases have an approved drug treatment',
    desktopIndex: 8,
    citationNumber: 2,
  },
  {
    type: 'info',
    icon: heartIcon,
    title: 'Compensation',
    subtitle:
      'Many clinical trials may offer compensation or reimbursement for participants’ time.',
    desktopIndex: 5,
  },
  {
    type: 'info',
    icon: lifeIcon,
    title: 'Research saves lives',
    subtitle:
      'Not only are you contributing to research that may benefit you, but it may also save the lives of people with the same condition for generations to come.',
    desktopIndex: 6,
  },
  {
    type: 'stat',
    percentageLabel: 'Over',
    percentage: 90,
    content:
      'of patients say one of their main motivations in participating in clinical trials is to help future patients',
    desktopIndex: 9,
    citationNumber: 3,
  },
];
