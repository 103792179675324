import { FC } from 'react';

import { Box, Button, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';

import { purple } from 'shared/src/palette/color-system';

import { CardStackable } from 'pages/condition-article/embedded-questionnaire/card-stackable/card-stackable';

import doctorProfilePicture from './doctor-profile.svg';

type EmbeddedQuestionnaireCardProps = {
  offset: number;
  questionText: string;
  onActionClick: () => void;
};

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    height: spacing(35),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: spacing(2),
  },
  image: {
    borderRadius: '100%',
    backgroundColor: purple[50],
  },
  actionsContainer: {
    padding: spacing(4, 0, 7),
    maxWidth: spacing(55),
    margin: 'auto',
  },
}));

export const EmbeddedQuestionnaireCard: FC<EmbeddedQuestionnaireCardProps> = ({
  offset,
  questionText,
  onActionClick,
}) => {
  const classes = useStyles();

  return (
    <CardStackable offset={offset} className={classes.card}>
      <CardContent className={classes.content}>
        <Box className={classes.imageContainer}>
          <img src={doctorProfilePicture} alt="" className={classes.image} />
        </Box>

        <Typography align="center" variant="h5" color="textSecondary">
          {questionText}
        </Typography>
        <Grid container spacing={1} className={classes.actionsContainer}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth onClick={onActionClick}>
              Yes
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" fullWidth onClick={onActionClick}>
              No
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </CardStackable>
  );
};
