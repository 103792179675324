import { createContext, FC, useContext } from 'react';

import { LocaleMatcher } from '@phensley/locale-matcher';
import { getUserLocales as getBrowserLocales } from 'get-user-locale';
import { di, injectable } from 'react-magnetic-di/macro';

import { isSupportedLocale, SupportedLocale, supportedLocales } from 'shared/src/locales/locales';

export const FALLBACK_LOCALE: SupportedLocale = 'en-US';

const matcher = new LocaleMatcher(supportedLocales.slice());

export const chooseLocaleFromPreferences = (localePreferences: string[]): SupportedLocale => {
  const match = matcher.match(localePreferences);
  const foundLocale = match.distance === 0 ? match.locale.id : undefined;

  if (foundLocale && isSupportedLocale(foundLocale)) {
    return foundLocale;
  }

  return FALLBACK_LOCALE;
};

const localeContext = createContext<SupportedLocale>(FALLBACK_LOCALE);

export const LocaleProvider: FC<{ locale?: SupportedLocale }> = ({ locale, children }) => {
  di(getBrowserLocales);

  const chosenLocale = locale ?? chooseLocaleFromPreferences(getBrowserLocales());

  return <localeContext.Provider value={chosenLocale}>{children}</localeContext.Provider>;
};

/**
 * Gets the user's best _browser_ locale from a list of locales we support.
 *
 * Consider using usePatientLocale if you want to use a patient's locale from the DB.
 */
export const useLocale = (): SupportedLocale => useContext(localeContext);

export const mockUseLocale = (locale: SupportedLocale): typeof useLocale =>
  injectable(useLocale, () => locale);
