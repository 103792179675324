import { Typography } from '@material-ui/core';

export const Disclaimer = () => (
  <div>
    <Typography variant="h5" gutterBottom>
      Disclaimer
    </Typography>
    <Typography variant="body1" gutterBottom>
      Here at HealthMatch, we’ve done our best to ensure that the information provided in this
      article is helpful, up to date, and, most importantly, accurate.
    </Typography>
    <Typography variant="body1" gutterBottom>
      However, we can’t replace the one-to-one advice of a qualified medical practitioner or outline
      all of the possible risks associated with this particular drug and your circumstances.
    </Typography>
    <Typography variant="body1" gutterBottom>
      It is therefore important for you to note that the information contained in this article does
      not constitute professional medical or healthcare advice, diagnosis or recommendation of
      treatment and is not intended to, nor should be used to, replace professional medical advice.
      This article may not always be up to date and is not exhaustive of all of the risks and
      considerations relevant to this particular drug. In no circumstances should this article be
      relied upon without independent consideration and confirmation by a qualified medical
      practitioner.
    </Typography>
    <Typography variant="body1">
      Your doctor will be able to explain all possible uses, dosages, precautions, interactions with
      other drugs, and other potential adverse effects, and you should always talk to them about any
      kind of medication you are taking, thinking about taking or wanting to stop taking.
    </Typography>
  </div>
);
