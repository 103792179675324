import { di } from 'react-magnetic-di/macro';

import { FlagsStatus } from './config-cat-utils';
import { useConfigCat } from './feature-flag-provider';
import { useDevModeFlags } from './use-dev-flags';

export enum FlagResolution {
  Loading = 'loading',
  Resolved = 'resolved',
  Default = 'default',
}

export interface FlagEvaluation<T> {
  value: T;
  resolution: FlagResolution;
}

/**
 * Evaluates a feature flag variation, defaulting to a provided value if the
 * ConfigCat context is not present or if the given flag name does not resolve to
 * a flag from ConfigCat or URL flags.
 */
export const useFeatureFlag = <T>(flagName: string, defaultValue: T): FlagEvaluation<T> => {
  di(useConfigCat);

  const flags = useConfigCat();
  const devFlags = useDevModeFlags();

  // Dev mode settings override the real value
  if (devFlags.has(flagName)) {
    return {
      value: devFlags.get(flagName) as T,
      resolution: FlagResolution.Resolved,
    };
  }

  let resolution = FlagResolution.Default;
  let value: T = defaultValue;

  const resolvedValue: T | null | undefined = flags?.flagValues.get(flagName) as any;
  if (flags?.status === FlagsStatus.LOADING) {
    resolution = FlagResolution.Loading;
  } else if (resolvedValue != null) {
    value = resolvedValue;
    resolution = FlagResolution.Resolved;
  }

  return {
    value,
    resolution,
  };
};
