import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import {
  PublicTrialConditionCategoryBySlug,
  PublicTrialConditionCategoryBySlugVariables,
} from 'pages/public-trial/types/queries.contentful.generated';

const PUBLIC_TRIAL_CONDITION_CATEGORY_BY_SLUG_QUERY = gql`
  query PublicTrialConditionCategoryBySlug($slug: String!, $preview: Boolean!) {
    conditionCategoryCollection(limit: 1, preview: $preview, where: { slug: $slug }) {
      items {
        sys {
          ...SysFragment
        }
        conditionIdList
        condition
      }
    }
  }
  ${SYS_FRAGMENT}
`;

export const usePublicTrialConditionCategoryBySlug = contentfulQueryFactory<
  PublicTrialConditionCategoryBySlug,
  PublicTrialConditionCategoryBySlugVariables
>(PUBLIC_TRIAL_CONDITION_CATEGORY_BY_SLUG_QUERY);
