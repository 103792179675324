import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import {
  FetchContentHubHome,
  FetchContentHubHomeVariables,
} from 'pages/content-hub/types/queries.contentful.generated';

const FETCH_CONTENT_HUB_HOME = gql`
  query FetchContentHubHome($preview: Boolean) {
    contentHubCollection(preview: $preview, limit: 1) {
      items {
        heroArticle {
          title
          slug
          heroImage {
            ...AssetFragment
          }
        }
        featuredArticlesCollection(preview: $preview, limit: 10) {
          items {
            sys {
              ...SysFragment
            }
            title
            slug
            heroImage {
              ...AssetFragment
            }
          }
        }
        collectionsCollection(limit: 5) {
          items {
            sys {
              ...SysFragment
            }
            shortTitle
            slug
            articlesCollection {
              items {
                category {
                  condition
                }
              }
            }
          }
        }
        medicationsCollection(limit: 10) {
          items {
            sys {
              ...SysFragment
            }
            title
            slug
          }
        }
        conditionsCollection(limit: 5) {
          items {
            sys {
              ...SysFragment
            }
            condition
            slug
            heroImage {
              ...AssetFragment
            }
          }
        }
      }
    }
  }
  ${ASSET_FRAGMENT}
  ${SYS_FRAGMENT}
`;

export const useFetchContentHubHomeRaw = contentfulQueryFactory<
  FetchContentHubHome,
  FetchContentHubHomeVariables
>(FETCH_CONTENT_HUB_HOME);
