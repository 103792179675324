import { ComponentType, FC, ReactText, useCallback } from 'react';

import { Box, Grow, IconButton, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import {
  OptionsObject,
  SnackbarMessage,
  SnackbarProvider,
  // eslint-disable-next-line no-restricted-imports
  useSnackbar as useNotistackSnackbar,
  VariantType,
} from 'notistack';
import { di } from 'react-magnetic-di/macro';

import { CheckMarkCircleIcon, ErrorIcon, InfoCircle } from 'shared/src/icon/icon-images';
import { CloseIcon } from 'shared/src/icon/icons';
import { green, lightPurple, red } from 'shared/src/palette/color-system';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[400],
    flexWrap: 'nowrap',
  },
  error: {
    backgroundColor: red[500],
    flexWrap: 'nowrap',
  },
  info: {
    backgroundColor: lightPurple[500],
    color: theme.palette.text.primary,
    flexWrap: 'nowrap',
  },
  warning: {
    flexWrap: 'nowrap',
  },
}));

export const CloseSnackbarIcon: FC<{ snackbarKey: number | ReactText }> = ({ snackbarKey }) => {
  di(useSnackbar);
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(snackbarKey)}
    >
      <Box width={16} height={16} fontSize={16}>
        <CloseIcon fontSize="inherit" />
      </Box>
    </IconButton>
  );
};

export const ToastManager: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={30}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={Grow as ComponentType<TransitionProps>}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
      action={key => <CloseSnackbarIcon snackbarKey={key} />}
      iconVariant={{
        // @ts-expect-error have to break ts to make icons show up due to custom variants
        successCustom: (
          <Box width={20} height={20} marginRight={2}>
            <CheckMarkCircleIcon />
          </Box>
        ),
        errorCustom: (
          <Box width={20} height={20} marginRight={2}>
            <ErrorIcon />
          </Box>
        ),
        infoCustom: (
          <Box width={20} height={20} marginRight={2}>
            <InfoCircle />
          </Box>
        ),
        defaultCustom: (
          <Box width={20} height={20} marginRight={2}>
            <InfoCircle />
          </Box>
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export const useSnackbar = () => {
  const { enqueueSnackbar: enqueueNotiStackbar, closeSnackbar } = useNotistackSnackbar();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const enqueueSnackbar = useCallback(
    // We don't need to support the default variant. Use info instead
    (
      message: SnackbarMessage,
      variant: Exclude<VariantType, 'default'>,
      options?: OptionsObject
    ) => {
      const defaultAnchorOrigin = isXs
        ? { vertical: 'top' as const, horizontal: 'center' as const }
        : { vertical: 'bottom' as const, horizontal: 'left' as const };
      return enqueueNotiStackbar(message, {
        anchorOrigin: defaultAnchorOrigin,
        ...options,
        variant,
      });
    },
    [isXs, enqueueNotiStackbar]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
};
