import { FC } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { Link } from 'shared/src/link/link';

import { ItemType } from './links';

const useStyles = makeStyles(() => ({
  link: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    gridRow: '1',
  },
}));

export const LinkSection: FC<{
  sectionTitle: string;
  gridColumnName: string;
  links: readonly ItemType[];
  allLinkText?: string;
  allLinkTo?: string;
}> = ({ sectionTitle, gridColumnName, links, allLinkTo, allLinkText }) => {
  const classes = useStyles();

  return (
    <>
      <Box gridColumn={gridColumnName}>
        <Typography variant="h6">{sectionTitle}</Typography>
      </Box>
      {links.map(({ title, to }) => (
        <Box gridColumn={gridColumnName} key={title}>
          <Link
            data-testid={`overflow-link-${title}`}
            to={to}
            color="inherit"
            variant="body2"
            className={classes.link}
          >
            {title}
          </Link>
        </Box>
      ))}
      {allLinkText && allLinkTo && (
        <Box gridColumn={gridColumnName}>
          <Link to={allLinkTo} color="primary" variant="body2" className={classes.link}>
            {allLinkText}
          </Link>
        </Box>
      )}
    </>
  );
};
