import { FC } from 'react';

import { Fab, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { ArrowLeftIcon, ArrowRightIcon } from 'shared/src/icon/icons';
import { gray } from 'shared/src/palette/color-system';

import { mod } from './mod';
import { CarouselIndicatorProps, CarouselIndicatorPropsBase } from './types';

const useStyles = makeStyles<Theme, CarouselIndicatorPropsBase>(theme => ({
  button: {
    '&&': {
      boxShadow: (props: CarouselIndicatorPropsBase) =>
        props.dark ? theme.shadows[0] : theme.shadows[1],
    },
  },
  count: {
    textAlign: 'center',
    color: props => (props.dark ? gray[0] : theme.palette.text.secondary),
    fontSize: theme.typography.pxToRem(18),
    width: theme.spacing(10),
  },
}));

export const CarouselArrows: FC<CarouselIndicatorProps> = ({ count, index, onChange, dark }) => {
  const classes = useStyles({ dark });
  return (
    <Grid container wrap="nowrap" alignContent="center" alignItems="center" justifyContent="center">
      <Grid item>
        <Fab
          color="primary"
          aria-label="previous"
          className={classes.button}
          onClick={() => onChange(index - 1)}
        >
          <ArrowLeftIcon />
        </Fab>
      </Grid>
      <Grid item>
        <Typography className={classes.count}>
          {mod(index, count) + 1}/{count}
        </Typography>
      </Grid>
      <Grid item>
        <Fab
          color="primary"
          aria-label="next"
          className={classes.button}
          onClick={() => onChange(index + 1)}
        >
          <ArrowRightIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};
