import { ComponentType, useCallback, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useStickyIntersection } from 'shared/src/use-near-viewport/use-sticky-intersection';

import sourceImageX2 from './manuri-compressed-x2.jpg';
import sourceImage from './manuri-compressed.jpg';

const useStyles = makeStyles({
  root: {
    transition: 'opacity 0.15s ease-in-out',
  },
});

const sourceWidth = 3962.1;
const sourceHeight = 2475;
const viewBox = `0 0 ${sourceWidth} ${sourceHeight}`;

function useDPR(): number {
  /**
   * Due to the SVG `image` element, not supporting
   * a responsive `srcset` property or an equivalent
   * to the picture element, we manually check the
   * device pixel ratio (the density of pixels on the
   * display) to check if it's retina or not.
   */
  if (typeof window !== 'undefined') {
    return window.devicePixelRatio ?? 1;
  }
  return 1;
}

export const AuraImage: ComponentType<{
  className?: string;
}> = ({ className }) => {
  const [opacity, setOpacity] = useState(0);
  const style = useStyles();
  const devicePixelRatio = useDPR();
  const intersectionRef = useRef(null);
  const intersection = useStickyIntersection(intersectionRef, {
    rootMargin: '500px',
  });

  let src: string | undefined;
  if (intersection?.hasIntersected) {
    src = devicePixelRatio > 1 ? sourceImageX2 : sourceImage;
  }

  const onLoad = useCallback(() => {
    setOpacity(1);
  }, []);

  return (
    <svg
      ref={intersectionRef}
      xmlns="http://www.w3.org/2000/svg"
      className={`${style.root} ${className}`}
      viewBox={viewBox}
      style={{ maxWidth: 660, opacity }}
    >
      <defs>
        <pattern
          id="manuri"
          patternUnits="userSpaceOnUse"
          width={sourceWidth}
          height={sourceHeight}
        >
          <image href={src} x="0" y="0" width={sourceWidth} height={sourceHeight} onLoad={onLoad} />
        </pattern>
      </defs>
      <path
        fill="#ffeddf"
        d="M3962.1,2475H.21c-.49-67.96-.16-136.76,1.29-204.61,3.38-157.73,12.6-291.33,27.41-397.11,20.35-145.31,51.18-241.82,97.03-303.72,167.71-226.41,364.65-352.93,555.11-475.29,24.76-15.91,50.36-32.35,75.45-48.74,100.54-65.66,117.65-113.95,158.98-230.63,25.17-71.05,56.49-159.47,115.46-270.66,43.57-82.17,92.28-155.58,144.76-218.21,57.64-68.78,122.16-127.39,191.78-174.21,74.05-49.8,156.11-87.82,243.91-112.99C1701.22,13.06,1799.75,0,1904.26,0s202.68,15.91,288.85,47.28c81.39,29.63,153.56,73.17,214.5,129.39,54.76,50.53,100.16,110.98,134.91,179.68,30.43,60.15,52.3,125.68,64.99,194.78,5.34,29.07,10.26,58.24,15.03,86.45,11.92,70.56,23.17,137.2,38.61,190.01,13.95,47.7,26.11,65.63,30.9,71.3,17.35,1.55,38.43,2.96,62.56,4.58,82.32,5.52,195.05,13.08,302.34,36.23,146.32,31.58,248.47,84.85,312.3,162.85,68.01,83.13,106.78,191.99,115.21,323.56,5.1,79.51-1.73,151.24-9.15,204.48,2.25,2.22,4.97,4.81,8.23,7.79,13.39,12.22,31.04,26.57,49.73,41.77,49.87,40.54,111.94,90.99,167.54,159.43,60.39,74.32,145.06,202.82,205.53,392.93,24.55,77.18,43.15,158.12,55.76,242.49Z"
      />
      <path
        fill="#fee1c7"
        d="M3830.5,2475H130.23c-2.55-340.5-15.04-642.51,100.17-828.07,154.51-248.87,383.59-353.08,597.18-492.57,213.59-139.49,165.64-261.54,318.21-549.24,152.57-287.7,379.23-475.13,758.47-475.13s536.16,231.03,575.39,444.62c39.23,213.59,56.67,440.26,196.16,453.34,139.49,13.08,475.13,13.08,592.83,156.92,117.7,143.84,87.18,370.52,74.1,453.34-13.08,82.82,143.85,143.85,257.18,283.34,77.36,95.21,185.18,273.69,230.58,553.45Z"
      />
      <path
        fill="url(#manuri)"
        d="M246.09,2475c.16-76.96-3.21-204.4-.13-306,6.54-215.55,6.54-310.35,80.87-450.64,49.99-94.35,135.49-159.52,226.36-231.98,82.89-66.09,170.36-122.29,262.97-181.78,25.15-16.16,51.15-32.86,77.03-49.77,171.27-111.85,211.43-225.21,257.93-356.47,23.6-66.61,50.34-142.1,100.68-237.02,72.37-136.46,155.84-235.28,255.19-302.1,109.31-73.52,239.25-109.25,397.27-109.25,142.1,0,254.42,37.15,333.83,110.41,79.82,73.65,111.24,168.93,123.54,235.89,5.01,27.3,9.79,55.57,14.4,82.91,16.84,99.74,32.75,193.95,63.52,273.03,20.12,51.7,44.82,92.05,75.53,123.36,40.85,41.66,92.56,66.5,149.52,71.84,21.4,2.01,45.45,3.62,73.3,5.49,78.79,5.28,176.86,11.86,266.32,31.17,114.88,24.79,156.79,58.75,171.54,76.78,34.26,41.88,54.19,102.85,59.21,181.23,4.46,69.63-4.01,134.59-10.77,177.39-5.29,33.49-1.29,66.86,11.88,99.2,9.45,23.2,23.39,45.28,42.61,67.5,26.76,30.94,60.82,58.63,96.88,87.95,43.07,35.01,91.89,74.7,131.2,123.08,28.84,35.5,71.59,95.92,111.49,182.21,25.35,54.83,76.08,233.55,93.1,295.57H246.09Z"
      />
    </svg>
  );
};
