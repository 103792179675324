import { di } from 'react-magnetic-di/macro';
import useLocalStorageState from 'use-local-storage-state';
import {
  LocalStorageOptions,
  LocalStorageState,
} from 'use-local-storage-state/src/useLocalStorageState';
import useSessionStorageState from 'use-session-storage-state';

import { useContextStorage } from 'shared/src/util/context-storage/use-context-storage';

/**
 * useReliableStorage exists to provide a reliable storage API.
 *
 * This hook will read and write to and from both browser storage (session or local,
 * via their respective hooks) and context storage.
 *
 * In the event that browser storage is disabled on a user's computer, the hook
 * will still be able to get and set the value in context storage to enable
 * the apps to continue working as normal.
 */
export const useReliableStorage = <T>(
  useBrowserStorage: (
    key: string,
    options?: LocalStorageOptions<T>
  ) => LocalStorageState<T | undefined>,
  key: string,
  initialValue?: T
): [T | undefined, (newValue: T | undefined) => void, () => void] => {
  di(useContextStorage);

  const [valInBrowserStorage, setInBrowserStorage, { removeItem: removeFromBrowserStorage }] =
    useBrowserStorage(key, {
      defaultValue: initialValue,
    });
  const [valInContextStorage, setInContextStorage, removeFromContextStorage] = useContextStorage(
    key,
    initialValue
  );

  const set = (newValue: T | undefined): void => {
    setInBrowserStorage(newValue);
    setInContextStorage(newValue);
  };

  const remove = (): void => {
    removeFromBrowserStorage();
    removeFromContextStorage();
  };

  return [valInBrowserStorage ?? valInContextStorage, set, remove];
};

export const useReliableSessionStorage = <T>(key: string, initialValue?: T) =>
  useReliableStorage<T>(useSessionStorageState, key, initialValue);

export const useReliableLocalStorage = <T>(key: string, initialValue?: T) =>
  useReliableStorage<T>(useLocalStorageState, key, initialValue);
