import { hydrate, render } from 'react-dom';

import { initLogger } from 'shared/src/logger/logger';

import { getAppState } from 'mats/src/components/app-state/app-state';

import { makeApolloClient, MakeApolloClientCallback } from 'components/app/apollo-provider';
import { AppClient } from 'components/app/app.client';

// Init logger before we start rendering
initLogger('healthmatch.io');

const rootNode = document.getElementById('root');
const renderOrHydrate = (rootNode?.childElementCount ?? 0) > 0 ? hydrate : render;

const makeClientSideApollo: MakeApolloClientCallback = ({ extraLinks }) => {
  const cacheData = getAppState()?.apolloCache;
  return makeApolloClient({ extraLinks, cacheData });
};

// TODO: replace render callback as it's not supported by React 18
// https://github.com/reactwg/react-18/discussions/5
renderOrHydrate(
  <AppClient onCreateApolloProvider={makeClientSideApollo} />,
  document.getElementById('root'),
  () => {
    // Remove server-side generated CSS as per
    // https://material-ui.com/guides/server-rendering/#the-client-side
    // TODO: don't remove CSSBaseline to avoid fonts flashing
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }
);
