import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import {
  ConditionCategoryForPublicTrialsPage,
  ConditionCategoryForPublicTrialsPageVariables,
} from 'pages/trials-public/types/queries.contentful.generated';

const CONDITION_CATEGORY_FOR_PUBLIC_TRIALS_PAGE = gql`
  query ConditionCategoryForPublicTrialsPage($preview: Boolean, $slug: String!) {
    conditionCategoryCollection(where: { slug: $slug }, preview: $preview, limit: 1) {
      items {
        sys {
          ...SysFragment
        }
        title
        slug
        condition
        conditionIdList
        conditionDescription {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        healthcareStatsCollection {
          items {
            sys {
              ...SysFragment
            }
            bigNumber
            description {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
          }
        }
        faqEntriesCollection {
          items {
            sys {
              ...SysFragment
            }
            question
            answer {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
          }
        }
        referencesFooter {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
      }
    }
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
`;

export const useConditionCategoryForPublicTrialsPage = contentfulQueryFactory<
  ConditionCategoryForPublicTrialsPage,
  ConditionCategoryForPublicTrialsPageVariables
>(CONDITION_CATEGORY_FOR_PUBLIC_TRIALS_PAGE);
