import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useUpdateEffect } from 'react-use';

import { EventName } from 'shared/src/types/graphql-schema';

import { useAuth } from 'mats/src/components/auth/use-auth';

export const FacebookTrackingPixel = ({ trackingID }: { trackingID?: string }) => {
  di(Helmet, useAuth);

  const authenticatedUserId = useAuth().getAuthenticatedUserId();

  useUpdateEffect(() => {
    if (window.fbq && trackingID) {
      window.fbq('init', trackingID, {
        external_id: authenticatedUserId, // fbq automatically hashes the ID
      });
    }
  }, [authenticatedUserId]);

  return trackingID ? (
    <Helmet>
      <>
        <script>{`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
   fbq('init', '${trackingID}'${
          authenticatedUserId ? `, { external_id: ${authenticatedUserId} }` : ''
        });
  fbq('track', 'PageView');`}</script>
        <noscript>{`
            <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=${trackingID}&ev=PageView&noscript=1"
            />
          `}</noscript>
      </>
    </Helmet>
  ) : null;
};

// https://healthmatch.atlassian.net/wiki/spaces/DBI/pages/1505394689/Facebook+Pixel+CAPI
type MetaEventName =
  | 'InitiateCheckout'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'Search'
  | 'CompleteRegistration'
  | 'SubmitApplication';

const eventToMetaEventMap: Record<EventName, MetaEventName> = {
  ConditionSearch: 'Search',
  QuestionnaireStarted: 'AddToCart',
  ScreeningQuestionAnswered: 'AddToWishlist',
  QuestionnaireCompleted: 'InitiateCheckout',
  AccountCreated: 'CompleteRegistration',
  SubmitApplication: 'SubmitApplication',
};

export interface MetaParamObject {
  alias: string;
  search_string?: string;
}

interface MetaEventData {
  eventID: string;
}

export const fbqTrack = (
  eventName: EventName,
  eventData: MetaEventData,
  paramObj?: MetaParamObject
): void => {
  const metaEventName = eventToMetaEventMap[eventName];
  window.fbq?.('track', metaEventName, paramObj, eventData);
};
