import { PollingMode, User } from 'configcat-common';
import { IConfigCatClient } from 'configcat-common/lib/ConfigCatClient';
import { getClient } from 'configcat-js-ssr';

export enum FlagsStatus {
  LOADING = 'loading',
  FAILED = 'failed',
  SUCCESS = 'success',
}

type FlagValue = unknown;
export type Flags = Map<string, FlagValue>; // `undefined` means the flags didn't load.

export interface FlagState {
  configCatClient: IConfigCatClient;
  flagValues: Flags;
  status: FlagsStatus;
}

export const getFlagValues = async (
  client: IConfigCatClient,
  userInfo: FlagUser
): Promise<Flags> => {
  const user =
    userInfo === ANONYMOUS_CONFIGCAT_USER
      ? undefined
      : new User(userInfo.identifier, userInfo.email, undefined, userInfo.customAttributes);

  const flagKeys = await client.getAllKeysAsync();
  // Each flag name/key maps to [key, value]
  const flagValues = flagKeys.map(async key => [
    key,
    await client.getValueAsync(key, undefined, user),
  ]);
  return new Map<string, FlagValue>(
    // Wait for all flag values to resolve.
    (await Promise.all(flagValues)) as Array<[string, FlagValue]>
  );
};

export interface UserIdInfo {
  // A unique identifier for the user, e.g. email address. This will be converted to a UUID before sending to ConfigCat
  identifier: string;
  email?: string;
  customAttributes?: { [key: string]: string };
}

export const ANONYMOUS_CONFIGCAT_USER = 'ANONYMOUS_CONFIGCAT_USER' as const;

export type FlagUser = UserIdInfo | typeof ANONYMOUS_CONFIGCAT_USER;

export const refreshFlagState = async (
  client: IConfigCatClient,
  userInfo: FlagUser
): Promise<FlagState> => {
  let status = FlagsStatus.SUCCESS;

  await client.forceRefreshAsync();
  const flagValues = await getFlagValues(client, userInfo);
  // ConfigCat SDK doesn't allow us to differentiate between error getting flags and no flags existing.
  if (flagValues.size === 0) {
    status = FlagsStatus.FAILED;
  }
  return { configCatClient: client, flagValues, status };
};

export interface ConfigCatConfig {
  sdkKey?: string;
  baseUrl?: string;
  timeout: number;
}

export const getConfigCatConfig = (): ConfigCatConfig => ({
  sdkKey: import.meta.env.VITE_CONFIG_CAT_KEY,
  baseUrl: import.meta.env.VITE_CONFIG_CAT_BASE_URL,
  // 1.2s is typical maximum if you're, say, the opposite side of the planet from the singly-homed flag server...
  timeout: 2000,
});

export const initConfigCat = ({
  sdkKey,
  timeout,
  baseUrl,
}: ConfigCatConfig & Required<Pick<ConfigCatConfig, 'sdkKey'>>): FlagState => {
  const configCatClient = getClient(sdkKey, PollingMode.ManualPoll, {
    requestTimeoutMs: timeout,
    baseUrl,
  });
  return {
    configCatClient,
    flagValues: new Map<string, unknown>(),
    status: FlagsStatus.LOADING,
  };
};
