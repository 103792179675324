import { FC } from 'react';

import { Box, Container, Grid, Link as MuiLink, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Link } from 'shared/src/link/link';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  link: {
    display: 'inline-flex',
  },
}));

export const StatsSection: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root} paddingY={{ xs: 7, md: 10 }}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-around" spacing={1}>
          <Grid item xs={12} md={3}>
            <Typography variant="h2" color="inherit">
              4,500+
              <Typography variant="h3" color="inherit" component="div" gutterBottom>
                trials
              </Typography>
            </Typography>
            <Typography paragraph>
              With hundreds of trials already available, we’re adding new trials for different
              conditions every day.
            </Typography>
            <MuiLink color="inherit" href="#home-condition-selector" className={classes.link}>
              Find trials <ChevronRightIcon />
            </MuiLink>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box marginTop={{ xs: 4, sm: 0 }}>
              <Typography variant="h2" color="inherit">
                350+
                <Typography variant="h3" color="inherit" component="div" gutterBottom>
                  conditions
                </Typography>
              </Typography>
              <Typography paragraph>
                From acne to insomnia, multiple sclerosis to cancer, we’re here to help you take
                charge of your condition.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box marginTop={{ xs: 4, sm: 0 }}>
              <Typography variant="h2" color="inherit">
                1,000,000+
                <Typography variant="h3" color="inherit" component="div" gutterBottom>
                  users
                </Typography>
              </Typography>
              <Typography paragraph>
                Regardless of your age, background and gender, we won’t stop searching until we find
                you the right match.
              </Typography>
              <Link color="inherit" to="/patients" className={classes.link}>
                Hear from patients we&apos;ve matched <ChevronRightIcon />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
