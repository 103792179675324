import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { beige, gray, green, lightPurple, purple, red, vividPurple } from './color-system';

export const palette: PaletteOptions = {
  background: {
    // Assigned as background color to `body` and used in `Paper`/`Card` components
    default: gray[0],
  },
  primary: {
    main: purple[500],
    light: '#423c96', // active
    dark: vividPurple[500], // hover
  },
  secondary: {
    main: lightPurple[50],
    contrastText: purple[500],
    light: lightPurple[100], // active
    dark: lightPurple[200], // hover
  },
  error: {
    main: red[500],
    light: red[600], // active
    dark: red[400], // hover
  },
  action: {
    disabledBackground: gray[50],
    disabled: gray[400],
  },
  text: {
    primary: gray[600], // body text
    secondary: gray[1000], // headers
    disabled: gray[400],
  },
  warning: {
    main: beige[700],
  },
  info: {
    main: lightPurple[700],
  },
  success: {
    main: green[500],
  },
};
