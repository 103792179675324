import { Children, FC } from 'react';

import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import { ConditionAuthor } from 'contentful-gql/src/types/graphql-schema';

import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';

import { ReviewedBy, WrittenBy } from 'components/content-meta/author-credit/author-credit';
import { UpdatedAt } from 'components/content-meta/date-meta/date-meta';

type StyleProps = {
  childLength: number;
};

const useStyles = makeStyles<Theme, StyleProps>(({ spacing, breakpoints }) => ({
  grid: {
    display: 'grid',
    justifyContent: 'space-between',
    gridAutoFlow: 'column',
    [breakpoints.down('sm')]: {
      gridTemplateRows: ({ childLength }: StyleProps) => `repeat(${childLength}, 1fr)`,
      gridGap: spacing(5),
    },
    [breakpoints.down('xs')]: {
      paddingTop: spacing(3),
      gridGap: spacing(3),
    },
  },
}));

type Author = Pick<ConditionAuthor, 'name' | 'slug'> & {
  profilePicture: AssetFragment | null;
};
type MedicalReviewer = Pick<ConditionAuthor, 'name' | 'slug'> & {
  profilePicture: AssetFragment | null;
};

type ContentMetaProps = {
  author: Author | null;
  medicalReviewer: MedicalReviewer | null;
  updatedAt: string | null;
};

export const ContentMeta: FC<ContentMetaProps> = ({ author, medicalReviewer, updatedAt }) => (
  <ContentMetaContainer>
    {updatedAt && <UpdatedAt date={updatedAt} />}
    {medicalReviewer && <ReviewedBy author={medicalReviewer} />}
    {author && <WrittenBy author={author} />}
  </ContentMetaContainer>
);

export const ContentMetaContainer: FC<BoxProps> = ({ children, className, ...props }) => {
  const classes = useStyles({ childLength: Children.count(children) });

  const finalClassName = className != null ? `${className} ${classes.grid}` : classes.grid;

  return (
    <Box {...props} component="aside" className={finalClassName}>
      {children}
    </Box>
  );
};
