import { FC } from 'react';

import { useEffectOnce } from 'react-use';

import { useAnalytics } from 'shared/src/analytics/analytics';

/**
 * This component sets up a listener for hubspot form submit events, and publishes the event to rudderstack.
 * The actual hubspot forms live in webflow, but we can still listen to the events here.
 */
export const HubspotEventPublisher: FC = () => {
  const analytics = useAnalytics();

  useEffectOnce(() => {
    const onEvent = (event: WindowEventMap['message']) => {
      if (event.data?.type === 'hsFormCallback' && event.data?.eventName === 'onFormSubmit') {
        analytics.track({
          event: 'hmio.hubspot_form.submitted',
          properties: { formId: event.data?.id },
        });
      }
    };

    window.addEventListener('message', onEvent);
    return () => {
      window.removeEventListener('message', onEvent);
    };
  });

  return null;
};
