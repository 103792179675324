import { gql } from '@apollo/client';

export const PATIENT_FRAGMENT = gql`
  fragment PatientRecordHmio on Patient {
    id
    firstName
    lastName
    email
    emailVerified
    country
    phoneNumber
    dob
    suburb
    state
    lat
    lng
    sex
    suburbLastConfirmedAt
    phoneLastConfirmedAt
    phoneVerifiedAt
    termsVersion
    hasAccountCreatedConversionEventBeenSent
    conditions {
      id
      name
    }
    contactPreferences {
      channels
      schedule
    }
    localeWithInfo {
      languageCode
    }
  }
`;
