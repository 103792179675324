import { FC } from 'react';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import fetch from 'cross-fetch';
import { BareFetcher, SWRConfig } from 'swr';

import { LocalisedMuiPickersUtilsProvider } from 'shared/src/pickers/localised-mui-pickers-utils-provider/localised-mui-pickers-utils-provider';
import { StylesProvider, theme } from 'shared/src/theme/theme';
import { ToastManager } from 'shared/src/toast/toast-manager';

import { FacebookTrackingPixel } from 'mats/src/components/conversion-event-tracking/pixels/facebook-pixel/facebook-tracking-pixel';
import { RedditPixel } from 'mats/src/components/conversion-event-tracking/pixels/reddit-pixel/reddit-pixel';
import { TikTokPixel } from 'mats/src/components/conversion-event-tracking/pixels/tiktok-pixel/tiktok-pixel';

import { ApolloProviderWithProxy, MakeApolloClientCallback } from 'components/app/apollo-provider';

type SharedProvidersProps = {
  onCreateApolloProvider: MakeApolloClientCallback;
  swrFallback: Record<string, string> | null;
};

const fetcher: BareFetcher = (resource, init) => fetch(resource, init).then(res => res.text());

export const SharedProviders: FC<SharedProvidersProps> = ({
  children,
  onCreateApolloProvider,
  swrFallback,
}) => (
  <SWRConfig
    value={{
      fetcher,
      fallback: swrFallback ?? {},
      // revalidateIfStale: false,
      // revalidateOnFocus: false,
      // revalidateOnReconnect: false,
    }}
  >
    <FacebookTrackingPixel trackingID={import.meta.env.VITE_FB_TRACKING_ID} />
    <TikTokPixel tiktokPixelId={import.meta.env.VITE_TT_PIXEL_ID} />
    <RedditPixel redditPixelId={import.meta.env.VITE_REDDIT_PIXEL_ID} />
    <ApolloProviderWithProxy onCreateApolloProvider={onCreateApolloProvider}>
      <StylesProvider>
        <MuiThemeProvider theme={theme}>
          <ToastManager>
            <LocalisedMuiPickersUtilsProvider>
              <CssBaseline />
              {children}
            </LocalisedMuiPickersUtilsProvider>
          </ToastManager>
        </MuiThemeProvider>
      </StylesProvider>
    </ApolloProviderWithProxy>
  </SWRConfig>
);
