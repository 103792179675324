import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import { CONTENT_LINK_FRAGMENT } from 'components/content-links/query.contentful';
import { REDIRECT_FRAGMENT } from 'components/contentful-redirect/redirect.contentful';
import {
  ConditionCategoryBySlug,
  ConditionCategoryBySlugVariables,
} from 'pages/condition-category/types/queries.contentful.generated';

const CONDITION_CATEGORY_BY_SLUG_ARTICLE_FRAGMENT = gql`
  fragment ConditionCategoryLinkedArticleFragment on ConditionArticle {
    sys {
      ...SysFragment
    }
    title
    slug
  }
`;

const CONDITION_CATEGORY_BY_SLUG_CATEGORY_FRAGMENT = gql`
  fragment ConditionCategoryLinkedCategoryFragment on ConditionCategory {
    sys {
      ...SysFragment
    }
    condition
    slug
  }
`;

const CONDITION_CATEGORY_BY_SLUG_CONDITION_LINK_FRAGMENT = gql`
  fragment ConditionCategoryLinkFragment on ConditionLink {
    linkedPage {
      ... on ConditionCategory {
        ...ConditionCategoryLinkedCategoryFragment
      }
      ... on ConditionArticle {
        ...ConditionCategoryLinkedArticleFragment
      }
    }
  }
  ${CONDITION_CATEGORY_BY_SLUG_CATEGORY_FRAGMENT}
  ${CONDITION_CATEGORY_BY_SLUG_ARTICLE_FRAGMENT}
`;

const CONDITION_CATEGORY_BY_SLUG_QUERY = gql`
  query ConditionCategoryBySlug($preview: Boolean, $slug: String!, $orphanArticleTag: String!) {
    conditionCategoryCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      items {
        sys {
          ...SysFragment
        }
        slug
        title
        titleMeta
        descriptionMeta
        subtitle
        overview {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        condition
        conditionIdList
        heroImage {
          ...AssetFragment
        }
        reviewedAt
        author {
          name
          profilePicture {
            ...AssetFragment
          }
          slug
        }
        medicalReviewer {
          name
          profilePicture {
            ...AssetFragment
          }
          slug
        }
        sectionsCollection(limit: 10) {
          items {
            sys {
              id
            }
            type
            heroImage {
              ...AssetFragment
            }
            references {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
            textBody {
              json
              links {
                assets {
                  block {
                    ...AssetFragment
                  }
                }
                entries {
                  block {
                    ...EmbeddedEntryFragment
                  }
                  hyperlink {
                    ...SluggableEntryFragment
                  }
                }
              }
            }
          }
        }
        subpagesCollection(limit: 5) {
          items {
            ... on ConditionArticle {
              ...ConditionCategoryLinkedArticleFragment
            }
            ... on ConditionLink {
              title
              ...ConditionCategoryLinkFragment
            }
          }
        }
        relatedConditionsCollection(limit: 12) {
          items {
            ... on ConditionArticle {
              ...ConditionCategoryLinkedArticleFragment
            }
            ... on ConditionCategory {
              ...ConditionCategoryLinkedCategoryFragment
              title
              linkedFrom {
                conditionArticleCollection(limit: 3) {
                  ...ContentLinksFragment
                }
              }
            }
            ... on ConditionLink {
              ...ConditionCategoryLinkFragment
              linkedPage {
                ... on ConditionCategory {
                  linkedFrom {
                    conditionArticleCollection(limit: 3) {
                      ...ContentLinksFragment
                    }
                  }
                }
              }
            }
          }
        }
        linkedFrom {
          medicationArticleCollection {
            items {
              sys {
                ...SysFragment
              }
              genericMedicationName
              medicationName
              slug
            }
          }
        }
      }
    }
    latestNews: conditionArticleCollection(
      preview: $preview
      limit: 20
      where: { category: { slug: $slug } }
      order: [sys_publishedAt_DESC]
    ) {
      ...ContentLinksFragment
    }
    editorsPicks: conditionArticleCollection(
      preview: $preview
      limit: 6
      where: { category: { slug: $slug } }
      order: [searchVolume_DESC]
    ) {
      ...ContentLinksFragment
    }
    orphanArticles: conditionArticleCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: [$orphanArticleTag] } } }
      limit: 12
    ) {
      ...ContentLinksFragment
    }
    ...RedirectFragment
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
  ${CONDITION_CATEGORY_BY_SLUG_ARTICLE_FRAGMENT}
  ${CONDITION_CATEGORY_BY_SLUG_CATEGORY_FRAGMENT}
  ${CONDITION_CATEGORY_BY_SLUG_CONDITION_LINK_FRAGMENT}
  ${CONTENT_LINK_FRAGMENT}
  ${REDIRECT_FRAGMENT}
`;

export const useConditionCategoryBySlug = contentfulQueryFactory<
  ConditionCategoryBySlug,
  ConditionCategoryBySlugVariables
>(CONDITION_CATEGORY_BY_SLUG_QUERY);

export type ConditionCategoryBySlugContent = DeepExtractTypeSkipArrays<
  ConditionCategoryBySlug,
  ['conditionCategoryCollection', 'items']
>;
