import { FC } from 'react';

import { Box, Container, Grid, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { gray } from 'shared/src/palette/color-system';

import { Footer } from 'components/footer/footer';
import { HeaderNav } from 'components/header-nav/header-nav';
import { InformationSnippet } from 'components/information-snippet/information-snippet';
import { PageBase } from 'components/page-base/page-base';

import { CitationFooter } from './components/citation-footer';
import { WhyClinicalTrialsHeroBanner } from './components/hero-banner/hero-banner';
import { InformationHeader } from './components/information-header';
import { InformationStat } from './components/information-stat';
import { informationData } from './const';

const useStyles = makeStyles({
  page: {
    backgroundColor: gray[50],
  },
});

const FOOTER_BLEED = { kind: 'background', color: gray[50] } as const;

export const WhyClinicalTrials: FC = () => {
  di(HeaderNav, Footer);

  const classes = useStyles();

  const downSm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const contentToRender = downSm
    ? informationData
    : [...informationData] // we don't want to mutate the original array so copy it
        .sort((a, b) => a.desktopIndex - b.desktopIndex);

  return (
    <PageBase windowTitle="Why Clinical Trials?" footerBleed={FOOTER_BLEED}>
      <Box className={classes.page}>
        <Box paddingBottom={6}>
          <WhyClinicalTrialsHeroBanner />
          <InformationHeader />
          <Container>
            <Grid spacing={4} container>
              {contentToRender.map(data => {
                if (data.type === 'info') {
                  return (
                    <Grid xs={12} md={6} item key={data.title}>
                      <InformationSnippet
                        renderIcon={classname => (
                          <img alt="" src={data.icon} className={classname} />
                        )}
                        title={data.title}
                        subtitle={data.subtitle}
                      />
                    </Grid>
                  );
                }
                return (
                  <InformationStat
                    text={data.content}
                    percentage={data.percentage}
                    percentageLabel={data.percentageLabel}
                    citationNumber={data.citationNumber}
                    key={data.content}
                  />
                );
              })}
              <CitationFooter />
            </Grid>
          </Container>
        </Box>
      </Box>
    </PageBase>
  );
};
