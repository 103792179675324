import { ComponentProps, FC } from 'react';

import { Card, makeStyles, Theme } from '@material-ui/core';

type CardStackableProps = {
  offset: number;
} & ComponentProps<typeof Card>;

type StyleProps = {
  offset: number;
};

const PERSPECTIVE_OFFSET = 800;
const TRANSLATE_SPACING_SCALE = 2;

const useStyles = makeStyles<Theme, StyleProps>(({ spacing, transitions }) => ({
  card: {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    transform: ({ offset }) => {
      const translateOffset = offset * spacing(TRANSLATE_SPACING_SCALE);

      return `perspective(${PERSPECTIVE_OFFSET}px) translate3d(0px, ${translateOffset}px, ${-translateOffset}px)`;
    },
    '@media (prefers-reduced-motion: no-preference)': {
      transition: transitions.create('transform'),
    },
  },
}));

export const CardStackable: FC<CardStackableProps> = ({
  offset,
  children,
  className,
  ...props
}) => {
  const classes = useStyles({ offset });

  const finalClassName = className ? `${className} ${classes.card}` : classes.card;

  return (
    <Card {...props} className={finalClassName}>
      {children}
    </Card>
  );
};
