import { FC } from 'react';

import { LinkProps as MuiLinkProps } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import {
  ExternalLink,
  ExternalLinkButton,
  Link,
  ButtonLink,
  ButtonLinkProps,
} from 'shared/src/link/link';

export function useHmioHost(): string | undefined {
  return import.meta.env.VITE_HMIO_URL;
}

export const HmioLink: FC<MuiLinkProps<RouterLink, RouterLinkProps>> = ({ to, ...props }) => {
  di(Link, ExternalLink, useHmioHost);

  const hmioHost = useHmioHost();

  if (hmioHost == null) {
    return <Link {...props} to={to} />;
  }

  const toWithoutSlash = typeof to === 'string' && to.startsWith('/') ? to.slice(1) : to;

  return <ExternalLink {...props} to={`${hmioHost}/${toWithoutSlash}`} eventLabel="mats" />;
};

export const HmioLinkButton: FC<ButtonLinkProps> = ({ to, ...props }) => {
  di(ButtonLink, ExternalLinkButton, useHmioHost);

  const hmioHost = useHmioHost();

  if (hmioHost == null) {
    return <ButtonLink {...props} to={to} />;
  }

  const toWithoutSlash = typeof to === 'string' && to.startsWith('/') ? to.slice(1) : to;

  return <ExternalLinkButton {...props} to={`${hmioHost}/${toWithoutSlash}`} />;
};
