import { useMemo } from 'react';

import { ApolloError, gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';
import { notEmpty } from 'shared/src/util/not-empty';

import { FindBlogPosts, FindBlogPostsVariables } from './types/queries.contentful.generated';

const FIND_BLOG_POSTS = gql`
  query FindBlogPosts($limit: Int, $offset: Int, $preview: Boolean) {
    blogsIndexPageCollection(preview: $preview) {
      items {
        featuredBlog {
          sys {
            ...SysFragment
          }
          category
          date
          heroImage {
            ...AssetFragment
          }
          keywords
          slug
          title
        }
      }
    }

    blogPostCollection(limit: $limit, skip: $offset, preview: $preview, order: [date_DESC]) {
      total
      items {
        sys {
          ...SysFragment
        }
        category
        date
        heroImage {
          ...AssetFragment
        }
        keywords
        slug
        title
      }
    }
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
`;

const useFindBlogPostsRaw = contentfulQueryFactory<FindBlogPosts, FindBlogPostsVariables>(
  FIND_BLOG_POSTS
);

export type FeaturedBlogPost = DeepExtractTypeSkipArrays<
  FindBlogPosts,
  ['blogsIndexPageCollection', 'items', 'featuredBlog']
>;

export type BlogPostCollectionItem = DeepExtractTypeSkipArrays<
  FindBlogPosts,
  ['blogPostCollection', 'items']
>;

export const useFindBlogPosts = ({
  offset,
  limit,
  skip,
}: {
  offset: number;
  limit: number;
  skip?: boolean;
}): {
  data:
    | {
        featuredBlog?: FeaturedBlogPost;
        items: BlogPostCollectionItem[];
        total: number;
      }
    | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const variables = { limit, offset };
  const { data, loading, error } = useFindBlogPostsRaw({ variables, skip });

  return useMemo(() => {
    const items = data?.blogPostCollection?.items?.filter(notEmpty);
    const total = data?.blogPostCollection?.total;
    const featuredBlog = data?.blogsIndexPageCollection?.items?.[0]?.featuredBlog ?? undefined;

    if (items == null || total == null) {
      return { data: undefined, error, loading };
    }

    return {
      loading,
      error,
      data: {
        items,
        total,
        featuredBlog,
      },
    };
  }, [data, loading, error]);
};
