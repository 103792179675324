import { FC } from 'react';

import { Box, Container, Link, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { di } from 'react-magnetic-di/macro';

import { PageBase } from 'components/page-base/page-base';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(8),
  },
  sectionHeader: {
    marginBottom: theme.spacing(3),
  },
  pageTitle: {
    marginBottom: theme.spacing(3),
  },
}));

export const PrivacyPolicy: FC = () => {
  di(PageBase);

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PageBase windowTitle="Privacy Policy">
      <Box marginY={6}>
        <Container maxWidth="md">
          <Box marginBottom={8}>
            <header>
              <Typography variant="h2" component="h1" className={classes.pageTitle}>
                {t('hmio.privacy.pageTitle')}
              </Typography>
            </header>
            <section className={classes.section}>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.privacy.intro1" />
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>{t('hmio.privacy.intro2')}</strong>
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.privacy.intro2')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.privacy.intro3')}
              </Typography>
            </section>
            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.platformHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.platform1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.platform2')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.platform2-1')}</li>
                <li>{t('hmio.privacy.platform2-2')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.platform3')}
              </Typography>
            </section>
            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.sensitiveInfoHeading')}
              </Typography>
              <Typography variant="body2" component="ol" gutterBottom type="a">
                <li>{t('hmio.privacy.sensitiveInfo1')}</li>
                <li>{t('hmio.privacy.sensitiveInfo2')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.sensitiveInfo3')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.sensitiveInfo4')}
              </Typography>
            </section>
            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.deidentifiedInfoHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.deidentifiedInfo1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.deidentifiedInfo2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.deidentifiedInfo3')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.deidentifiedInfo4')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.whatPersonalInfoHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.whatPersonalInfo1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.whatPersonalInfo2')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.whatPersonalInfo2-1')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-2')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-3')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-4')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-5')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-6')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-7')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-8')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-9')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-10')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-11')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-12')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-13')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-14')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-15')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo2-16')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.whatPersonalInfo3')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.whatPersonalInfo3-1')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo3-2')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo3-3')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo3-4')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo3-5')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo3-6')}</li>
                <li>{t('hmio.privacy.whatPersonalInfo3-7')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.whatPersonalInfo4')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.whyPersonalInfoHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.whyPersonalInfo1')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.whyPersonalInfo1-1')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-2')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-3')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-4')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-5')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-6')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-7')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-8')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-9')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-10')}</li>
                <li>{t('hmio.privacy.whyPersonalInfo1-11')}</li>
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.personalInfoMarketingHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoMarketing1')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.personalInfoMarketing1-1')}</li>
                <li>{t('hmio.privacy.personalInfoMarketing1-2')}</li>
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoMarketing2')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoMarketing3')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoMarketing4')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoMarketing5')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.personalInfoDisclosureHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosure1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosure2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosure3')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosure4')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.personalInfoDisclosure4-1')}</li>
                <li>{t('hmio.privacy.personalInfoDisclosure4-2')}</li>
                <li>{t('hmio.privacy.personalInfoDisclosure4-3')}</li>
                <li>{t('hmio.privacy.personalInfoDisclosure4-4')}</li>
                <li>{t('hmio.privacy.personalInfoDisclosure4-5')}</li>
                <li>{t('hmio.privacy.personalInfoDisclosure4-6')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosure5')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.personalInfoDisclosureCountryHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosureCountry1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.personalInfoDisclosureCountry2')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.cookiesHeading')}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t('hmio.privacy.cookiesGenerallyHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesGenerally1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesGenerally2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesGenerally3')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.cookiesGenerally3-1')}</li>
                <li>{t('hmio.privacy.cookiesGenerally3-2')}</li>
                <li>{t('hmio.privacy.cookiesGenerally3-3')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesGenerally4')}
              </Typography>

              <Typography variant="h6" gutterBottom>
                {t('hmio.privacy.cookiesMarketingHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesMarketing1')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.cookiesMarketing1-1')}</li>
                <li>{t('hmio.privacy.cookiesMarketing1-2')}</li>
              </Typography>

              <Typography variant="h6" gutterBottom>
                {t('hmio.privacy.cookiesOptOutHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesOptOut1')}
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans
                  t={t}
                  i18nKey="hmio.privacy.cookiesOptOut2"
                  components={{
                    facebookLink: (
                      <Link href="https://www.facebook.com/help/109378269482053/?helpref=hc_fnav" />
                    ),
                  }}
                />
              </Typography>

              <Typography variant="h6" gutterBottom>
                {t('hmio.privacy.cookiesDoNotTrackHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.cookiesDoNotTrack1')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.securityHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.security1')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.security1-1')}</li>
                <li>{t('hmio.privacy.security1-2')}</li>
                <li>{t('hmio.privacy.security1-3')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.security2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.security3')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.security4')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.childrenHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.children1')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.linksHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.links1')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.accessingHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.accessing1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.accessing2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.accessing3')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.gdprHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.gdpr1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.gdpr2')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.gdprPersonalInfoHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.gdprPersonalInfo1')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.gdprPersonalInfo1-1')}</li>
                <li>{t('hmio.privacy.gdprPersonalInfo1-2')}</li>
                <li>{t('hmio.privacy.gdprPersonalInfo1-3')}</li>
                <li>{t('hmio.privacy.gdprPersonalInfo1-4')}</li>
                <li>{t('hmio.privacy.gdprPersonalInfo1-5')}</li>
                <li>{t('hmio.privacy.gdprPersonalInfo1-6')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.gdprPersonalInfo2')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.gdprRightsHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.gdprRights1')}
              </Typography>
              <Typography variant="body2" component="ul" gutterBottom>
                <li>{t('hmio.privacy.gdprRights1-1')}</li>
                <li>{t('hmio.privacy.gdprRights1-2')}</li>
                <li>{t('hmio.privacy.gdprRights1-3')}</li>
                <li>{t('hmio.privacy.gdprRights1-4')}</li>
                <li>{t('hmio.privacy.gdprRights1-5')}</li>
                <li>{t('hmio.privacy.gdprRights1-6')}</li>
                <li>{t('hmio.privacy.gdprRights1-7')}</li>
                <li>{t('hmio.privacy.gdprRights1-8')}</li>
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.gdprRights2')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.complaintHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.complaint1')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography variant="h3" component="h2" className={classes.sectionHeader}>
                {t('hmio.privacy.contactHeading')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.privacy.contact1')}
              </Typography>
              <address>
                HealthMatch <br />
                Attn: Privacy Officer <br />
                10-14 Waterloo Street <br />
                Surry Hills <br />
                NSW 2010
                <br />
                Australia <br />
                <Link href="mailto: privacy@healthmatch.io">privacy@healthmatch.io</Link>
              </address>
            </section>

            <Typography variant="subtitle2" paragraph>
              <strong>{t('hmio.privacy.lastUpdated')}</strong>
            </Typography>
          </Box>
        </Container>
      </Box>
    </PageBase>
  );
};
