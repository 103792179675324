import { FC } from 'react';

import {
  DropdownContentDrawerButton,
  DropdownContentToolbarButton,
} from '../dropdown-drawer-button/dropdown-content-button';

import { Patients } from './patients';

export const PatientsDrawerButton: FC<{ onNavigation: () => void }> = ({ onNavigation }) => (
  <DropdownContentDrawerButton title="For patients" onNavigation={onNavigation}>
    <Patients showGraphic={false} />
  </DropdownContentDrawerButton>
);

export const PatientsToolbarButton: FC = () => (
  <DropdownContentToolbarButton title="For patients">
    <Patients showGraphic />
  </DropdownContentToolbarButton>
);
