import { Typography, Grid, makeStyles } from '@material-ui/core';

import { beige, lightBeige } from 'shared/src/palette/color-system';

import { HeroBanner } from 'components/hero-banner/hero-banner';

import brainsTrustSuperSquadPhoto from './images/brains-trust-super-squad.jpg';
import background1 from './images/hero-section-bg-1.svg';
import background2 from './images/hero-section-bg-2.svg';
import background3 from './images/hero-section-bg-3.svg';
import patientSuccess from './images/patient-success.jpg';

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundColor: beige[500],
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url("${background1}"), url(${background2}), url(${background3})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '15%, 35%',
      backgroundPosition: 'top left, bottom center, bottom right',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("${background1}"), url(${background2})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '20%, 60%',
      backgroundPosition: 'top left, bottom right -80%',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(45),
    },
  },
}));

export const HeroSection = () => {
  const classes = useStyles();

  return (
    <HeroBanner
      colorTop={lightBeige[500]}
      colorBottom={lightBeige[500]}
      imageTop={brainsTrustSuperSquadPhoto}
      imageBottom={patientSuccess}
      className={classes.banner}
      imageFit="cover"
    >
      <Grid container className={classes.content} direction="column" justifyContent="center">
        <Typography variant="h2" component="h1" gutterBottom>
          We are changing the future of medicine.
        </Typography>
        <Typography paragraph>
          HealthMatch is driven by a mission to accelerate medical research allowing for faster and
          more efficient access to life-changing medication. Through technology that puts the
          metaphorical steering wheel back in the hands of the patient, our goal is to make the
          process of finding and accessing clinical trials as simple as possible for all.
        </Typography>
        <Typography paragraph>
          At HealthMatch the patient is always at the centre of everything we do.
        </Typography>
      </Grid>
    </HeroBanner>
  );
};
