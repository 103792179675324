import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import { ButtonLink } from 'shared/src/link/link';

import notFoundImageDesktop from './page-not-found-desktop.svg';
import notFoundImageMobile from './page-not-found-mobile.svg';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: theme.spacing(112),
    objectFit: 'contain',
  },
}));

export const NotFoundPageContent = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box marginBottom={8}>
        <Typography variant="h4" align="center">
          Sorry! We couldn&apos;t find the page that you’re looking for.
        </Typography>
        <Box marginTop={5}>
          <Grid container justifyContent="center">
            <ButtonLink to="/" variant="contained" color="primary">
              Return to home
            </ButtonLink>
          </Grid>
        </Box>
      </Box>
      <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center">
        <img alt="" src={notFoundImageDesktop} className={classes.image} />
      </Box>
      <Box display={{ xs: 'flex', md: 'none' }} justifyContent="center">
        <img alt="" src={notFoundImageMobile} className={classes.image} />
      </Box>
    </Container>
  );
};
