import { createContext, createRef, FC, useContext, useEffect, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { getCLS, getFCP, getFID, getLCP, getTTFB, Metric } from 'web-vitals';

import { SupportedLocale } from 'shared/src/locales/locales';

export type EventProperty =
  | string
  | number
  | boolean
  | { [key: string]: EventProperty }
  | (string | number | boolean | { [key: string]: EventProperty })[]
  | undefined;

type Properties = Record<string, EventProperty>;

export type EventProperties = Properties & {
  event: string;
  category?: string;
  label?: string;
};

export type PageViewProperties = Properties & {
  page?: string;
  name?: string;
  category?: string;
};

export type UserInfo = { userId: string; isLoggedIn: boolean; anonymousId?: string } & Record<
  string,
  any
>;

interface AnalyticsBase {
  init(userInfo?: UserInfo): Promise<void>;

  ready(pluginId?: string): Promise<void>;

  identify(userInfo: UserInfo): void;

  track(properties: EventProperties): Promise<void>;

  page(properties?: PageViewProperties): Promise<void>;

  getAnonymousId(pluginId: string): string | null;

  // Sets a context property that will be included in all future events
  setContext(propertyName: string, propertyValue: EventProperty): void;

  // Removes a context property
  unsetContext(propertyName: string): void;
}

export interface AnalyticsPlugin extends Partial<AnalyticsBase> {
  readonly name: string;
}

type PendingEventType =
  | {
      type: 'track';
      properties: EventProperties;
    }
  | {
      type: 'page';
      properties: PageViewProperties;
    };

export class Analytics implements AnalyticsBase {
  private plugins: Map<string, AnalyticsPlugin>;

  private isInitialized: boolean;

  private locale?: SupportedLocale;

  // list of events that are fired before `init` is called
  // this is useful for cases where events are fired on first page view that don't wait for `init`
  private pendingEvents: PendingEventType[];

  constructor({ plugins }: { plugins: AnalyticsPlugin[] }) {
    this.plugins = new Map(plugins.map(plugin => [plugin.name, plugin]));
    this.isInitialized = false;
    this.pendingEvents = [];
    this.track = this.track.bind(this);
  }

  async init(userInfo?: UserInfo): Promise<void> {
    await Promise.allSettled(Array.from(this.plugins.values(), plugin => plugin.init?.(userInfo)));
    this.isInitialized = true;

    this.pendingEvents.forEach(pendingEvent => {
      switch (pendingEvent.type) {
        case 'page':
          this.page(pendingEvent.properties);
          break;
        case 'track':
          this.track(pendingEvent.properties);
          break;
        default:
          break;
      }
    });

    this.pendingEvents = [];
  }

  async ready(): Promise<void> {
    await Promise.allSettled(Array.from(this.plugins.values(), plugin => plugin.ready?.()));
  }

  identify(userInfo: UserInfo): void {
    for (const plugin of this.plugins.values()) {
      plugin.identify?.(userInfo);
    }
  }

  async track(properties: EventProperties): Promise<void> {
    const eventProperties = this.locale ? { locale: this.locale, ...properties } : properties;

    if (!this.isInitialized) {
      this.pendingEvents.push({ type: 'track', properties: eventProperties });
      return;
    }

    await Promise.allSettled(
      Array.from(this.plugins.values(), plugin => plugin.track?.(eventProperties))
    );
  }

  async page(properties: PageViewProperties): Promise<void> {
    const eventProperties = this.locale ? { locale: this.locale, ...properties } : properties;

    if (!this.isInitialized) {
      this.pendingEvents.push({ type: 'page', properties: eventProperties });
      return;
    }

    await Promise.allSettled(
      Array.from(this.plugins.values(), plugin => plugin.page?.(eventProperties))
    );
  }

  getAnonymousId(pluginId: string): string | null {
    return this.plugins.get(pluginId)?.getAnonymousId?.(pluginId) ?? null;
  }

  setContext(propertyName: string, propertyValue: EventProperty): void {
    for (const plugin of this.plugins.values()) {
      plugin.setContext?.(propertyName, propertyValue);
    }
  }

  unsetContext(propertyName: string): void {
    for (const plugin of this.plugins.values()) {
      plugin.unsetContext?.(propertyName);
    }
  }

  setLocale(locale: SupportedLocale): void {
    this.locale = locale;
  }
}

const AnalyticsContext = createContext<Analytics | null>(null);

export const AnalyticsProvider: FC<{ analytics: Analytics }> = ({ children, analytics }) => (
  <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
);

export const useAnalytics = (): Analytics => {
  const analytics = useContext<Analytics | null>(AnalyticsContext);
  if (!analytics) {
    throw new Error('AnalyticsProvider is missing in the tree');
  }
  return analytics;
};

const OnMount: FC<{ onMount: () => void }> = ({ onMount }) => {
  di(useAnalytics);

  const ref = createRef<HTMLDivElement>();

  const [triggered, setTriggered] = useState(false);

  // Use a ref here to ensure we wait until the actual DOM element is mounted
  useEffect(() => {
    onMount();
    setTriggered(true);
  }, [onMount, setTriggered]);

  // Unmount when done
  if (triggered) {
    return null;
  }

  return <div ref={ref} style={{ display: 'none' }} />;
};

export const PageAnalytics: FC = () => {
  di(useAnalytics, useLocation);

  const analyticsFromHook = useAnalytics();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  // If search params contain email, this block will remove it
  searchParams.delete('email');

  const relativeUrl = `${location.pathname}${searchParams.toString() && '?'}${searchParams}`;

  useEffect(() => {
    analyticsFromHook.page({ page: relativeUrl });
  }, [analyticsFromHook, relativeUrl]);

  useEffectOnce(() => {
    const handleReport = (metric: Metric) => {
      if (metric.name === 'CLS') {
        datadogLogs.logger.info('web vital', {
          event: `hmio.webvital.${metric.name}`,
          score: metric.value, // this is a score as a percentage of the page that changes: https://web.dev/cls/
        });
      } else {
        datadogLogs.logger.info('web vital', {
          event: `hmio.webvital.${metric.name}`,
          time: metric.value, // all other metrics are time in ms
        });
      }
    };

    getCLS(handleReport);
    getFCP(handleReport);
    getFID(handleReport);
    getLCP(handleReport);
    getTTFB(handleReport);
  });

  const onDomElementMounted = () => {
    if (performance == null || typeof performance.getEntriesByType !== 'function') {
      return;
    }

    const navigationEvent = performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming;

    if (navigationEvent == null) {
      return;
    }

    // Record time to first React render
    datadogLogs.logger.info('web vital', {
      event: 'hmio.webvital.react_render',

      // Total time from browser's connection attempt -> this (first) React render
      time: Math.round(performance.now() - navigationEvent.connectStart),
    });
  };

  return <OnMount onMount={onDomElementMounted} />;
};
