import { Statistic } from 'components/statistic';
import { Citation } from 'pages/why-clinical-trials/components/citation';

type InformationStatProps = {
  text: string;
  percentageLabel?: string;
  percentage: number;
  citationNumber?: number;
};

export const InformationStat = ({
  text,
  percentage,
  percentageLabel,
  citationNumber,
}: InformationStatProps) => (
  <Statistic
    bigNumber={`${percentage}%`}
    content={
      <>
        {text}
        <Citation>{citationNumber}</Citation>
      </>
    }
    direction="row"
    bigNumberLabel={percentageLabel}
  />
);
