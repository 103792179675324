import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChatColorIcon, PageInfoIcon } from 'shared/src/icon/icons';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  subtitle: {
    fontSize: typography.pxToRem(30),
    color: palette.text.secondary,
    paddingBottom: spacing(2),
  },
}));

export const WhyClinicalTrials = () => {
  const classes = useStyles();

  return (
    <Box paddingY={{ xs: 7, md: 10 }} paddingX={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box paddingBottom={2}>
              <ChatColorIcon fontSize="large" />
            </Box>
            <Typography variant="h3" component="h6" className={classes.subtitle}>
              Why clinical trials?
            </Typography>
            <Typography paragraph variant="body2" color="textSecondary">
              Clinical trials are vital for researchers and physicians to advance modern medicine
              and improve the quality of life for future patients. All the drugs and medical
              equipment we use today are available because patients participated in clinical trials.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box paddingBottom={2}>
              <PageInfoIcon fontSize="large" />
            </Box>
            <Typography variant="h3" component="h6" className={classes.subtitle}>
              How do I apply for a trial?
            </Typography>
            <Typography paragraph variant="body2" color="textSecondary">
              Our platform helps you find the right match by showing you only trials you’re eligible
              for and simplifying the jargon. You’re then able to view matches and apply for trials
              in an easy-to-use dashboard. Once you’ve been accepted, we’ll put you in direct
              contract with the trial group.
            </Typography>
            <Typography paragraph variant="body2" color="textSecondary">
              If you don’t match with a clinical trial today, we won’t stop searching until we find
              the right match for you.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
