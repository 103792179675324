import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';

import { injectable } from 'react-magnetic-di/macro';

type EmailStateType = [string, Dispatch<SetStateAction<string>>];

const EmailContext = createContext<EmailStateType>(['', () => {}]);

export const useEmailContext = () => {
  const emailState = useContext(EmailContext);
  if (!emailState) {
    throw new Error('EmailProvider is missing in the tree');
  }
  return emailState;
};

export const makeMockUseEmailContext = (value = '', setter = () => {}) =>
  injectable(useEmailContext, () => [value, setter]);

/**
 * We store email addresses in context for some uses cases where an email needs to be shared across pages but we don't
 * want to place the email in the url search param as our paths get passed to analytics services
 */
export const EmailProvider: FC = ({ children }) => {
  const state = useState<string>('');
  return <EmailContext.Provider value={state}>{children}</EmailContext.Provider>;
};
