import { gql } from '@apollo/client';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

const EMBEDDED_CONDITION_ARTICLE_FRAGMENT = gql`
  fragment EmbeddedConditionArticle on ConditionArticle {
    heroImage {
      ...AssetFragment
    }
    slug
  }
  ${ASSET_FRAGMENT}
`;

const EMBEDDED_CONDITION_CATEGORY_FRAGMENT = gql`
  fragment EmbeddedConditionCategory on ConditionCategory {
    heroImage {
      ...AssetFragment
    }
    slug
  }
  ${ASSET_FRAGMENT}
`;

export const EMBEDDED_ENTRY_FRAGMENT = gql`
  fragment EmbeddedEntryFragment on Entry {
    sys {
      ...SysFragment
    }
    ... on ConditionLink {
      linkedPage {
        ... on ConditionArticle {
          ...EmbeddedConditionArticle
        }
        ... on ConditionCategory {
          ...EmbeddedConditionCategory
        }
      }
      title
    }
    ... on ConditionArticle {
      ...EmbeddedConditionArticle
      title
    }
    ... on ConditionCategory {
      ...EmbeddedConditionCategory
      title
    }
  }
  ${EMBEDDED_CONDITION_ARTICLE_FRAGMENT}
  ${EMBEDDED_CONDITION_CATEGORY_FRAGMENT}
  ${SYS_FRAGMENT}
`;
