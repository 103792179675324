import { gql } from '@apollo/client';

export const CONTENT_LINK_FRAGMENT = gql`
  fragment ContentLinksFragment on ConditionArticleCollection {
    items {
      sys {
        ...SysFragment
      }
      heroImage {
        ...AssetFragment
      }
      category {
        sys {
          id
        }
        condition
        slug
      }
      slug
      title
      searchVolume
    }
  }
`;

export const RELATED_CONDITIONS_FRAGMENT = gql`
  fragment RelatedConditionsFragment on ConditionCategoryRelatedConditionsCollection {
    items {
      ... on ConditionArticle {
        sys {
          id
        }
      }
      ... on ConditionCategory {
        linkedFrom {
          conditionArticleCollection(limit: 3) {
            ...ContentLinksFragment
          }
        }
      }
      ... on ConditionLink {
        linkedPage {
          ... on ConditionArticle {
            sys {
              id
            }
          }
          ... on ConditionCategory {
            sys {
              id
            }
            linkedFrom {
              conditionArticleCollection(limit: 3) {
                ...ContentLinksFragment
              }
            }
          }
        }
      }
    }
  }
`;
