import { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { HeroBanner } from 'components/hero-banner/hero-banner';

import heroBannerBackground from './patients-banner-pattern.svg';
import patientsPortrait1 from './patients-banner-person-1.jpg';
import patientsPortrait2 from './patients-banner-person-2.jpg';
import patientsFinger from './patients-finger.svg';

const useStyles = makeStyles(theme => ({
  background: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${heroBannerBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#eae6fd',
    },
  },
  finger: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  person: {
    width: '100%',
    borderRadius: 'inherit',
    verticalAlign: 'middle',
  },
}));

export const PatientsHeroBanner: FC = () => {
  di(ConditionSelectPanel);

  const classes = useStyles();

  return (
    <>
      <HeroBanner
        imageTop={patientsPortrait1}
        imageBottom={patientsPortrait2}
        colorTop="#ffe6c8"
        colorBottom="#ddd6ff"
        className={classes.background}
      >
        <Box paddingTop={{ xs: 4, sm: 2 }}>
          <Typography variant="h2">It’s time to take control</Typography>
          <Box marginTop={3} marginBottom={4}>
            <Typography variant="body2" color="textSecondary">
              You’re in control of your condition. It’s not in control of you. At HealthMatch, we’re
              here to help you stay in control.
            </Typography>
          </Box>
          <ConditionSelectPanel />
        </Box>
      </HeroBanner>
      <Container>
        <Box marginY={{ xs: 7, sm: 10 }}>
          <Grid container alignItems="center" justifyContent="space-around" direction="row-reverse">
            <Grid item xs={12} sm={5}>
              <Typography variant="h2" gutterBottom>
                Let’s take control – together
              </Typography>
              <Typography variant="body2" paragraph>
                You’ve spent hours researching your condition. We know. You’ve tried treatment after
                treatment. We know. There are more options out there. We know – they’re on our
                platform.
              </Typography>
              <Typography variant="body2" paragraph>
                Like you, we’ve spent hours researching conditions, treatments, and trials. We’ve
                built a platform that puts you in control. Of your condition. Your health. Your
                life.
              </Typography>
              <Typography variant="body2" paragraph>
                You’ll feel empowered. You’ll be empowered! You’ll access cutting-edge treatments,
                new medications, innovative studies. On your terms. We have your perfect match. It’s
                with us – HealthMatch.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <img alt="" className={classes.finger} src={patientsFinger} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
