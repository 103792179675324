import { FC } from 'react';

import { Container, makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { withFeatureFlagCover } from 'components/hide-under-feature-flag/with-feature-flag-cover';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { NotFound } from 'pages/not-found/not-found';

import { TopicHubCategories } from './components/topic-hub-categories/topic-hub-categories';
import { TopicHubHero } from './components/topic-hub-hero/topic-hub-hero';
import { useTopicHubPage } from './queries.contentful';
import { TopicHubPageList } from './types/queries.contentful.generated';

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    display: 'grid',
    gridGap: spacing(10),
    gridAutoFlow: 'row',
    margin: spacing(5, 0, 10),
  },
}));

type TopicHubData = DeepExtractTypeSkipArrays<
  TopicHubPageList,
  ['topicHubPageCollection', 'items']
>;

export const TopicHubPage: FC<{
  topicHubPage: Pick<
    TopicHubData,
    'title' | 'heroBlog' | 'featuredArticlesCollection' | 'articlesCollection'
  >;
}> = ({ topicHubPage }) => {
  const styles = useStyles();

  return (
    <Container>
      <div className={styles.header}>
        <Container disableGutters maxWidth="sm">
          <Typography variant="h2" component="h1" align="center">
            {topicHubPage.title}
          </Typography>
        </Container>
        <TopicHubHero
          heroBlog={topicHubPage.heroBlog}
          articles={topicHubPage.featuredArticlesCollection?.items}
        />
        <TopicHubCategories articles={topicHubPage.articlesCollection?.items} />
      </div>
    </Container>
  );
};

export const TopicHubPageRoute = withFeatureFlagCover('enableTopicHubPage')(
  ({ match }: RouteComponentProps<{ slug: string }>) => {
    const { data, error, loading } = useTopicHubPage({
      variables: {
        slug: match.params.slug,
      },
    });

    if (loading) {
      return <LoadingPage windowTitle="Loading" />;
    }

    const topicHubPage = data?.topicHubPageCollection?.items?.[0];

    if (error || topicHubPage == null) {
      return <NotFound />;
    }

    return (
      <PageBase
        windowTitle={topicHubPage.titleMeta ?? ''}
        descriptionMeta={topicHubPage.descriptionMeta ?? ''}
        windowTitleHmPrefix={false}
      >
        <TopicHubPage topicHubPage={topicHubPage} />;
      </PageBase>
    );
  }
);
