import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { CONDITION_AUTHOR_FRAGMENT } from 'shared/src/fragments/contentful/condition-author.contentful';

import { CONTENT_LINK_FRAGMENT } from 'components/content-links/query.contentful';
import {
  ConditionArticlesByCommonMedicalReviewer,
  ConditionArticlesByCommonMedicalReviewerVariables,
} from 'pages/medical-reviewer/types/queries.contentful.generated';

const CONDITION_ARTICLES_BY_COMMON_MEDICAL_REVIEWER = gql`
  query ConditionArticlesByCommonMedicalReviewer($slug: String, $preview: Boolean) {
    conditionAuthorCollection(where: { slug: $slug }, limit: 1, preview: $preview) {
      items {
        ...ConditionAuthorFragment
      }
    }
    conditionArticleCollection(
      where: { medicalReviewer: { slug: $slug } }
      limit: 20
      preview: $preview
    ) {
      ...ContentLinksFragment
    }
  }
  ${CONDITION_AUTHOR_FRAGMENT}
  ${CONTENT_LINK_FRAGMENT}
`;

export const useConditionArticlesByCommonMedicalReviewer = contentfulQueryFactory<
  ConditionArticlesByCommonMedicalReviewer,
  ConditionArticlesByCommonMedicalReviewerVariables
>(CONDITION_ARTICLES_BY_COMMON_MEDICAL_REVIEWER);
