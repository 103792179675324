import { FC, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';
import { useEffectOnce } from 'react-use';

import { PageBase } from 'components/page-base/page-base';

const useStyles = makeStyles(() => ({
  widgetFrame: {
    border: 0,
    width: '100%',
    height: 300,
    margin: '-10px 0 -40px 0',
  },
}));

type Props = {
  timekitProjectId: string;
};

export const BookingWidget: FC<Props> = props => {
  di(PageBase);
  const classes = useStyles();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const receiveMessage = (msg: MessageEvent) => {
    if (msg.origin !== window.location.origin) {
      return;
    }

    if (msg.data && msg.data.type === 'InitRequest' && iframeRef.current != null) {
      iframeRef.current.contentWindow?.postMessage(
        {
          type: 'InitResponse',
          projectId: props.timekitProjectId,
        },
        // The iframe must be same origin as the page hosting this widget
        window.location.origin,
        []
      );
    }

    if (msg.data && msg.data.type === 'ResizeIframe') {
      if (iframeRef != null && iframeRef.current != null) {
        iframeRef.current.style.height = `${msg.data.height}px`;
      }
    }
  };

  useEffectOnce(() => {
    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  });

  return (
    <iframe
      ref={iframeRef}
      title="Booking widget"
      className={classes.widgetFrame}
      src="/booking-widget.html"
    />
  );
};
