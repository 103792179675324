import { gql } from '@apollo/client';

export const TRIAL_SITE_FRAGMENT = gql`
  fragment TrialSiteRecordHmio on TrialSite {
    id
    acceptsOutOfState
    isAutoApply
    recruitingStatus
    maxDistance
    applicationMode
    screeningFormLink
    bookingLink
    compensationType
    compensationTextOverride
    supportsInboundCalling
    inboundCallingConfig {
      phoneNumber
      enabled
    }
  }
`;
