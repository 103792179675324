import { FC } from 'react';

import { Box, Grid, Hidden, Typography, useTheme } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Element } from 'react-scroll';

import { Link } from 'shared/src/link/link';

import { DirectoryLegend } from './directory-legend/directory-legend';

export type DirectoryItem = {
  slug: string;
  title: string;
};

type DirectoryProps = {
  legendTitle: string;
  inputs: [string, DirectoryItem[]][];
};

export const Directory: FC<DirectoryProps> = ({ inputs, legendTitle }) => {
  di(Link);
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} md={9}>
        {inputs.map(([letter, items]) => (
          <Element name={letter} key={`guide-category-${letter}`}>
            <Box paddingTop={{ xs: 5, sm: 7 }}>
              <Typography variant="h2" color="primary">
                {letter}
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns={`repeat(auto-fill, minmax(${theme.spacing(25)}px, auto))`}
                gridGap={theme.spacing(2)}
                paddingY={3}
              >
                {items.map(cat => (
                  <Box paddingTop={{ xs: 3, sm: 0 }} key={cat.title}>
                    <Link to={`/${cat.slug}`} color="textSecondary">
                      <b>{cat.title}</b>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Element>
        ))}
      </Grid>
      <Hidden smDown>
        <Grid item xs={3}>
          <DirectoryLegend title={legendTitle} lettersWithArticles={inputs.map(([l]) => l)} />
        </Grid>
      </Hidden>
    </>
  );
};
