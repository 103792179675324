import {
  AnalyticsPlugin,
  EventProperties,
  EventProperty,
  PageViewProperties,
  UserInfo,
} from './analytics';

type LoggingConfig = {
  noAutoInit?: boolean;
  log: (...args: unknown[]) => void;
};

export class LoggingPlugin implements AnalyticsPlugin {
  readonly name = 'logging';

  private readonly readyPromise: Promise<void>;

  private readyResolve = () => {};

  constructor(private readonly config: LoggingConfig) {
    this.readyPromise = config.noAutoInit
      ? new Promise(resolve => {
          this.readyResolve = resolve;
        })
      : Promise.resolve();
  }

  private log(method: string, ...args: unknown[]) {
    this.config.log(`[Analytics ${method}]:`, ...args);
  }

  async init(userInfo?: UserInfo): Promise<void> {
    this.log('Init', userInfo);
    this.readyResolve();
    await this.readyPromise;
  }

  ready(): Promise<void> {
    return this.readyPromise;
  }

  async page(properties?: PageViewProperties): Promise<void> {
    await this.readyPromise;
    this.log('Page', properties);
  }

  async track(properties: EventProperties): Promise<void> {
    await this.readyPromise;
    this.log('Track', properties);
  }

  identify(userInfo: UserInfo): void {
    this.log('Identify', userInfo);
  }

  getAnonymousId(): string | null {
    this.log('Get Anonymous Id');
    return null;
  }

  setContext(propertyName: string, propertyValue: EventProperty): void {
    this.log('Context set', propertyName, propertyValue);
  }
}
