import {
  useMutation,
  useQuery,
  useLazyQuery,
  DocumentNode,
  MutationHookOptions,
  QueryHookOptions,
} from '@apollo/client';

export function mutationFactory<D, V>(
  mutation: DocumentNode,
  factoryOptions?: MutationHookOptions<D, V>
) {
  return function useCustomMutation(options?: MutationHookOptions<D, V>) {
    return useMutation<D, V>(
      mutation,
      (options && factoryOptions && { ...factoryOptions, ...options }) || factoryOptions || options
    );
  };
}

export function queryFactory<D, V = {}>(
  query: DocumentNode,
  factoryOptions?: QueryHookOptions<D, V>
) {
  return function useCustomQuery(options?: QueryHookOptions<D, V>) {
    return useQuery<D, V>(
      query,
      (options && factoryOptions && { ...factoryOptions, ...options }) || factoryOptions || options
    );
  };
}

export function lazyQueryFactory<D, V = {}>(query: DocumentNode) {
  return function useCustomLazyQuery(options?: QueryHookOptions<D, V>) {
    return useLazyQuery<D, V>(query, options);
  };
}
