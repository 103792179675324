import { useMemo } from 'react';

import { ApolloError, gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { CONDITION_AUTHOR_FRAGMENT } from 'shared/src/fragments/contentful/condition-author.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';
import { notEmpty } from 'shared/src/util/not-empty';

import { ArticleLink } from 'components/content-links/content-links';

import {
  BlogPostsByAuthor,
  BlogPostsByAuthorVariables,
} from './types/queries.contentful.generated';

const BLOG_POSTS_BY_AUTHOR = gql`
  query BlogPostsByAuthor($slug: String!, $preview: Boolean) {
    author: conditionAuthorCollection(where: { slug: $slug }, limit: 1, preview: $preview) {
      items {
        ...ConditionAuthorFragment
      }
    }

    posts: blogPostCollection(preview: $preview, where: { authorProfile: { slug: $slug } }) {
      items {
        sys {
          ...SysFragment
        }
        heroImage {
          ...AssetFragment
        }
        slug
        title
      }
    }
    categories: conditionCategoryCollection(preview: $preview, where: { author: { slug: $slug } }) {
      items {
        sys {
          ...SysFragment
        }
        heroImage {
          ...AssetFragment
        }
        slug
        title
      }
    }
    articles: conditionArticleCollection(preview: $preview, where: { author: { slug: $slug } }) {
      items {
        sys {
          ...SysFragment
        }
        heroImage {
          ...AssetFragment
        }
        category {
          condition
        }
        slug
        title
      }
    }
    medArticles: medicationArticleCollection(
      preview: $preview
      where: { author: { slug: $slug } }
    ) {
      items {
        sys {
          ...SysFragment
        }
        slug
        title
      }
    }
  }
  ${CONDITION_AUTHOR_FRAGMENT}
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
`;

const useBlogAuthorRaw = contentfulQueryFactory<BlogPostsByAuthor, BlogPostsByAuthorVariables>(
  BLOG_POSTS_BY_AUTHOR
);

export type Author = DeepExtractTypeSkipArrays<BlogPostsByAuthor, ['author', 'items']>;

type BlogAuthorData = {
  author: Author;
  posts?: ArticleLink[];
  categories?: ArticleLink[];
  articles?: ArticleLink[];
  medArticles?: ArticleLink[];
};

type UseBlogAuthor = {
  data: BlogAuthorData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useBlogAuthor = ({ slug, skip }: { slug: string; skip?: boolean }): UseBlogAuthor => {
  const variables = { slug };
  const { data, loading, error } = useBlogAuthorRaw({ variables, skip });

  return useMemo((): UseBlogAuthor => {
    const posts = data?.posts?.items?.filter(notEmpty);
    const categories = data?.categories?.items?.filter(notEmpty);
    const articles = data?.articles?.items?.filter(notEmpty);
    const medArticles = data?.medArticles?.items?.filter(notEmpty);
    const author = data?.author?.items?.[0] ?? undefined;

    // Don't render the page if there's no linked blog posts to avoid showing medical reviewer as blog authors
    if (author == null) {
      return { data: undefined, error, loading };
    }

    return {
      loading,
      error,
      data: {
        author,
        posts: posts?.map(it => ({
          ...it,
          slug: it.slug && `blog/${it.slug}`,
          searchVolume: null,
          category: null,
        })),
        categories: categories?.map(it => ({
          ...it,
          slug: it.slug && it.slug,
          searchVolume: null,
          category: null,
        })),
        articles: articles?.map(it => ({
          ...it,
          slug:
            (it.slug && it.category?.condition && `${it.category?.condition}/${it.slug}`) ?? null,
          searchVolume: null,
          category: null,
        })),
        medArticles: medArticles?.map(it => ({
          ...it,
          heroImage: null,
          slug: it.slug && `medications/${it.slug}`,
          searchVolume: null,
          category: null,
        })),
      },
    };
  }, [data, loading, error]);
};
