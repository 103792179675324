import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useUpdateEffect } from 'react-use';

import { EventName } from 'shared/src/types/graphql-schema';

import { useAuth } from 'mats/src/components/auth/use-auth';

export const TikTokPixel = ({ tiktokPixelId }: { tiktokPixelId?: string }) => {
  di(Helmet, useAuth);

  const authenticatedUserId = useAuth().getAuthenticatedUserId();

  useUpdateEffect(() => {
    if (window.ttq && tiktokPixelId) {
      window.ttq?.identify({
        external_id: authenticatedUserId,
      });
    }
  }, [authenticatedUserId]);

  return tiktokPixelId ? (
    <Helmet>
      <script>{`!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('${tiktokPixelId}');
  ttq.page();
  ttq.identify({external_id: ${authenticatedUserId}});
}(window, document, 'ttq');`}</script>
    </Helmet>
  ) : null;
};

type TiktokEventName =
  | 'InitiateCheckout'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'Search'
  | 'CompleteRegistration'
  | 'SubmitApplication';

const eventToTiktokEventMap: Record<EventName, TiktokEventName> = {
  ConditionSearch: 'Search',
  QuestionnaireStarted: 'AddToCart',
  ScreeningQuestionAnswered: 'AddToWishlist',
  QuestionnaireCompleted: 'InitiateCheckout',
  AccountCreated: 'CompleteRegistration',
  SubmitApplication: 'SubmitApplication',
};

export interface TikTokParamObject {
  value: string;
  currency: string;
  contents: [Content];
}

interface Content {
  content_id: string;
  content_type: string;
  content_name: string;
}

export interface TikTokEventData {
  event_id: string;
}

export const ttqTrack = (
  eventName: EventName,
  eventData: TikTokEventData,
  paramObj?: TikTokParamObject
): void => {
  const tiktokEventName = eventToTiktokEventMap[eventName];
  window.ttq?.track(tiktokEventName, paramObj, eventData);
};
