import { Redirect } from 'react-router';

import { unreachableResult } from 'shared/src/types/unreachable-error';

import { RedirectFragment } from './types/redirect.contentful.generated';

export const getRedirectIfExists = (
  redirect: NonNullable<RedirectFragment['redirectCollection']>['items'][0] | undefined
) => {
  if (!redirect?.newPage?.__typename) {
    return null;
  }
  const redirectType = redirect.newPage.__typename;

  switch (redirectType) {
    case 'ConditionArticle':
    case 'ConditionCategory': {
      return <Redirect to={`/${redirect.newPage.slug}`} />;
    }
    case 'MedicationArticle': {
      return <Redirect to={`/medications/${redirect.newPage.slug}`} />;
    }
    default:
      return unreachableResult(redirectType, null);
  }
};
