import { FC } from 'react';

import { Box, Container, Divider, makeStyles } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { lightPurple } from 'shared/src/palette/color-system';
import { notEmpty } from 'shared/src/util/not-empty';

import { PageBase } from 'components/page-base/page-base';
import { Conditions, ConditionsProps } from 'pages/patients/components/conditions/conditions';
import {
  PatientStories,
  PatientStoriesProps,
} from 'pages/patients/components/patient-stories/patient-stories';
import { usePatientsPageList } from 'pages/patients/queries.contentful';

import { PatientsHeroBanner } from './components/hero-banner/hero-banner';

const useStyles = makeStyles({
  page: {
    backgroundColor: '#fcfcfc',
  },
});

type PatientsContentProps = PatientStoriesProps & ConditionsProps;

const FOOTER_BLEED = { kind: 'background', color: lightPurple[500] } as const;

export const PatientsContent: FC<PatientsContentProps> = ({ stories, conditions }) => {
  di(PageBase);

  return (
    <PageBase windowTitle="Patient Stories" footerBleed={FOOTER_BLEED}>
      <PatientsHeroBanner />
      <Container>
        <Divider />
      </Container>
      <Box marginY={{ xs: 8, sm: 10 }}>
        <PatientStories stories={stories} />
      </Box>
      <Conditions conditions={conditions} />
    </PageBase>
  );
};

export const Patients = () => {
  const classes = useStyles();
  const { data } = usePatientsPageList();

  const stories = data?.patientsStoryCollection?.items.filter(notEmpty) ?? [];
  const conditions = data?.conditionLandingPageCollection?.items.filter(notEmpty) ?? [];

  return (
    <Box className={classes.page}>
      <PatientsContent stories={stories} conditions={conditions} />
    </Box>
  );
};
