import { FC, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { AssetInfo, ContentfulImage } from 'shared/src/contentful-image/contentful-image';

import FemaleDoctor from 'pages/public-trial/components/trial-card-thumbnail/female-doctor.jpeg';
import MaleDoctor from 'pages/public-trial/components/trial-card-thumbnail/male-doctor.jpeg';
import SeniorFemale from 'pages/public-trial/components/trial-card-thumbnail/senior-female.jpeg';
import SeniorMaleLaugh from 'pages/public-trial/components/trial-card-thumbnail/senior-male-laughing.jpeg';
import SeniorMaleSmile from 'pages/public-trial/components/trial-card-thumbnail/senior-male-smiling.jpeg';

const useStyles = makeStyles(theme => ({
  thumbnail: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: theme.spacing(5),
  },
  patientImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(5),
  },
}));

type TrialCardThumbnailProps = {
  image?: AssetInfo;
  trialId: string;
};

export const TrialCardThumbnail: FC<TrialCardThumbnailProps> = ({ image, trialId }) => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const fallbackImages = [FemaleDoctor, MaleDoctor, SeniorFemale, SeniorMaleSmile, SeniorMaleLaugh];
  const imageHash = trialId.slice(-1).charCodeAt(0) % fallbackImages.length;

  return (
    <div className={classes.thumbnail}>
      {image ? (
        <>
          {loading && <Skeleton variant="circle" width="100%" height="100%" />}
          <ContentfulImage
            image={image}
            resize="fill"
            imageFocus="face"
            maxWidth={100}
            maxHeight={100}
            onLoad={() => setLoading(false)}
            className={classes.patientImage}
          />
        </>
      ) : (
        <img
          src={fallbackImages[imageHash]}
          className={classes.patientImage}
          alt=""
          onLoad={() => setLoading(false)}
        />
      )}
    </div>
  );
};
