import { FC } from 'react';

import { Typography, TypographyProps, makeStyles } from '@material-ui/core';

import { nantesFontFamily } from 'shared/src/fonts/fonts';

type Props = Omit<TypographyProps<'blockquote'>, 'variant'>;

const useStyles = makeStyles(theme => ({
  quote: {
    fontStyle: 'italic',
    fontFamily: nantesFontFamily,
    '&&': theme?.hmCustom?.typography?.quote ?? {},
  },
}));

export const Quote: FC<Props> = props => {
  const styles = useStyles();
  return <Typography {...props} variant="h5" className={styles.quote} component="blockquote" />;
};
