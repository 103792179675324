import { gql } from '@apollo/client';

import { queryFactory } from 'shared/src/util/query-factory';

import { TRIAL_PUBLIC_FRAGMENT } from 'mats/src/fragments/trial/trial-public-record-fragment';

import { GetTrialPublic, GetTrialPublicVariables } from './types/queries.generated';

const GET_TRIAL_PUBLIC = gql`
  query GetTrialPublic($id: TrialID!) {
    trialByIdOpaque(id: $id) {
      ...TrialPublicRecord
    }
  }
  ${TRIAL_PUBLIC_FRAGMENT}
`;

export const useGetTrialPublic = queryFactory<GetTrialPublic, GetTrialPublicVariables>(
  GET_TRIAL_PUBLIC
);
