import Cookies from 'js-cookie';

import { SupportedLocale } from 'shared/src/locales/locales';

const COOKIE_LOCALE_KEY = 'locale';
const COOKIE_TSP_LOGGED_IN_KEY = 'tsp';
const COOKIE_EMAIL_HINT_KEY = 'email_hint';

/**
 * The locale is stored in a cookie so that it can be accessed by both mats and hmio.
 */
export const useLocaleFromCookie = (fallback?: SupportedLocale) =>
  [
    (Cookies.get(COOKIE_LOCALE_KEY) || fallback) as SupportedLocale | undefined,
    (newValue: SupportedLocale) =>
      Cookies.set(COOKIE_LOCALE_KEY, newValue, { domain: getDomainWithoutSubdomain() }),
    () => Cookies.remove(COOKIE_LOCALE_KEY, { domain: getDomainWithoutSubdomain() }),
  ] as const;

/**
 * Remember if the user has logged in to the TSP at some point.
 */
export const useTspLoggedInFromCookie = () =>
  [
    !!Cookies.get(COOKIE_TSP_LOGGED_IN_KEY),
    () => Cookies.set(COOKIE_TSP_LOGGED_IN_KEY, '1', { domain: getDomainWithoutSubdomain() }),
    () => Cookies.remove(COOKIE_TSP_LOGGED_IN_KEY, { domain: getDomainWithoutSubdomain() }),
  ] as const;

const useHintFromCookie = (key: string) =>
  [
    Cookies.get(key),
    (newValue: string) => Cookies.set(key, newValue, { domain: getDomainWithoutSubdomain() }),
    () => Cookies.remove(key, { domain: getDomainWithoutSubdomain() }),
  ] as const;

/**
 * This hints the existing user's email when signing up via the referral + reverse signup flow.
 */
export const useEmailHintFromCookie = () => useHintFromCookie(COOKIE_EMAIL_HINT_KEY);

function getDomainWithoutSubdomain() {
  const fullHostname = window.location.hostname;
  const parts = fullHostname.split('.');
  return parts.slice(-2).join('.');
}
