import { Container, Grid, Theme, useMediaQuery, makeStyles, Box } from '@material-ui/core';

import { AspectRatioContainer } from 'shared/src/aspect-ratio-container/aspect-ratio-container';
import { FullCoverImage } from 'shared/src/image/image';

import coolGang from './images/cool-gang.jpg';
import jamesJudyBob from './images/james-judy-bob.jpg';

const useStyles = makeStyles(theme => ({
  image: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: theme.spacing(2),
    },
  },
}));

export const MiddleImagesSection = () => {
  const classes = useStyles();
  const isWide = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box marginBottom={{ xs: 8, sm: 0 }}>
      <Container maxWidth={isWide ? false : 'lg'} disableGutters={isWide}>
        <Grid container spacing={isWide ? 0 : 1}>
          <Grid item xs={12} sm={6}>
            <AspectRatioContainer ratio={500 / 800}>
              <FullCoverImage src={coolGang} alt="" className={classes.image} />
            </AspectRatioContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AspectRatioContainer ratio={500 / 800}>
              <FullCoverImage src={jamesJudyBob} alt="" className={classes.image} />
            </AspectRatioContainer>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
