import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import {
  GuideDirectoryConditionCategories,
  GuideDirectoryConditionCategoriesVariables,
} from './types/queries.contentful.generated';

const CONDITION_CATEGORIES = gql`
  query GuideDirectoryConditionCategories($preview: Boolean) {
    conditionCategoryCollection(preview: $preview, where: { condition_exists: true }) {
      items {
        sys {
          id
        }
        condition
        slug
      }
    }
  }
`;

export const useGuideDirectoryConditionCategories = contentfulQueryFactory<
  GuideDirectoryConditionCategories,
  GuideDirectoryConditionCategoriesVariables
>(CONDITION_CATEGORIES);
