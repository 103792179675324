import { FC } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { RichTextData, RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { FullWidthImage } from 'shared/src/image/image';

import clinicalTrialsHero from './clinical-trials-hero.png';
import clinicalTrialsHeroWebp from './clinical-trials-hero.webp';

type ClinicalTrialsProps = {
  conditionName: string;
  content?: RichTextData | null;
};

export const ClinicalTrialsInfo: FC<ClinicalTrialsProps> = ({ conditionName, content }) => (
  <Box paddingTop={4} paddingBottom={{ xs: 7, md: 10 }}>
    <Container>
      <Grid container>
        <Grid item xs={12} sm={6}>
          {content ? (
            <RichText document={content} />
          ) : (
            <>
              <Typography variant="h3" component="h1" gutterBottom>
                Clinical trials for {conditionName.toLocaleLowerCase()}
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                Clinical trials give you access to cutting-edge medications and treatments that have
                the potential to change lives - completely free of charge. Plus, you’ll get access
                to expert care from leading specialists who can help you take control of your
                health.
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                It’s simple to get started. Answer a few questions, and we’ll start searching for
                the right trial based on your profile. We’ll only match you to trials you’re
                eligible for, or we keep searching until we find the right one. Whenever new trials
                are created, we automatically check if you qualify. Once we’ve found the right
                match, you can apply, and we’ll connect you with the trial team directly once you’ve
                been accepted.
              </Typography>
              <Typography paragraph variant="body2" color="textSecondary">
                When you take part in a clinical trial, you contribute to research that may improve
                your quality of life and the lives of people with the same condition for generations
                to come.
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <picture>
            <source srcSet={clinicalTrialsHeroWebp} type="image/webp" />
            <source srcSet={clinicalTrialsHero} type="image/png" />
            <FullWidthImage src={clinicalTrialsHero} alt="" lazy={false} />
          </picture>
          <Box paddingY={3} />
        </Grid>
      </Grid>
    </Container>
  </Box>
);
