import { Locale, LocaleCode } from 'shared/src/types/graphql-schema';

export type SimpleLocale = Pick<Locale, 'code' | 'tag' | 'displayName'>;

export const SELECTABLE_LOCALES: SupportedLocale[] = ['en-US', 'es-US'];

export const localeCodeByCountryCode = (countryCode: CountryCode): LocaleCode => {
  switch (countryCode) {
    case 'AU':
      return LocaleCode.EN_AU;
    case 'HK':
      return LocaleCode.EN_HK;
    case 'NZ':
      return LocaleCode.EN_NZ;
    case 'US':
      return LocaleCode.EN_US;
    default:
      return LocaleCode.EN_US;
  }
};

export const getSupportedLocaleLanguageCode = (locale: SupportedLocale): string =>
  locale.split('-')[0].toLowerCase();

export const getSupportedLocaleCountryCode = (locale: SupportedLocale): CountryCode =>
  (locale.split('-')[1] ?? 'US') as CountryCode;

export const getLocaleLanguageCode = (locale: LocaleCode): string =>
  locale.split('_')[0].toLowerCase();

export const getLocalSupportedLocaleLanguage = (locale: SupportedLocale): string => {
  switch (getSupportedLocaleLanguageCode(locale)) {
    case 'en':
      return 'English';
    case 'es':
      return 'español';
    default:
      return 'English';
  }
};

export const getSupportedLocaleLanguage = (locale: SupportedLocale): string => {
  switch (getSupportedLocaleLanguageCode(locale)) {
    case 'en':
      return 'English';
    case 'es':
      return 'Spanish';
    default:
      return 'English';
  }
};

/**
 * Note these are locales supported by the frontend
 */
export const supportedLocales = [
  'en-AU',
  'en-HK',
  'en-NZ',
  'en-US',
  'en-GB',
  'es-US',
  'en',
] as const;

// Some TS magic to extract the possible types from the array https://github.com/microsoft/TypeScript/issues/28046#issuecomment-480516434
export type SupportedLocale = typeof supportedLocales[number];

export const isSupportedLocale = (locale: string): locale is SupportedLocale =>
  supportedLocales.includes(locale as never);

export const localeToGraphqlLocaleCode = (locale: SupportedLocale): LocaleCode => {
  switch (locale) {
    case 'en-AU':
      return LocaleCode.EN_AU;
    case 'en-HK':
      return LocaleCode.EN_HK;
    case 'en-NZ':
      return LocaleCode.EN_NZ;
    case 'en-US':
      return LocaleCode.EN_US;
    case 'es-US':
      return LocaleCode.ES_US;
    // TODO: Uncomment if/when we have a UK locale in the backend
    // case 'en-GB':
    //   return LocaleCode.EN_GB;
    default:
      return LocaleCode.EN_US;
  }
};

export const graphqlLocaleCodeToSupportedLocale = (locale: LocaleCode): SupportedLocale => {
  switch (locale) {
    case LocaleCode.EN_AU:
      return 'en-AU';
    case LocaleCode.EN_HK:
      return 'en-HK';
    case LocaleCode.EN_NZ:
      return 'en-NZ';
    case LocaleCode.EN_US:
      return 'en-US';
    case LocaleCode.ES_US:
      return 'es-US';
    case LocaleCode.EN:
      return 'en-US';
    default:
      return 'en-US';
  }
};

export const isPatientLanguageSupported = (
  patientLocale: SupportedLocale,
  supportedLocaleCodes: LocaleCode[]
) =>
  supportedLocaleCodes
    .map(el => getLocaleLanguageCode(el))
    .includes(getSupportedLocaleLanguageCode(patientLocale));

export const isSupportedLocaleLanguageEnglish = (locale: SupportedLocale) =>
  getSupportedLocaleLanguageCode(locale) === 'en';
