import { ApolloLink, HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
import { stripIgnoredCharacters } from 'graphql';
import { Agent as HttpAgent } from 'http';
import { Agent as HttpsAgent } from 'https';

import { CONTENTFUL_CLIENT_NAME } from './factory';
import { disablePreviewMiddleware, insertPreviewMiddleware } from './middleware';

type UrlConfig = {
  host: string;
  space: string;
  accessToken: string;
  environment: string;
};

export function createContentfulLink({
  enablePreview,
  main,
  config,
  fetchOptions,
}: {
  enablePreview: boolean;
  main: ApolloLink;
  config: UrlConfig;
  fetchOptions?: { agent: HttpAgent | HttpsAgent };
}): ApolloLink {
  const contentfulHttpLink = new HttpLink({
    fetch,
    // Stay under Contentful 8kb query limit; this roughly halves its size
    // https://www.apollographql.com/docs/react/api/link/apollo-link-http#print
    print: (ast, print) => stripIgnoredCharacters(print(ast)),
    uri: getContentfulApolloLink(config),
    ...fetchOptions,
  });

  const middleware = new ApolloLink(
    enablePreview ? insertPreviewMiddleware : disablePreviewMiddleware
  );

  return ApolloLink.split(
    o => o.getContext().clientName === CONTENTFUL_CLIENT_NAME,
    ApolloLink.from([middleware, contentfulHttpLink]),
    main
  );
}

function getContentfulApolloLink({ accessToken, environment, host, space }: UrlConfig): string {
  return `${host}/content/v1/spaces/${space}/environments/${environment}?access_token=${accessToken}`;
}
