import { FieldPolicy, FieldReadFunction, InMemoryCache } from '@apollo/client';
import possibleTypesContentful from 'contentful-gql/src/types/possible-types.json';

import possibleTypesKatch from 'shared/schemata/possible-types.json';

/**
 * Looks if there's a cached subtype with matching and returns a reference to it if found
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/#fieldpolicy-api-reference
 */
const readSubtypesOf =
  (subtypes: string[]): FieldReadFunction =>
  (existing, { args, canRead, toReference }) => {
    if (existing) {
      return existing;
    }

    for (const type of subtypes) {
      const ref = toReference({ __typename: type, id: args?.id });
      if (canRead(ref)) {
        return ref;
      }
    }

    return null;
  };

const possibleTypes = {
  ...possibleTypesKatch.possibleTypes,
  ...possibleTypesContentful.possibleTypes,
};

const contentfulQueryFieldPolicy: FieldPolicy = {
  // this generates a `keyFields` array like above for contentful queries specifically
  // apollo gets a bit confused since we inject `preview` at runtime so this strips it out and keeps the cache consistent
  keyArgs: record => Object.keys(record ?? {}).filter(key => key !== 'preview'),
};

// automatically generate contentfulQueryFieldPolicies for known types
const contentfulQueryFieldPolicies = Object.fromEntries(
  possibleTypesContentful.possibleTypes.Entry.map(entryName => {
    const queryName = entryName[0].toLowerCase() + entryName.slice(1);

    return [`${queryName}Collection`, contentfulQueryFieldPolicy];
  })
);

export const createCache = () =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Match: {
        keyFields: ['trial', ['id']],
      },
      Mutation: {
        fields: {},
      },
      Query: {
        fields: {
          // Take conditions for `condition` query from cache by id
          // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
          condition(_, { args, toReference }) {
            return toReference({
              __typename: 'Condition',
              id: args?.id,
            });
          },
          attributeDefinition: readSubtypesOf(possibleTypes.AttributeDefinition),
          ...contentfulQueryFieldPolicies,
        },
      },
      Patient: {
        fields: {
          attributeValues: readSubtypesOf(possibleTypes.AttributeValue),
        },
      },
      // this is a contentful field that we don't want to be a separate entry in the cache
      Sys: {
        keyFields: false,
      },
    },
  });
