import { memo } from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AspectRatioContainer } from 'shared/src/aspect-ratio-container/aspect-ratio-container';
import { AccentuatedContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';
import { Link } from 'shared/src/link/link';

import { KeywordsLine, MetaInfoLine } from 'components/article-info/article-info';

interface Props {
  featured?: boolean;
  mainImage?: AssetFragment;
  title: string;
  category?: string;
  date?: string;
  keywords?: readonly string[];
  url: string;
}

const useStyles = makeStyles(() => ({
  mainImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  detailsSection: {
    marginTop: 15,
  },
}));

const heroImageMaxWidth = 720;

export const ArticleCard = memo(
  ({ featured, mainImage, title, category, date, keywords, url }: Props) => {
    const classNames = useStyles();

    return (
      <div>
        {mainImage && (
          <Link to={url}>
            <Box maxWidth={heroImageMaxWidth}>
              <AspectRatioContainer ratio={1290 / 1790}>
                <AccentuatedContentfulImage
                  borderRadius="md"
                  image={mainImage}
                  maxWidth={heroImageMaxWidth}
                  className={classNames.mainImage}
                  containerClassName={classNames.mainImage}
                  lazy={!featured}
                />
              </AspectRatioContainer>
            </Box>
          </Link>
        )}
        <div className={classNames.detailsSection}>
          <MetaInfoLine category={category} date={date} />
          <Typography variant={featured ? 'h3' : 'h5'} gutterBottom>
            <Link to={url}>{title}</Link>
          </Typography>
          {keywords && <KeywordsLine keywords={keywords} />}
        </div>
      </div>
    );
  }
);
