/**
 * @param array an array of objects
 * @param keySelect generates a key for the given object in the return object
 * @param valueSelect select the value to populate the list
 * @returns an object containing the value selected by valueSelector keyed by keySelector
 */
export const collectBy = <T, OUT>(
  array: T[],
  keySelect: (input: T) => string | number,
  valueSelect: (input: T) => OUT | null
): Record<string | number, OUT[]> =>
  Object.fromEntries(
    array
      .reduce<Map<string | number, OUT[]>>((acc, input) => {
        const key = keySelect(input);
        const value = valueSelect(input);

        if (value === null) {
          return acc;
        }

        const current = acc.get(key) ?? [value];
        acc.set(key, current.includes(value) ? current : current.concat(value));

        return acc;
      }, new Map<string | number, OUT[]>())
      .entries()
  );
