import { Helmet } from 'react-helmet-async';

import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';

export const CanonicalUrl = ({ url }: { url: string }) => {
  // remove the trailing slash if one exists
  const sanitizedUrl = url.replace(/\/+$/, '');

  return (
    <Helmet>
      <link rel="canonical" href={sanitizedUrl} />
      <meta property="og:url" content={sanitizedUrl} />
    </Helmet>
  );
};

export const OgImage = ({
  image,
}: {
  image: Partial<Pick<AssetFragment, 'url' | 'contentType' | 'height' | 'width' | 'description'>>;
}) => (
  <Helmet>
    {image.url && <meta property="og:image" content={image.url} />}
    {image.contentType && <meta property="og:image:type" content={image.contentType} />}
    {image.height && <meta property="og:image:height" content={image.height.toString()} />}
    {image.width && <meta property="og:image:width" content={image.width.toString()} />}
    {image.description && <meta property="og:image:alt" content={image.description} />}
  </Helmet>
);

export const OgArticleType = ({
  published,
  modified,
  author,
}: {
  published?: string | null | undefined;
  modified?: string | null | undefined;
  author?: string | null | undefined;
}) => (
  <Helmet>
    <meta property="og:type" content="article" />
    {published && <meta property="og:article:published_time" content={published} />}
    {modified && <meta property="og:article:modified_time" content={modified} />}
    {author && <meta property="og:article:author" content={author} />}
  </Helmet>
);
