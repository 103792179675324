import { FC, ImgHTMLAttributes, useRef } from 'react';

import { makeStyles } from '@material-ui/core';

import { useStickyIntersection } from 'shared/src/use-near-viewport/use-sticky-intersection';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  fullCover: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

// img props, with a mandatory alt prop, since we require that anyway for accessibility
type Props = ImgHTMLAttributes<HTMLImageElement> & {
  alt: NonNullable<ImgHTMLAttributes<HTMLImageElement>['alt']>;
  // set to false if you want to force eager loading of the image (for images above-the-fold)
  lazy?: boolean;
};

// A simple helper for making an image fit into its container
export const FullWidthImage = ({ className, alt, lazy, ...props }: Props) => {
  const classes = useStyles();
  const cName = className ? `${classes.fullWidth} ${className}` : classes.fullWidth;
  return <LazyImage alt={alt} className={cName} forceLoad={!lazy} {...props} />;
};

// An image the completely covers its containing element
export const FullCoverImage = ({ className, alt, ...props }: Props) => {
  const classes = useStyles();
  const cName = className ? `${classes.fullCover} ${className}` : classes.fullCover;
  return <LazyImage alt={alt} className={cName} {...props} />;
};

// an image that lazy loads when near the viewport
export const LazyImage: FC<Omit<Props, 'ref'> & { forceLoad?: boolean }> = ({
  forceLoad,
  ...props
}) => {
  const intersectionRef = useRef(null);
  const intersection = useStickyIntersection(intersectionRef, {
    rootMargin: '500px',
  });

  return (
    <img
      ref={intersectionRef}
      {...props}
      src={intersection?.hasIntersected || forceLoad ? props.src : undefined}
      alt={props.alt}
    />
  );
};
