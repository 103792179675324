import { gql } from '@apollo/client';

import { SHARED_LOCATION_FRAGMENT } from 'shared/src/fragments/locations/queries';

export const MATS_LOCATION_FRAGMENT = gql`
  fragment MatsLocation on Location {
    ...SharedLocation
    contactEmail
    contactPhoneNumber
  }

  ${SHARED_LOCATION_FRAGMENT}
`;
