import { FC } from 'react';

import {
  DropdownContentDrawerButton,
  DropdownContentToolbarButton,
} from 'components/header-nav/components/dropdown-drawer-button/dropdown-content-button';

import { Sponsors } from './sponsors';

export const SponsorsDrawerButton: FC<{ onNavigation: () => void }> = ({ onNavigation }) => (
  <DropdownContentDrawerButton title="For sponsors" onNavigation={onNavigation}>
    <Sponsors showGraphic={false} />
  </DropdownContentDrawerButton>
);

export const SponsorsToolbarButton: FC = () => (
  <DropdownContentToolbarButton title="For sponsors">
    <Sponsors showGraphic />
  </DropdownContentToolbarButton>
);
