import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ContentfulDate } from 'shared/src/contentful-text/components/date';

const useStyles = makeStyles({
  metaLine: {
    fontWeight: 700,
    fontSize: 14,
  },
  keywordsLine: {
    fontSize: 14,
  },
});

const MetaTypography: FC = ({ children }) => {
  const classNames = useStyles();
  return (
    <Typography className={classNames.metaLine} gutterBottom>
      {children}
    </Typography>
  );
};

interface MetaInfoLineProps {
  category?: string;
  date?: string;
}

export const MetaInfoLine = ({ category, date }: MetaInfoLineProps) => {
  if (category && date) {
    // Add a divider bar if both are present
    return (
      <MetaTypography>
        {category} | <ContentfulDate date={date} />
      </MetaTypography>
    );
  }
  if (category || date) {
    return (
      <MetaTypography>
        {category}
        {date && <ContentfulDate date={date} />}
      </MetaTypography>
    );
  }

  return null;
};

interface KeywordsLineProps {
  keywords: readonly string[];
  gutterBottom?: boolean;
}

export const KeywordsLine = ({ keywords, gutterBottom }: KeywordsLineProps) => {
  const classNames = useStyles();

  return (
    <Typography className={classNames.keywordsLine} gutterBottom={!!gutterBottom}>
      {keywords.join(', ')}
    </Typography>
  );
};
