import { FC } from 'react';

import { makeStyles, Theme } from '@material-ui/core';

type Props = { ratio: number };

const useStyles = makeStyles<Theme, Props>(() => ({
  aspectContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: props => `${props.ratio * 100}%`,
  },
  aspectContainerInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}));

export const AspectRatioContainer: FC<Props> = ({ children, ...props }) => {
  const classNames = useStyles(props);

  return (
    <div className={classNames.aspectContainer}>
      <div className={classNames.aspectContainerInner}>{children}</div>
    </div>
  );
};
