import { FC } from 'react';

import { Box, Link as MuiLink, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-scroll';

import { gray, purple } from 'shared/src/palette/color-system';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

type DirectoryLegendProps = {
  lettersWithArticles: string[];
  title: string;
};

const useStyles = makeStyles(theme => ({
  disabledLink: {
    textAlign: 'center',
    color: gray[400],
  },
  link: {
    textAlign: 'center',
    position: 'relative',
    // Use absolute-positioned block for active item underline
    // to avoid jumps when changing active menu item
    '&:after': {
      display: 'block',
      position: 'absolute',
      left: -2,
      right: -4,
      height: 4,
      bottom: -6,
      backgroundColor: purple[500],
      borderRadius: 4,
    },

    // typography hover is pretty specific so we have to override that
    '&&:hover': {
      color: purple[500],
    },
  },
  linkActive: {
    fontWeight: theme.typography.fontWeightBold,
    color: purple[500],
    '&:after': {
      content: '""',
    },
  },
}));

export const DirectoryLegend: FC<DirectoryLegendProps> = ({ lettersWithArticles, title }) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <Box position="sticky" top={theme.spacing(10)} paddingBottom={2}>
      <Box paddingBottom={4}>
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(8, ${theme.spacing(1.5)}px)`}
        gridGap={theme.spacing(3)}
      >
        {alphabet.map(letter => {
          if (lettersWithArticles.find(l => l === letter)) {
            return (
              <MuiLink
                underline="none"
                color="textSecondary"
                className={classes.link}
                activeClass={classes.linkActive}
                component={Link}
                href={`#${letter}`}
                to={letter}
                key={`guide-legend-${letter}`}
                spy // Update active menu element on scroll
                hashSpy // Update hash on scroll
                smooth // Animated scroll when clicking on menu item
              >
                {letter}
              </MuiLink>
            );
          }
          return (
            <Typography
              variant="body2"
              className={classes.disabledLink}
              key={`guide-legend-${letter}`}
            >
              {letter}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};
