import { gql } from '@apollo/client';

import { queryFactory } from 'shared/src/util/query-factory';

import { TRIAL_PUBLIC_FRAGMENT } from 'mats/src/fragments/trial/trial-public-record-fragment';

import {
  PublicTrialIdsForCondition,
  PublicTrialIdsForConditionVariables,
  PublicTrialsForCondition,
  PublicTrialsForConditionVariables,
} from './types/queries.generated';

const PUBLIC_TRIALS_FOR_CONDITION = gql`
  query PublicTrialsForCondition($conditionId: ConditionID!) {
    searchTrials(filter: { conditionId: { equals: $conditionId } }) {
      ...TrialPublicRecord
    }
  }
  ${TRIAL_PUBLIC_FRAGMENT}
`;

const PUBLIC_TRIAL_IDS_FOR_CONDITION = gql`
  query PublicTrialIdsForCondition($conditionId: ConditionID!) {
    searchTrials(filter: { conditionId: { equals: $conditionId } }) {
      id
    }
  }
`;

export const usePublicTrialsForCondition = queryFactory<
  PublicTrialsForCondition,
  PublicTrialsForConditionVariables
>(PUBLIC_TRIALS_FOR_CONDITION);

export const usePublicTrialIdsForCondition = queryFactory<
  PublicTrialIdsForCondition,
  PublicTrialIdsForConditionVariables
>(PUBLIC_TRIAL_IDS_FOR_CONDITION);
