import { FC } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Link } from 'react-router-dom';

import { HealthMatchIcon } from 'shared/src/icon/icons';
import { darkPurple, green, lightPurple, purple } from 'shared/src/palette/color-system';

import { Logo } from 'components/header-nav/components/logo/logo';
import { PageBase } from 'components/page-base/page-base';
import { DeiStatsCarousel } from 'pages/dei-report/components/dei-stats-carousel';
import { PatientCarousel } from 'pages/dei-report/components/patient-callout';

import downArrow from './down-arrow.svg';
import feasoScreenshot from './feaso.png';
import heroBg from './hero-bg.svg';
import heroImageMobile from './hero-image-mobile.png';
import heroImage from './hero-image.png';
import mapImage from './map.svg';
import reportCover from './report-cover.png';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  sectionHeading: {
    marginTop: spacing(6),
    marginBottom: spacing(3),
    fontSize: '32px',
    fontFamily: 'Lato, sans-serif',
    [breakpoints.up('sm')]: {
      fontSize: '40px',
      marginTop: spacing(12),
      marginBottom: spacing(4),
    },
  },
  callout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h3': {
      color: purple[500],
    },
    [breakpoints.up('sm')]: {
      height: '100%',
      backgroundColor: lightPurple[100],
      padding: spacing(2),
    },
  },
  secondary: {
    // secondary colors for title if on mobile
    // done this way since the color is different on mobile and web
    [breakpoints.down('xs')]: {
      '& h3': {
        color: green[500],
      },
    },
  },
  icon: {
    color: lightPurple[200],
  },
  leftAlign: {
    display: 'flex',
    [breakpoints.up('md')]: {
      width: '50%',
    },
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    // Used to push the "discover" arrow to the bottom
    justifyContent: 'space-between',
    height: 700,
    paddingTop: 64,
    paddingBottom: 16,
    backgroundImage: `url(${heroBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    marginBottom: 30,
    [breakpoints.down('sm')]: {
      height: 500,
      marginBottom: 0,
    },
  },
  heroLogoContainer: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  heroTitle: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 75,
    fontWeight: 400,
    marginTop: 24,
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 45,
    },
  },
  heroSubtitleContainer: {
    paddingRight: 200,
    [breakpoints.down('sm')]: {
      // When small, need to add padding on both sides because of the curved circle
      paddingRight: 64,
      paddingLeft: 64,
    },
  },
  heroSubtitle: {
    marginTop: 24,
    fontSize: 30,
    fontWeight: 400,
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 20,
    },
  },
  heroDiscoverText: {
    fontSize: 15,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      textTransform: 'uppercase',
      fontWeight: 700,
    },
  },
  heroLayout: {
    display: 'flex',
    padding: '0 64px',
    [breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  heroTitleText: {
    flex: 1,
  },
  heroImageCluster: {
    flexBasis: '30%',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileImageCluster: {
    display: 'none',
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  purple: {
    color: purple[500],
  },
}));

const FOOTER_BLEED = { kind: 'background', color: lightPurple[500] } as const;

const DownloadButton: FC<{ text: string }> = ({ text }) => {
  const styles = useStyles();
  return (
    <Box pt={4} textAlign="center">
      <Button
        color="primary"
        variant="contained"
        endIcon={<HealthMatchIcon className={styles.icon} />}
        component={Link}
        to="/dei-report/download"
      >
        {text}
      </Button>
    </Box>
  );
};

const Callout: FC<{ title: string; secondary?: boolean }> = ({ title, children, secondary }) => {
  const styles = useStyles();
  return (
    <Box className={`${styles.callout} ${secondary && styles.secondary}`}>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <HealthMatchIcon color={secondary ? 'secondary' : 'primary'} style={{ fontSize: '32px' }} />
      </Box>
      <Typography variant="h4" component="h3" style={{ fontSize: '32px' }}>
        {title}
      </Typography>
      <Box pt={2}>{children}</Box>
    </Box>
  );
};

const Paragraph: FC<TypographyProps> = ({ ...props }) => (
  <Typography color="textSecondary" variant="body2" paragraph {...props} />
);

type Props = {
  color: 'light' | 'dark';
};
const SectionHeading: FC<Props> = ({ ...props }) => {
  const styles = useStyles();
  return (
    <Typography
      component="h2"
      variant="h2"
      className={styles.sectionHeading}
      style={{
        color: props.color === 'light' ? purple[500] : darkPurple[500],
      }}
    >
      {props.children}
    </Typography>
  );
};

export const DeiReport: FC = () => {
  di(PageBase);

  const styles = useStyles();

  return (
    <PageBase windowTitle="Quantifying DEI in clinical trials" footerBleed={FOOTER_BLEED}>
      <header className={styles.heroContainer}>
        <Container maxWidth="md">
          <div className={styles.heroLayout}>
            <Box flex={1}>
              <div className={styles.heroLogoContainer}>
                <Logo />
              </div>
              <Typography component="h1" variant="h1" color="primary" className={styles.heroTitle}>
                Quantifying DEI in clinical trials
              </Typography>
              <Box className={styles.heroSubtitleContainer}>
                <Paragraph color="primary" className={styles.heroSubtitle}>
                  Understanding real-world patients and their needs.
                </Paragraph>
              </Box>
            </Box>
            <div className={styles.heroImageCluster}>
              <img alt="" src={heroImage} loading="lazy" width="100%" />
            </div>
          </div>
        </Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box width={180}>
            <Paragraph color="primary" className={styles.heroDiscoverText}>
              Discover the future of clinical trials
            </Paragraph>
          </Box>
          <Box>
            <img alt="" src={downArrow} width={25} />
          </Box>
        </Box>
      </header>
      <Box display={{ xs: 'block', sm: 'none' }} paddingBottom={5}>
        <DownloadButton text="Download the report" />
      </Box>
      <Container maxWidth="md">
        <main>
          <Box marginTop={{ xs: 4, sm: 8 }} marginBottom={4}>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <Paragraph>
                  HealthMatch was founded to democratize access to clinical trials. There are widely
                  publicized challenges to achieving just representation of our society and this has
                  been reflected through decades of clinical trial participation statistics.
                </Paragraph>
                <Paragraph>
                  We have embarked on a mission to collect the data, share the drivers and champion
                  the voice of interested trial participants so that as an industry we are better
                  positioned to drive positive change from actionable insights.
                </Paragraph>
              </Box>
              <Box paddingLeft={4} className={styles.mobileImageCluster}>
                <img width={130} alt="" loading="lazy" src={heroImageMobile} />
              </Box>
            </Box>
          </Box>

          <Box marginTop={4}>
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <Callout title="7,903 responses from HealthMatch patients...">
                  <Paragraph>
                    We have undertaken one of the largest clinical trial patient engagement surveys
                    ever undertaken.
                  </Paragraph>
                  <Paragraph>
                    We&apos;ve heard from underrepresented patients across all race/ethnicity
                    segments, including Black/African American, Hispanic, Asian, American
                    Indian/Alaska Native, Native Hawaiian/Pacific Islander and White.
                  </Paragraph>
                </Callout>
              </Grid>
              <Grid item sm={6}>
                <Callout title="87% of responses interested in further exploration..." secondary>
                  <Paragraph>
                    We have clear avenues for further research and engagement to build a holistic
                    understanding of systemic issues by continuing to explore
                  </Paragraph>
                  <Typography color="textSecondary" variant="body2">
                    <ul>
                      <li>Interactions with the health system</li>
                      <li>Expectations from clinical trials</li>
                      <li>Drivers and disruptors of engagement</li>
                    </ul>
                  </Typography>
                </Callout>
              </Grid>
            </Grid>
          </Box>

          <Box display={{ xs: 'none', sm: 'block' }}>
            <DownloadButton text="Download your copy of the report" />
          </Box>
          <SectionHeading color="light">A deep dive into patient experiences</SectionHeading>

          <DeiStatsCarousel />

          <Paragraph>
            HealthMatch is uniquely positioned to accomplish this with a unique view of the
            industry; and direct connections with patients that are ready and willing to engage with
            clinical trials if given the opportunity.
          </Paragraph>

          <Paragraph>
            HealthMatch is a clinical trial recruitment platform with over 1.1 million patients. We
            use data-rich profiles of each patient to pre-screen and connect them with clinical
            trials to in real-time.
          </Paragraph>

          <SectionHeading color="dark">We know our patients</SectionHeading>

          <Box className={styles.leftAlign}>
            <Paragraph>
              The broad footprint of our patients and responses across the United States gives us
              confidence in the robustness and utility of our data. Our team talks with patients
              every day to hear their stories so that we can advocate on their behalf.
            </Paragraph>
          </Box>

          <Box marginTop={2} marginBottom={8}>
            <img
              alt="Map of USA showing relative concentration of HealthMatch patients"
              src={mapImage}
            />
          </Box>

          <PatientCarousel />

          <SectionHeading color="light">Let&apos;s shape the future of DEI together</SectionHeading>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" component="h4">
                  Shape the future of clinical trials by understanding real patients and their
                  needs.
                </Typography>

                <Box marginTop={2}>
                  <img
                    alt="Report cover"
                    src={reportCover}
                    height="176px"
                    style={{ border: '0.5px solid grey' }}
                  />
                </Box>

                <Box marginTop={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/dei-report/download"
                  >
                    <Box textAlign="center">
                      Download our Quantifying Diversity, Equity and Inclusion in Clinical Trials
                      Report
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" component="h4">
                  Discover how we can help you design inclusive clinical trials with our Trial Site
                  Selector.
                </Typography>

                <Box marginTop={2}>
                  <img
                    alt="Screenshot of the HealthMatch Trial Site Selector"
                    src={feasoScreenshot}
                    height="176px"
                  />
                </Box>

                <Box marginTop={4} width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/dei-report/request-demo"
                    fullWidth
                  >
                    Request a demonstration today
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box marginTop={4} marginBottom={6}>
            <Typography color="primary" paragraph>
              Curious? Let&apos;s talk!{' '}
              <a href="mailto:diversity@healthmatch.io" className={styles.purple}>
                diversity@healthmatch.io
              </a>
            </Typography>
          </Box>
        </main>
      </Container>
    </PageBase>
  );
};
