import { Children, FC, useState } from 'react';

import {
  Box,
  Container,
  Fab,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';

import { nantesFontFamily } from 'shared/src/fonts/fonts';
import { ArrowLeftIcon, ArrowRightIcon } from 'shared/src/icon/icons';

import { mod } from './mod';

type CarouselMultipleProps = SwipeableViewsProps & {
  title?: string;
  row?: number;
};

const useStyles = makeStyles(theme => ({
  swipeableViews: {
    // Align with Container padding on the left, show next item on the right
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 3, 0, 2),
    },
    // Simulate default Container behavior
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.up('sm')]: {
      // Center align items
      margin: 'auto',
      // Hide next chunk from the screen
      borderWidth: theme.spacing(0, 3),
      borderStyle: 'solid',
      borderColor: 'transparent',
    },
  },
  title: {
    fontFamily: nantesFontFamily,
  },
}));

export const CarouselMultiple: FC<CarouselMultipleProps> = ({
  children,
  title,
  row = 3,
  ref,
  ...props
}) => {
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  const isCompact = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const elements = Children.toArray(children);
  const length = Math.ceil(elements.length / row);
  const hideArrows = elements.length <= row;

  const renderChunks = () => {
    const remainder = length * row - elements.length;
    // Pad the array from the right to prevent elements in the last chunk
    // stretching and taking the whole width if there's too few of them
    const padded =
      remainder > 0 ? elements.concat(Array.from({ length: remainder }, () => null)) : elements;
    const chunks = Array.from({ length }, (_, i) => padded.slice(i * row, (i + 1) * row));

    return chunks.map((chunk, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Box display="flex" key={i} justifyContent="space-between">
        {chunk.map((child, j) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box width="100%" key={j} marginLeft={j === 0 ? 0 : 2}>
            {child}
          </Box>
        ))}
      </Box>
    ));
  };

  const showArrows = !isCompact && !hideArrows;

  return (
    <>
      {(title || showArrows) && (
        <Container>
          <Box display="flex" justifyContent="space-between" marginBottom={4}>
            {title && (
              <Box flexGrow={1}>
                <Typography variant="h4" className={classes.title}>
                  {title}
                </Typography>
              </Box>
            )}
            {showArrows && (
              <>
                <Fab
                  color="secondary"
                  aria-label="previous"
                  size="small"
                  onClick={() => setIndex(i => mod(i - 1, length))}
                >
                  <ArrowLeftIcon fontSize="small" />
                </Fab>
                <Box marginLeft={4}>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="next"
                    onClick={() => setIndex(i => mod(i + 1, length))}
                  >
                    <ArrowRightIcon fontSize="small" />
                  </Fab>
                </Box>
              </>
            )}
          </Box>
        </Container>
      )}
      <SwipeableViews
        className={classes.swipeableViews}
        index={index}
        onChangeIndex={setIndex}
        {...props}
      >
        {isCompact
          ? Children.map(children, (child, i) => (
              <Box marginLeft={i === 0 ? 0 : 1} marginRight={1}>
                {child}
              </Box>
            ))
          : renderChunks()}
      </SwipeableViews>
    </>
  );
};
