import LatoBoldWoff from './lato/LatoLatin-Bold.woff';
import LatoBoldWoff2 from './lato/LatoLatin-Bold.woff2';
import LatoBoldItalicWoff from './lato/LatoLatin-BoldItalic.woff';
import LatoBoldItalicWoff2 from './lato/LatoLatin-BoldItalic.woff2';
import LatoItalicWoff from './lato/LatoLatin-Italic.woff';
import LatoItalicWoff2 from './lato/LatoLatin-Italic.woff2';
import LatoLightWoff from './lato/LatoLatin-Light.woff';
import LatoLightWoff2 from './lato/LatoLatin-Light.woff2';
import LatoLightItalicWoff from './lato/LatoLatin-LightItalic.woff';
import LatoLightItalicWoff2 from './lato/LatoLatin-LightItalic.woff2';
import LatoWoff from './lato/LatoLatin-Regular.woff';
import LatoWoff2 from './lato/LatoLatin-Regular.woff2';
import NantesBoldWoff from './nantes/NantesWeb-Bold.woff';
import NantesBoldWoff2 from './nantes/NantesWeb-Bold.woff2';
import NantesBoldItalicWoff from './nantes/NantesWeb-BoldItalic.woff';
import NantesBoldItalicWoff2 from './nantes/NantesWeb-BoldItalic.woff2';

export const fontFiles = {
  LatoBoldWoff,
  LatoBoldWoff2,
  LatoBoldItalicWoff,
  LatoBoldItalicWoff2,
  LatoItalicWoff,
  LatoItalicWoff2,
  LatoLightWoff,
  LatoLightWoff2,
  LatoLightItalicWoff,
  LatoLightItalicWoff2,
  LatoWoff,
  LatoWoff2,
  NantesBoldWoff,
  NantesBoldWoff2,
  NantesBoldItalicWoff,
  NantesBoldItalicWoff2,
};
export type FontFiles = typeof fontFiles;
