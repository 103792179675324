type CrawlablePageConfig = {
  exact: boolean;
  path: `/${string}`;
  key: string;
};

function createConfig({
  path,
  exact = true,
}: {
  path: `/${string}`;
  exact?: boolean;
}): CrawlablePageConfig {
  return { exact, path, key: path };
}

type WebflowPageConfig = {
  exact: boolean;
  webflowPath: `/${string}`;
  path: `/${string}`;
  key: string;
  variant: WebflowPageVariant;
  title?: string;
};

export enum WebflowPageVariant {
  nav,
  contentOnly,
}

function createWebflowConfig({
  webflowPath,
  path,
  exact = true,
  variant = WebflowPageVariant.nav,
  title,
}: {
  webflowPath: `/${string}`;
  path: `/${string}`;
  exact?: boolean;
  variant?: WebflowPageVariant;
  title?: string;
}): WebflowPageConfig {
  return { exact, webflowPath, path, key: webflowPath, variant, title };
}

// eslint-disable-next-line import/no-unused-modules
export const webflowPages = {
  HomePage: createWebflowConfig({
    webflowPath: '/',
    path: '/',
  }),
  EditorialGuidelinesPage: createWebflowConfig({
    webflowPath: '/editorial-guidelines',
    path: '/editorial-guidelines',
  }),
  SponsorsDepressionsStatsPage: createWebflowConfig({
    webflowPath: '/sponsors/depression-recruitment-insights',
    path: '/sponsors/depression-recruitment-insights',
    variant: WebflowPageVariant.contentOnly,
    // might be nice if we can get this from webflow instead?
    title: 'Depression patient statistics | HealthMatch',
  }),
  SponsorsPage: createWebflowConfig({
    webflowPath: '/for-sponsors-overview',
    path: '/sponsors',
  }),
  SponsorsScope: createWebflowConfig({
    webflowPath: '/scope-summit-2024',
    path: '/scope-summit-2024',
  }),
  SponsorsPricing: createWebflowConfig({
    webflowPath: '/sponsor-pricing',
    path: '/sponsors/pricing',
  }),
  SponsorsPatientInsights: createWebflowConfig({
    webflowPath: '/patient-insights',
    path: '/sponsors/patient-insights',
  }),
  SponsorsRecruitment: createWebflowConfig({
    webflowPath: '/recruitment',
    path: '/sponsors/recruitment',
  }),
  SitesOverview: createWebflowConfig({
    webflowPath: '/for-sites-overview',
    path: '/sites',
  }),
  SitesPricing: createWebflowConfig({
    webflowPath: '/sponsor-pricing',
    path: '/sites/pricing',
  }),
  About: createWebflowConfig({
    webflowPath: '/about',
    path: '/about',
  }),
};

export const crawlablePages = {
  ...webflowPages,
  AboutPage: createConfig({ path: '/about' }),
  BlogIndexPage: createConfig({ path: '/blog' }),
  GuidesDirectoryRoute: createConfig({ path: '/guides' }),
  MedicationsDirectoryRoute: createConfig({ path: '/medications' }),
  PatientsPage: createConfig({ path: '/patients' }),
  DeiReport: createConfig({ path: '/dei-report' }),
  PrivacyPolicy: createConfig({ path: '/privacy' }),
  TermsOfUse: createConfig({ path: '/terms' }),
  WhyClinicalTrials: createConfig({ path: '/why-clinical-trials' }),
};
