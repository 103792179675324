import { NextLink, Operation } from '@apollo/client';

export function disablePreviewMiddleware(operation: Operation, next: NextLink) {
  Object.assign(operation.variables, { preview: false });
  return next(operation);
}

export function insertPreviewMiddleware(operation: Operation, next: NextLink) {
  if (import.meta.env.MODE === 'development') {
    if (!containsPreviewParamInQuery(operation)) {
      throw new Error('all gql queries needs to have a `preview` param');
    }

    if (!containsPreviewArgsInAllOperations(operation)) {
      throw new Error('all queries need to pass their preview param to their operations');
    }
  }

  Object.assign(operation.variables, { preview: true });
  return next(operation);
}

/**
 * Ensures that the query has a preview argument
 *
 * ```
 * // will pass
 * query A($preview: Boolean) {}
 *
 * // will fail
 * query A {}
 *
 * // will fail
 * query A($preview: Int) {}
 * ```
 */
function containsPreviewParamInQuery({ query: { definitions } }: Operation): boolean {
  return definitions.every(def => {
    if (def.kind !== 'OperationDefinition') {
      return true;
    }
    if (def.variableDefinitions == null) {
      return false;
    }

    return def.variableDefinitions.some(vardef => {
      const type = vardef.type.kind === 'NonNullType' ? vardef.type.type : vardef.type;

      if (type.kind !== 'NamedType') {
        return false;
      }

      return vardef.variable.name.value === 'preview' && type.name.value === 'Boolean';
    });
  });
}

/**
 * Ensures that preview is wired to all operations like
 *
 * ```
 * query Name($preview: Boolean) {
 *   // will pass
 *   operationA(preview: $preview) {
 *
 *   }
 *
 *   // will fail
 *   operationB {
 *
 *   }
 * }
 * ```
 */
function containsPreviewArgsInAllOperations({ query: { definitions } }: Operation): boolean {
  return definitions.every(def => {
    if (def.kind !== 'OperationDefinition') {
      return true;
    }

    return def.selectionSet.selections.every(sel => {
      if (sel.kind !== 'Field') {
        return true;
      }

      if (sel.arguments == null) {
        return false;
      }

      return sel.arguments.some(arg => {
        if (arg.value.kind !== 'Variable') {
          return false;
        }

        return arg.name.value === 'preview' && arg.value.name.value === 'preview';
      });
    });
  });
}
