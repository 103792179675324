import { FC } from 'react';

import { Box, makeStyles, Theme, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';

import { useAnalytics } from 'shared/src/analytics/analytics';
import healthmatchLogo from 'shared/src/assets/healthmatch-logo-primary.svg';
import { Link } from 'shared/src/link/link';

type LogoProps = {
  responsive?: boolean;
};

/*
 * Multiple an arbitrary width by this to determine
 * the appropiate height relative to the aspect ratio.
 *
 * The reason this works is it's an example of one of
 * the possible heights divided by the relative width.
 */
export const ASPECT_RATIO = 33 / 200;

function useLogoDimension({ responsive }: { responsive?: boolean }): {
  width?: number;
  height?: number;
} {
  di(useMediaQuery);
  const { spacing } = useTheme();
  const isXsDown = useMediaQuery((t: Theme) => t.breakpoints.down('xs'));

  const width = spacing(responsive && isXsDown ? 16 : 25);
  return { width, height: width * ASPECT_RATIO };
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  logo: {
    width: spacing(25),
    height: spacing(25) * ASPECT_RATIO,
  },
  responsiveLogo: {
    width: spacing(25),
    height: spacing(25) * ASPECT_RATIO,
    [breakpoints.down('xs')]: {
      width: spacing(16),
      height: spacing(16) * ASPECT_RATIO,
    },
  },
}));

export const Logo: FC<LogoProps> = ({ responsive }) => {
  di(Link, useLocation, useAnalytics, useMediaQuery);

  const styles = useStyles();
  const className = responsive ? styles.responsiveLogo : styles.logo;
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const { width, height } = useLogoDimension({ responsive });

  const analytics = useAnalytics();
  const onLinkClickAnalytics =
    pathSegments[1] === 'questionnaire' && !!pathSegments[3] && !!pathSegments[5]
      ? () =>
          analytics.track({
            event: 'hmio.questionnaire.clicked',
            conditionId: +pathSegments[3],
            attributeId: +pathSegments[5],
            questionType: 'unknown',
            clickFrom: 'question',
            action: 'logo',
          })
      : () => {};

  // keep existing functionality while ff is off

  return (
    <Link to="/" onClick={onLinkClickAnalytics}>
      {/* Pixel perfect spacing by YC */}
      <Box marginTop={{ xs: 0.875, md: 0.375 }}>
        <img
          width={width}
          height={height}
          alt="HealthMatch logo"
          src={healthmatchLogo}
          className={className}
        />
      </Box>
    </Link>
  );
};
