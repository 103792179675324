import { createContext } from 'react';

import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';
import { EmbeddedEntryFragment } from 'shared/src/fragments/contentful/types/embedded-entry-fragment.contentful.generated';
import { SluggableEntryFragment } from 'shared/src/fragments/contentful/types/sluggable-entry-fragment.contentful.generated';

export const ContentfulLinkContext = createContext<{
  assets: Map<string, AssetFragment>;
  entries: Map<string, EmbeddedEntryFragment>;
  links: Map<string, SluggableEntryFragment>;
}>({
  assets: new Map(),
  entries: new Map(),
  links: new Map(),
});
