import { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { vividPurple } from 'shared/src/palette/color-system';

const useStyles = makeStyles(theme => ({
  citationRef: {
    color: vividPurple[500],
    margin: theme.spacing(0.25),
  },
}));

export const Citation: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <sup className={classes.citationRef}>
      <b>{children}</b>
    </sup>
  );
};
