import { memo, ReactElement } from 'react';

import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { Typography } from '@material-ui/core';

import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';
import { EmbeddedEntryFragment } from 'shared/src/fragments/contentful/types/embedded-entry-fragment.contentful.generated';
import { SluggableEntryFragment } from 'shared/src/fragments/contentful/types/sluggable-entry-fragment.contentful.generated';
import { ExternalLink, Link } from 'shared/src/link/link';
import { Quote } from 'shared/src/typography/quote';
import { notEmpty } from 'shared/src/util/not-empty';

import { BlockEntryLink } from './components/block-entry-link';
import { ContentfulLinkContext } from './components/context';
import { InlineEntryLink } from './components/inline-entry-link';
import { MediaBlock } from './components/media';

export function createRenderOptions(overrides?: Partial<Options['renderNode']>): Options {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => <MediaBlock id={node.data.target.sys.id} />,
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography gutterBottom variant="h1">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography gutterBottom variant="h2">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography gutterBottom variant="h3">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography gutterBottom variant="h4">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography gutterBottom variant="h5">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography gutterBottom variant="h6">
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography variant="body2" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: node => <BlockEntryLink id={node.data.target.sys.id} />,
      [INLINES.HYPERLINK]: (node, children) =>
        node.data.uri.startsWith('/') ? (
          <Link to={node.data.uri}>{children}</Link>
        ) : (
          <ExternalLink eventLabel="Contentful-link" to={node.data.uri} target="_blank">
            {children}
          </ExternalLink>
        ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <InlineEntryLink id={node.data.target.sys.id}>{children}</InlineEntryLink>
      ),
      // Don't render *inline* embeddable entries, until we explicitly support them
      [INLINES.EMBEDDED_ENTRY]: () => null,
      // Don't render direct *links* to assets, until we explicitly support them
      [INLINES.ASSET_HYPERLINK]: () => null,
      ...overrides,
    },
  };
}

const defaultRenderOptions = createRenderOptions();

export type RichTextData = {
  json: Document;
  links: {
    entries: {
      block: Array<EmbeddedEntryFragment | null>;
      hyperlink: Array<SluggableEntryFragment | null>;
    };
    assets: {
      block: Array<AssetFragment | null>;
    };
  };
};

export type RichTextProps = {
  document: RichTextData;
  renderOptions?: Options;
};

function getMap<T extends AssetFragment | SluggableEntryFragment | EmbeddedEntryFragment>(
  links: (T | null)[]
): Map<string, T> {
  return new Map(links.filter(notEmpty).map(link => [link.sys.id, link]));
}

export const RichText = memo(
  ({ document, renderOptions = defaultRenderOptions }: RichTextProps): ReactElement => {
    const assets = getMap(document.links.assets.block);
    const links = getMap(document.links.entries.hyperlink);
    const entries = getMap(document.links.entries.block);

    return (
      // It's fine because we memoize the whole component
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <ContentfulLinkContext.Provider value={{ assets, entries, links }}>
        <div>{documentToReactComponents(document.json, renderOptions)}</div>
      </ContentfulLinkContext.Provider>
    );
  }
);
