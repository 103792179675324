import { FC, useState } from 'react';

import { Box, Grid, IconButton, Theme, useMediaQuery } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { AutoPlayCarousel } from 'shared/src/carousel/carousel';
import { fontWeightBold } from 'shared/src/fonts/fonts';
import { darkPurple, green, lightGreen, orange, purple } from 'shared/src/palette/color-system';

const STAT_BOX_WIDTH = 304;
const STAT_BOX_SPACING = 16;
const STAT_BOX_BUTTON_WIDTH = 38; // a compact mui icon button is 30px wide, plus 8px for the margin that we add.

const StatsBox: FC<{ backgroundColor: string; color?: string }> = ({
  children,
  backgroundColor,
  color,
}) => (
  <Grid item>
    <Box
      height={288}
      width={{ xs: '100%', sm: STAT_BOX_WIDTH }}
      bgcolor={backgroundColor}
      color={color ?? 'white'}
      display="flex"
      alignItems="center"
      paddingX={{ xs: 1, sm: 3 }}
      paddingY={{ xs: 1, sm: 2 }}
      lineHeight={1.2}
      fontSize="12px"
    >
      <p>{children}</p>
    </Box>
  </Grid>
);

const StatLineBold: FC = ({ children }) => (
  <Box fontWeight={fontWeightBold} lineHeight={2} component="span">
    {children}
    <br />
  </Box>
);

const StatLine: FC = ({ children }) => (
  <span>
    {children}
    <br />
  </span>
);

const Stat: FC<{ num: string; unit?: string }> = ({ num, unit }) => (
  <>
    <span style={{ fontSize: 62, fontWeight: 700, lineHeight: 1 }}>{num}</span>
    {unit && <span style={{ fontSize: 25, fontWeight: 700 }}>{unit}</span>}
    <br />
  </>
);

const slides = [
  <StatsBox backgroundColor={purple[50]} color={darkPurple[500]}>
    <Stat num="90" unit="%" />
    <StatLineBold>of all respondents</StatLineBold>
    <StatLine>
      expect research clinics to provide flexible appointment times - including outside ordinary
      business hours.
    </StatLine>
  </StatsBox>,
  <StatsBox backgroundColor={purple[500]}>
    <StatLineBold>Non-white respondents were</StatLineBold>
    <Stat num="97" unit="%" />
    <StatLine>
      more likely to find it very important that similar people to them were participating in
      trials.
    </StatLine>
    <StatLineBold>In support of this:</StatLineBold>
    <Stat num="55" unit="%" />
    <StatLineBold>of white respondents</StatLineBold>
    <StatLine>
      said it was not important that people participating in trials were similar to them.
    </StatLine>
  </StatsBox>,
  <StatsBox backgroundColor={darkPurple[500]}>
    <StatLineBold>Black/African American men were</StatLineBold>
    <Stat num="86" unit="%" />
    <StatLine>
      more likely to consider family obligations as something that makes it hard for them to take
      part in a clinical trial.
    </StatLine>
  </StatsBox>,

  <StatsBox backgroundColor={lightGreen[500]}>
    <Stat num="45" unit="%" />
    <StatLineBold>of all respondents</StatLineBold>
    <StatLine>will travel for up to 30 minutes to visit a research clinic</StatLine>
    <StatLineBold>but only...</StatLineBold>
    <Stat num="22" unit="%" />
    <StatLineBold>of all respondents</StatLineBold>
    <StatLine>will travel for an hour or longer.</StatLine>
  </StatsBox>,

  <StatsBox backgroundColor={green[500]}>
    <StatLineBold>Asian women were</StatLineBold>
    <Stat num="4.6" unit="x" />
    <StatLine>
      more likely to say carer responsibilities make it harder for them to participate in a clinical
      trial.
    </StatLine>
  </StatsBox>,

  <StatsBox backgroundColor={purple[50]} color={darkPurple[500]}>
    <StatLineBold>Hispanic respondents were</StatLineBold>
    <Stat num="2.28" unit="x" />
    <StatLine>
      less likely to have interacted with core health services in the last 6 months.
    </StatLine>
  </StatsBox>,

  <StatsBox backgroundColor={orange[500]}>
    <StatLineBold>
      American Indian/Alaska Native, Asian, Black/African American and Hispanic respondents were
    </StatLineBold>
    <Stat num="64" unit="%" />
    <StatLine>
      more likely to consider compensation for participation in a trial to be very important
    </StatLine>
  </StatsBox>,

  <StatsBox backgroundColor={purple[500]}>
    <StatLineBold>Black/African American men were</StatLineBold>
    <Stat num="2.2" unit="x" />
    <StatLine>more likely to have applied to a trial</StatLine>
    <StatLineBold>but approximately...</StatLineBold>
    <Stat num="half" />
    <StatLine>as likely to have participated in a trial. </StatLine>
  </StatsBox>,

  <StatsBox backgroundColor={lightGreen[500]}>
    <StatLineBold>BIPOC respondents were</StatLineBold>
    <Stat num="43" unit="%" />
    <StatLine>
      more likely to always consider news and politics when they interact with the healthcare
      system.
    </StatLine>
  </StatsBox>,
];

export const DeiStatsCarousel: FC = () => {
  const [index, setIndex] = useState(0);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <Box
      display="flex"
      alignItems="center"
      marginX={{ xs: '-16px', md: `-${STAT_BOX_BUTTON_WIDTH}px` }}
      marginBottom={6}
    >
      <Box marginRight={{ xs: 0, sm: 1 }}>
        <IconButton
          size="small"
          onClick={() => {
            if (index > 0) {
              setIndex(index - 1);
            }
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Box>
      <AutoPlayCarousel
        style={{
          paddingRight: isMobile
            ? 0
            : `calc(100% - ${STAT_BOX_WIDTH}px - ${STAT_BOX_SPACING}px - ${
                2 * STAT_BOX_BUTTON_WIDTH
              }px)`, // this is a bit of a hack to make the carousel display multiple slides at once
        }}
        index={index}
        onChangeIndex={setIndex}
        overscanSlideAfter={5}
        overscanSlideBefore={5}
        interval={5000}
        slideRenderer={({ index: slideIndex, key }) => {
          const lookupIndex = slideIndex % slides.length;
          return <div key={key}>{slides[lookupIndex]}</div>;
        }}
      />
      <Box marginLeft={{ xs: 0, sm: 1 }}>
        <IconButton
          size="small"
          onClick={() => {
            setIndex(index + 1);
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};
