import { FC } from 'react';

import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

type StyleProps = {
  spacing: number;
  borderBetween?: string;
};

type Props = StyleProps & BoxProps;

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  verticalStack: props => ({
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(props.spacing),
      borderBottom: `1px solid ${props.borderBetween}`,
    },
  }),
}));

/**
 * Component that adds a fixed amount of spacing between each direct child
 */
export const VerticalStack: FC<Props> = ({
  spacing,
  borderBetween,
  className,
  children,
  ...boxProps
}) => {
  const styles = useStyles({ spacing, borderBetween });

  return (
    <Box
      {...boxProps}
      className={className != null ? `${className} ${styles.verticalStack}` : styles.verticalStack}
    >
      {children}
    </Box>
  );
};
