import { FC, useEffect, useRef } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { PinIcon } from 'shared/src/icon/icons';
import { gray, vividPurple } from 'shared/src/palette/color-system';

import { TrialPublicRecord } from 'mats/src/fragments/trial/types/trial-public-record-fragment.generated';

export type PublicTrialLocation = TrialPublicRecord['trialSites'][0]['location'];

const useStyles = makeStyles(theme => ({
  site: {
    [theme.breakpoints.down('xs')]: {
      flexShrink: 0,
      maxWidth: 200,
      padding: theme.spacing(0, 3),
      '&:not(:first-child)': {
        borderLeft: `1px solid ${gray[200]}`,
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
      '&:not(:first-child)': {
        borderTop: `1px solid ${gray[200]}`,
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  siteName: {
    '$site:hover &': {
      color: vividPurple[500],
    },
  },
  pinIcon: {
    fontSize: theme.spacing(2),
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

interface SiteProps {
  location: PublicTrialLocation;
  active: boolean;

  onSetActive(id: LocationID): void;
}

export const Site: FC<SiteProps> = ({ location, active, onSetActive }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (active && element) {
      element.parentElement?.scrollTo({
        top: element.offsetTop - element.parentElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [active]);

  return (
    <div
      role="button"
      ref={ref}
      tabIndex={0}
      className={classes.site}
      onClick={() => onSetActive(location.id)}
      onKeyPress={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onSetActive(location.id);
        }
      }}
    >
      <Typography
        variant="subtitle1"
        className={classes.siteName}
        color={active ? 'primary' : 'textSecondary'}
      >
        {location.name}
      </Typography>
      <Box marginTop={1}>
        <PinIcon className={classes.pinIcon} />
        {location.state}, {location.countryCode}
      </Box>
    </div>
  );
};
