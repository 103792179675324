import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';
import { SYS_FRAGMENT } from 'shared/src/fragments/contentful/sys-fragment.contentful';

import { CONTENT_LINK_FRAGMENT } from 'components/content-links/query.contentful';
import { REDIRECT_FRAGMENT } from 'components/contentful-redirect/redirect.contentful';
import {
  MedicationArticleBySlug,
  MedicationArticleBySlugVariables,
} from 'pages/medication-article/types/queries.contentful.generated';

const MEDICATION_ARTICLE_BY_SLUG_QUERY = gql`
  query MedicationArticleBySlug($slug: String!, $preview: Boolean!) {
    medicationArticleCollection(where: { slug: $slug }, preview: $preview, limit: 1) {
      items {
        sys {
          ...SysFragment
        }
        slug
        author {
          name
          profilePicture {
            ...AssetFragment
          }
          slug
        }
        reviewedAt
        medicalReviewer {
          name
          profilePicture {
            ...AssetFragment
          }
          slug
        }
        textBody {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        faqs {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        title
        descriptionMeta
        titleMeta
        pregnancyWarning
        prescriptionStatus
        references {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        conditionCategoriesCollection(limit: 20) {
          items {
            sys {
              ...SysFragment
            }
            condition
            conditionIdList
            slug
            linkedFrom {
              conditionArticleCollection(limit: 12) {
                ...ContentLinksFragment
              }
            }
          }
        }
      }
    }
    ...RedirectFragment
  }
  ${SYS_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${CONTENT_LINK_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${REDIRECT_FRAGMENT}
`;

export const useMedicationArticleBySlug = contentfulQueryFactory<
  MedicationArticleBySlug,
  MedicationArticleBySlugVariables
>(MEDICATION_ARTICLE_BY_SLUG_QUERY);

export type MedicationArticle = DeepExtractTypeSkipArrays<
  MedicationArticleBySlug,
  ['medicationArticleCollection', 'items']
>;
