import { ReactNode } from 'react';

import {
  Box,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';

import { AssetInfo, ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichTextData, RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';
import { gray } from 'shared/src/palette/color-system';

import { RoundedCard } from 'pages/condition-landing-page/components/common/rounded-card';

// TODO: Theme this
const useStyles = makeStyles((theme: Theme) => ({
  card: {
    '&:last-child>div': {
      marginBottom: 0,
    },
  },
  container: {
    backgroundColor: gray[50],
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
  cardAvatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    objectFit: 'cover',
  },
  cardContent: {
    fontSize: theme.typography.pxToRem(18),
    // TODO: map richtext content to proper Typography components
    // and change bold to a h6 in content
    '& b': {
      color: gray[1000],
    },
  },
}));
interface TitledCardProps {
  title: string;
  img: AssetInfo | null;
  children: ReactNode | ReactNode[];
}

const TitledCard = ({ children, title, img }: TitledCardProps) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <RoundedCard>
      <CardHeader
        avatar={
          img ? (
            <ContentfulImage
              maxWidth={theme.spacing(13)}
              image={img}
              className={styles.cardAvatar}
            />
          ) : null
        }
        title={<Typography variant="h5">{title}</Typography>}
      />
      <Box marginX={2} paddingBottom={2} className={styles.cardContent}>
        <Typography component="div">{children}</Typography>
      </Box>
    </RoundedCard>
  );
};

type Foundation = {
  foundationType: string | null;
  name: string | null;
  image: AssetInfo | null;
  description: RichTextData | null;
};

type ConditionResource = {
  sys: { id: string };
  icon: AssetFragment | null;
  description: RichTextData | null;
  title: string | null;
};

interface Props {
  foundation: Foundation;
  resources: ConditionResource[];
}

export const SupportSection = ({ foundation, resources }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3" gutterBottom>
              {foundation.foundationType}
            </Typography>
            <TitledCard title={foundation.name ?? ''} img={foundation.image}>
              {foundation.description && <RichText document={foundation.description} />}
            </TitledCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3" gutterBottom>
              Resources
            </Typography>
            {resources.map(resource => {
              if (!resource.description) {
                return null;
              }
              return (
                <div className={styles.card} key={resource.sys.id}>
                  <TitledCard title={resource.title ?? ''} img={resource.icon}>
                    <RichText document={resource.description} />
                  </TitledCard>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
