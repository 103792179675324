import { FC } from 'react';

import { LinkProps as MuiLinkProps } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { ExternalLink, ExternalLinkButton, ButtonLinkProps } from 'shared/src/link/link';

// eslint-disable-next-line import/no-unused-modules
export const MatsLink: FC<MuiLinkProps<RouterLink, RouterLinkProps>> = ({ to, ...props }) => {
  di(ExternalLink);

  const toWithoutSlash = typeof to === 'string' && to.startsWith('/') ? to.slice(1) : to;

  return (
    <ExternalLink
      {...props}
      to={`${import.meta.env.VITE_MATS_URL}/${toWithoutSlash}`}
      eventLabel="mats"
    />
  );
};

export const MatsLinkButton: FC<ButtonLinkProps> = ({ to, ...props }) => {
  di(ExternalLinkButton);

  const toWithoutSlash = typeof to === 'string' && to.startsWith('/') ? to.slice(1) : to;

  return (
    <ExternalLinkButton {...props} to={`${import.meta.env.VITE_MATS_URL}/${toWithoutSlash}`} />
  );
};
