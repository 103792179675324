import { createContext } from 'react';

interface FeatureFlagUserUpdateContextType {
  triggerUserUpdate: () => void;
}

export const featureFlagUserUpdateContext = createContext<FeatureFlagUserUpdateContextType>({
  triggerUserUpdate: () => {
    if (import.meta.env.MODE !== 'production') {
      throw new Error(
        "Attempted to update the ConfigCat user without a context provider. Are you writing a test or story? Don't forget to DI!"
      );
    } else {
      console.error('FeatureFlagUserUpdateContext provider is missing.');
    }
  },
});
