import { FC } from 'react';

import { Box, Container, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { RouteComponentProps } from 'react-router-dom';

import { PageBase } from 'components/page-base/page-base';
import { NotFound } from 'pages/not-found/not-found';

import { BookingWidget } from './components/booking-widget/booking-widget';

const BookATimePage: FC<{ projectId: string }> = ({ projectId }) => {
  di(PageBase);

  return (
    <PageBase windowTitle="Book a time">
      <Box marginY={4}>
        <Container>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Book a screening call with trial site coordinator
          </Typography>
          <BookingWidget timekitProjectId={projectId} />
        </Container>
      </Box>
    </PageBase>
  );
};

export const BookATimePageRoute: FC<RouteComponentProps<{ projectId: string }>> = ({ match }) => {
  const { projectId } = match.params;

  if (!match.params.projectId) {
    return <NotFound />;
  }

  return <BookATimePage projectId={projectId} />;
};
