import { memo, ReactNode } from 'react';

import { makeStyles, Typography, Theme } from '@material-ui/core';
import { ConditionAuthor } from 'contentful-gql/src/types/graphql-schema';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { AssetFragment } from 'shared/src/fragments/contentful/types/asset-fragment.contentful.generated';
import { Link } from 'shared/src/link/link';
import { linkColor } from 'shared/src/palette/color-system';

type BlogAuthorProfile = Pick<ConditionAuthor, 'name' | 'slug'> & {
  profilePicture: AssetFragment | null;
};

const useStyles = makeStyles<
  Theme,
  {
    hasPicture: boolean;
  }
>(theme => ({
  author: {
    display: 'grid',
    gridTemplateAreas: '"picture writtenBy" "picture name"',
    gridGap: ({ hasPicture }) => (hasPicture ? theme.spacing(0, 1) : undefined),
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'start',
  },
  pictureEl: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: '50%',
  },
  picture: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    gridArea: 'picture',
  },
  name: {
    gridArea: 'name',
  },
  nameLink: {
    gridArea: 'name',
    color: linkColor,
  },
  writtenBy: {
    gridArea: 'writtenBy',
  },
}));

export const WrittenBy = ({ author }: { author: BlogAuthorProfile }) => (
  <AbstractAuthorCredit author={author} urlSegment="authors" creditKind="Written by" />
);

export const ReviewedBy = ({ author }: { author: BlogAuthorProfile }) => (
  <AbstractAuthorCredit author={author} urlSegment="reviewers" creditKind="Medically reviewed by" />
);

const AbstractAuthorCredit = memo(
  ({
    author,
    urlSegment: segment,
    creditKind,
  }: {
    author: BlogAuthorProfile;
    urlSegment: string;
    creditKind: string;
  }) => {
    const hasPicture = author.profilePicture != null;
    const styles = useStyles({ hasPicture });
    const link = author.slug != null ? `/${segment}/${author.slug}` : undefined;
    const nameClassName = link == null ? styles.name : styles.nameLink;

    return (
      <div className={styles.author}>
        {author.profilePicture && (
          <MaybeLinkableGridArea className={styles.picture} link={link}>
            <ContentfulImage
              image={author.profilePicture}
              resize="thumb"
              imageFocus="face"
              maxHeight={40}
              className={styles.pictureEl}
              lazy={false}
            />
          </MaybeLinkableGridArea>
        )}
        {author.name && (
          <>
            <Typography variant="body1" color="textSecondary" className={styles.writtenBy}>
              {creditKind}
            </Typography>
            <MaybeLinkableGridArea className={nameClassName} link={link}>
              <Typography variant="body1">
                <strong>{author.name}</strong>
              </Typography>
            </MaybeLinkableGridArea>
          </>
        )}
      </div>
    );
  }
);

const MaybeLinkableGridArea = ({
  children,
  className,
  link,
}: {
  children: ReactNode;
  className: string;
  link: string | undefined;
}) => {
  if (link == null) {
    return <span className={className}>{children}</span>;
  }

  return (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
};
