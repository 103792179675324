import { ReactNode } from 'react';

import { Box } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { RoundedCard } from 'pages/condition-landing-page/components/common/rounded-card';

interface StatProps {
  bigNumberLabel?: string;
  bigNumber: string | number;
  bigNumberColor?: string;
  content: string | ReactNode | ReactNode[];
  direction: 'row' | 'column';
}

const useStatStyles = makeStyles<Theme, StatProps>((theme: Theme) => ({
  stat: ({ direction, bigNumberLabel }) => ({
    height: '100%',
    marginBottom: 0,
    paddingTop: bigNumberLabel ? 12 : 0,
    display: 'flex',
    flexDirection: direction,
    justifyContent: direction === 'column' ? 'center' : 'initial',
  }),
  headerContainer: {
    width: 'initial',
  },
  cardHeader: ({ direction, bigNumberLabel }) => ({
    display: 'flex',
    flexDirection: direction,
    justifyContent: 'center',
    paddingBottom: direction === 'column' ? 0 : 16,
    paddingTop: bigNumberLabel ? 0 : 16,
    alignItems: 'initial',
  }),
  bigText: {
    '&&': {
      color: ({ bigNumberColor }: StatProps) =>
        bigNumberColor ? `#${bigNumberColor}` : theme.palette.primary.main,
    },
  },
  cardContent: ({ direction }) => ({
    display: 'flex',
    fontSize: theme.typography.pxToRem(16),
    justify: direction === 'row' ? 'center' : 'initial',
    '&&': {
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      '&&': {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
  }),
  bigNumberLabel: {
    paddingLeft: theme.spacing(2),
    '&&': {
      color: theme.palette.text.secondary,
    },
  },
}));

export const Statistic = (props: StatProps) => {
  const styles = useStatStyles(props);
  const { direction, content, bigNumber, bigNumberLabel } = props;

  return (
    <Grid item xs={12} md={4}>
      <RoundedCard classes={{ root: styles.stat }}>
        <Grid
          classes={{ root: styles.headerContainer }}
          justifyContent="center"
          direction={direction}
          container
          item
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            {bigNumberLabel && (
              <Typography variant="h6" classes={{ root: styles.bigNumberLabel }}>
                {bigNumberLabel}
              </Typography>
            )}
            <CardHeader
              className={styles.cardHeader}
              title={
                <Typography className={styles.bigText} variant="h2">
                  {bigNumber}
                </Typography>
              }
            />
          </Box>
        </Grid>
        <CardContent className={styles.cardContent}>
          <Grid justifyContent="center" direction="column" container>
            <Typography component={typeof content === 'string' ? 'p' : 'div'}>{content}</Typography>
          </Grid>
        </CardContent>
      </RoundedCard>
    </Grid>
  );
};
