import { FC, ReactElement } from 'react';

import {
  Box,
  Card,
  Container,
  Grid,
  Link as MuiLink,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useRouteMatch } from 'react-router';

import { Link } from 'shared/src/link/link';
import { beige } from 'shared/src/palette/color-system';

import { usePublicTrialIdsForCondition } from 'mats/src/components/trial-list-public/queries';

import graphIcon from 'components/images/icon-graph.svg';
import heartIcon from 'components/images/icon-heart.svg';
import stethoscopeIcon from 'components/images/icon-stethoscope.svg';
import {
  InformationSnippet,
  RenderWithClassName,
} from 'components/information-snippet/information-snippet';

type ExploreMoreProps = {
  condition: string | null;
  conditionIdList: string[];
  treatmentsSlug: string | null;
  statisticsSlug: string | null;
  fallback: ReactElement | null;
};

const useStyles = makeStyles(({ typography, spacing }) => ({
  exploreTitle: {
    fontSize: typography.pxToRem(35),
    paddingBottom: spacing(5),
    textDecoration: 'initial',
  },
  link: {
    '&:hover': {
      textDecoration: 'initial',
    },
  },
  cardContainer: {
    // spacing=2 around the cards causes it to be offset by 8px... Seems to be a bug in mui4
    margin: 0,
  },
}));

export const ExploreMore: FC<ExploreMoreProps> = ({
  condition,
  conditionIdList,
  statisticsSlug,
  treatmentsSlug,
  fallback,
}) => {
  di(usePublicTrialIdsForCondition, useRouteMatch);

  const classes = useStyles();
  const match = useRouteMatch<{ condition: string }>('/:condition/');

  const conditionId =
    conditionIdList && conditionIdList.length === 1 ? parseInt(conditionIdList[0], 10) : 0;

  const {
    data: clinicalTrialData,
    loading,
    error,
  } = usePublicTrialIdsForCondition({
    skip: !conditionId,
    variables: {
      conditionId: conditionId as ConditionID,
    },
  });

  const shouldRenderClinicalTrials =
    !!clinicalTrialData && clinicalTrialData.searchTrials.length > 0;

  const shouldRender = shouldRenderClinicalTrials || !!treatmentsSlug || !!statisticsSlug;

  if (loading || error || !shouldRender) {
    return fallback;
  }

  const conditionName = condition?.toLocaleLowerCase();
  const conditionLinkPrefix = match?.params.condition ?? '';

  return (
    <Box paddingY={10} bgcolor={beige[50]}>
      <Container>
        <Grid container>
          <Grid container item xs={12} className={classes.exploreTitle}>
            <Typography
              variant="h3"
              align="center"
              style={{
                margin: 'auto',
              }}
            >
              Explore more
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2} className={classes.cardContainer}>
            {shouldRenderClinicalTrials && (
              <ExploreMoreSection
                link={`/${conditionLinkPrefix}/clinical-trials`}
                title="Clinical trials"
                subtitle={`Explore clinical trials for ${conditionName} and see those actively looking for patients near you.`}
                renderIcon={classname => <img alt="" src={stethoscopeIcon} className={classname} />}
              />
            )}
            {treatmentsSlug && (
              <ExploreMoreSection
                link={`/${treatmentsSlug}`}
                title="Treatments"
                subtitle={`Learn more about proven and effective treatment options for ${conditionName}.`}
                renderIcon={classname => <img alt="" src={heartIcon} className={classname} />}
              />
            )}
            {statisticsSlug && (
              <ExploreMoreSection
                link={`/${statisticsSlug}`}
                title="Statistics"
                subtitle={`Find up-to-date information on how common ${conditionName} is, who is most at risk, and more.`}
                renderIcon={classname => <img alt="" src={graphIcon} className={classname} />}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const ExploreMoreSection: FC<{
  link: string;
  title: string;
  subtitle: string;
  renderIcon: RenderWithClassName;
}> = ({ link, title, subtitle, renderIcon }) => {
  const classes = useStyles();

  // todo remove when we deprecate 'treatmentsUrl' and 'statisticsUrl' in condition category
  if (link.startsWith('http')) {
    return (
      <Grid item xs={12} sm={4}>
        <Card>
          <MuiLink href={link} className={classes.link} component="a">
            <Box padding={4}>
              <InformationSnippet
                size="small"
                renderIcon={renderIcon}
                title={title}
                subtitle={subtitle}
              />
            </Box>
          </MuiLink>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={4}>
      <Card>
        <Link to={link} className={classes.link}>
          <Box padding={4}>
            <InformationSnippet
              size="small"
              renderIcon={renderIcon}
              title={title}
              subtitle={subtitle}
            />
          </Box>
        </Link>
      </Card>
    </Grid>
  );
};
