/* eslint-disable no-nested-ternary */
import { useContext, useState } from 'react';

import { contextStorageContext } from 'shared/src/util/context-storage/context-storage-provider';

/**
 * useContextStorage takes a generic value and stores it in React context as a string.
 */
export const useContextStorage = <T>(
  key: string,
  initialValue?: T
): [T | undefined, (newValue: T | undefined) => void, () => void] => {
  const contextStorage = useContext(contextStorageContext);

  const [state, setState] = useState<T | undefined>(() => {
    const val = contextStorage?.get(key);
    if (val == null) {
      contextStorage?.set(key, initialValue != null ? JSON.stringify(initialValue) : undefined);
      return initialValue;
    }
    return JSON.parse(val);
  });

  const setInStorage = (newValue: T | undefined) => {
    contextStorage?.set(key, newValue != null ? JSON.stringify(newValue) : undefined);
    setState(newValue);
  };

  const removeFromStorage = () => {
    contextStorage?.remove(key);
    setState(undefined);
  };

  return [state, setInStorage, removeFromStorage];
};
