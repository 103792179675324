import { FC } from 'react';

import Grid from '@material-ui/core/Grid';

import { RichTextData, RichText } from 'shared/src/contentful-text/rich-text-renderer';

import { Statistic } from 'components/statistic';

type HealthcareStat = {
  bigNumber: string | null;
  description: RichTextData | null;
  sys: { id: string };
};

interface Props {
  stats: HealthcareStat[];
  statsHeadingColor?: string;
}

export const StatsSection: FC<Props> = ({ stats, statsHeadingColor }) => (
  <Grid container spacing={3} alignItems="stretch">
    {stats.map(stat => {
      if (!stat.bigNumber || !stat.description) {
        return null;
      }

      return (
        <Statistic
          direction="column"
          bigNumber={stat.bigNumber}
          bigNumberColor={statsHeadingColor}
          content={<RichText document={stat.description} />}
          key={stat.sys.id}
        />
      );
    })}
  </Grid>
);
