import { FC } from 'react';

import { ThemeProvider, Theme, Typography } from '@material-ui/core';
import { deepmerge } from '@material-ui/utils';

import { latoFontFamily, nantesFontFamily } from 'shared/src/fonts/fonts';
import { gray } from 'shared/src/palette/color-system';
import { buildHeadingStyles, spacing } from 'shared/src/theme/theme';

export const BlogHeading = ({ children }: { children: any }) => (
  <Typography variant="h2" component="h1">
    {children}
  </Typography>
);

export const BlogSubHeading = ({
  children,
  gutterBottom,
}: {
  gutterBottom?: boolean;
  children: any;
}) => (
  <Typography variant="h3" component="h2" gutterBottom={gutterBottom}>
    {children}
  </Typography>
);

export const BlogContentThemeProvider: FC = ({ children }) => (
  <ThemeProvider
    theme={(theme: Theme) =>
      deepmerge(theme, {
        hmCustom: {
          typography: {
            quote: {
              ...theme.hmCustom?.typography?.quote,
              fontStyle: 'normal',
              fontFamily: nantesFontFamily,
              marginBottom: spacing(2),
            },
          },
        },
        typography: {
          ...theme.typography,
          h2: {
            ...theme.typography.h3,
          },
          h3: {
            ...theme.typography.h4,
            fontFamily: nantesFontFamily,
          },
          h4: buildHeadingStyles(26, latoFontFamily),
          h5: buildHeadingStyles(22, latoFontFamily),
          h6: buildHeadingStyles(20, latoFontFamily),
          body2: {
            ...theme.typography.body2,
            color: gray[1000],
          },
        },
        overrides: {
          MuiTypography: {
            gutterBottom: {
              marginTop: spacing(5),
              marginBottom: spacing(2),
            },
          },
        },
      })
    }
  >
    {children}
  </ThemeProvider>
);
