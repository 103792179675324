import { DependencyList, useMemo } from 'react';

import { collectBy } from 'shared/src/util/collect-by';
import { notEmpty } from 'shared/src/util/not-empty';

import { DirectoryItem } from 'components/guides-directory/directory/directory';

export const useDirectoryItemsGroupedByTitle = <T>(
  items: (T | null)[] | null | undefined,
  titleSelector: (input: T) => string | null | undefined,
  slugSelector: (input: T) => string | null | undefined,
  deps: DependencyList
): [string, DirectoryItem[]][] =>
  useMemo(() => {
    const noNullItems: T[] = (items ?? []).filter(
      (i): i is T => notEmpty(i) && titleSelector(i) != null && slugSelector(i) != null
    );

    return Object.entries(
      collectBy(
        noNullItems,
        // we pre-check to ensure that this is not nullish so we can safely typecast here
        i => titleSelector(i)?.charAt(0) as string,
        i => ({ title: titleSelector(i) as string, slug: slugSelector(i) as string })
      )
    ).sort((a, b) => a.toLocaleString().localeCompare(b.toLocaleString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
