import { Container, Typography, Box, Grid, makeStyles } from '@material-ui/core';

import { FullWidthImage } from 'shared/src/image/image';

import alisterPortrait from './images/alister-portrait.png';
import manuriPortrait from './images/manuri-portrait.png';
import paulPortrait from './images/paul-portrait.png';

const useStyles = makeStyles(theme => ({
  memberContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textContainer: {
    minHeight: theme.spacing(10),
  },
}));

const MemberLockup = ({
  imgSrc,
  name,
  subtitle,
}: {
  imgSrc: string;
  name: string;
  subtitle: string;
}) => {
  const classes = useStyles();

  return (
    <Grid item sm={4} className={classes.memberContainer}>
      <FullWidthImage src={imgSrc} alt={name} />
      <Box className={classes.textContainer}>
        <Typography variant="h5" align="center">
          {name}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {subtitle}
        </Typography>
      </Box>
    </Grid>
  );
};

export const BoardMembersSection = () => (
  <Container maxWidth="lg">
    <Box marginY={{ xs: 7, sm: 10 }}>
      <Typography variant="h3" align="center" gutterBottom>
        Our board members
      </Typography>
      <Grid container spacing={2}>
        <MemberLockup
          imgSrc={paulPortrait}
          name="Paul Bassat"
          subtitle="Co-Founder & Partner at Square Peg Capital"
        />
        <MemberLockup
          imgSrc={manuriPortrait}
          name="Manuri Gunawardena"
          subtitle="Founder & CEO at HealthMatch"
        />
        <MemberLockup
          imgSrc={alisterPortrait}
          name="Alister Coleman"
          subtitle="Founder and General Partner at Folklore"
        />
      </Grid>
    </Box>
  </Container>
);
