export type ColorSpread = Record<50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900, string>;
type GraySpread = Record<keyof ColorSpread | 0 | 1000, string>;

export const linkColor = '#0066FF';

export const purple: ColorSpread = {
  50: '#D7D7FF',
  100: '#BDBDFF',
  200: '#9797F2',
  300: '#7E7EE0',
  400: '#6E65C9',
  500: '#5E4DB2',
  600: '#4E45A8',
  700: '#453B9C',
  800: '#3E3090',
  900: '#311D7B',
};

export const gray: GraySpread = {
  0: '#FFFFFF',
  50: '#F8F8F8',
  100: '#F0F0F0',
  200: '#E6E6E6',
  300: '#D5D5D5',
  400: '#B0B0B0',
  500: '#727272',
  600: '#5B5B5B',
  700: '#454545',
  800: '#2E2E2E',
  900: '#171717',
  1000: '#000000',
};

export const green: ColorSpread = {
  50: '#CFFFF6',
  100: '#A1EEE0',
  200: '#72D5C7',
  300: '#55BBAF',
  400: '#38938E',
  500: '#1F5B5F',
  600: '#174C4E',
  700: '#103D3E',
  800: '#082D2D',
  900: '#001E1C',
};

export const beige: ColorSpread = {
  50: '#FFF4EB',
  100: '#FFEDDF',
  200: '#FFE7D2',
  300: '#FEE1C7',
  400: '#FEDBBB',
  500: '#FED5B0',
  600: '#FBBA8B',
  700: '#F79E65',
  800: '#F4823F',
  900: '#F0681B',
};

export const lightPurple: ColorSpread = {
  50: '#F3F0FF',
  100: '#ECE8FF',
  200: '#E4DEFF',
  300: '#DBD4FF',
  400: '#D6CDFF',
  500: '#CFC5FF',
  600: '#C3B9FC',
  700: '#AB9FF5',
  800: '#9386EE',
  900: '#7A6CE7',
};

export const vividPurple: ColorSpread = {
  50: '#E6DCFF',
  100: '#D6C8FF',
  200: '#c5b3ff',
  300: '#B59FFF',
  400: '#A48AFF',
  500: '#8B6BFF',
  600: '#7D5BFC',
  700: '#724FF9',
  800: '#623DF6',
  900: '#562FF3',
};

export const darkPurple: ColorSpread = {
  50: '#E7E8F1',
  100: '#BFBFD3',
  200: '#9996B4',
  300: '#726D95',
  400: '#4B4577',
  500: '#251D59',
  600: '#1D1648',
  700: '#150F37',
  800: '#0D0827',
  900: '#050116',
};

export const lightGreen: ColorSpread = {
  50: '#E1F7F7',
  100: '#C0E8E9',
  200: '#9FD8DB',
  300: '#7FC9CD',
  400: '#5DB9BF',
  500: '#3CAAB1',
  600: '#399A9F',
  700: '#358B8E',
  800: '#327B7D',
  900: '#2E6B6B',
};

export const lightBeige: ColorSpread = {
  50: '#F7F0F0',
  100: '#F2E4E2',
  200: '#EDD9D5',
  300: '#E9CDC7',
  400: '#E4C2BA',
  500: '#DFB6AC',
  600: '#D4928A',
  700: '#C96E68',
  800: '#BE4A45',
  900: '#B22523',
};

export const orange: ColorSpread = {
  50: '#FFEEDF',
  100: '#FDDAC4',
  200: '#FBC7AB',
  300: '#F9B593',
  400: '#F8A37B',
  500: '#F69265',
  600: '#EF8551',
  700: '#E9773D',
  800: '#E26928',
  900: '#DB5B14',
};

export const red: ColorSpread = {
  50: '#FFECF2',
  100: '#FFCED7',
  200: '#FFAFBB',
  300: '#FF909E',
  400: '#FF7182',
  500: '#EA5D62',
  600: '#D64752',
  700: '#D9323C',
  800: '#CA2A3A',
  900: '#B92134',
};

export const peach: ColorSpread = {
  50: '#FFF4EB',
  100: '#FFEDDF',
  200: '#FFE7D2',
  300: '#FEE1C7',
  400: '#FEDBBB',
  500: '#FED5B0',
  600: '#FBBA8B',
  700: '#F79E65',
  800: '#F4823F',
  900: '#F0681B',
};

export const pastelPeach: ColorSpread = {
  50: '#F7F0F0',
  100: '#F2E4E2',
  200: '#EDD9D5',
  300: '#E9CDC7',
  400: '#E4C2BA',
  500: '#DFB6AC',
  600: '#D4928A',
  700: '#C96E68',
  800: '#BE4A45',
  900: '#B22523',
};

export const blue: ColorSpread = {
  50: '#F1F6FD',
  100: '#DFEBFB',
  200: '#BFD7F8',
  300: '#A4C5F4',
  400: '#84B1F1',
  500: '#649CED',
  600: '#2877E6',
  700: '#1559B7',
  800: '#0E3A77',
  900: '#071D3B',
};

export const pink: ColorSpread = {
  50: '#FEF6FD',
  100: '#FDEDFC',
  200: '#FAD7F7',
  300: '#F7C5F4',
  400: '#F5B3F0',
  500: '#F29EEC',
  600: '#E959DF',
  700: '#D31CC7',
  800: '#8B1383',
  900: '#480A44',
};
