import { Box, Container, makeStyles, Typography, useTheme } from '@material-ui/core';

import { gray } from 'shared/src/palette/color-system';

import folkloreLogo from './images/folklore-logo.svg';
import squarePegLogo from './images/square-peg-logo.svg';

const useStyles = makeStyles({
  logo: {
    height: '100%',
  },
});

export const InvestorsSection = () => {
  const classes = useStyles();
  const { spacing } = useTheme();

  return (
    <Box paddingTop={7} paddingBottom={5} bgcolor={gray[50]}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Our investors
        </Typography>
        <Box display="flex" justifyContent="space-evenly" alignItems="flex-end">
          <Box margin={2} height={{ xs: spacing(4.0), sm: spacing(6.5) }}>
            <img src={squarePegLogo} className={classes.logo} alt="Square Peg logo" />
          </Box>
          <Box margin={2} height={{ xs: spacing(2.8), sm: spacing(4.5) }}>
            <img src={folkloreLogo} className={classes.logo} alt="Folklore logo" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
