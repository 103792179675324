import { FC } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { AspectRatioContainer } from 'shared/src/aspect-ratio-container/aspect-ratio-container';
import { CarouselMultiple } from 'shared/src/carousel/carousel-multiple';
import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { Link } from 'shared/src/link/link';

import {
  ContentLinksFragment,
  RelatedConditionsFragment,
} from './types/query.contentful.generated';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
}));

export type ArticleLink = Omit<
  DeepExtractTypeSkipArrays<ContentLinksFragment, ['items']>,
  '__typename'
>;

export const ContentLink: FC<{ link: ArticleLink; categoryLinks?: boolean }> = ({
  link,
  categoryLinks,
}) => {
  const { heroImage, category, slug, sys, title } = link;
  const ratio = heroImage?.height && heroImage?.width ? heroImage.height / heroImage.width : 1;
  const updated = sys.publishedAt ? format(new Date(sys.publishedAt), 'MMM y') : null;
  const classes = useStyles();
  const showCategoryLinks = !!category?.slug && !!category.condition && categoryLinks;

  return (
    <Box>
      {heroImage && (
        <AspectRatioContainer ratio={ratio}>
          <ContentfulImage maxWidth="sm" className={classes.image} image={heroImage} />
        </AspectRatioContainer>
      )}
      <Box marginY={2}>
        <Typography color="textSecondary">
          <b>
            {showCategoryLinks && (
              <>
                <Link color="textSecondary" to={`/${category.slug}`}>
                  {category.condition}
                </Link>
                {' | '}
              </>
            )}
            {updated && (showCategoryLinks ? updated : `Last updated: ${updated}`)}
          </b>
        </Typography>
      </Box>
      <Link to={`/${slug}`} variant="h5" color="textSecondary">
        {title}
      </Link>
    </Box>
  );
};

export const ContentLinks: FC<{ links: ArticleLink[]; title: string; categoryLinks?: boolean }> = ({
  links,
  title,
  categoryLinks,
}) => (
  <CarouselMultiple title={title}>
    {links.map(link => (
      <ContentLink categoryLinks={categoryLinks} link={link} key={link.sys.id} />
    ))}
  </CarouselMultiple>
);

/**
 * Turns entries queried by `RELATED_CONDITIONS_FRAGMENT` into a flat sorted `ArticleLink` list
 * ready for consumption by `ContentLinks`
 */
export const normalizeRelatedArticles = (
  collection?: RelatedConditionsFragment | null,
  selfId?: string
): ArticleLink[] => {
  const seenIds = new Set<string>(selfId);

  const extractNested = (item: RelatedConditionsFragment['items'][0]) => {
    switch (item?.__typename) {
      case 'ConditionCategory':
        return item.linkedFrom?.conditionArticleCollection?.items;
      case 'ConditionLink':
        return item.linkedPage?.__typename === 'ConditionCategory'
          ? item.linkedPage.linkedFrom?.conditionArticleCollection?.items
          : null;
      default:
        return null;
    }
  };

  const existsAndNotDuplicate = <T extends ArticleLink | null | undefined>(
    article: T
  ): article is NonNullable<T> => {
    if (!article || seenIds.has(article.sys.id)) {
      return false;
    }
    seenIds.add(article.sys.id);
    return true;
  };

  const bySearchVolume = (a: ArticleLink, b: ArticleLink) =>
    (b.searchVolume ?? 0) - (a.searchVolume ?? 0);

  return (
    collection?.items
      .flatMap(extractNested)
      .filter(existsAndNotDuplicate)
      .sort(bySearchVolume)
      .slice(0, 12) ?? []
  );
};
