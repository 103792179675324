import { createContext, FC, useMemo, useRef } from 'react';

type ContextStorageContext = {
  get: (key: string) => string | undefined;
  set: (key: string, newValue: string | undefined) => void;
  remove: (key: string) => void;
};

export const contextStorageContext = createContext<ContextStorageContext | null>(null);

/**
 * Context Storage Provider exists to act as a fallback in case users have their browser storage disabled.
 * This is more likely to occur with work computers where IT departments have disabled this functionality
 * on computers.
 *
 * The context storage provider (via the useContextStorage hook) will serialise values to strings, and store
 * these in React context in a dictionary.
 */
export const ContextStorageProvider: FC = ({ children }) => {
  const store = useRef<{ [key: string]: string | undefined }>({});

  const get = (key: string) => store.current[key];

  const set = (key: string, newValue: string | undefined) => {
    store.current[key] = newValue;
  };

  const remove = (key: string) => {
    delete store.current[key];
  };

  const value = useMemo(
    () => ({
      get,
      set,
      remove,
    }),
    []
  );

  return <contextStorageContext.Provider value={value}>{children}</contextStorageContext.Provider>;
};
