import { FC } from 'react';

import { Box, Link as MuiLink, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-scroll';

import { beige, purple } from 'shared/src/palette/color-system';

import { slugify } from 'pages/condition-category/components/slugify/slugify';

const useStyles = makeStyles(theme => ({
  link: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
    position: 'relative',

    // typography hover is pretty specific so we have to override that
    '&&:hover': {
      color: purple[500],
    },
  },
  columns: {
    margin: theme.spacing(2, 0, 0),
    padding: 0,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      columns: 2,
      columnGap: theme.spacing(5),
    },
  },
  row: {
    margin: theme.spacing(0, 0, 0, 3),
  },
}));

export const ArticleContentMenu: FC<{ items: string[] }> = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      padding={{ xs: 3, sm: 4 }}
      marginBottom={5}
      bgcolor={beige[50]}
      borderRadius={theme.shape.borderRadius}
    >
      <Typography variant="h6">Content</Typography>
      <Box className={classes.columns} component="ul">
        {items.map(name => {
          const slug = slugify(name);
          return (
            <li key={slug} className={classes.row}>
              <MuiLink
                underline="none"
                color="textSecondary"
                className={classes.link}
                component={Link}
                href={`#${slug}`}
                to={slug}
                spy // Update active menu element on scroll
                hashSpy // Update hash on scroll
                smooth // Animated scroll when clicking on menu item
              >
                {name}
              </MuiLink>
            </li>
          );
        })}
      </Box>
    </Box>
  );
};
