import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import {
  MedicationDirectoryMedicationArticles,
  MedicationDirectoryMedicationArticlesVariables,
} from './types/queries.contentful.generated';

const CONDITION_CATEGORIES = gql`
  query MedicationDirectoryMedicationArticles($preview: Boolean) {
    medicationArticleCollection(preview: $preview) {
      items {
        sys {
          id
        }
        genericMedicationName
        medicationName
        slug
      }
    }
  }
`;

export const useMedicationDirectoryMedicationArticles = contentfulQueryFactory<
  MedicationDirectoryMedicationArticles,
  MedicationDirectoryMedicationArticlesVariables
>(CONDITION_CATEGORIES);
