import { FC } from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import { ButtonLinkProps } from 'shared/src/link/link';
import { gray } from 'shared/src/palette/color-system';

import { HmioLinkButton } from 'mats/src/components/hmio-link/hmio-link';

const useStyles = makeStyles({
  relatedLinkButton: {
    backgroundColor: gray[0],
    '&:hover': {
      backgroundColor: gray[50],
    },
  },
  relatedLinkRipple: {
    backgroundColor: gray[300],
  },
});

export const ConditionLinkButton: FC<ButtonLinkProps> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <HmioLinkButton
      variant="contained"
      size="medium"
      className={classes.relatedLinkButton}
      TouchRippleProps={{ classes: { child: classes.relatedLinkRipple } }}
      {...props}
    >
      <Typography noWrap>{children}</Typography>
    </HmioLinkButton>
  );
};
