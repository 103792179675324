import { FC } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  accordion: {
    backgroundColor: 'transparent',
  },
  accordionExpanded: {},
  accordionSummary: {
    display: 'inline-flex',
    padding: 0,
    '&$accordionExpanded': {
      minHeight: theme.spacing(6),
    },
  },
  accordionContent: {
    '$accordionExpanded &': {
      margin: 0,
    },
  },
  accordionDetails: {
    marginTop: -theme.spacing(4),
  },
}));

export const FootNotes: FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Accordion elevation={0} classes={{ root: classes.accordion }}>
      <AccordionSummary
        classes={{
          root: classes.accordionSummary,
          expanded: classes.accordionExpanded,
          content: classes.accordionContent,
        }}
        expandIcon={<ExpandMoreIcon />}
        IconButtonProps={{
          disableRipple: true,
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>{children}</AccordionDetails>
    </Accordion>
  );
};
