import { FC } from 'react';

import { makeStyles, Theme } from '@material-ui/core';

type StatusPillStyleProps = {
  textColor: string;
  backgroundColor: string;
};

const useStyles = makeStyles<Theme, StatusPillStyleProps>(({ spacing, shape }) => ({
  pill: {
    '&::before': {
      content: '""',
      position: 'relative' as const,
      top: spacing(0.25),
      left: -spacing(1),
      width: spacing(2),
      height: spacing(2),
      borderRadius: '100%',
      background: ({ textColor }: StatusPillStyleProps) => textColor,
      display: 'inline-block',
    },
    background: ({ backgroundColor }: StatusPillStyleProps) => backgroundColor,
    color: ({ textColor }: StatusPillStyleProps) => textColor,
    borderRadius: shape.borderRadius,
    padding: spacing(1, 2.5, 1, 3),
    fontWeight: 700,
  },
}));

type StatusPillProps = {
  text: string;
  textColor: string;
  backgroundColor: string;
};

export const StatusPill: FC<StatusPillProps> = ({ text, textColor, backgroundColor }) => {
  const classes = useStyles({ textColor, backgroundColor });

  return <div className={classes.pill}>{text}</div>;
};
