import { FC } from 'react';

import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';

import { CarouselWithDots } from 'shared/src/carousel/carousel';
import { darkPurple, gray, green, lightGreen, purple } from 'shared/src/palette/color-system';

import patientPhoto1 from './patient-photo-1.png';
import patientPhoto2 from './patient-photo-2.png';
import patientPhoto3 from './patient-photo-3.png';
import patientPhoto4 from './patient-photo-4.png';
import patientPhoto5 from './patient-photo-5.png';
import patientPhoto6 from './patient-photo-6.png';
import patientPhoto7 from './patient-photo-7.png';
import patientPhoto8 from './patient-photo-8.png';
import patientPhoto9 from './patient-photo-9.png';

type PatientResponse = {
  details: string;
  response: string;
  image: string;
  backgroundColor: string;
};

const patientResponses: PatientResponse[] = [
  {
    backgroundColor: lightGreen[500],
    image: patientPhoto1,
    details: 'Edna, OH (Female, Black/African American, 73)',
    response:
      "I have tried to participate in several clinical trials and have been turned down because of various medical conditions such as blood clots and distance to receive treatments. I am so interested in becoming involved in expanding my life expectancy and helping finding a cure for Parkinson's Disease.",
  },
  {
    backgroundColor: green[500],
    image: patientPhoto2,
    details: 'Yi Ching, TX (Female, Asian American)',
    response:
      'It would be easier for me to participate if I was given “proper follow-up after the completion of a trial - someone making sure I am doing okay, ways to keep tabs on whether the clinical trial results in a medication or treatment actually being offered to mass market.”',
  },
  {
    backgroundColor: darkPurple[500],
    image: patientPhoto3,
    details: 'Miranda, MI (Female, Hispanic origin, 26)',
    response:
      'I’d be able to participate in clinical trials “if it was easily worked into my life, without making drastic work changes.”',
  },
  {
    backgroundColor: purple[500],
    image: patientPhoto4,
    details: 'HealthMatch Patient, TX (Male, Hispanic origin, 72)',
    response:
      '“The cure behind the treatment is important to me. It makes me feel like I’m helping another.”',
  },
  {
    backgroundColor: lightGreen[500],
    image: patientPhoto5,
    details: 'HealthMatch Patient, WA (Female, Native Hawaiian/Other Pacific Islander, 67)',
    response:
      'It would be easier for me to participate in a clinical trial if I was given “plenty of information about possible side effects and expected outcomes.”',
  },
  {
    backgroundColor: darkPurple[500],
    image: patientPhoto6,
    details: 'HealthMatch Patient, OK (Male, American Indian/Alaska Native, 53)',
    response:
      "“I don't have a definite way to and from the place, I need help with transportation” to and from the clinical trial.",
  },
  {
    backgroundColor: green[500],
    image: patientPhoto7,
    details: 'HealthMatch Patient, PA (Female, American Indian/Alaska Native, 50)',
    response:
      '“I would love to participate in a trial. Every single one I’ve applied for I’ve been turned down. Every single one.”',
  },
  {
    backgroundColor: purple[500],
    image: patientPhoto8,
    details: 'HealthMatch Patient, CA (Female, Black/African American)',
    response:
      'It would be easier for me to participate in a clinical trial “knowing that I was helping improve the outcome for my community.”',
  },
  {
    backgroundColor: lightGreen[500],
    image: patientPhoto9,
    details: 'HealthMatch Patient, MI (Female, Hispanic origin, 26)',
    response:
      'I’d be able to participate in clinical trials “if it was easily worked into my life, without making drastic work changes.”',
  },
];

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    paddingTop: spacing(12),
    [breakpoints.up('sm')]: {
      paddingTop: spacing(6),
      paddingBottom: spacing(6),
    },
  },
  whiteText: {
    color: gray[0],
  },
  box: {
    borderRadius: spacing(5),
  },
  grid: {
    borderRadius: spacing(20),
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: spacing(4),
  },
  circleAvatar: {
    borderRadius: spacing(20),
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    [breakpoints.down('xs')]: {
      width: '50%',
      marginTop: spacing(-15),
    },
  },
  doubleQuotes: {
    fontSize: spacing(6),
    marginLeft: spacing(-2),
    lineHeight: 0, // this removes line spacing that gets added to the first line because of the big quotes
  },
}));

const PatientCallout: FC<{ patient: string; image: string; backgroundColor: string }> = ({
  children,
  patient,
  image,
  backgroundColor,
}) => {
  const styles = useStyles();
  return (
    <Container maxWidth="sm" className={styles.container}>
      {/* Side by side grid on laptops and up */}
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Grid
          container
          className={`${styles.whiteText} ${styles.grid}`}
          style={{ backgroundColor }}
        >
          <Grid item sm={3}>
            <img src={image} alt="Patient" className={styles.circleAvatar} />
          </Grid>
          <Grid item sm={9}>
            <div className={styles.centerContent}>
              <Typography paragraph style={{ fontSize: '12px' }}>
                <span className={styles.doubleQuotes}>&ldquo;</span>
                {children}
              </Typography>
              <Typography style={{ fontStyle: 'italic' }}>{patient}</Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      {/* mobile devices */}
      <Box display={{ xs: 'block', sm: 'none' }}>
        <div
          className={`${styles.whiteText} ${styles.box} ${styles.centerContent}`}
          style={{ backgroundColor }}
        >
          <Box textAlign="center">
            <img src={image} alt="Patient" className={styles.circleAvatar} />
          </Box>
          <Typography paragraph>{children}</Typography>
          <Typography style={{ fontStyle: 'italic' }}>{patient}</Typography>
        </div>
      </Box>
    </Container>
  );
};

export const PatientCarousel: FC = () => (
  <CarouselWithDots
    autoPlay
    autoPlayInterval={9000}
    slides={patientResponses}
    renderSlide={({ slide, key }) => (
      <PatientCallout
        key={key}
        patient={slide.details}
        image={slide.image}
        backgroundColor={slide.backgroundColor}
      >
        {slide.response}
      </PatientCallout>
    )}
  />
);
