import { FC } from 'react';

import { Box, Container, Link, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { di } from 'react-magnetic-di/macro';

import { Link as RouterLink } from 'shared/src/link/link';

import { PageBase } from 'components/page-base/page-base';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    marginBottom: theme.spacing(3),
  },
  summary: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    lineHeight: 1.18,
  },
  section: {
    marginBottom: theme.spacing(8),
  },
  sectionHeader: {
    marginBottom: theme.spacing(3),
  },
  subsection: {
    fontWeight: 700,
  },
}));

export const TermsOfUse: FC = () => {
  di(PageBase);
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <PageBase windowTitle="Terms of Use">
      <Box marginY={6}>
        <Container maxWidth="md">
          <Box marginBottom={8}>
            <header>
              <Typography variant="h2" component="h1" className={classes.pageTitle}>
                {t('hmio.terms.title')}
              </Typography>
            </header>
            <Typography variant="subtitle1" gutterBottom>
              {t('hmio.terms.lastUpdated')}
            </Typography>
            <Typography variant="subtitle2" paragraph className={classes.summary}>
              {t('hmio.terms.coloredTextExplainer')}
            </Typography>

            <section className={classes.section}>
              <Typography variant="body2" paragraph>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.intro"
                  components={{ privacyLink: <RouterLink to="/privacy" /> }}
                />
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.introSummary"
                  components={{ privacyLink: <RouterLink to="/privacy" /> }}
                />
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section1Title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                1.1
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section1-1" />
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                1.2
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>{t('hmio.terms.section1-2')}</strong>
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section1-2-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section2Title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                2.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section2-1Intro')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section2-1-a')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section2-1-a-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section2-1-b" />
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section2-1-b-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section2-1-c')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section2-1-d')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section2-1-e')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section2-1-e-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section3Title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                3.1
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section3-1" />
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                3.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section3-2')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                3.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section3-3')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                3.4
              </Typography>
              <Typography variant="body2">{t('hmio.terms.section3-4')}</Typography>
              <ol type="a">
                <li>
                  <Typography variant="body2">{t('hmio.terms.section3-4-a')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section3-4-b')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section3-4-c')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section3-4-d')}</Typography>
                </li>
              </ol>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section-3-4-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section4-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                4.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section4-1')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                4.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section4-2')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                4.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section4-3')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section4-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section5-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section5-summary')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                5.1
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section5-1" />
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                5.2
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.section5-2"
                  components={{ emailLink: <Link href="mailto: hello@healthmatch.io" /> }}
                />
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                5.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section5-3')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                5.4
              </Typography>
              <Typography variant="body2">{t('hmio.terms.section5-4-if')}</Typography>
              <ol type="a">
                <li>
                  <Typography variant="body2">{t('hmio.terms.section5-4-a')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section5-4-b')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section5-4-c')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section5-4-d')}</Typography>
                </li>
              </ol>
              <Typography variant="body2">{t('hmio.terms.section-5-4-then')}</Typography>
              <ol type="a" start={5}>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section5-4-e')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section5-4-f')}</Typography>
                </li>
              </ol>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section5-4-summary')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                5.5
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section5-5')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                5.6
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section5-6')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section6-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.1
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section6-1-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-1')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.2
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section6-2-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-c')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-d')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-e')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-f')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-g')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-h')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-i')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-j')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-k')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-l')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-2-m')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.3
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section6-3-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-c')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-d')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-e')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-f')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-g')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-h')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-i')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-j')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-k')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-l')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-m')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-n')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-o')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-3-p')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.4
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-4')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.5
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section6-5-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-5')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.6
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section6-6-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-6')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                6.7
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section6-7-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section6-7')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section7-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section7-summary')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                7.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section7-1')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section8-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section8-summary')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                8.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section8-1')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                8.2
              </Typography>
              <Typography variant="body2">{t('hmio.terms.section8-2')}</Typography>
              <ol type="i">
                <li>
                  <Typography variant="body2">{t('hmio.terms.section8-2-i')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section8-2-ii')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section8-2-iii')}</Typography>
                </li>
              </ol>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section9-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section9-summary')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                9.1
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section9-1" />
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                9.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section9-2')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                9.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section9-3')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                9.4
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section9-4')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section10-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                10.1
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section10-1-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section10-1')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                10.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section10-2')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section11-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.1
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.section11-1"
                  components={{ privacyLink: <RouterLink to="/privacy" /> }}
                />
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section11-1-summary')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-2')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-3')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.section11-3-summary"
                  components={{ emailLink: <Link href="mailto: support@healthmatch.io" /> }}
                />
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.4
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-4')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.5
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-5')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.6
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-6')}
              </Typography>
              <address>
                HealthMatch L4 <br />
                Attention: Privacy Officer 10-14 Waterloo Street <br />
                Surry Hills, NSW <br />
                Australia <br />
                <Link href="mailto: Privacy@healthmatch.io">Privacy@healthmatch.io</Link>
              </address>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section11-6-summary')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.7
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-7')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                11.8
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section11-8')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section11-8-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section12-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                {t('hmio.terms.section12-1-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-1')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section12-1-summary')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                {t('hmio.terms.section12-2-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-c')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-d')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-e')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-f')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-2-g')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                {t('hmio.terms.section12-3-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section12-3')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section13-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                13.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section13-1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section13-1-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section13-1-b')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section13-1-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section14-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                14.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section14-1')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section14-1-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section15-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.1
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-1')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-2')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-2-a')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section15-2-a-summary')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-2-b')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-2-c')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-2-d')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-2-e')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-3')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.4
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-4')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section15-4-summary')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.5
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section15-5" />
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.6
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-6')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                15.7
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section15-7')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section16-title')}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                16.1
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section16-1" />
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-1-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-1-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-1-c')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section16-1-summary')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                16.2
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-2')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                16.3
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-3')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-3-a')}
              </Typography>
              <Typography variant="body2">{t('hmio.terms.section16-3-b')}</Typography>
              <ol type="i">
                <li>
                  <Typography variant="body2">{t('hmio.terms.section16-3-b-i')}</Typography>
                </li>
                <li>
                  <Typography variant="body2">{t('hmio.terms.section16-3-b-ii')}</Typography>
                </li>
              </ol>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section16-3-summary')}
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                component="h3"
                className={classes.subsection}
              >
                16.4
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section16-4')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section17-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section17')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section18-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section18')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section19-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section19" />
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section19-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section20-title')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section20-aus')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section20-aus-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section20-us-1')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section20-us-2')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section20-us-summary')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section21-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section21-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section21')}
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-1" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-2" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-3" />
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section21-3-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section21-3-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-4" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.section21-5"
                  components={{ homeLink: <RouterLink to="/" /> }}
                />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-6" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-7" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-8" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-9" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans
                  t={t}
                  i18nKey="hmio.terms.section21-10"
                  components={{ privacyLink: <RouterLink to="/privacy" /> }}
                />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-11" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-12" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-13" />
              </Typography>
              <Typography variant="body2" paragraph>
                <Trans t={t} i18nKey="hmio.terms.section21-14" />
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section22-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section22-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-c')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-d')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-e')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-f')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section22-g')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section23-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section23-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section23')}
              </Typography>

              <Typography variant="body2" paragraph>
                {t('hmio.terms.section23-a')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section23-b')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section23-c')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section23-d')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section23-e')}
              </Typography>
            </section>

            <section className={classes.section}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                className={classes.sectionHeader}
              >
                {t('hmio.terms.section24-title')}
              </Typography>
              <Typography variant="subtitle2" paragraph className={classes.summary}>
                {t('hmio.terms.section24-summary')}
              </Typography>
              <Typography variant="body2" paragraph>
                {t('hmio.terms.section24')}
              </Typography>
            </section>
          </Box>
        </Container>
      </Box>
    </PageBase>
  );
};
