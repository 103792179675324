import { FC } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { ArrowRightIcon } from 'shared/src/icon/icons';
import { purple } from 'shared/src/palette/color-system';

import { MatsLinkButton } from 'mats/src/components/mats-link/mats-link';

import { ALL_CONDITIONS_ID } from 'components/constants';
import heartIcon from 'components/images/icon-heart.svg';

type EmbeddedQuestionnaireCtaProps = {
  conditionIds?: string[];
};

const useStyles = makeStyles(({ spacing, typography }) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: spacing(2),
    height: spacing(8),
  },
  image: {
    borderRadius: '100%',
    backgroundColor: purple[50],
  },
  actionsContainer: {
    padding: spacing(2, 0, 7),
    width: spacing(55),
    margin: 'auto',
  },
  forwardIcon: {
    '&&': {
      fontSize: typography.body1.fontSize,
    },
  },
}));

export const EmbeddedQuestionnaireCta: FC<EmbeddedQuestionnaireCtaProps> = ({ conditionIds }) => {
  const classes = useStyles();

  const ctaLinkTo =
    conditionIds === undefined || conditionIds.length > 1
      ? `/signup/${ALL_CONDITIONS_ID}`
      : `/signup/${conditionIds[0]}`;

  return (
    <Box>
      <Box className={classes.imageContainer}>
        <img src={heartIcon} alt="" />
      </Box>

      <Typography align="center" variant="h5" color="textSecondary">
        You are potentially a match for one of 4,000+ clinical trials on HealthMatch today.
      </Typography>
      <Grid container spacing={1} className={classes.actionsContainer}>
        <Grid item xs={12}>
          <MatsLinkButton
            to={ctaLinkTo}
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<ArrowRightIcon className={classes.forwardIcon} />}
          >
            Check your eligibility
          </MatsLinkButton>
        </Grid>
      </Grid>
    </Box>
  );
};
