import { ComponentType, FC, ReactNode } from 'react';

import { Box, Drawer, Grid } from '@material-ui/core';
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { deepmerge } from '@material-ui/utils';

export type DrawerContentComponent = ComponentType<{
  onCloseDrawer: () => void;
}>;

export interface DrawerTriggerProps {
  layout: 'full' | 'auto';
  renderedForSEO?: boolean;
  children: ReactNode;
  isOpen: boolean;
  onToggleOpen: (isOpen?: boolean) => void;
  anchor: 'left' | 'top';
}

const useStyles = makeStyles(theme => ({
  drawerPaperFullScreen: {
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    height: '100%',
  },
  componentContainer: {
    flexGrow: 1,
    flexShrink: 0,
    paddingBottom: theme.spacing(4),
  },
}));

// The Z index on modals is set as an inline style which can't be overridden without !important.
// Instead, we can change the z-index using the theme.
export const ModalZIndexThemeProvider: FC = ({ children }) => (
  <ThemeProvider
    theme={(theme: Theme) =>
      deepmerge(theme, {
        zIndex: {
          modal: theme.zIndex.drawer,
        },
      })
    }
  >
    {children}
  </ThemeProvider>
);

export const DrawerTrigger = ({
  layout,
  renderedForSEO,
  children,
  isOpen,
  onToggleOpen,
  anchor,
}: DrawerTriggerProps) => {
  const classes = useStyles();
  const paperClassName = layout === 'full' ? classes.drawerPaperFullScreen : undefined;

  return (
    <ModalZIndexThemeProvider>
      <Drawer
        keepMounted={renderedForSEO}
        anchor={anchor}
        open={isOpen}
        onClose={() => onToggleOpen(false)}
        ModalProps={{
          hideBackdrop: true,
          disablePortal: renderedForSEO,
        }}
        classes={{ paper: paperClassName }}
        data-test-id="nav-drawer"
      >
        {/* Add space to account for the navbar */}
        <Box paddingTop={8} height="100%">
          <Grid container direction="column" wrap="nowrap" className={classes.contentContainer}>
            <Grid
              container
              direction="column"
              justifyContent={anchor === 'left' ? 'flex-start' : 'flex-end'}
              wrap="nowrap"
              className={classes.componentContainer}
            >
              {children}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </ModalZIndexThemeProvider>
  );
};
