import { gql } from '@apollo/client';
import { contentfulQueryFactory } from 'contentful-gql/src/factory';

import { ASSET_FRAGMENT } from 'shared/src/fragments/contentful/asset-fragment.contentful';
import { EMBEDDED_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/embedded-entry-fragment.contentful';
import { SLUGGABLE_ENTRY_FRAGMENT } from 'shared/src/fragments/contentful/sluggable-entry-fragment.contentful';

import { REDIRECT_FRAGMENT } from 'components/contentful-redirect/redirect.contentful';

import { FindBlogPost, FindBlogPostVariables } from './types/queries.contentful.generated';

const FIND_BLOG_POST = gql`
  query FindBlogPost($slug: String, $preview: Boolean) {
    blogPostCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      total
      items {
        sys {
          id
          publishedAt
          firstPublishedAt
        }
        author
        authorProfile {
          name
          slug
          profilePicture {
            ...AssetFragment
          }
        }
        medicalReviewer {
          name
          slug
          profilePicture {
            ...AssetFragment
          }
        }
        category
        content {
          json
          links {
            assets {
              block {
                ...AssetFragment
              }
            }
            entries {
              block {
                ...EmbeddedEntryFragment
              }
              hyperlink {
                ...SluggableEntryFragment
              }
            }
          }
        }
        date
        heroImage {
          ...AssetFragment
        }
        keywords
        title
        subtitle
        titleMeta
        descriptionMeta
        slug
      }
    }
    ...RedirectFragment
  }
  ${ASSET_FRAGMENT}
  ${EMBEDDED_ENTRY_FRAGMENT}
  ${SLUGGABLE_ENTRY_FRAGMENT}
  ${REDIRECT_FRAGMENT}
`;

export const useFindBlogPost = contentfulQueryFactory<FindBlogPost, FindBlogPostVariables>(
  FIND_BLOG_POST
);
