import { FC } from 'react';

import { di } from 'react-magnetic-di/macro';

import { PageBase } from 'components/page-base/page-base';
import { WebflowContent } from 'components/webflow-content/webflow-content';

import { WindowTitle } from '../../components/window-title/window-title';

type WebflowPageProps = {
  windowTitle?: string;
  webflowPath: string;
};

export const WebflowPage: FC<WebflowPageProps> = ({ windowTitle, webflowPath }) => {
  di(PageBase, WebflowContent);

  return (
    <PageBase windowTitle={windowTitle ?? 'easily find the right clinical trial'}>
      <WebflowContent url={webflowPath} />
    </PageBase>
  );
};

export const WebflowPageContentOnly: FC<WebflowPageProps> = ({ windowTitle, webflowPath }) => {
  di(WebflowContent);

  return (
    <>
      <WindowTitle
        hmPrefix={windowTitle == null}
        title={windowTitle ?? 'easily find the right clinical trial'}
      />
      <WebflowContent url={webflowPath} />
    </>
  );
};
