import { FC } from 'react';

import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SearchIcon } from 'shared/src/icon/icons';
import { ButtonLink } from 'shared/src/link/link';
import { gray, purple } from 'shared/src/palette/color-system';

type BrowseCardPublicProps = {
  conditionId: ConditionID;
};

const useStyles = makeStyles(({ spacing, shape }) => ({
  card: {
    height: '100%',
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${purple[500]}`,
  },
  image: {
    height: '160px',
    width: '100%',
    objectFit: 'cover',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  searchIcon: {
    color: purple[500],
    background: gray[0],
    width: spacing(5),
    height: spacing(5),
    borderRadius: shape.borderRadius,
    display: 'flex',
    alignContent: 'center',
    '& > .MuiSvgIcon-root': {
      width: spacing(3),
      height: spacing(3),
      margin: 'auto',
    },
  },
}));

export const BrowseCardPublic: FC<BrowseCardPublicProps> = ({ conditionId }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box>
        <Box marginY={5} />
        <Box marginX={3} marginY={2} display="flex">
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
        </Box>
        <Box marginX={3} marginY={2}>
          <Typography variant="h5" className={classes.title}>
            Browse 100+ other trials on HealthMatch
          </Typography>
        </Box>
        <Box marginX={3} marginY={2}>
          <Typography>
            Create an account and start answering some questions to see what trials match your
            profile.
          </Typography>
        </Box>
        <Box margin={3} marginTop={1}>
          <ButtonLink color="primary" variant="outlined" to={`/signup/${conditionId}`}>
            Find a match
          </ButtonLink>
        </Box>
      </Box>
    </Card>
  );
};
