import { FC } from 'react';

import { Box } from '@material-ui/core';

import { ConditionLinkButton } from 'components/condition-link-button/condition-link-button';

export type ConditionPageMeta = {
  condition: string;
  slug: string;
};

type RelatedConditionLinksProps = {
  links: ConditionPageMeta[];
};

export const RelatedConditionLinks: FC<RelatedConditionLinksProps> = ({ links }) => (
  <Box display="flex" justifyContent="center">
    <Box display="flex" overflow="auto" paddingX={2}>
      {links.map(link => (
        <Box paddingLeft={1} paddingBottom={2} paddingTop={1} key={link.slug}>
          <ConditionLinkButton to={`/${link.slug}`}>{link.condition}</ConditionLinkButton>
        </Box>
      ))}
    </Box>
  </Box>
);
