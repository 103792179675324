import { FC } from 'react';

import { AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { NavBar } from 'shared/src/nav-bar/nav-bar';

import {
  DrawerLoggedOutItems,
  ToolbarLoggedOutItems,
} from './components/logged-out-items/logged-out-items';
import { Logo } from './components/logo/logo';

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(12),
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(6),
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}));

type HeaderNavProps = { variant?: 'fullNav' | 'logoOnlyNav' };

export const HeaderNav: FC<HeaderNavProps> = ({ variant = 'fullNav' }) => {
  di(ToolbarLoggedOutItems, DrawerLoggedOutItems);

  const classes = useStyles();

  if (variant === 'logoOnlyNav') {
    return (
      <AppBar
        color="transparent"
        elevation={0}
        position="relative"
        component="nav"
        className={classes.appBar}
      >
        <Logo responsive />
      </AppBar>
    );
  }

  return (
    <NavBar
      logo={<Logo />}
      ToolbarContent={ToolbarLoggedOutItems}
      DrawerContent={DrawerLoggedOutItems}
    />
  );
};
