import { NormalizedCacheObject } from '@apollo/client';

import { Flags } from 'shared/src/feature-flags/config-cat-utils';
import { SupportedLocale } from 'shared/src/locales/locales';

type AppState = {
  anonymousId?: string;
  flagValues: Flags;
  locale: SupportedLocale;
  apolloCache: NormalizedCacheObject;
};

export const stringifyAppState = (appState: AppState): string =>
  `'${JSON.stringify({
    ...appState,
    flagValues: Object.fromEntries(appState.flagValues),
    // Escape existing backslashes and single quotes to make it suitable for wrapping in single quotes
  }).replace(/[\\']/g, '\\$&')}'`;

let appState: AppState | null | undefined;

export const getAppState = (): AppState | null => {
  if (appState !== undefined) {
    return appState;
  }

  try {
    if (typeof window.__APP_STATE__ !== 'undefined') {
      const appStateString = window.__APP_STATE__;
      delete window.__APP_STATE__;
      const rawAppState = JSON.parse(appStateString);
      appState = {
        ...rawAppState,
        flagValues: new Map(Object.entries(rawAppState.flagValues)),
      };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error getting app state', e);
  }

  appState ??= null;

  return appState;
};
