import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import { FontFiles } from './font-files';

export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightBold = 700;

export const latoFontFamily = 'Lato, Arial, sans-serif';
export const nantesFontFamily = 'Nantes, Georgia, serif';

type FontFace = Exclude<NonNullable<BaseCSSProperties['@font-face']>, Array<unknown>>;

export const getFontFaces = ({
  LatoBoldWoff,
  LatoBoldWoff2,
  LatoBoldItalicWoff,
  LatoBoldItalicWoff2,
  LatoItalicWoff,
  LatoItalicWoff2,
  LatoLightWoff,
  LatoLightWoff2,
  LatoLightItalicWoff,
  LatoLightItalicWoff2,
  LatoWoff,
  LatoWoff2,
  NantesBoldWoff,
  NantesBoldWoff2,
  NantesBoldItalicWoff,
  NantesBoldItalicWoff2,
}: FontFiles): FontFace[] => [
  {
    fontFamily: 'Nantes',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: fontWeightBold,
    src: `
    url(${NantesBoldWoff2}) format('woff2'),
    url(${NantesBoldWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Nantes',
    fontStyle: 'italic',
    fontDisplay: 'fallback',
    fontWeight: fontWeightBold,
    src: `
    url(${NantesBoldItalicWoff2}) format('woff2'),
    url(${NantesBoldItalicWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: fontWeightRegular,
    src: `
    url(${LatoWoff2}) format('woff2'),
    url(${LatoWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontDisplay: 'fallback',
    fontWeight: fontWeightRegular,
    src: `
    url(${LatoItalicWoff2}) format('woff2'),
    url(${LatoItalicWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: fontWeightLight,
    src: `
    url(${LatoLightWoff2}) format('woff2'),
    url(${LatoLightWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontDisplay: 'fallback',
    fontWeight: fontWeightLight,
    src: `
    url(${LatoLightItalicWoff2}) format('woff2'),
    url(${LatoLightItalicWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: fontWeightBold,
    src: `
    url(${LatoBoldWoff2}) format('woff2'),
    url(${LatoBoldWoff}) format('woff')
  `,
  },
  {
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontDisplay: 'fallback',
    fontWeight: fontWeightBold,
    src: `
    url(${LatoBoldItalicWoff2}) format('woff2'),
    url(${LatoBoldItalicWoff}) format('woff')
  `,
  },
];
