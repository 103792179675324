import { FC, useCallback, useState } from 'react';

import { Box, Container, duration, makeStyles, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';

import { EmbeddedQuestionnaireCta } from 'pages/condition-article/embedded-questionnaire/embedded-questionnaire-cta/embedded-questionnaire-cta';

import { EmbeddedQuestionnaireCard } from './embedded-questionnaire-card/embedded-questionnaire-card';

type EmbeddedQuestionnaireProps = {
  conditionName: string;
  conditionIds?: string[];
  questionOverrides?: string[];
};

const useStyles = makeStyles(({ transitions, spacing }) => ({
  wrapper: {
    position: 'relative',
    // cards don't take up vertical space due to stacking...
    height: spacing(40),
    margin: spacing(8, 'auto', 4),
  },
  ctaEnterDone: {
    opacity: 1,
    transform: 'translateY(0)',
    '@media (prefers-reduced-motion: no-preference)': {
      transition: transitions.create(['opacity', 'transform'], {
        duration: duration.enteringScreen,
      }),
    },
  },
  ctaEnterActive: {
    opacity: 0,
    transform: 'translateY(50%)',
  },
  cardExit: {
    opacity: 1,
    transform: 'translateY(0)',
    '@media (prefers-reduced-motion: no-preference)': {
      transition: transitions.create(['opacity', 'transform'], {
        duration: duration.leavingScreen,
      }),
    },
  },
  cardExitActive: {
    opacity: 0,
    transform: 'translateY(-50%)',
  },
}));

export const EmbeddedQuestionnaire: FC<EmbeddedQuestionnaireProps> = ({
  questionOverrides,
  conditionName,
  conditionIds,
}) => {
  di(EmbeddedQuestionnaireCard);

  const { pathname } = useLocation();

  const DEFAULT_EMBEDDED_QUESTIONS = [
    `Have you been diagnosed with ${conditionName}?`,
    `Have you taken medication for ${conditionName}?`,
    `Do you want to know if there are any ${conditionName} clinical trials you might be eligible for?`,
  ];

  const classes = useStyles();

  const [questionIndex, setQuestionIndex] = useState(3);
  const [activeCardState, setActiveCardState] = useState(true);

  const onActiveCardExited = useCallback(() => {
    setQuestionIndex(questionIndex - 1);
    setActiveCardState(true);
  }, [questionIndex]);

  const onActiveCardAction = useCallback(() => {
    setActiveCardState(false);
  }, []);

  const questions =
    questionOverrides && questionOverrides.length === 3
      ? questionOverrides
      : DEFAULT_EMBEDDED_QUESTIONS;

  if (pathname.includes('vaccine')) {
    return null;
  }

  return (
    <Box mt={6}>
      <Typography variant="h3" align="center">
        Discover which clinical trials you are eligible for
      </Typography>
      <Container maxWidth="sm" className={classes.wrapper}>
        <CSSTransition
          in={questionIndex === 0}
          unmountOnExit
          classNames={{
            enterActive: classes.ctaEnterActive,
            enterDone: classes.ctaEnterDone,
          }}
          timeout={duration.enteringScreen}
        >
          <EmbeddedQuestionnaireCta conditionIds={conditionIds} />
        </CSSTransition>
        {questionIndex !== 0 &&
          questions
            .slice(-questionIndex)
            .reverse()
            .map((question, index) => {
              const isActiveCard = index === questionIndex - 1;

              return (
                <CSSTransition
                  in={!isActiveCard || activeCardState}
                  classNames={{
                    exit: classes.cardExit,
                    exitActive: classes.cardExitActive,
                  }}
                  timeout={duration.leavingScreen}
                  key={question}
                  onExited={onActiveCardExited}
                >
                  <EmbeddedQuestionnaireCard
                    offset={questionIndex - index}
                    questionText={question}
                    onActionClick={onActiveCardAction}
                  />
                </CSSTransition>
              );
            })}
      </Container>
    </Box>
  );
};
