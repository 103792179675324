import { FC, useContext } from 'react';

import { Link } from 'shared/src/link/link';

import { ContentfulLinkContext } from './context';

// An alternative to `UnreachableError` that renders children instead of throwing
// for cases when someone changes allowed links in Contentful with changing the codes
const RenderUnreachable: FC<{ link: never }> = ({ children }) => <>{children}</>;

export const InlineEntryLink: FC<{ id: string }> = ({ children, id }) => {
  const { links } = useContext(ContentfulLinkContext);

  const link = links.get(id);

  if (!link) {
    return <>{children}</>;
  }

  switch (link.__typename) {
    case 'ConditionCategory':
    case 'ConditionArticle':
      return <Link to={`/${link.slug}`}>{children}</Link>;
    case 'MedicationArticle':
      return <Link to={`/medications/${link.slug}`}>{children}</Link>;
    case 'BlogPost':
      return <Link to={`/blog/${link.slug}`}>{children}</Link>;

    case 'ConditionBodySection': {
      const slug = link.linkedFrom?.conditionCategoryCollection?.items[0]?.slug;

      if (!slug) {
        return <>{children}</>;
      }

      return <Link to={`/${slug}#${link.type}`}>{children}</Link>;
    }

    case 'BlogsIndexPage':
    case 'ConditionAuthor':
    case 'ConditionLandingPage':
    case 'ConditionLink':
    case 'ConditionResource':
    case 'ContentTypeEntryCollection':
    case 'EnhancedTrialCard':
    case 'FaqEntry':
    case 'Foundation':
    case 'HealthcareStat':
    case 'NewsLink':
    case 'PatientsStory':
    case 'Redirect':
    case 'TopicHubPage':
    case 'ContentHub':
      // unknown how to link to others as of yet...
      return <>{children}</>;

    default:
      // This should fail if SluggableEntryFragment gets updated without
      return <RenderUnreachable link={link}>{children}</RenderUnreachable>;
  }
};
