import { makeStyles } from '@material-ui/core';

import { darkPurple, lightPurple } from 'shared/src/palette/color-system';

const useWaveStyles = makeStyles(({ spacing }) => ({
  wave: {
    height: spacing(12),
    position: 'relative',
    maxWidth: '100vw',
    overflow: 'hidden',
  },
  waveImage: {
    minWidth: '100%',
    height: spacing(12),
  },
}));

export const Wave = ({ background }: { background: string | undefined }) => {
  const styles = useWaveStyles();

  return (
    <div className={styles.wave} style={{ background }}>
      <svg
        className={styles.waveImage}
        viewBox="0 0 1440 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 29.8102V99.6204H1440V29.8102C1440 29.8102 1291 70 927.5 29.8102C442.5 -37.2627 0 29.8102 0 29.8102Z"
          fill={lightPurple[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 58.1605V154.8H1440V76.7898C1361.95 58.1607 1309.41 58.1607 1207.34 58.1607C1170.73 58.1607 1113.56 60.2522 1043.7 62.8083C870.087 69.1602 618.07 78.3808 408.284 65.4997C114.079 47.4353 0 58.1605 0 58.1605Z"
          fill={darkPurple[500]}
        />
      </svg>
    </div>
  );
};
