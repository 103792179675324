import { gql } from '@apollo/client';

import { mutationFactory } from 'shared/src/util/query-factory';

import {
  SendConversionsTrackingEvent,
  SendConversionsTrackingEventVariables,
} from './types/queries.generated';

const SEND_CONVERSIONS_TRACKING_EVENT = gql`
  mutation SendConversionsTrackingEvent($input: ConversionsEventInput!) {
    sendConversionsTrackingEvent(input: $input) {
      status
    }
  }
`;

export const useSendConversionsEventMutation = mutationFactory<
  SendConversionsTrackingEvent,
  SendConversionsTrackingEventVariables
>(SEND_CONVERSIONS_TRACKING_EVENT);
