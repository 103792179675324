import { FC } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichText } from 'shared/src/contentful-text/rich-text-renderer';

import { ContentThemeProvider } from 'components/content-theme/content-theme';
import { FootNotes } from 'components/foot-notes/foot-notes';
import { ConditionCategoryBySlugContent } from 'pages/condition-category/queries.contentful';

const inlineImageMaxWidth = 640;

type Section = DeepExtractTypeSkipArrays<
  ConditionCategoryBySlugContent,
  ['sectionsCollection', 'items']
>;

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    maxWidth: inlineImageMaxWidth,
    marginTop: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ConditionBodySection: FC<{ section: Section }> = ({ section }) => {
  const classes = useStyles();

  return (
    <>
      <ContentThemeProvider>
        <Typography variant="h3">{section.type}</Typography>
        {section.heroImage && (
          <ContentfulImage
            className={classes.image}
            image={section.heroImage}
            maxWidth={inlineImageMaxWidth}
          />
        )}
        {section.textBody && (
          <Box marginTop={5}>
            <RichText document={section.textBody} />
          </Box>
        )}
      </ContentThemeProvider>
      {/* Wrap Accordion in a separate Box so it's a first child and doesn't show a delimiter before itself */}
      {section.references && (
        <Box marginTop={8}>
          <FootNotes title="Sources">
            <RichText document={section.references} />
          </FootNotes>
        </Box>
      )}
    </>
  );
};
