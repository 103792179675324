import { FC } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { ContentfulDate } from 'shared/src/contentful-text/components/date';
import { PageInfoIcon } from 'shared/src/icon/icons';

type DateMetaProps = {
  date: string;
  dateDescriptor: string;
};

const useStyles = makeStyles(({ spacing }) => ({
  svgIcon: {
    height: spacing(5),
    width: spacing(5),
  },
}));

const DateMeta: FC<DateMetaProps> = ({ date, dateDescriptor }) => {
  const classes = useStyles();

  return (
    <Box alignItems="center" display="flex">
      <PageInfoIcon className={classes.svgIcon} />
      <Box paddingLeft={1}>
        <Typography variant="body1" color="textSecondary" noWrap>
          {dateDescriptor}
        </Typography>
        <Typography variant="body1" color="textSecondary" noWrap>
          <b>
            <ContentfulDate date={date} format="MMMM d, yyyy" />
          </b>
        </Typography>
      </Box>
    </Box>
  );
};

export const UpdatedAt: FC<{ date: string }> = ({ date }) => (
  <DateMeta date={date} dateDescriptor="Updated at" />
);
