import { useMemo } from 'react';

import { GetConditions } from 'shared/src/condition-select/types/queries.generated';

export const useMemoizeAndMakeConditionOption = (
  conditions: GetConditions['conditions'],
  existingConditions: ConditionID[]
) =>
  // Prevent needless re-renders of Autocomplete by memoizing the massaged data
  useMemo(
    () =>
      conditions
        .map(condition => ({
          id: condition.id,
          aliases: condition?.aliases
            .filter(alias => alias !== condition?.name) // Remove aliases if they are same as name. TODO fix on the server side
            .sort((a, b) => a.localeCompare(b)),
          value: condition?.name,
          label: condition?.name,
          prevAdded: existingConditions?.includes(condition?.id),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [conditions, existingConditions]
  );
