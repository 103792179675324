import { FC } from 'react';

import { Button, Card, Grid, makeStyles, Typography, TypographyStyle } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { ConditionLandingPageFragmentMinimal } from 'shared/src/fragments/contentful/types/condition-landing-page.contentful.generated';
import { gray } from 'shared/src/palette/color-system';

type Props = {
  condition: ConditionLandingPageFragmentMinimal;
};

const textLines = 3;
const getBoxHeight = (style: TypographyStyle, fontSize: number, lines: number) =>
  (Number(style.lineHeight) || 1) * fontSize * lines;

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  container: {
    height: '100%',
    paddingBottom: theme.spacing(3),
  },
  image: {
    height: theme.spacing(32),
    width: '100%',
    objectFit: 'contain',
    backgroundColor: gray[50],
  },
  content: {
    margin: theme.spacing(0, 3),
  },
  title: {
    marginTop: theme.spacing(3),
  },
  text: {
    margin: theme.spacing(2, 0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // Magic clamp the text to 3 lines
    display: '-webkit-box',
    lineClamp: textLines,
    boxOrient: 'vertical',
    minHeight: getBoxHeight(theme.typography.body1, 14, textLines),
  },
}));

export const ConditionCard: FC<Props> = ({ condition }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Grid className={classes.container} container direction="column" wrap="nowrap">
        {condition.contentImage && (
          <Grid item>
            <ContentfulImage
              className={classes.image}
              maxWidth="sm"
              image={condition.contentImage}
            />
          </Grid>
        )}
        <Grid className={classes.content} item xs={12}>
          <Typography className={classes.title} variant="h5" component="h3">
            {condition.condition}
          </Typography>
        </Grid>
        <Grid className={classes.content} item>
          <Typography className={classes.text}>{condition.conditionCardText}</Typography>
        </Grid>
        <Grid className={classes.content} item>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={`/patients/${condition.slug}`}
          >
            Resources
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
