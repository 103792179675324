import { forwardRef, useState, ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';

type ButtonChromelessProps = {
  className?: string;
  onClick(): void;
  children?: ReactNode;
};

const useStyles = makeStyles(() => ({
  chromelessButton: {
    cursor: 'pointer',
    outline: 'none',
  },
}));

/**
 * > This button has no STYLE...
 * > It has no grace...
 * > This button has a small interface...
 * >
 * > - Donkey Kong Rap (probably)
 *
 * Use this when you need a clickable element but don't want
 * the baggage of button element.
 */
export const ButtonChromeless = forwardRef<HTMLDivElement, ButtonChromelessProps>(
  ({ className, onClick, children }, ref) => {
    const classes = useStyles();
    const [pressed, setPressed] = useState(false);

    const onPointerDown = () => setPressed(true);
    const onPointerUp = () => setPressed(false);
    const onKeyDown = ({ key }: { key: string }) => {
      if (key === 'Enter' || key === 'Space') {
        onClick();
      }
    };

    return (
      <div
        ref={ref}
        aria-pressed={pressed}
        className={`${classes.chromelessButton} ${className}`}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }
);
