import { ReactNode } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export type RenderWithClassName = (className: string) => ReactNode;

type InformationSnippetProps = {
  renderIcon: RenderWithClassName;
  title: string;
  subtitle: string;
  size?: 'large' | 'small';
};

const useStyles = makeStyles<Theme, { size: 'large' | 'small' }>(({ spacing, breakpoints }) => ({
  icon: {
    width: ({ size }) => (size === 'large' ? spacing(10) : spacing(5)),

    height: ({ size }) => (size === 'large' ? spacing(10) : spacing(5)),
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      marginBottom: 0,
      padding: spacing(1),
    },
  },
  container: {
    maxWidth: spacing(45),
    margin: 'auto',
    height: ({ size }) => (size === 'large' ? spacing(35) : 'initial'),
    [breakpoints.down('sm')]: {
      maxWidth: 'initial',
      width: 'inherit',
      height: 'inherit',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

export const InformationSnippet = ({
  renderIcon,
  title,
  subtitle,
  size,
}: InformationSnippetProps) => {
  const classes = useStyles({ size: size ?? 'large' });

  return (
    <Grid className={classes.container} item>
      <Grid className={classes.titleContainer} item>
        {renderIcon(classes.icon)}
        <Typography variant={size === 'large' ? 'h4' : 'h6'}>{title}</Typography>
      </Grid>
      <Typography color="textSecondary">{subtitle}</Typography>
    </Grid>
  );
};
