
export function assertExists<T>(value: T | undefined | null, message?: string): T {
  if (value == null) {
    const errorMessage = message ?? (
        `assertion failed, value is not defined${message ? `: ${message}` : ''}`
    );
    throw new Error(errorMessage);
  }

  return value;
}
