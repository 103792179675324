import { FC, MouseEvent, useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { getIsServer } from 'shared/src/util/get-is-server';

// TODO: share these webflow constants with the server
const WEBFLOW_PAGE_DIV_ID = 'webflow-page-meta';
const WEBFLOW_DATA_PAGE_ATTR = 'data-wf-page';

const MIDDLE_CLICK = 1;

/**
 * Re-attach the data-wf-page attribute to the document's html tag and re-init the webflow interactions engine.
 * Webflow requires this for its interactions to work for some reason.
 * A normal webflow page would have the data-wf-page attribute on the html tag by default, but we need to do it manually
 * because we're just scraping some of the content and re-inserting it into the DOM
 * https://forum.pinegrow.com/t/possible-webflow-javascript-animations-issue-interactions-2-0/3570
 */
const restartWebflowInteractionsEngine = () => {
  const dataWfPage = document
    .querySelector(`#${WEBFLOW_PAGE_DIV_ID}`)
    ?.attributes.getNamedItem(WEBFLOW_DATA_PAGE_ATTR)?.value;
  if (dataWfPage) {
    document.querySelector('html')?.setAttribute(WEBFLOW_DATA_PAGE_ATTR, dataWfPage);
  }
  if (window.Webflow) {
    window.Webflow.require('ix2').init();
  }
};

export const WebflowContent: FC<{ url: string }> = ({ url }) => {
  const [forceFetch, setForceFetch] = useState(false);
  const shouldFetch = getIsServer() || forceFetch;
  const { data: html } = useSWR(shouldFetch ? `/static-pages${url}` : null);

  const history = useHistory();

  const containerRef = useCallback(
    async (node: HTMLDivElement | null) => {
      // Trigger downloading HTML if not hydrating
      if (node && !node.firstChild) {
        setForceFetch(true);
      }

      restartWebflowInteractionsEngine();

      // Re-attach scripts to load and execute them sequentially in downloaded HTML because
      // innerHTML doesn't do that for us
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#security_considerations
      if (node?.firstChild && forceFetch && html) {
        for (const script of node.querySelectorAll('script')) {
          // eslint-disable-next-line no-await-in-loop
          await new Promise<void>(resolve => {
            const clone = document.createElement('script');
            clone.textContent = script.textContent;
            for (const attr of script.attributes) {
              clone.setAttribute(attr.name, attr.value);
            }
            const parent = script.parentElement;
            script.remove();

            // If the script is remote, wait for it to load before appending the next script.
            // Some of the webflow scripts depend on globals defined in the previous script.
            if (clone.attributes.getNamedItem('src')) {
              clone.onload = () => {
                resolve();
              };
              clone.onerror = () => {
                resolve();
              };
            } else {
              resolve();
            }

            parent?.appendChild(clone);
          });
        }
      }
    },
    [setForceFetch, forceFetch, html]
  );

  const handleClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    const openInNewTab =
      event.ctrlKey || event.shiftKey || event.metaKey || event.button === MIDDLE_CLICK;
    if (openInNewTab) {
      return;
    }

    const link = event.target instanceof window.HTMLElement ? event.target.closest('a') : null;
    // Unlike `href` property, `href` attribute returns URL just as it was authored
    const href = link?.getAttribute('href');
    // Only change same-site absolute link behaviour
    if (href?.startsWith('/') && link?.target !== '_blank') {
      event.preventDefault();
      history.push(href);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      id="static-page"
      onClick={handleClick}
      ref={containerRef}
      dangerouslySetInnerHTML={{ __html: html ?? '' }}
      suppressHydrationWarning
    />
  );
};
