import { Box, Button, Container, Grid, Typography } from '@material-ui/core';

import { FullWidthImage } from 'shared/src/image/image';

import illustration from './images/community-illustration.svg';

export const CommunitySection = () => (
  <Box paddingY={{ xs: 7, md: 10 }}>
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} sm={5}>
          <Typography variant="h2" gutterBottom>
            Our community
          </Typography>
          <Typography paragraph>
            We are a diverse team of doctors, engineers, scientists and people dedicated to
            challenging the status quo of medical research.
          </Typography>
          <Typography paragraph>
            We are united by a passion to deliver better healthcare options, for all, regardless of
            location, background or means. This means access to trials and the revolutionary
            treatments that come from them.
          </Typography>
          <Typography paragraph>
            We are supported by a growing community of doctors, patients, non-for profit
            organisations and advocacy groups who believe in equitable access to treatment options.
            Our innovative partners are working tirelessly on the next breakthroughs and cures.
          </Typography>
          <Typography paragraph>
            We are backed by forward thinking investors that think big and invest for a better world
            tomorrow.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="https://jobs.lever.co/health-match"
          >
            Join our mission
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Box paddingY={3} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FullWidthImage src={illustration} alt="" />
        </Grid>
      </Grid>
    </Container>
  </Box>
);
