import { ApolloError } from '@apollo/client/errors';
import { di } from 'react-magnetic-di/macro';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { EnhancedTrialCardFields } from 'shared/src/trials/components/trial-card/trial-card-details/trial-card-details';
import { notEmpty } from 'shared/src/util/not-empty';

import { useRawGetEnhancedTrialCardsByTrialId } from './queries.contentful';
import { GetEnhancedTrialCardsByTrialId } from './types/queries.contentful.generated';

type EnhancedTrialCard = DeepExtractTypeSkipArrays<
  GetEnhancedTrialCardsByTrialId,
  ['enhancedTrialCardCollection', 'items']
>;

const normalizeCards = (card: EnhancedTrialCard | null): EnhancedTrialCardFields | null =>
  card?.trialId == null
    ? null
    : {
        ...card,
        trialId: card.trialId,
        appointmentOptions: card.appointmentOptions?.filter(notEmpty) ?? null,
      };

export const useEnhancedTrialCardsQuery = (
  trialIds: string[],
  skipQuery: boolean
): {
  data: EnhancedTrialCardFields[];
  loading: boolean | undefined;
  error: ApolloError | undefined;
} => {
  di(useRawGetEnhancedTrialCardsByTrialId);

  const { data, loading, error } = useRawGetEnhancedTrialCardsByTrialId({
    variables: { trialIds, maxTrials: trialIds.length },
  });

  if (skipQuery) {
    return { data: [], loading: undefined, error: undefined };
  }

  const enhancedTrialCards =
    data?.enhancedTrialCardCollection?.items?.map(normalizeCards).filter(notEmpty) ?? [];

  return { data: enhancedTrialCards, loading, error };
};
