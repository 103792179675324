import { FC } from 'react';

import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { gray } from 'shared/src/palette/color-system';

import {
  patientClinicalTrialsLinks,
  patientGuideLinks,
  patientTopicLinks,
} from 'components/header-nav/components/links/links';
import guidesGraphic from 'components/header-nav/components/patients/guides-graphic.svg';

import { LinkSection } from '../links/link-section';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  guide: {
    alignItems: 'flex-start',
    display: 'grid',
    [breakpoints.up('md')]: {
      gridGap: spacing(14),
      gridTemplateColumns: spacing(36, '1fr'),
      justifyContent: 'center',
      margin: '0 auto',
    },
  },
  startColumn: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: spacing(3),
    // Fallback to ensure client side rendered doesn't
    // display the graphic. But the component should also
    // avoid rendering the column for the layout
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  startColumnGraphic: {
    borderRadius: spacing(2.5),
  },
  sectionsDiv: {
    color: gray[1000],
    display: 'grid',
    height: '100%',
    width: '100%',
    justifySelf: 'flex-start',
    [breakpoints.down('sm')]: {
      gridAutoFlow: 'row',
      gridGap: spacing(5, 0),
    },
    [breakpoints.up('sm')]: {
      gridAutoFlow: 'column',
      gridGap: spacing(4.5, 4),
      gridTemplateRows: 'repeat(10, 1fr)',
      gridTemplateColumns:
        '[clinical-trials] minmax(auto, 1fr) [topics] minmax(auto, 1fr) [guides] minmax(auto, 1fr)',
    },
  },
}));

export const Patients: FC<{
  showGraphic: boolean;
}> = ({ showGraphic }) => {
  const classes = useStyles();
  const spaceForGraphic = useMediaQuery((t: Theme) => t.breakpoints.up('md'));

  return (
    <div className={classes.guide} data-test-id="global-navigation-guides">
      {spaceForGraphic && showGraphic && (
        <div className={classes.startColumn}>
          {/* decorative image, so no alt text */}
          <img className={classes.startColumnGraphic} alt="" src={guidesGraphic} />
          <Typography variant="h6">Everything you need to know in one place.</Typography>
        </div>
      )}
      <div className={classes.sectionsDiv}>
        <LinkSection
          sectionTitle="Clinical trials"
          gridColumnName="clinical-trials"
          links={patientClinicalTrialsLinks}
        />
        <LinkSection sectionTitle="Topics" gridColumnName="topics" links={patientTopicLinks} />
        <LinkSection
          sectionTitle="Guides"
          gridColumnName="guides"
          links={patientGuideLinks}
          allLinkText="All guides"
          allLinkTo="/guides"
        />
      </div>
    </div>
  );
};
