import { FC } from 'react';

import { Box, Container } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { notEmpty } from 'shared/src/util/not-empty';

import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { MainContentSection } from 'pages/condition-landing-page/components/main-content-section/main-content-section';
import { TitleSection } from 'pages/condition-landing-page/components/title-section/title-section';
import { NotFound } from 'pages/not-found/not-found';

import { FAQSection } from './components/faq-section/faq-section';
import { FooterSection } from './components/footer-section/footer-section';
import { StatsSection } from './components/stats-section/stats-section';
import { SupportSection } from './components/support-section/support-section';
import { useConditionLandingPageBySlug } from './queries.contentful';
import { ConditionLandingPageBySlug } from './types/queries.contentful.generated';

type LandingPage = DeepExtractTypeSkipArrays<
  ConditionLandingPageBySlug,
  ['conditionLandingPageCollection', 'items']
>;

export const ConditionLandingPage: FC<{ landingPage: LandingPage }> = ({ landingPage }) => {
  di(useConditionLandingPageBySlug, TitleSection, FooterSection, PageBase);

  const stats = landingPage.statsCollection?.items.filter(notEmpty) ?? [];
  const resources = landingPage.resourcesCollection?.items.filter(notEmpty) ?? [];
  const faqs = landingPage.faqsCollection?.items.filter(notEmpty) ?? [];

  return (
    <PageBase windowTitle={landingPage.windowTitle ?? landingPage.contentTitle ?? ''}>
      {landingPage.mainImage && landingPage.introParagraph && (
        <TitleSection
          titleText={landingPage.title as string}
          introContent={landingPage.introParagraph}
          mainImage={landingPage.mainImage}
          featuredCondition={landingPage.conditionId as ConditionID}
        />
      )}
      {landingPage.contentTitle && landingPage.mainContent && landingPage.contentImage ? (
        <MainContentSection
          title={landingPage.contentTitle}
          mainContent={landingPage.mainContent}
          mainContentImage={landingPage.contentImage}
        />
      ) : null}
      {stats && stats.length > 0 ? (
        <Box bgcolor={`#${landingPage.themeColor}`} py={{ xs: 7, sm: 10 }}>
          <Container>
            <StatsSection stats={stats} />
          </Container>
        </Box>
      ) : null}
      {landingPage.foundation && resources.length > 0 ? (
        <SupportSection foundation={landingPage.foundation} resources={resources} />
      ) : null}
      {faqs.length > 0 ? <FAQSection faqs={faqs} /> : null}
      <FooterSection
        footerTitle={landingPage.conditionSearchFooterTitle ?? undefined}
        referencesFooter={landingPage.referencesFooter}
        featuredCondition={landingPage.conditionId as ConditionID}
      />
    </PageBase>
  );
};

export const ConditionLandingPageRoute = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const { data, error, loading } = useConditionLandingPageBySlug({
    variables: {
      slugForLandingPage: match.params.slug,
      slug: `patients/${match.params.slug}`,
    },
  });

  let newSlug: string | undefined;
  if (data?.redirectCollection?.items?.[0]?.newPage?.slug != null) {
    newSlug = `/${data.redirectCollection.items[0].newPage.slug}/clinical-trials`;
  }

  if (newSlug != null) {
    return <Redirect to={newSlug} />;
  }

  if (loading) {
    return <LoadingPage windowTitle="Loading" />;
  }

  if (error || !data?.conditionLandingPageCollection?.items?.[0]) {
    return <NotFound />;
  }

  return <ConditionLandingPage landingPage={data.conditionLandingPageCollection.items[0]} />;
};
