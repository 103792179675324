import { useTranslation } from 'react-i18next';
import { di } from 'react-magnetic-di/macro';

import { useLocaleFromCookie } from 'shared/src/cross-origin-cookies/cross-origin-cookies';
import { useLocale } from 'shared/src/locale-provider/locale-provider';
import {
  graphqlLocaleCodeToSupportedLocale,
  localeToGraphqlLocaleCode,
  SupportedLocale,
} from 'shared/src/locales/locales';

import { useAuth } from 'mats/src/components/auth/use-auth';
import { usePatientBasicInfoQuery, useUpdatePatient } from 'mats/src/hooks/patient/queries';

type HookReturn = {
  locale: SupportedLocale;
  setLocale: (locale: SupportedLocale) => void;
};

/**
 * Get and set the current patient's locale (if any) from the backend if they've signed up,
 * from sessionstorage if they're in the process of signing up, or fall back to browser locale
 * if none of the above.
 */
export const usePatientLocale = (): HookReturn => {
  di(
    useAuth,
    useLocale,
    useLocaleFromCookie,
    usePatientBasicInfoQuery,
    useUpdatePatient,
    useTranslation
  );

  const { i18n } = useTranslation();

  const { isAuthenticated } = useAuth();

  const browserLocale = useLocale();
  const [cookieLocale, setCookieLocale] = useLocaleFromCookie();
  const { data: getPatientLocale } = usePatientBasicInfoQuery({
    skip: !isAuthenticated,
  });

  const [updatePatient, { client }] = useUpdatePatient();

  let locale: SupportedLocale = browserLocale;

  if (isAuthenticated && getPatientLocale != null) {
    locale = graphqlLocaleCodeToSupportedLocale(getPatientLocale.patient.localeWithInfo.code);
  } else if (cookieLocale != null) {
    locale = cookieLocale;
  }

  const setLocale = async (newLocale: SupportedLocale) => {
    if (!isAuthenticated) {
      // No patient signed in, so store in session storage
      setCookieLocale(newLocale);
    } else {
      await updatePatient({
        variables: {
          input: {
            locale: localeToGraphqlLocaleCode(newLocale),
          },
        },
      });
      await client.resetStore();
    }
    i18n.changeLanguage(newLocale);
  };

  return { locale, setLocale };
};
