import { RefObject, useEffect, useState } from 'react';

import { useIntersection } from 'react-use';

type StickyIntersection = {
  hasIntersected: boolean;
};

export const useStickyIntersection = (
  ref: RefObject<HTMLElement>,
  init: IntersectionObserverInit
): (IntersectionObserverEntry & StickyIntersection) | null => {
  const [ret, setRet] = useState<(IntersectionObserverEntry & StickyIntersection) | null>(null);
  const intersection = useIntersection(ref, init);

  useEffect(() => {
    if (intersection !== null) {
      setRet({
        ...intersection,
        hasIntersected: ret?.hasIntersected || intersection.isIntersecting,
      });
    }
  }, [intersection, ret?.hasIntersected]);

  return ret;
};
