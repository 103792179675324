import { FC, Fragment } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { RichText, RichTextData } from 'shared/src/contentful-text/rich-text-renderer';

type FaqRecordShape = {
  sys: { id: string };
  question: string | null;
  answer: RichTextData | null;
};

type FaqSectionProps = {
  faqs: FaqRecordShape[];
  spacing: number;
};

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    paddingBottom: spacing(2),
  },
}));

export const FaqSection: FC<FaqSectionProps> = ({ faqs, spacing }) => {
  const classes = useStyles();

  return (
    <>
      {faqs.map((f, index) => {
        if (!f.answer || !f.question) {
          return null;
        }

        return (
          <Fragment key={f.sys.id}>
            <Typography variant="h6" className={classes.title}>
              {f.question}
            </Typography>
            <RichText document={f.answer} />
            {index !== faqs.length - 1 && <Box paddingBottom={spacing} />}
          </Fragment>
        );
      })}
    </>
  );
};
