import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { lightGreen, orange } from 'shared/src/palette/color-system';
import { StatusPill } from 'shared/src/status-pill/status-pill';
import { RecruitingStatus } from 'shared/src/types/graphql-schema';

type RecruitingPillProps = {
  status: RecruitingStatus;
};

export const RecruitingPill: FC<RecruitingPillProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <StatusPill
      text={
        status === RecruitingStatus.recruiting
          ? t('mats.trialsPage.statusRecruiting')
          : t('mats.trialsPage.statusNotYetOpen')
      }
      textColor={status === RecruitingStatus.recruiting ? lightGreen[600] : orange[500]}
      backgroundColor={status === RecruitingStatus.recruiting ? lightGreen[50] : orange[50]}
    />
  );
};
