import { ComponentType, FC, useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import { lightPurple } from 'shared/src/palette/color-system';

import { AuthorAbout, AuthorProfilePicture, AuthorTitle } from 'components/author/author';
import { ArticleLink, ContentLinks } from 'components/content-links/content-links';
import { ErrorPage } from 'components/error/error-page';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';

import { Author, useBlogAuthor } from './queries.contentful';

type State =
  | { kind: 'error' }
  | { kind: 'loading' }
  | { kind: 'not-found' }
  | {
      kind: 'show';
      author: Author;
      posts?: ArticleLink[];
      categories?: ArticleLink[];
      articles?: ArticleLink[];
      medArticles?: ArticleLink[];
    };

// despite the fact that "blog posts" are conditionally
// rendered, this page is never shown unless there are blog posts.
const FOOTER_BLEED = { kind: 'background', color: lightPurple[50] } as const;

export const BlogAuthorPage: FC<RouteComponentProps<{ slug: string }>> = ({ match }) => {
  const { data, error, loading } = useBlogAuthor({ slug: match.params.slug });

  let state: State;
  if (loading) {
    state = { kind: 'loading' };
  } else if (error || !data) {
    state = { kind: 'error' };
  } else {
    const { author, posts, categories, articles, medArticles } = data;
    state = { kind: 'show', author, posts, categories, articles, medArticles };
  }

  switch (state.kind) {
    case 'error':
      return <ErrorPage />;

    case 'loading':
      return <LoadingPage windowTitle="Author" />;
    default:
      break;
  }

  const windowTitle = state.author.name ?? 'Author';

  return (
    <PageBase windowTitle={windowTitle} footerBleed={FOOTER_BLEED}>
      <BlogAuthor
        author={state.author}
        posts={state.posts}
        categories={state.categories}
        articles={state.articles}
        medArticles={state.medArticles}
      />
    </PageBase>
  );
};

const useStyles = makeStyles(theme => ({
  author: {
    display: 'grid',
    gridGap: theme.spacing(3),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  links: {
    width: '100%',
    background: lightPurple[50],
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}));

export const BlogAuthor: ComponentType<{
  author: Author;
  posts?: ArticleLink[];
  categories?: ArticleLink[];
  articles?: ArticleLink[];
  medArticles?: ArticleLink[];
}> = ({ author, posts, categories, articles, medArticles }) => {
  const classes = useStyles();
  const { about, profilePicture, name } = author;

  const combinedArticles = useMemo(
    () =>
      [...(posts ?? []), ...(articles ?? []), ...(medArticles ?? []), ...(categories ?? [])].sort(
        (a, b) => {
          if (a.sys.publishedAt == null || b.sys.publishedAt == null) {
            return 0;
          }

          return new Date(a.sys.publishedAt).getDate() - new Date(b.sys.publishedAt).getDate();
        }
      ),
    [articles, categories, medArticles, posts]
  );

  return (
    <>
      <div className={classes.author}>
        {profilePicture && <AuthorProfilePicture profilePicture={profilePicture} label="Author" />}
        {name && <AuthorTitle name={name} />}
        {about && <AuthorAbout about={about} />}
      </div>
      {combinedArticles && combinedArticles.length > 0 && (
        <div className={classes.links}>
          <ContentLinks title={`Articles written by ${author.name}`} links={combinedArticles} />
        </div>
      )}
    </>
  );
};
