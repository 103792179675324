import { gql } from '@apollo/client';

// only public fields on this fragment
export const TRIAL_PUBLIC_FRAGMENT = gql`
  fragment TrialPublicRecord on Trial {
    id
    title
    idOpaque
    patientRecruitingStatus
    commitmentInPerson
    commitmentTelehealth
    commitmentLength
    commitmentIsTelehealth
    commitments {
      id
      displayText
    }
    conditions {
      id
      name
    }
    ageRequirements {
      operator
      value
    }
    genderRequirements
    trialSites {
      id
      recruitingStatus
      location {
        id
        name
        state
        countryCode
        latitude
        longitude
      }
    }
  }
`;
