import { ComponentProps, FC } from 'react';

import { Box, Link as MuiLink, makeStyles } from '@material-ui/core';
import { Element, Link } from 'react-scroll';

import { purple } from 'shared/src/palette/color-system';

import { slugify } from 'pages/condition-category/components/slugify/slugify';

const useStyles = makeStyles(theme => ({
  link: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
    position: 'relative',

    // Use absolute-positioned block for active item underline
    // to avoid jumps when changing active menu item
    '&:after': {
      display: 'block',
      position: 'absolute',
      left: -2,
      right: -2,
      height: 4,
      bottom: 0,
      backgroundColor: purple[500],
      borderRadius: 4,
    },

    // typography hover is pretty specific so we have to override that
    '&&:hover': {
      color: purple[500],
    },
  },
  linkActive: {
    color: purple[500],
    '&:after': {
      content: '""',
    },
  },
}));

export const ContentMenu: FC<{ className?: string; items: string[] }> = ({ className, items }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      alignContent="flex-end"
      flexDirection="column"
      className={className}
    >
      {items.map(name => {
        const slug = slugify(name);
        return (
          <MuiLink
            underline="none"
            color="textSecondary"
            className={classes.link}
            activeClass={classes.linkActive}
            component={Link}
            href={`#${slug}`}
            to={slug}
            key={slug}
            spy // Update active menu element on scroll
            hashSpy // Update hash on scroll
            smooth // Animated scroll when clicking on menu item
          >
            {name}
          </MuiLink>
        );
      })}
    </Box>
  );
};

export const ContentMenuPlaceholder: FC<{ length: number }> = ({ length }) => {
  const classes = useStyles();

  return (
    <>
      {Array.from({ length }, (_, i) => (
        <div className={classes.link} key={i}>
          <br />
        </div>
      ))}
    </>
  );
};

type ElementProps = ComponentProps<typeof Element>;

export const ContentSection: FC<ElementProps> = ({ name, ref, ...props }) => (
  <Element name={slugify(name)} {...props} />
);
