import { FC } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { CarouselWithArrows } from 'shared/src/carousel/carousel';

import {
  PatientStory,
  PatientStoryCard,
} from 'pages/patients/components/patient-story-card/patient-story-card';

export type PatientStoriesProps = {
  stories: PatientStory[];
};

export const PatientStories: FC<PatientStoriesProps> = ({ stories }) => (
  <>
    <Container>
      <Box marginBottom={{ xs: 4, sm: 5 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8}>
            <Typography variant="h2" align="center" gutterBottom>
              Patient Stories
            </Typography>
            <Typography color="textSecondary" align="center">
              We can’t walk the walk, unless we talk the talk. So hear it firsthand, from people who
              have taken that step – and taken control.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Grid container alignItems="stretch">
          {stories.map(story => (
            <Grid item sm={4} xs={12} key={story.sys.id}>
              <PatientStoryCard story={story} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
    <Box display={{ xs: 'block', sm: 'none' }}>
      <CarouselWithArrows
        slides={stories}
        renderSlide={({ slide, key }) => (
          <Box margin={2} marginBottom={4} key={key}>
            <PatientStoryCard elevation={1} story={slide} />
          </Box>
        )}
      />
    </Box>
  </>
);
