import { gql } from '@apollo/client';

import { queryFactory } from 'shared/src/util/query-factory';

import { GetExistingPatientConditions } from './types/queries.generated';

const GET_EXISTING_PATIENT_CONDITIONS_QUERY = gql`
  query GetExistingPatientConditions {
    patient {
      id
      conditions {
        id
      }
    }
  }
`;

export const useGetExistingPatientConditions = queryFactory<GetExistingPatientConditions>(
  GET_EXISTING_PATIENT_CONDITIONS_QUERY
);
